import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from '../globals'; 

@Injectable({
  providedIn: 'root'
})
export class MasterService {
    url=AppSettings.API_ENDPOINT;
    constructor(private http: HttpClient) { }

    getRegistrationDetails(registrationNumber){
        return this.http.get(this.url+'/MotorMaster/GetQueryVehicleData?registrationNo='+registrationNumber);
    }

    saveVehicle(data){      
      const httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      }    
      return this.http.post(this.url+'/MotorMaster/SaveQueryVehicleData',data,httpOptions);      
  }

    GetPopularCityOfRegistration(){
      return this.http.get(this.url+'/MotorMaster/GetPopularCityOfRegistrationAsync');
    }
    GetCityOfRegistration(rtoCode){
      return this.http.get(this.url+'/MotorMaster/GetCityOfRegistration?SearchText='+rtoCode);
    }
    
    GetManufacturer(VehicleType){
      return this.http.get(this.url+'/MotorMaster/GetManufacturer?VehicleType='+VehicleType);
    }
    GetPopularManufacturer(VehicleType){
      return this.http.get(this.url+'/MotorMaster/GetManufacturer?VehicleType='+VehicleType+'&isPopular=1');
    }
           
    GetVehicleModels(ManufacturerId){
      return this.http.get(this.url+'/MotorMaster/GetVehicleModels?ManufacturerId='+ManufacturerId);
    }
    GetPopularVehicleModels(ManufacturerId){
      return this.http.get(this.url+'/MotorMaster/GetVehicleModels?ManufacturerId='+ManufacturerId+'&isPopular=1');
    }
    GetVariant(modelId){
      return this.http.get(this.url+'/MotorMaster/GetVariant?modelId='+modelId);
    } 

    getAddons(vehicleType){
      return this.http.get(this.url+'/MotorAddOn/GetMotorAddOns?vehiclType='+vehicleType);
    } 

    getVoluntryExcess(vehicleType){
      return this.http.get(this.url+'/VoluntryExcess/GetVoluntryExcessByVehicleType/'+vehicleType);
    } 
       
    GetUnPopularCityOfRegistration(searchtext){
      return this.http.get(this.url+'/MotorMaster/GetUnPopularCityOfRegistration?SearchText='+searchtext);
    }

    GetRCBSCityRegistration(searchtext){
      return this.http.get('http://1.22.180.122:4441/api/MasterEntry/RTO/'+ searchtext + '/admin@riskcare.co.in');
    }

    GetUserDetails(userid){  
      
      let headers_object = new HttpHeaders().append('Authorization', 'Bearer '+sessionStorage.getItem('access_token'));
      return this.http.get(`${this.url}/Authenticate/GetUser/` + userid ,{ headers:headers_object});
    }
    geUser(userId:string){
      let headers_object = new HttpHeaders().append('Authorization', 'Bearer '+sessionStorage.getItem('access_token'));
      return this.http.get(this.url+'/Authenticate/GetUser?user='+userId,{
        headers: headers_object
      });
    }

    GetUserProfile(userId:string){
      let headers_object = new HttpHeaders().append('Authorization', 'Bearer '+sessionStorage.getItem('access_token'));
      return this.http.get(this.url+'/Admin/GetUserProfile?userid='+userId,{
        headers: headers_object
      });
    }
}