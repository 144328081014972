import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { HealthservicesService } from 'src/app/services/healthservices.service';

@Component({
  selector: 'app-job-opening',
  templateUrl: './job-opening.component.html',
  styleUrls: ['./job-opening.component.css']
})
export class JobOpeningComponent implements OnInit {
  positionData:any=[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private router:Router,private contactService:HealthservicesService) { }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit():void{
    this.dtOptions={
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      lengthMenu:[5,10,15,20,25,30],
    }
    this.fetchJobPostion();
  }

  fetchJobPostion(){
    let val ={
      flag:'All'
    }
    this.contactService.fetchPosition(val).subscribe((data: any) => { 
       this.positionData = data;
       this.dtTrigger.next("");
      },
      (err:any) => {
        // console.log("positionData err=",err);
        // handleError("Oops something went wrong","Please try again later");
      }
    );
  }

  navigate(data:any){
    this.router.navigateByUrl('/career', { state: data });
  }

}
