import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AppSettings} from '../../../globals';
import { HealthProposalService } from '../../../services/proposal.service';
import { premiumData } from '../../../common/Models/premium.models';
declare var $: any;
@Component({
  selector: 'app-paymentsummery',
  templateUrl: './paymentsummery.component.html',
  styleUrls: ['./paymentsummery.component.css']
})
export class PaymentsummeryComponent implements OnInit {
  quoteId:number;
  productId:number;
  companyId:number;
  planId:number;
  premiunDetails:premiumData;
  image:string;
  productName:string;
  companyName:string;  
  planType:string;
  NetPremium:number;
  ServiceTaxAmount: number;
  FinalPremium: number;
  ServiceTax:number;
  ProposerName:string;
  ProposerMobileNumber:string;
  ProposerEmailID:string;
  constructor(private proposalService:HealthProposalService,private router:ActivatedRoute
    ,private route:Router) { }

  ngOnInit() {
    this.getPremium();
  }

  getPremium(){
    let ProposalId=parseInt(sessionStorage.getItem('proposalId')) ; 
    // this.quoteId=parseInt(localStorage.getItem('quotaionId'));
    //let ProposalId=parseInt(this.router.snapshot.queryParamMap.get('proposalId'));
     this.proposalService.GetPremiumSummaryData(ProposalId).subscribe((res:any)=>{
        //this.premiunDetails=res;
        this.productId=res.ProductID;
        // this.companyId=res.CompanyId;  
        // this.planId=res.PlanTypeId;
        this.image=res.CompanyLogo;
        // this.productName=res.ProductName;
        // this.companyName=res.CompanyName;  
        // this.planType=res.PlanType;
        this.ProposerName=res.SalutationName+' '+res.ProposerName+' '+res.LastName;
        this.ProposerMobileNumber=res.ProposerMobileNumber;
        this.ProposerEmailID=res.ProposerEmailID;
        this.productName=res.ProductName;
        this.planType=res.PlanName;
        this.NetPremium=res.BasicPremium;
        this.ServiceTaxAmount =res.ServiceTaxAmount;
        this.FinalPremium=res.FinalPremium;
        this.ServiceTax=18;
      });
    //this.proposalService.getPremium(policyDetailsId,this.quoteId).subscribe((res:premiumData)=>{
    //   this.premiunDetails=res;
    //   this.productId=res.ProductId;
    //   this.companyId=res.CompanyId;  
    //   this.planId=res.PlanTypeId;
    //   this.image=res.ProductImage;
    //   this.productName=res.ProductName;
    //   this.companyName=res.CompanyName;  
    //   this.planType=res.PlanType;
    //   this.NetPremium=res.NetPremium;
    //   this.ServiceTaxAmount =res.ServiceTaxAmount;
    //   this.FinalPremium=res.FinalPremium;
    //   this.ServiceTax=res.ServiceTax;
    // });

  }

  goToPaymentGetway($event){     
    $event.preventDefault();
    $("#paymentSummary").modal("hide");
   
    let proposalId=sessionStorage.getItem('proposalId'); 
   // let proposalId=this.router.snapshot.queryParamMap.get('proposalId');
    if(proposalId !=null && proposalId !=""){
      if(this.productId==26){
        let paymentUrl=AppSettings.DomainUrlCare+'/proposal/Processproposal?ProposalId='+proposalId+'&ProductId='+this.productId;        
        window.location.href=paymentUrl;
      }else{
        let paymentUrl=AppSettings.DomainUrlHEALTH+'/proposal/Processproposal?ProposalId='+proposalId+'&ProductId='+this.productId;
        //window.open(paymentUrl, '_blank');
        window.location.href=paymentUrl;
      }
    
    
    }
  }

}
