import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { MotorService } from 'src/app/services/motor.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {
  ErrorMsg: string;
  constructor(private router:ActivatedRoute,
    private globalService:GlobalService,private motorService:MotorService ) {}

  ngOnInit() {
    this.ErrorMsg=this.router.snapshot.queryParamMap.get('ErrorMsg');
  }

}
