import { Component, OnInit } from '@angular/core';
import { HealthservicesService } from '../../services/healthservices.service';
import { Renewal } from '../../common/Models/healthMaster.models';
import Swal from 'sweetalert2';

declare var $: any;
@Component({
  selector: 'app-bike-insurance-renewal',
  templateUrl: './bike-insurance-renewal.component.html',
  styleUrls: ['./bike-insurance-renewal.component.css']
})
export class BikeInsuranceRenewalComponent implements OnInit {

  Renewal:any= new Renewal();
  constructor(private healthMasterService:HealthservicesService) { 
    this.Renewal=new Renewal();
    this.Renewal.CustomerName="";
    this.Renewal.Email="";
    this.Renewal.Mobile="";
    this.Renewal.Product="TwoWheeler";
    this.Renewal.Gender="M";
    this.Renewal.PolicyNo="";
    this.Renewal.InsuranceCompanyName="";
    this.Renewal.Request="";
    this.Renewal.PolicyDate="";
  }

  ngOnInit() {
  }
  openNewpopup() {
    $("#Newpopup").modal('show');
    $(".modal-backdrop").removeClass('modal-backdrop fade show');
  }
  openNewpopup1() {
    $("#Newpopup1").modal('show');
    $(".modal-backdrop").removeClass('modal-backdrop fade show');
  }
  openNewpopup2() {
    $("#Newpopup2").modal('show');
    $(".modal-backdrop").removeClass('modal-backdrop fade show');
  }
  Save(){debugger;
    
    if(this.Renewal.CustomerName==""){
      Swal.fire('Please enter Name', '', 'error');
      document.getElementById("myNameIs").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("myNameIs").style.border = "1px solid #A5A5A5";
    }
    if(this.Renewal.Mobile==""){
      Swal.fire('Please enter Mobile Number', '', 'error');
      document.getElementById("myNumber").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("myNumber").style.border = "1px solid #A5A5A5";
    }
    if(this.Renewal.Email==""){
      Swal.fire('Please enter Email Id', '', 'error');
      document.getElementById("myEmail").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("myEmail").style.border = "1px solid #A5A5A5";
    }
    if(this.Renewal.PolicyNo==""){
      Swal.fire('Please enter Policy Number', '', 'error');
      document.getElementById("policyno").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("policyno").style.border = "1px solid #A5A5A5";
    }
    if(this.Renewal.PolicyDate==""){
      Swal.fire('Please enter Policy Date', '', 'error');
      document.getElementById("PolicyDate").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("PolicyDate").style.border = "1px solid #A5A5A5";
    }
    if(this.Renewal.InsuranceCompanyName==""){
      Swal.fire('Please enter Insurance Company Name', '', 'error');
      document.getElementById("nameofinsurancecompany").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("nameofinsurancecompany").style.border = "1px solid #A5A5A5";
    }
    if(this.Renewal.Request==""){
      Swal.fire('Please enter Request', '', 'error');
      document.getElementById("remark").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("remark").style.border = "1px solid #A5A5A5";
    }

    this.healthMasterService.postRenewalData(this.Renewal).subscribe((res:any)=>{   
      if(res==true){
        let data=res;
        Swal.fire('Thank you for providing your details. Our team will contact you soon.', '', 'success');
      }
    });
  }
}
