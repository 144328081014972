import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators,  AbstractControl 
  ,ReactiveFormsModule,FormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { Customer } from '../../Models/Customer';
import { HealthservicesService } from 'src/app/services/healthservices.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registrationportal',
  templateUrl: './registrationportal.component.html',
  styleUrls: ['./registrationportal.component.css']
})
export class RegistrationportalComponent implements OnInit {
  submitted:boolean =false;
  ConfirmPasswordVal:boolean =false;
  CustomerSignUp:any =new Customer();
  FirstName:any;
  LastName:any;
  ConfirmPassword:any;
  matchPassword:boolean =false;
  SignUpform: FormGroup = new FormGroup({
    FirstName: new FormControl(),
    LastName: new FormControl(''),
    MobileNo: new FormControl(''),
    EmailId: new FormControl(''),
    Password: new FormControl(''),
    ConfirmPassword: new FormControl(''),
  });
  constructor(private healthMasterService:HealthservicesService,private formBuilder:FormBuilder, private router:Router ) { }


  ngOnInit() {
  
      this.SignUpform = this.formBuilder.group({
        FirstName: ['', [Validators.required]],
        LastName:['', [Validators.required]],
        MobileNo: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        EmailId: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        Password: ['', [Validators.required]],
        ConfirmPassword: ['', [Validators.required]],
  
        });
  }
  //  matchPassword() {
  //   let password = this.f['Password'].value;
  //   if (this.f['ConfirmPassword'].value.touched || this.f['ConfirmPassword'].value.dirty) {
  //     let verifyPassword = this.f['ConfirmPassword'].value;
  //     if (password != verifyPassword) {
  //       this.f['ConfirmPassword'].value.setErrors({ matchPassword: true });
  //     } else {
  //       return null;
  //     }
  //   }
  // }

  get f(): { [key: string]: AbstractControl; }
  { return this.SignUpform.controls; }

  confirmPassword(){
    if(this.CustomerSignUp.password !==this.ConfirmPassword){
       this.matchPassword = true;
    // Swal.fire('Passsword and Confirm Password does not match', '', 'error');
    }
    else {
      this.matchPassword = false;
    }
  }

  async SignUp(){
    debugger;
    this.submitted =true;
    console.log(this.f['ConfirmPassword'].errors);
    console.log(this.SignUpform);
    if(this.SignUpform.invalid){
      return;
    }
    else if(this.f['Password'].value !==this.f['ConfirmPassword'].value){
      //this.confirmPassword()
       Swal.fire('Passsword and Confirm Password does not match', '', 'error');
  
    }
    else{
      this.CustomerSignUp.fullName=this.FirstName +" " +this.LastName;
      //this.CustomerSignUp.flag="SignUp"

      //alert(JSON.stringify(this.CustomerSignUp));

      //console.log(this.CustomerSignUp);

      var val ={
        fullName: this.CustomerSignUp.fullName,
        emailId: this.CustomerSignUp.emailId,
        mobileNo:this.CustomerSignUp.mobileNo,
        password: this.CustomerSignUp.password,
        flag: "SignUp",
      }

      await this.healthMasterService.SignUpCustomer(val).then(
        (Data:any)=>{
          //alert(Data);
          if(Data.length >0){

           
            // Swal.fire("", Data, "success").then(function(){
            //   this.router.navigate(['/login']);
            // });

            Swal.fire({
              title: Data,
              text: '',
              icon: 'success',
             // showCancelButton: true,
              confirmButtonColor: '#3085d6',
             // cancelButtonColor: '#d33',
              confirmButtonText: 'Success'
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(['/login']);
              }
            })

           // this.onReset();
           
          }
        } ,
        (error) => console.log("ApIerror=",error)
      )
    }
  }

  onReset(){
    this.FirstName="";
    this.LastName="";
    this.ConfirmPassword="";
    this.CustomerSignUp.fullName="";
    this.CustomerSignUp.mobileNo="";
    this.CustomerSignUp.password="";
    this.CustomerSignUp.emailId="";
   // this.CustomerSignUp ="";
  }


}
