import { Component, OnInit } from '@angular/core';
import { HealthservicesService } from '../../services/healthservices.service';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-rejected-pos',
  templateUrl: './rejected-pos.component.html',
  styleUrls: ['./rejected-pos.component.css']
})
export class RejectedPosComponent implements OnInit {

  constructor(private service: HealthservicesService) { }
  userProfileList: any;
  userProfileData: any;
  dashboard: any;
  userBankData: any;
  closeResult: string = '';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userId: string = "";
  remark: string = "";
  approved: string = "";
  rejected: string = "";
  onhold: string = "";
  totalPOS: string = "";
  imagePath: any;
  ValidOrNotProp: string = "";
  Action: string = "";
IsPersonalPDF: boolean=false;
  IsPANPDF: boolean=false;
  IsAadharfrontPDF: boolean=false;
  IsAadharbackPDF: boolean=false;
  IsBankPDF: boolean=false;
  IsEduPDF: boolean=false;
  ngOnInit(): void {
    this.imagePath = this.service.imagePath;
    this.userProfileData = {
      AadharImageBack: "",
      AadharImageFront: "",
      AadharNo: "",
      Address1: "",
      Address2: "",
      AlternateNumber: "",
      City: "",
      CreatedBy: "",
      CreatedByIP: "",
      CreatedDate: "",
      DOB: "",
      EducationalImage: "",
      EducationalQualification: "",
      Emailid: "",
      FirstName: "",
      GSTINNumber: "",
      Gender: "",
      IsActive: "",
      LastName: "",
      MaritialStatus: "",
      MiddleName: "",
      ModifyBy: "",
      ModifyByIP: "",
      ModifyDate: "",
      PANImagePath: "",
      PANNumber: "",
      PinCode: "",
      ProfileImagePath: "",
      RequestId: "",
      State: "",
      Status: "",
      UserId: "",
      UserMasterId: "",
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
    this.getAcceptedRequest();
  }
  getAcceptedRequest() {
    var val = {
      Criteria: "Status",
      Value: "User Not Verified"
    }
    this.service.Select_UserProfileBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userProfileList = data;
        this.dtTrigger.next();
      }
      else {
        // Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  // open(content: any, data: any) {
  //   debugger;
  //   this.userId = data.UserId;
  //   let result = this.getUserProfileDetails(this.userId);
  //   this.getBankDetails(this.userId);

  //   this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });

  // }
  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }
  getUserProfileDetails(userid: string) {
    var val = {
      Criteria: "UserId",
      Value: userid
    }
    this.service.Select_UserProfileBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userProfileData = data[0];
        
        if(this.userProfileData.ProfileImagePath.substring(this.userProfileData.ProfileImagePath.length - 3)=='pdf'){
          this.IsPersonalPDF=true;
        }
        else{
          this.IsPersonalPDF=false;
        }
        
        if(this.userProfileData.PANImagePath.substring(this.userProfileData.PANImagePath.length - 3)=='pdf'){
          this.IsPANPDF=true;
        }
        else{
          this.IsPANPDF=false;
        }
        
        if(this.userProfileData.AadharImageFront.substring(this.userProfileData.AadharImageFront.length - 3)=='pdf'){
          this.IsAadharfrontPDF=true;
        }
        else{
          this.IsAadharfrontPDF=false;
        }
        
        if(this.userProfileData.AadharImageBack.substring(this.userProfileData.AadharImageBack.length - 3)=='pdf'){
          this.IsAadharbackPDF=true;
        }
        else{
          this.IsAadharbackPDF=false;
        }
        
        if(this.userProfileData.EducationalImage.substring(this.userProfileData.EducationalImage.length - 3)=='pdf'){
          this.IsEduPDF=true;
        }
        else{
          this.IsEduPDF=false;
        }

        return this.userProfileData;
      }
      else {
        // Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  getUserPANDetails(userid: string) {
    var val = {
      Criteria: "UserId",
      Value: userid
    }
    this.service.Select_UserProfileBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userProfileData = data[0];
      }
      else {
        // Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  getBankDetails(userid: string) {
    var val = {
      Criteria: "CustomerId",
      Value: userid
    }
    this.service.Select_UserBankDetailBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userBankData = data[0];
        
        if(this.userBankData.BankProof.substring(this.userBankData.BankProof.length - 3)=='pdf'){
          this.IsBankPDF=true;
        }
        else{
          this.IsBankPDF=false;
        }
        
      }
      else {
        // Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  getVerificationData(user: any) {
    debugger;
    this.getUserProfileDetails(user);
    this.getBankDetails(user);
  }
}
