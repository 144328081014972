import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import Validation from '../../services/validation';
import { HealthservicesService} from '../../services/healthservices.service' 
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-details',
  templateUrl: './add-details.component.html',
  styleUrls: ['./add-details.component.css']
})
export class AddDetailsComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private service: HealthservicesService, private router: Router) { }
  form: FormGroup = new FormGroup({});
  Gender: any;
  Email: any = localStorage.getItem("EmailId");;
  FullName: string = "";
  submitted = false;
  Pincode: string = "";
  PAN: string = "";
  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {

        FullName: ['', [Validators.required]],
        Email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        Gender: ['', [Validators.required]],
        Pincode: ['', [Validators.required, Validators.pattern('[0-9]{6}')]],
        PAN: ['', [Validators.required, Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      }



    );
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  addDetails() {
    debugger;
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let PANNo = new RegExp("[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}");
    if (!PANNo.test(this.PAN)) {
      Swal.fire("", "Please enter correct PAN Number", "error");
      return;
    }
    var val = {
      IsActive: true,
      UserId: localStorage.getItem("UserId"),
      FirstName: this.FullName,
      PinCode: this.Pincode,
      Gender: this.Gender,
      Email: this.Email,
      PANNumber: this.PAN
    }
    this.service.CreateUserProfile(val).subscribe((data: any) => {
      debugger;
      // this.otpBox = "Y";
      // this.phoneBox = "N";
      if (data.Status == "SUCCESS") {
        // this.router.navigate(["/pos-details"]);
        Swal.fire("", "Personal details added successfully", "success")
        localStorage.setItem("Pincode", this.Pincode);
        localStorage.setItem("PANNumber", this.PAN);
        window.open("pos-details","_self");
      }
      else {
        Swal.fire("", data.ErrorDescription, "error")
      }
    });
  }
}
