import { Component, OnInit } from '@angular/core';
import { HealthservicesService } from '../../services/healthservices.service';
import { ContactUs } from '../../common/Models/healthMaster.models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  ContactUs:any= new ContactUs();
  constructor(private healthMasterService:HealthservicesService) { 
    this.ContactUs=new ContactUs();
    this.ContactUs.Name="";
    this.ContactUs.Email="";
    this.ContactUs.Message="";
  }
  
  ngOnInit(): void {
  }
 
  Save(){debugger;
    // var val = 
    // {
    //   Name: this.ContactUs.Name,
    // Email: this.ContactUs.Email,
    // Mobile: this.ContactUs.Mobile,
    // Message: this.ContactUs.Message

    // }
    this.healthMasterService.postContact(this.ContactUs).subscribe((res:any)=>{   
      if(res==true){
        let data=res;
        Swal.fire('Thank you for providing your details. Our team will contact you soon.', '', 'success');
      }
      
    });
  }

}
