import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HealthservicesService } from '../../services/healthservices.service';
import { RCBSData, images } from '../../common/Models/proposal.models';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { isRegExp } from 'util';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
@Component({
  selector: 'app-posp-pending-request',
  templateUrl: './posp-pending-request.component.html',
  styleUrls: ['./posp-pending-request.component.css']
})
export class PospPendingRequestComponent implements OnInit {

  constructor(private service: HealthservicesService, private formBuilder: FormBuilder, private route: Router) { }
  userProfileList: any;
  userProfileData: any;
  dashboard: any;
  userBankData: any;
  closeResult: string = '';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userId: string = "";
  PersonalDetailsRemark: string = "0";
  approved: string = "0";
  rejected: string = "0";
  onhold: string = "0";
  totalPOS: string = "0";
  imagePath: any;
  ValidOrNotProp: string = "";
  PersonalDetailsAction: string = "";
  PANDetailsAction: string = "";
  AadharDetailsAction: string = "";
  BankDetailsAction: string = "";
  EducationalDetailsAction: string = "";
  PANDetailsRemark: string = "0";
  AadharDetailsRemark: string = "0";
  BankDetailsRemark: string = "0";
  EducationalDetailsRemark: string = "0";
  PersonalEdit: boolean = false;
  PANEdit: boolean = false;
  BankEdit: boolean = false;
  EducationEdit: boolean = false;
  AadharEdit: boolean = false;
  ShowHidePersonalRemark: boolean = false;
  ShowHidePANRemark: boolean = false;
  ShowHideAadharRemark: boolean = false;
  ShowHideBankRemark: boolean = false;
  ShowHideEducationRemark: boolean = false;
  PersonalForm: FormGroup = new FormGroup({});
  PANForm: FormGroup = new FormGroup({});
  AdharForm: FormGroup = new FormGroup({});
  BankForm: FormGroup = new FormGroup({});
  EducationalForm: FormGroup = new FormGroup({});
  submitted = false;
  formData = new FormData();
  vformData = new FormData();
  RCBSData = new RCBSData();
  images : images[] = [];
  IsPersonalPDF: boolean=false;
  IsPANPDF: boolean=false;
  IsAadharfrontPDF: boolean=false;
  IsAadharbackPDF: boolean=false;
  IsBankPDF: boolean=false;
  IsEduPDF: boolean=false;
  emailPattern: string = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";
  ngOnInit(): void {
    this.imagePath = this.service.imagePath;
    this.userBankData = {
      BankProof: "",
      BankName: "",
      AccountHolderName: "",
      AccountNumber: "",
      IFSCCode: ""

    }
    this.userProfileData = {
      AadharImageBack: "",
      AadharImageFront: "",
      AadharNo: "",
      Address1: "",
      Address2: "",
      AlternateNumber: "",
      City: "",
      CreatedBy: "",
      CreatedByIP: "",
      CreatedDate: "",
      DOB: "",
      EducationalImage: "",
      EducationalQualification: "",
      Emailid: "",
      FirstName: "",
      GSTINNumber: "",
      Gender: "",
      IsActive: "",
      LastName: "",
      MaritialStatus: "",
      MiddleName: "",
      ModifyBy: "",
      ModifyByIP: "",
      ModifyDate: "",
      PANImagePath: "",
      PANNumber: "",
      PinCode: "",
      ProfileImagePath: "",
      RequestId: "",
      State: "",
      Status: "",
      UserId: "",
      UserMasterId: "",
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [5, 10, 25],
      processing: true
    };

    this.PersonalForm = this.formBuilder.group(
      {
        PersonalDetailsAction: ['', [Validators.required]],
        PersonalDetailsRemark: ['0', [Validators.required]]
      });
    this.PANForm = this.formBuilder.group(
      {
        // PANCardNumber: ['', [Validators.required, Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
        // DateOfBirth: ['', [Validators.required]],
        // PANImg: ['', [Validators.required,]]
        PANDetailsAction: ['', [Validators.required]],
        PANDetailsRemark: ['0', [Validators.required]]
      });
    this.AdharForm = this.formBuilder.group(
      {
        // AadharCardNumber: ['', [Validators.required, Validators.pattern("^[0-9]{4}[0-9]{4}[0-9]{4}$")]],
        // AadharAddress: ['', [Validators.required]],
        // Pincode: ['', [Validators.required]],
        // City: ['', [Validators.required]],
        // State: ['', [Validators.required]],
        // AadharImageFront: ['', [Validators.required]],
        // AadharImageBack: ['', [Validators.required]],
        AadharDetailsAction: ['', [Validators.required]],
        AadharDetailsRemark: ['0', [Validators.required]]
      });
    this.BankForm = this.formBuilder.group(
      {
        // AccountNumber: ['', [Validators.required, Validators.pattern("[0-9]{9,18}")]],
        // IFSCCode: ['', [Validators.required, Validators.pattern("^[A-Z]{4}0[A-Z0-9]{6}$")]],
        // BankName: ['', [Validators.required]],
        // BankBranchName: ['', [Validators.required]],
        // AccountHolderName: ['', [Validators.required]],
        // ChqImg: ['', [Validators.required]]
        BankDetailsAction: ['', [Validators.required]],
        BankDetailsRemark: ['0', [Validators.required]],

      });
    this.EducationalForm = this.formBuilder.group(
      {
        EducationalDetailsAction: ['', [Validators.required]],
        EducationalDetailsRemark: ['0', [Validators.required]]

      });

      // this.GenerateDashboard();
    this.getPendingRequest();
  }
  get f(): { [key: string]: AbstractControl } {
    return this.PersonalForm.controls;
  }
  get p(): { [key: string]: AbstractControl } {
    return this.PANForm.controls;
  }
  get a(): { [key: string]: AbstractControl } {
    return this.AdharForm.controls;
  }
  get b(): { [key: string]: AbstractControl } {
    return this.BankForm.controls;
  }
  get e(): { [key: string]: AbstractControl } {
    return this.EducationalForm.controls;
  }
  fnShowHidePersonalRemark() {
    debugger;
    if (this.PersonalDetailsAction == "Hold" || this.PersonalDetailsAction == "Invalid") {
      this.ShowHidePersonalRemark = true;
    }
    else {
      this.ShowHidePersonalRemark = false;
    }
  }
  fnShowHidePANRemark() {
    if (this.PANDetailsAction == "Hold" || this.PANDetailsAction == "Invalid") {
      this.ShowHidePANRemark = true;
    }
    else {
      this.ShowHidePANRemark = false;
    }
  }
  fnShowHideAadharRemark() {
    if (this.AadharDetailsAction == "Hold" || this.AadharDetailsAction == "Invalid") {
      this.ShowHideAadharRemark = true;
    }
    else {
      this.ShowHideAadharRemark = false;
    }
  }
  fnShowHideBANKRemark() {
    if (this.BankDetailsAction == "Hold" || this.BankDetailsAction == "Invalid") {
      this.ShowHideBankRemark = true;
    }
    else {
      this.ShowHideBankRemark = false;
    }
  }
  fnShowHideEducationRemark() {
    if (this.EducationalDetailsAction == "Hold" || this.EducationalDetailsAction == "Invalid") {
      this.ShowHideEducationRemark = true;
    }
    else {
      this.ShowHideEducationRemark = false;
    }
  }
  editPersonal() {
    this.PersonalEdit = true;
  }
  closeEdit() {
    this.PersonalEdit = false;
  }
  editPAN() {
    debugger;
    this.PANEdit = true;
    var now = this.userProfileData.DateOfBirth.split("-");

    var f = new Date(now[2], now[1] - 1, now[0])
    now = f;
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);

    var today = now.getFullYear() + "-" + (month) + "-" + (day);
    this.userProfileData.DateOfBirth = today;
  }
  closePANEdit() {
    this.PANEdit = false;
  }
  editAadhar() {
    this.AadharEdit = true;
  }
  closeAadharEdit() {
    this.AadharEdit = false;
  }
  editBank() {
    this.BankEdit = true;
  }
  closeBankEdit() {
    this.BankEdit = false;
  }
  editEducation() {
    this.EducationEdit = true;
  }
  closeEducationEdit() {
    this.EducationEdit = false;
  }
  getPendingRequest() {
    var val = {
      Criteria: "Status",
      Value: "Pending"
    }
    this.service.Select_UserProfileBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userProfileList = data;
        this.dtTrigger.next();
      }
      else {
        // Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  // open(content: any, data: any) {
  //   debugger;
  //   this.userId = data.UserId;
  //   let result = this.getUserProfileDetails(this.userId);
  //   this.getBankDetails(this.userId);

  //   this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });

  // }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }
  getUserProfileDetails(userid: string) {
    var val = {
      Criteria: "UserId",
      Value: userid
    }
    this.service.Select_UserProfileBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userProfileData = data[0];
        
        if(this.userProfileData.ProfileImagePath.substring(this.userProfileData.ProfileImagePath.length - 3)=='pdf'){
          this.IsPersonalPDF=true;
        }
        else{
          this.IsPersonalPDF=false;
        }
        
        if(this.userProfileData.PANImagePath.substring(this.userProfileData.PANImagePath.length - 3)=='pdf'){
          this.IsPANPDF=true;
        }
        else{
          this.IsPANPDF=false;
        }
        
        if(this.userProfileData.AadharImageFront.substring(this.userProfileData.AadharImageFront.length - 3)=='pdf'){
          this.IsAadharfrontPDF=true;
        }
        else{
          this.IsAadharfrontPDF=false;
        }
        
        if(this.userProfileData.AadharImageBack.substring(this.userProfileData.AadharImageBack.length - 3)=='pdf'){
          this.IsAadharbackPDF=true;
        }
        else{
          this.IsAadharbackPDF=false;
        }
        
        if(this.userProfileData.EducationalImage.substring(this.userProfileData.EducationalImage.length - 3)=='pdf'){
          this.IsEduPDF=true;
        }
        else{
          this.IsEduPDF=false;
        }

        return this.userProfileData;
      }
      else {
        // Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  getUserPANDetails(userid: string) {
    var val = {
      Criteria: "UserId",
      Value: userid
    }
    this.service.Select_UserProfileBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userProfileData = data[0];
      }
      else {
        // Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  getBankDetails(userid: string) {
    var val = {
      Criteria: "CustomerId",
      Value: userid
    }
    this.service.Select_UserBankDetailBy_Id(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.userBankData = data[0];
        
        if(this.userBankData.BankProof.substring(this.userBankData.BankProof.length - 3)=='pdf'){
          this.IsBankPDF=true;
        }
        else{
          this.IsBankPDF=false;
        }
        
      }
      else {
        // Swal.fire("", data.ErrorDescription, "error");
      }
    });

  }
  submitPersonalDataStatus() {
    debugger;
    this.submitted = true;
    let email = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
    let number = new RegExp("^((\\+91-?)|0)?[0-9]{10}$");
    if (this.userProfileData.FirstName == "") {
      // Swal.fire("", "Please enter Full Name", "error");
      return;
    }
    else if (this.userProfileData.Emailid == "") {
      Swal.fire("", "Please enter Email Id", "error");
      return;
    }

    else if (!email.test(this.userProfileData.Emailid)) {
      Swal.fire("", "Please enter correct Email Id", "error");
      return;
    }
    // else if (this.userProfileData.AlternateNumber == "") {
    //   Swal.fire("", "Please enter Alternate Number", "error");
    //   return;
    // }
    // else if (!number.test(this.userProfileData.AlternateNumber)) {
    //   Swal.fire("", "Please enter correct Alternate Number", "error");
    //   return;
    // }
    else {
      if (this.PersonalForm.invalid) {
        return;
      }
      debugger;
      var val = {
        CreatedDate: null,
        ModifyDate: null,
        IsActive: true,
        Userid: this.userProfileData.UserId,
        Status: "Active",
        DocumentType: "User Profile",
        ValidOrNot: this.PersonalDetailsAction,
        Remarks: this.PersonalDetailsRemark,
        CreatedBy: "",
        CreatedByIP: "",
        ModifyBy: "",
        ModifyByIP: ""
      }
      this.service.CreateUserStatusHistory(val).subscribe((data: any) => {
        debugger;
        if (data.Status == "SUCCESS") {
          Swal.fire("", "Status Updated Successfully", "success");
          this.CreatePersonalDetails();
        }
        else {
          // Swal.fire("", data.ErrorDescription, "error");
        }
      });
    }
  }
  GenerateDashboard() {
    var val = {
      Criteria: "",
      Value: ""
    }
    this.service.GenerateDashboard(val).subscribe((data: any) => {
      debugger;
      if (data.length > 0) {
        this.dashboard = data
        for (let i = 0; i < data.length; i++) {
          if (data[i].Status == "User Verified") {
            this.approved = data[i].NoOfUsers;
          }
          else if (data[i].Status == "User Not Verified") {
            this.rejected = data[i].NoOfUsers;
          }
          else {
            this.onhold = data[i].NoOfUsers;
          }
        }
        this.totalPOS = data[0].TotalCount;
      }
      else {
        // Swal.fire("", data.ErrorDescription, "error");
      }
    });
  }
  submitPANDataStatus() {
    debugger;
    this.submitted = true;
    let PAN = new RegExp("[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}");
    if (this.userProfileData.PANNumber == "") {
      Swal.fire("", "Please enter PAN Number", "error");
      return;
    }

    else if (!PAN.test(this.userProfileData.PANNumber)) {
      Swal.fire("", "Please enter correct PAN Number", "error");
      return;
    }
    else if (this.userProfileData.DateOfBirth == "") {
      Swal.fire("", "Please enter Date Of Birth", "error");
      return;
    }

    else {
      if (this.PANForm.invalid) {
        return;
      }
      var val = {
        CreatedDate: null,
        ModifyDate: null,
        IsActive: true,
        Userid: this.userProfileData.UserId,
        Status: "Active",
        DocumentType: "PAN",
        ValidOrNot: this.PANDetailsAction,
        Remarks: this.PANDetailsRemark,
        CreatedBy: "",
        CreatedByIP: "",
        ModifyBy: "",
        ModifyByIP: ""
      }
      this.service.CreateUserStatusHistory(val).subscribe((data: any) => {
        debugger;
        if (data.Status == "SUCCESS") {
          Swal.fire("", "Status Updated Successfully", "success");
          this.CreatePANDetails();
        }
        else {
          // Swal.fire("", data.ErrorDescription, "error");
        }
      });
    }
  }
  submitAadharDataStatus() {
    debugger;
    this.submitted = true;
    this.submitted = true;
    let Aadhar = new RegExp("^[0-9]{4}[0-9]{4}[0-9]{4}$");
    let number = new RegExp("^[0-9]{6}$");
    if (this.userProfileData.AadharNo == "") {
      Swal.fire("", "Please enter Aadhaar Number", "error");
      return;
    }

    else if (!Aadhar.test(this.userProfileData.AadharNo)) {
      Swal.fire("", "Please enter correct Aadhaar Number", "error");
      return;
    }
    else if (this.userProfileData.Address1 == "") {
      Swal.fire("", "Please enter Address line 1", "error");
      return;
    }
    else if (this.userProfileData.State == "") {
      Swal.fire("", "Please enter State", "error");
      return;
    }
    else if (this.userProfileData.City == "") {
      Swal.fire("", "Please enter City", "error");
      return;
    }
    else if (this.userProfileData.PinCode == "") {
      Swal.fire("", "Please enter PinCode", "error");
      return;
    }
    else if (!number.test(this.userProfileData.PinCode)) {
      Swal.fire("", "Please enter correct PinCode", "error");
      return;
    }
    else {
      if (this.AdharForm.invalid) {
        return;
      }
      var val = {
        CreatedDate: null,
        ModifyDate: null,
        IsActive: true,
        Userid: this.userProfileData.UserId,
        Status: "Active",
        DocumentType: "Aadhar",
        ValidOrNot: this.AadharDetailsAction,
        Remarks: this.AadharDetailsRemark,
        CreatedBy: "",
        CreatedByIP: "",
        ModifyBy: "",
        ModifyByIP: ""
      }
      this.service.CreateUserStatusHistory(val).subscribe((data: any) => {
        debugger;
        if (data.Status == "SUCCESS") {
          Swal.fire("", "Status Updated Successfully", "success");
          this.CreateUserAadharDetails();
        }
        else {
          // Swal.fire("", data.ErrorDescription, "error");
        }
      });
    }
  }
  submitEducationDataStatus() {
    debugger;;
    this.submitted = true;
    if (this.EducationalForm.invalid) {
      return;
    }

    var val = {
      CreatedDate: null,
      ModifyDate: null,
      IsActive: true,
      Userid: this.userProfileData.UserId,
      Status: "Active",
      DocumentType: "Educational",
      ValidOrNot: this.EducationalDetailsAction,
      Remarks: this.EducationalDetailsRemark,
      CreatedBy: "",
      CreatedByIP: "",
      ModifyBy: "",
      ModifyByIP: ""
    }
    this.service.CreateUserStatusHistory(val).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        Swal.fire("", "Status Updated Successfully", "success");
        this.CreateUserEducationalDetail();
      }
      else {
        // Swal.fire("", data.ErrorDescription, "error");
      }
    });
  }
  submitBankDataStatus() {
    this.submitted = true;
    let number = new RegExp("[0-9]{9,18}");
    let IFSCCode = new RegExp("^[a-zA-Z]{4}0[a-zA-Z0-9]{6}$");
    if (this.userBankData.BankName == "") {
      Swal.fire("", "Please enter Bank Name", "error");
      return;
    }

    else if (this.userBankData.AccountHolderName == "") {
      Swal.fire("", "Please enter Account Holder Name", "error");
      return;
    }
    else if (this.userBankData.AccountNumber == "") {
      Swal.fire("", "Please enter Account Number", "error");
      return;
    }
    else if (!number.test(this.userBankData.AccountNumber)) {
      Swal.fire("", "Please enter correct Account Number", "error");
      return;
    }
    else if (this.userBankData.IFSCCode == "") {
      Swal.fire("", "Please enter IFSC Code", "error");
      return;
    }
    else if (!IFSCCode.test(this.userBankData.IFSCCode)) {
      Swal.fire("", "Please enter correct IFSC Code", "error");
      return;
    }

    else {
      if (this.BankForm.invalid) {
        return;
      }
      var val = {
        CreatedDate: null,
        ModifyDate: null,
        IsActive: true,
        Userid: this.userProfileData.UserId,
        Status: "Active",
        DocumentType: "Bank",
        ValidOrNot: this.BankDetailsAction,
        Remarks: this.BankDetailsRemark,
        CreatedBy: "",
        CreatedByIP: "",
        ModifyBy: "",
        ModifyByIP: ""
      }
      this.service.CreateUserStatusHistory(val).subscribe((data: any) => {
        debugger;
        if (data.Status == "SUCCESS") {
          Swal.fire("", "Status Updated Successfully", "success");
          this.CreateUserBankDetails();
        }
        else {
          Swal.fire("", data.ErrorDescription, "error");
        }
      });
    }
  }
  getVerificationData(user: any) {
    debugger;
    this.getUserProfileDetails(user);
    this.getBankDetails(user);
  }
  redirectToApproved() {
    debugger;
    this.route.navigate(['accepted-posp']);
  }
  redirectToRejected() {
    debugger;
    this.route.navigate(['rejected-posp']);
  }
  redirectToOnHold() {
    debugger;
    this.route.navigate(['onhold-posp']);
  }
  redirectToTotalPOSP() {
    debugger;
    this.route.navigate(['total-posp']);
  }
  CreatePersonalDetails() {
    debugger;
    this.submitted = true;
    this.formData = new FormData();

    this.formData.append('UserId', this.userProfileData.UserId);
    this.formData.append('GSTINNumber', "");
    this.formData.append('FirstName', this.userProfileData.FirstName);
    this.formData.append('LastName', this.userProfileData.LastName);
    this.formData.append('MiddleName', this.userProfileData.MiddleName);
    this.formData.append('MaritialStatus', this.userProfileData.MaritialStatus)
    this.formData.append('AlternateNumber', this.userProfileData.AlternateNumber)
    this.formData.append('ChqProfileImg', this.userProfileData.ProfileImagePath.split('/').pop())
    this.formData.append('flag', 'update')
    this.vformData = this.formData;
    // if (this.PersonalForm.invalid) {
    //   return;
    // }

    this.service.UpdateUserProfile(this.vformData).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        if(this.PersonalDetailsAction=="Valid" && this.PANDetailsAction=="Valid" && this.AadharDetailsAction=="Valid"
        && this.BankDetailsAction=="Valid" && this.EducationalDetailsAction=="Valid"){
          this.PushPOSPInRCBS();
        }
        Swal.fire("", "Personal details added successfully", "success");
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
        // this.activate_tab2();
      }
    });
  }
  CreatePANDetails() {
    debugger;
    this.submitted = true;
    this.formData = new FormData();
    this.formData.append('UserId', this.userProfileData.UserId);
    this.formData.append('PANNumber', this.userProfileData.PANNumber)
    this.formData.append('DOB', this.userProfileData.DateOfBirth)
    this.formData.append('flag', 'update')
    this.formData.append('ChqPanImg', this.userProfileData.PANImagePath.split('/').pop())
    this.vformData = this.formData;
    this.service.CreateUserPANDetails(this.vformData).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        if(this.PersonalDetailsAction=="Valid" && this.PANDetailsAction=="Valid" && this.AadharDetailsAction=="Valid"
        && this.BankDetailsAction=="Valid" && this.EducationalDetailsAction=="Valid"){
          this.PushPOSPInRCBS();
        }
        Swal.fire("", "PAN details added successfully", "success")

      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
        // this.activate_tab3();
      }
    });
  }
  CreateUserAadharDetails() {
    debugger;
    this.submitted = true;
    // if (this.AdharForm.invalid) {
    //   return;
    // }
    this.formData = new FormData();
    this.formData.append('UserId', this.userProfileData.UserId);
    this.formData.append('AadharNo', this.userProfileData.AadharNo)
    this.formData.append('Address1', this.userProfileData.Address1)
    this.formData.append('City', this.userProfileData.City)
    this.formData.append('State', this.userProfileData.State)
    this.formData.append('Pincode', this.userProfileData.PinCode)
    this.formData.append('ChqAadharImg', this.userProfileData.AadharImageFront.split('/').pop())
    this.formData.append('ChqAadharbkImg', this.userProfileData.AadharImageBack.split('/').pop())
    this.formData.append('flag', 'update')
    this.vformData = this.formData;
    this.service.CreateUserAadharDetails(this.vformData).subscribe((data: any) => {
      debugger;

      if (data.Status == "SUCCESS") {
        if(this.PersonalDetailsAction=="Valid" && this.PANDetailsAction=="Valid" && this.AadharDetailsAction=="Valid"
        && this.BankDetailsAction=="Valid" && this.EducationalDetailsAction=="Valid"){
          this.PushPOSPInRCBS();
        }
        Swal.fire("", "Aadhaar details added successfully", "success")
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
        // this.activate_tab4();
      }
    });
  }
  CreateUserBankDetails() {
    debugger;
    // this.submitted = true;
    // if (this.BankForm.invalid) {
    //   return;
    // }
    this.formData = new FormData();
    this.formData.append('UserId', this.userBankData.UserId);
    this.formData.append('AccountHolderName', this.userBankData.AccountHolderName);
    this.formData.append('AccountNumber', this.userBankData.AccountNumber);
    this.formData.append('BankBranchName', this.userBankData.BankBranchName);
    this.formData.append('BankName', this.userBankData.BankName);
    this.formData.append('CreatedBy', this.userBankData.UserId);
    this.formData.append('CreatedByIP', "");
    this.formData.append('IFSCCode', this.userBankData.IFSCCode);
    this.formData.append('IsActive', "true");
    this.formData.append('ModifyBy', "Admin");
    this.formData.append('ModifyByIP', "");
    this.formData.append('CustomerId', this.userBankData.UserId);
    this.formData.append('Status', '1');
    this.formData.append('ChqImg', this.userBankData.BankProof.split('/').pop());
    this.formData.append('flag', 'update');
    this.vformData = this.formData;

    this.service.CreateUserBankDetail(this.vformData).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        if(this.PersonalDetailsAction=="Valid" && this.PANDetailsAction=="Valid" && this.AadharDetailsAction=="Valid"
        && this.BankDetailsAction=="Valid" && this.EducationalDetailsAction=="Valid"){
          this.PushPOSPInRCBS();
        }
        Swal.fire("", "Bank details added successfully", "success")
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
        // this.activate_tab5();
      }
    });
  }
  CreateUserEducationalDetail() {
    debugger;
    this.submitted = true;
    // if (this.EducationalForm.invalid) {
    //   return;
    // }
    this.formData = new FormData();
    this.formData.append('UserId', this.userProfileData.UserId);
    this.formData.append('EducationalQualification', this.userProfileData.EducationalQualification);
    this.formData.append('ChqEduImg', this.userProfileData.EducationalImage.split('/').pop());
    this.formData.append('flag', 'update');
    this.vformData = this.formData;
    this.service.CreateUserEducationalDetail(this.vformData).subscribe((data: any) => {
      debugger;
      if(this.PersonalDetailsAction=="Valid" && this.PANDetailsAction=="Valid" && this.AadharDetailsAction=="Valid"
        && this.BankDetailsAction=="Valid" && this.EducationalDetailsAction=="Valid"){
          this.PushPOSPInRCBS();
        }
      if (data.Status == "SUCCESS") {
        // if(this.PersonalDetailsAction=="Valid" && this.PANDetailsAction=="Valid" && this.AadharDetailsAction=="Valid"
        // && this.BankDetailsAction=="Valid" && this.EducationalDetailsAction=="Valid"){
        //   this.PushPOSPInRCBS();
        // }
        Swal.fire("", "Education details added successfully", "success")
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
        // this.activate_tab6();
      }
    });
  }

  PushPOSPInRCBS(){
    debugger;
    this.RCBSData.customerName = this.userProfileData.FirstName;
    this.RCBSData.address =  this.userProfileData.Address1+this.userProfileData.Address2;
    this.RCBSData.dob = this.userProfileData.DateOfBirth;
    this.RCBSData.landline =  '';
    this.RCBSData.mobile =  this.userProfileData.UserId;
    this.RCBSData.alternateMobile =  this.userProfileData.AlternateNumber;
    this.RCBSData.emailID =  this.userProfileData.Emailid;
    this.RCBSData.panCard =  this.userProfileData.PANNumber;
    this.RCBSData.aadhar =  this.userProfileData.AadharNo;
    this.RCBSData.parentOrChild =  '';
    this.RCBSData.rmName =  '';
    this.RCBSData.branch =  '';
    this.RCBSData.lobName =  '';
    this.RCBSData.tdsPercent =  '';
    this.RCBSData.fromDate =  '';
    this.RCBSData.toDate =  '';
    this.RCBSData.subPartnerName =  '';
    this.RCBSData.educationQualification =  this.userProfileData.EducationalQualification;
    this.RCBSData.accountName =  this.userBankData.AccountHolderName;
    this.RCBSData.accountNumber =  this.userBankData.AccountNumber;
    this.RCBSData.bankName =  this.userBankData.BankName;
    this.RCBSData.ifscCode =  this.userBankData.IFSCCode;
    this.RCBSData.bankBranchAddress =  '';
    this.RCBSData.posUserType =  '';
    this.RCBSData.assignProducts =  '';
    this.RCBSData.productPlan =  '';
    this.RCBSData.category =  '';
    this.RCBSData.isActive =  'true';
    this.RCBSData.createdBy =  '';
    let ftpdomain='ftp://uat.bimaraja.com:6761/UserDocument/';
    this.images.push({ uniqueID : this.userProfileData.UserId+'_'+this.userProfileData.ProfileImagePath.split('/').pop(), attachmentID : '0', businessCode : '0', 
    fileName : this.userProfileData.ProfileImagePath.split('/').pop(),
    fileType : 'ProfileImage',
    filePath : ftpdomain + '/' + this.userProfileData.UserId + '/ProfileImage/' + this.userProfileData.ProfileImagePath.split('/').pop(),
    fileSize : '0', fileContent : '0', fileTypeID : 'SelfImage' })

    this.images.push({ uniqueID : this.userProfileData.UserId+'_'+this.userProfileData.PANImagePath.split('/').pop(), attachmentID : '0', businessCode : '0', 
    fileName : this.userProfileData.PANImagePath.split('/').pop(),
    fileType : 'PANImage',
    filePath : ftpdomain + '/' + this.userProfileData.UserId + '/PANCard/' + this.userProfileData.PANImagePath.split('/').pop(),
    fileSize : '0', fileContent : '0', fileTypeID : 'PanCard' })

    this.images.push({ uniqueID : this.userProfileData.UserId+'_'+this.userProfileData.AadharImageFront.split('/').pop(), attachmentID : '0', businessCode : '0', 
    fileName : this.userProfileData.AadharImageFront.split('/').pop(),
    fileType : 'AadharFrontImage',
    filePath : ftpdomain + '/' + this.userProfileData.UserId + '/Aadhar/' + this.userProfileData.AadharImageFront.split('/').pop(),
    fileSize : '0', fileContent : '0', fileTypeID : 'Aadhar' })

    this.images.push({ uniqueID : this.userProfileData.UserId+'_'+this.userProfileData.AadharImageBack.split('/').pop(), attachmentID : '0', businessCode : '0', 
    fileName : this.userProfileData.AadharImageBack.split('/').pop(),
    fileType : 'AadharBackImage',
    filePath : ftpdomain + '/' + this.userProfileData.UserId + '/Aadhar/' + this.userProfileData.AadharImageBack.split('/').pop(),
    fileSize : '0', fileContent : '0', fileTypeID : 'Aadhar' })

    this.images.push({ uniqueID : this.userProfileData.UserId+'_'+this.userBankData.BankProof.split('/').pop(), attachmentID : '0', businessCode : '0', 
    fileName : this.userBankData.BankProof.split('/').pop(),
    fileType : 'BankProofImage',
    filePath : ftpdomain + '/' + this.userProfileData.UserId + '/BankProof/' + this.userBankData.BankProof.split('/').pop(),
    fileSize : '0', fileContent : '0', fileTypeID : 'BankDocument' })

    this.images.push({ uniqueID : this.userProfileData.UserId+'_'+this.userProfileData.EducationalImage.split('/').pop(), attachmentID : '0', businessCode : '0', 
    fileName : this.userProfileData.EducationalImage.split('/').pop(),
    fileType : 'EducationalImage',
    filePath : ftpdomain + '/' + this.userProfileData.UserId + '/EducationQualification/' + this.userProfileData.EducationalImage.split('/').pop(),
    fileSize : '0', fileContent : '0', fileTypeID : 'Education' })

    this.RCBSData.images=this.images;

    let RCBSObj = {
      RCBSData: this.RCBSData,
      images: this.images
    };

    this.service.PushPOSPInRCBS(this.RCBSData).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        Swal.fire("", "Data Synced in RCBS successfully", "success");
      }
      else {
        Swal.fire("", data.ErrorDescription, "error");
        // this.activate_tab5();
      }
    });
  }

}
