import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { Subject } from 'rxjs';
import { HealthservicesService } from 'src/app/services/healthservices.service';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  UserId:number;
  policies=[];
  noOfPolicy:number=0;
  totalPremium:number=0;
  totalEarning:number=0;
  // dtTrigger: Subject<any> = new Subject();
  // dtOptions: DataTables.Settings = {};

 // @ViewChild(DataTableDirective)
  dtElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  userEmail:any;
  FullName:any;
  userMobile:any;
  isLogin :any;
  CustomerPolicyList:any=[];
  constructor(private healthMasterService:HealthservicesService, private router:Router) {
    if(sessionStorage.getItem('EmailId')!=null || sessionStorage.getItem('MobileNo')!=null){     
      this.FullName=sessionStorage.getItem('FullName');
        this.userEmail=sessionStorage.getItem('EmailId');
        this.userMobile=sessionStorage.getItem('MobileNo');
        this.isLogin =  sessionStorage.getItem("isLogin");
        this.GetCustomerPolicyDetails();
    }else{
      this.router.navigate(['/login']);
    }
   }

  ngOnInit() {
    
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 5,
    // lengthMenu : [5, 10, 25],
    //   processing: true
    // };
    debugger;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [5,10,25,50,100],
      processing: true
    };
    $("#dashboard_left_panel").click(function(){
      $(".left_panel").toggleClass("show_left_panel");
    });
  }


  async GetCustomerPolicyDetails(){
    debugger;

        var val ={
          userName: this.userEmail,
          flag: 1,
        }
  
        await this.healthMasterService.GetCustomerPolicyDetails(val).then(
          (Data:any)=>{
            if(Data.length >0){
              console.log(Data);
              this.CustomerPolicyList = Data; 
              this.dtTrigger.next('');
             // console.log("this.CustomerPolicyList=",this.CustomerPolicyList);

            }
          } ,
          (error) => console.log("ApIerror=",error)
        )
      }


}
