import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-other-product',
  templateUrl: './other-product.component.html',
  styleUrls: ['./other-product.component.css']
})
export class OtherProductComponent implements OnInit {

  constructor() { }

  ngOnInit() 
  {
    var coll = document.getElementsByClassName("collapsible");
    var i;

   for (i = 0; i < coll.length; i++) {
   coll[i].addEventListener("click", function() {
    this.classList.toggle("active");
    var content = this.nextElementSibling;
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
   });
   }
  }

}
