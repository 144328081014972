import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandle } from 'src/app/common/models/ErrorHandle';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EncrDecrService } from 'src/app/services/EncrDecrService';
import { MasterService } from 'src/app/services/master.service';
import { MotorService } from 'src/app/services/motor.service';
import  {twoWheelerDetails,QuotationModel}  from '../../common/Models/twoWheeler'
import Swal from 'sweetalert2';
import { User ,UserAuthentication} from 'src/app/common/Models/userDetail';
import { GlobalService } from 'src/app/global.service';
import { token } from 'src/app/common/Models/token';
declare var $: any;
@Component({
  selector: 'app-two-wheeler',
  templateUrl: './two-wheeler.component.html',
  styleUrls: ['./two-wheeler.component.css']
})
export class TwoWheelerComponent implements OnInit {
  public customPatterns = { 'A': { pattern: new RegExp('/^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/')} };
  token:token;
  ErrorHandle:ErrorHandle;
  @ViewChild("registrationNumber", { static: false }) registrationNumber: ElementRef;
  isLogedUser:boolean=false;
  selectBike:string;
  isHaveBikeNumber:boolean=false;
  //full:number=0; 
  vehicleDetails:QuotationModel;
  constructor(private router:Router,private masterService:MasterService,private routerActive:ActivatedRoute,
    private EncrDecr: EncrDecrService,private authService:AuthenticationService,
    private globalService:GlobalService,private motorService:MotorService) { }

  ngOnInit() {
  
    $("#haveBikeNumber1").click(function(){
      $(this).toggleClass("active");
    });
    this.registrationNoMasking();
    let getValues=this.routerActive.snapshot.queryParamMap.get('userid');
    var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', getValues);
      var data = decrypted.split('|');
      // console.log(data);
      // alert(data);
      let vUserAuthentication = new UserAuthentication();
        vUserAuthentication.UserName=data[0];
        vUserAuthentication.Password=data[1];
        this.authService.getAuthentication(vUserAuthentication).subscribe((data: token)=>{          
          this.token=data;
          if(this.token.access_token !==''){             
              sessionStorage.setItem('access_token',this.token.access_token);
              //get user 
              this.authService.geUser(vUserAuthentication.UserName)
              .subscribe((data: User[]) =>{ 
                if(data.length>0){
                this.isLogedUser=true;  
                this.globalService.isLogin=true;  
                this.globalService.userName='Welcome,'+data[0].UserName;  
                sessionStorage.setItem('UserId',data[0].UserId.toString()); 
                sessionStorage.setItem('userName',data[0].UserName);
                sessionStorage.setItem('email',data[0].Email);
                sessionStorage.setItem('mobile',data[0].Mobile);
                if(data[0].UserName ==""){        
                  Swal.fire('Userid and password not matched', '', 'error');
                }
              }else{
                Swal.fire('Userid and password not matched', '', 'error');
              }
              },((err:ErrorHandle) => {
                this.ErrorHandle=err;    
                Swal.fire(this.ErrorHandle.statusText, '', 'error');  
            }));
          }
          else{              
              Swal.fire('Some error.', '', 'error');
          }         
          
        },((err:ErrorHandle) => {
          this.ErrorHandle=err;       
           if(this.ErrorHandle.statusText=="Bad Request"){
             Swal.fire('Userid and password are incorrect.', '', 'error');
           }
      }));
             
  }

  HaveBikeNumber(bikeType){    
    this.selectBike=bikeType;
    if(this.selectBike=="NEW"||this.selectBike=="NO"){
      sessionStorage.removeItem('registrationNumber'); 
      sessionStorage.removeItem('vehicledata');
      if(this.selectBike=="NO")this.selectBike="ROLLOVER"
      this.router.navigateByUrl('/rto-registration?type='+this.selectBike);
    }
   else{
     //this.isHaveBikeNumber=true;
     $("#isbikenumber").show();
   }
  }
  getRtoDetails(){
    //alert(this.registrationNumber.nativeElement.value)
    //console.log(this.registrationNumber.nativeElement.value);
   
    let registrationNumber=this.registrationNumber.nativeElement.value;
    if(registrationNumber==""){
      Swal.fire('Please enter registration number', '', 'error');
      return false;
    }  
    if(!this.validateRegistrationNo(registrationNumber)){
      Swal.fire('Please enter valid registration number', '', 'error');
      return false;
    }
    let regNo=registrationNumber;

    this.motorService.getVehicleByRegNo(regNo.replace(' ','')).subscribe((data: QuotationModel)=>{          
      if(data){
        //data.RegistrationNo=registrationNumber.toUpperCase();
        sessionStorage.setItem('registrationNumber',registrationNumber); 
        sessionStorage.setItem('vehicledata',JSON.stringify(data));
        this.vehicleDetails=data;
        this.vehicleDetails.RegistrationCode=registrationNumber.toUpperCase().substring(0,5);
        this.masterService.saveVehicle(data).subscribe(res=>{
          //this.router.navigateByUrl('/rto-registration?type=ROLLOVER');
          this.saveQuote();
        })
        
      }else{
        this.vehicleDetails.RegistrationCode=registrationNumber.substring(0,5);
        this.router.navigateByUrl('/rto-registration?type=ROLLOVER');
      } 
    },((err:ErrorHandle) => {
        this.ErrorHandle=err;   
        if(err.status==404) {
          sessionStorage.setItem('registrationNumber',regNo); 
         let vehicledata=new  twoWheelerDetails();
         vehicledata.RegistrationNo=regNo;
         vehicledata.RegistrationCode=regNo.substring(0,5);
         sessionStorage.setItem('vehicledata',JSON.stringify(vehicledata));
          this.router.navigateByUrl('/rto-registration?type=ROLLOVER');
        }   
         if(this.ErrorHandle.statusText=="Bad Request"){
           Swal.fire('Userid and password are incorrect.', '', 'error');
         }
  }));
    /*this.masterService.getRegistrationDetails(registrationNumber).subscribe((data: any[])=>{          
          if(data.length>0){
            sessionStorage.setItem('registrationNumber',registrationNumber); 
            this.router.navigateByUrl('/rto-registration');
          }else{
            this.router.navigateByUrl('/rto-registration');
          } 
        },((err:ErrorHandle) => {
            this.ErrorHandle=err;       
             if(this.ErrorHandle.statusText=="Not Found"){
               Swal.fire('Userid and password are incorrect.', '', 'error');
             }
      }));*/
           
    }

    saveQuote(){
     
      sessionStorage.setItem('vehicledata',JSON.stringify(this.vehicleDetails));
     const quoteModel=new  QuotationModel(); 
     quoteModel.UserId='';  
     quoteModel.ManufacturerId=this.vehicleDetails.ManufacturerId;
     quoteModel.ModelId=this.vehicleDetails.ModelId;
     quoteModel.VariantId=this.vehicleDetails.VariantId;
     quoteModel.SeatingCapacity =this.vehicleDetails.SeatingCapacity;
     quoteModel.CubicCapacity =this.vehicleDetails.CubicCapacity;
     quoteModel.FuelType =this.vehicleDetails.FuelType;
     quoteModel.VehicleType=5;
     quoteModel.BuyerState==this.vehicleDetails.RegistrationCode.substr(2);
     quoteModel.ManufacturerYear=this.vehicleDetails.ManufacturerYear;
     quoteModel.RegistrationCode=this.vehicleDetails.RegistrationCode;
    
     quoteModel.DayOfPurchase=this.vehicleDetails.DayOfPurchase;
     quoteModel.MonthOfPurchase=this.vehicleDetails.MonthOfPurchase;
     quoteModel.YearOfPurchase=this.vehicleDetails.YearOfPurchase;
    
       quoteModel.CurrentPolicyType='RollOver';
     //quoteModel.PolicyType=this.vehicleDetails.PolicyType;
     quoteModel.PolicyType="Comprehensive";
     quoteModel.PreviousPolicyType="Comprehensive";//this.vehicleDetails.PolicyType; 
     if(quoteModel.RegistrationCode!='' && sessionStorage.getItem('RegCode')=='' ){
        this.getCity(quoteModel.RegistrationCode);
    }
     quoteModel.CityOfRegistration=sessionStorage.getItem('RegCode');
     
     quoteModel.ClaimMade="Yes";
     quoteModel.AutomobileMembership="No";
     quoteModel.LastNCB=null;
     quoteModel.PACoverOwner="No";      
     quoteModel.OwnedBy="Individual";
     quoteModel.AntiTheftDevices="No";
     quoteModel.CNGKit="Not Fitted";
     quoteModel.GeoGraphicalExtension="No";
     quoteModel.PaidDriver="No";
     quoteModel.RestrictTPPDLiability="No";
     quoteModel.PACover="No";
     //quoteModel.PreviousPolicyExpirDate=this.vehicleDetails.PolicyExpiryDate;
     this.motorService.postQuoteData(quoteModel).subscribe((result:any)=>{
       if(result && result.status=="done")
       sessionStorage.setItem("sessionId",result.Id);   
  
       this.router.navigate(['/buildplan'], { queryParams: { sessionId: result.Id } })
      // this.router.navigateByUrl('/two-wheeler-quotation?sessionId='+result.Id);
     })
   
    }
    registrationCode:any;
  getCity(rtoCode){
   
    this.masterService.GetCityOfRegistration(rtoCode).subscribe(result=>{
      this.registrationCode=result;
      sessionStorage.setItem('RegCode',result[0].CityId);
    })
  }
    onBlurMethod(val){
      document.getElementById("mobile").style.border = "1px solid #A5A5A5";
      const regex = /^[A-Z]{2}\s[0-9]{2}\s[A-Z]{2}\s[0-9]{4}$/gm;
      const str = val;
      let m;
      if(m = regex.exec(str) == null){
    
        document.getElementById("mobile").style.border = "1px solid red";
        
      }else{
          while ((m = regex.exec(str)) !== null) {        
              if (m.index === regex.lastIndex) {
                  regex.lastIndex++;
              }
              m.forEach((match, groupIndex) => {
                  //alert(`Found match, group ${groupIndex}: ${match}`);              
                  
              });
          }
      }  
     }


     registrationNoMasking(){
      $("input[name='regNumber']").on("keyup", function(){
        $("input[name='number']").val(destroyMask(this.value));
      this.value = createMask($("input[name='number']").val());
    });
    
    
    
    var full=0;var trueLen=0;
    function createMask(string){

      console.log(string)
       console.log(string.length);
       var last4char='';
       var reg = /^\d+$/;
       if(string.length>8)
       {
        last4char=string.substr(string.length - 4) 
       }
      if(last4char!='')
      {
        if(reg.test(last4char))
        {   full=1;
           trueLen=string.length;
           console.log(full);
           if(string.length==9){
            return string.replace(/(\w{2})(\w{2})(\w{1})(\d{4})/,"$1-$2-$3-$4");
           }
           if(string.length==10){
             return string.replace(/(\w{2})(\w{2})(\w{2})(\d{4})/,"$1-$2-$3-$4");
           }
           if(string.length==11){
            return string.replace(/(\w{2})(\w{2})(\w{3})(\d{4})/,"$1-$2-$3-$4");
           }
          
        }  
        else return string;
      }
                                                    
      else return string;
      
    } 
    
    function destroyMask(string){
      console.log(string)
      console.log(full)
       if(full==1){
        console.log('trueLen '+trueLen)
       return string.replace(/\W/g,'').substring(0, trueLen);
       }  
      
       var last4char='';
       var reg = /^\d+$/;
       if(string.length>8)
       {
        last4char=string.substr(string.length - 4) 
       }
      if(last4char!='')
      {
         if(reg.test(last4char)){
            return string.replace(/\W/g,'').substring(0, string.length);
         }
       }
      
        return string.replace(/\W/g,'').substring(0, 14);
    }
  }


  validateRegistrationNo(regNo){  
    debugger
    const regex = /^[A-Z]{2}[-][0-9A-Z]{1,2}[-](?:[A-Z])?(?:[A-Z]*)[-][0-9]{4}$/gm;
    const str = regNo.toUpperCase();
    let m;
    if(m = regex.exec(str) == null){
      return false;
    }
    return true;

   
  }
    
    

}