import { Component, OnInit,AfterViewInit } from '@angular/core';
import { Router,ActivatedRoute , } from '@angular/router';
import { ErrorHandle } from 'src/app/common/models/ErrorHandle';
import  {Product}  from '../../common/Models/motor.product';
import{voluntryExcess} from '../../common/Models/motor.master';
import  {QuotationModel,premiumModel,addonMaster,addon,twoWheelerDetails}  from '../../common/Models/twoWheeler'

import { MotorService } from 'src/app/services/motor.service';
import { MasterService } from 'src/app/services/master.service';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/global.service';

declare var $: any;
@Component({
  selector: 'app-two-wheeler-quotation',
  templateUrl: './two-wheeler-quotation.component.html',
  styleUrls: ['./two-wheeler-quotation.component.css']
})
export class TwoWheelerQuotationComponent implements OnInit {
products:Product[]=[];
premiumList:premiumModel[]=[];
quoteModel:QuotationModel=null;
ErrorHandle:ErrorHandle;
vehicleData:twoWheelerDetails;
registrationCode:any;
addonList:addon[]=[];
addonCollection:any[]=[];
IDV:Number=0;
IDVCollection:premiumModel[]=[];
voluntaryExcess:voluntryExcess[]=[];
maxIDV:Number;minIDV:Number;
liabilityCoversYear:number;
urlCopy:string="";
selectedAddonList=[];
matchedAddonList=[];
ManufacturerList=[];
  ModelsList=[];
  VariantList=[];
  constructor(private router:Router,private activeRouter:ActivatedRoute
    ,private motorService:MotorService
    ,private masterService:MasterService,private globalService:GlobalService) { }

  ngOnInit() {
    this.liabilityCoversYear=1;
    this.getPremium(1);     
   //this.IDV=235800;
    // $(document).on('click',function(e){
    //   if(!(($(e.target).closest("#ncvid").length > 0 ) || ($(e.target).closest(".ncvCover").length > 0))){
    //   $("#ncvid").hide();
    //  }
    // });
    // $("#closeCompare").click(function(){
    //   $("#compare_boxid").hide();
    // });
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
    $("#showIDVDetails").click(function () {
      $('#idvdetailsDiv').show();
    });
    $(window).on('load', function () {
     // $("body").append("<div class='ncvCover'></div>");
      $('.filter_dropdown_content.ncv').show();
    });
    $('input[type="radio"]').click(function () {
      if ($(this).attr('id') == 'ncvno') {
        $('.showNCBDetails').show();
      }
      else {
        $('.showNCBDetails').hide();
      }
    });
    $("#showFilter").click(function(){
      $("#showFilterDetails").toggle();
    });
    $("ul.prevoiusNCB li a").click(function () {
      $(".ncvCover").remove();
      $(this).parents('.ncv').hide();
    });
    $('#ncvyes').click(function(){ $(".ncvCover").remove();
    $(this).parents('.ncv').hide();})
    $(".tw-addonsList li a.addDropdown").click(function () {
      $(this).next("ul.addons_dropdown").toggleClass("show");
    });  
    $("#show_filterInfo").click(function () {
      $('#ncvid').show();
    });
    $("#nvbClose").click(function(){
      $(this).parents('#ncvid').hide();
      $(".ncvCover").hide();
    });
    $("#idvClose").click(function(){
      $(this).parents('#idvdetailsDiv').hide();
    });
    
   
    //this.vehicleData=JSON.parse(sessionStorage.getItem('vehicledata'));
    
  }

  ngAfterViewInit() {
    
  }

  getPremium(mode){  debugger;

    let id=this.activeRouter.snapshot.queryParamMap.get('sessionId');
    this.motorService.getQuotationQuery('',id,3).subscribe((result:QuotationModel)=>{
   
      if(mode==1){
      this.quoteModel=result;  
      alert(this.quoteModel.CubicCapacity);
      // if(sessionStorage.getItem("addons")!=null){
      //   this.quoteModel.Addons=JSON.parse( sessionStorage.getItem("addons"));
      // }
      if(this.quoteModel.CurrentPolicyType=='NEW'){
        this.liabilityCoversYear=5;
      }
     this.GetManufacture();
     this.getVehicleModels(result.ManufacturerId);
     this.getVehicleVariants(result.ModelId);
      this.getCity(this.quoteModel.RegistrationCode);  
      this.getAddons();
      this.getVoluntaryExcess();
      }
      this.getQuotationProductList(id);    

    });   
   
  }
  getQuotationProductList(id){
    this.motorService.getQuotationProducts(id).subscribe((res:Product[])=>{
      this.products=res;
      console.log(res);
      this.IDVCollection=[];
      this.premiumList=[];
      this.products.forEach((v,i)=>{debugger;
        this.motorService.getQuotation(3,v.ProductId,0,v.PlanId,0,0,JSON.stringify(this.quoteModel)).subscribe((pemium:premiumModel)=>{
          console.log(pemium);
          if(parseInt( pemium.FinalPremium)>0){
            this.premiumList.push(pemium);
            this.IDVCollection.push(pemium);
            this.IDV=this.sortIDV(); 
          }  
          
        })
      }) 
      
    });
  }
  sortIDV(){    
    this.IDVCollection.sort(function(a, b){return Number(a.MaximumIDV)  -  Number(b.MaximumIDV)});   
     this.maxIDV=Number(this.IDVCollection[this.IDVCollection.length-1].MaximumIDV);  
    this.IDVCollection.sort(function(a, b){return Number(a.MinimumIDV)  -  Number(b.MinimumIDV)}); 
     this.minIDV= Number( this.IDVCollection[0].MinimumIDV);
    return this.maxIDV;
  }

  applyChanges(){
    this.premiumList=[];
    if(this.addonCollection.length>0){
      debugger
       let addons=[];
       for(let i=0;i<this.addonCollection.length;i++){
        addons.push({AddonId:this.addonCollection[i]})
       }
        this.quoteModel.Addons=addons;
        this.premiumList=[];
    }else{
      this.quoteModel.Addons=null;
    }

    let validate=true;
    if(this.quoteModel.AutomobileMembership=="Yes"){
        if(this.quoteModel.MembershipNo=="" || this.quoteModel.MembershipNo==null){
           Swal.fire('enter membership no', '', 'error');
            validate=false;
        }
         if(this.quoteModel.MembershipExpiry=="" || this.quoteModel.MembershipExpiry==null){
           Swal.fire('enter membership expiry date', '', 'error');
            validate=false;
        }

    }
    if(this.quoteModel.PACover=="Yes"){
      this.quoteModel.NoOfPassengers=this.quoteModel.SeatingCapacity;
      if(this.quoteModel.SumInsured=="" || this.quoteModel.SumInsured==null){
         Swal.fire('select suminsured', '', 'error');
          validate=false;
      }         

  }

if(validate==true){
  this.getPremium(2);
}
  
    
  }

  goPolicyType(policyType){
    this.quoteModel.PolicyType=policyType;
    this.quoteModel.PreviousPolicyType=policyType;  
    this.motorService.postQuoteData(this.quoteModel).subscribe((result:any)=>{
      if(result && result.success=="done")
       this.getQuotationProductList(result.Id);  
    }) 
  
  }

  claimMade(claim,value,currentNCB){
    if(claim=='Yes'){
      this.quoteModel.ClaimMade="Yes";
      this.quoteModel.LastNCB='';
      this.quoteModel.CurrentNCB='0';
    }else{
      this.quoteModel.ClaimMade="No";
      this.quoteModel.LastNCB=value;
      this.quoteModel.CurrentNCB=currentNCB;
    }

  }

  getAddons(){
    this.masterService.getAddons(this.quoteModel.VehicleType).subscribe((res:addon[])=>{
      this.addonList=res;
      if(this.quoteModel.Addons!=null && this.quoteModel.Addons.length>0){
        this.quoteModel.Addons.forEach((v,i)=>{
            let addon=this.addonList.find(element => element.AddOnId==v.AddOnId);
            addon.Checked=true;
            this.addCollection(addon.AddOnId);
        });
      }
      
    })
  }
  getVoluntaryExcess(){
    this.masterService.getVoluntryExcess(this.quoteModel.VehicleType).subscribe((res:voluntryExcess[])=>{
      this.voluntaryExcess=res;
    })
  }
  addCollection(addonId){
    let idx=-1;
    if(this.addonCollection.length>0)
     idx=this.addonCollection.indexOf(addonId);
    if(idx<0){
      this.addonCollection.push(addonId);
    }else{
      this.addonCollection.splice(idx,1);
    }    
  }
  getCity(rtoCode){
   
    this.masterService.GetCityOfRegistration(rtoCode).subscribe(result=>{
      this.registrationCode=result;
    })
  }

  goProposal(productPremium:premiumModel,flag=''){  
    let quotationArr=[this.quoteModel];
    let premiumArr=[productPremium];
    let addonArr=productPremium.Addons;
   let sessionId= this.activeRouter.snapshot.queryParamMap.get('sessionId');
    this.motorService.postPremiumData(quotationArr,premiumArr,addonArr).subscribe((result:any)=>{
      if(flag!='COMPARE' && flag!='viewPlan'){
        if(result.success=='done'){
          this.router.navigate(['/proposal'],{queryParams: {sessionId:sessionId,planId:productPremium.PlanId}});
        }
  
      }
      if(flag=='viewPlan'){
        if(result.success=='done'){
          localStorage.setItem('CompanyName',productPremium.CompanyName);
          localStorage.setItem('productId',productPremium.ProductId.toString());
          localStorage.setItem('ProductImage',productPremium.ProductImage);
          localStorage.setItem('ProductName',productPremium.ProductName);
          localStorage.setItem('ProductIDV',productPremium.ProductIDV);
          localStorage.setItem('FinalPremium',productPremium.FinalPremium.toString());
          
          
          const url = this.router.serializeUrl(this.router.createUrlTree(['/viewplandetails'], { queryParams: {sessionId:sessionId}}));
          window.open(url, '_blank'); 
          //this.router.navigate(['/proposal'],{queryParams: {sessionId:sessionId,planId:productPremium.PlanId}});
        }
  
      }
    });
      
      
      /*{
      next(result:any) {
          if(result.success=='done'){
            this.router.navigate(['/proposal']);
           // this.router.navigate(['/proposal'],{queryParams: {sessionId:sessionId}});
           // this.router.navigateByUrl='/proposal?sessionId='+this.router.snapshot.queryParamMap.get('sessionId');
          }        
      },
      error(msg) {
        Swal.fire(msg, '', 'error');
        console.log('Error : ', msg);
      }
    });*/
  }
  updateIDV(flag){
    this.premiumList=[];
    this.IDVCollection.forEach((v,i)=>{
      let idv=this.IDV;
      if(flag==1)idv=0;
      if(this.IDV>Number(v.MaximumIDV))idv=Number(v.MaximumIDV);
      if(this.IDV<Number(v.MinimumIDV))idv=Number(v.MinimumIDV);

      this.motorService.getQuotation(3,Number(v.ProductId),0,v.PlanId,Number(idv),0,JSON.stringify(this.quoteModel)).subscribe((pemium:premiumModel)=>{
        if(parseInt( pemium.FinalPremium)>0){
          this.premiumList.push(pemium);
          
        }  
        
      })
    });
  }

  updateVahicle():void{
  this.getVahicleDetails();
    this.getPremium(2);
    $('#vehicleModel').click();
  }

  getVahicleDetails(){
  let interval = setInterval(() => {
    if(this.vehicleData==null)this.vehicleData=new twoWheelerDetails();
    let vehicle= this.ManufacturerList.find(v=>v.ManufacturerId==Number(this.quoteModel.ManufacturerId));
    this.vehicleData.ManufacturerName=vehicle.ManufacturerName;
     vehicle= this.ModelsList.find(v=>v.ModelId==Number(this.quoteModel.ModelId));
     this.vehicleData.ModelName=vehicle.ModelName;
     vehicle= this.VariantList.find(v=>v.VehicleVariantId==Number(this.quoteModel.VariantId));
     this.vehicleData.VariantName=vehicle.VariantName;
     if (this.vehicleData.ModelName!='') {
      clearInterval(interval);
    }
    }, 5000);

   
  }

  GetManufacture(): void {   
    this.ManufacturerList = [];        
      this.masterService.GetManufacturer(this.quoteModel.VehicleType).subscribe((data: any[])=>{
        this.ManufacturerList=data;
                
  });    
}
getVehicleModels(manufacturerId:number): void {
  this.masterService.GetPopularVehicleModels(manufacturerId).subscribe((data: any[])=>{          
    this.ModelsList=data;
   },((err:ErrorHandle) => {
    this.ErrorHandle=err;       
     if(this.ErrorHandle.statusText=="Bad Request"){
       Swal.fire('Please contact administrator.', '', 'error');
     }
  }));
}


getVehicleVariants(modelId:number): void{
  this.masterService.GetVariant(modelId).subscribe((data: any[])=>{          
    this.VariantList=data;
    this.getVahicleDetails();
   },((err:ErrorHandle) => {
    this.ErrorHandle=err;       
     if(this.ErrorHandle.statusText=="Bad Request"){
       Swal.fire('Please contact administrator.', '', 'error');
     }
  }));
}

getVariant(variant){

  this.VariantList.forEach((v)=>{
    if(v.VehicleVariantId==variant){
      this.quoteModel.CubicCapacity=v.CubicCapacity;
      this.quoteModel.SeatingCapacity=v.SeatingCapacity;
    }
  })

  
}


  onValueChange(value) {
    if(Number(value)>this.maxIDV)value=this.maxIDV;
    this.IDV=value;
    let id=this.activeRouter.snapshot.queryParamMap.get('sessionId');
    //this.getQuotationProductList(id);   
  }
  RedirectTorto(check){
    if(check=='rto'){
      this.router.navigate(['/rto-registration']);
    }else{
      let id=this.activeRouter.snapshot.queryParamMap.get('sessionId');
      //this.router.navigate(['/buildplan']);
      this.router.navigate(['/buildplan'], { queryParams: { sessionId:id } })
    }
    
  }

  planCompare(quotepremium, isChecked: boolean){  
    //alert(quotepremium.ProductId);  
    console.log(quotepremium);
      if(isChecked) {
        if(this.globalService.compareArr.length < 4){
        this.globalService.compareArr.push(quotepremium);
              $("#compare_boxid").show();
        }else{
          document.getElementById("compare_"+quotepremium.ProductId).click();
          $("#compare_boxid").show();
          Swal.fire('Max four company compare yet time. ', '', 'error');
        }
        this.goProposal(quotepremium,'COMPARE')
      } else {
        this.globalService.compareArr.forEach((element,index)=>{
          if(element.ProductId==quotepremium.ProductId){
            if(this.globalService.compareArr.length == 1){
              $("#compare_boxid").hide();
              this.globalService.compareArr.splice(index,1);
            }else{
              this.globalService.compareArr.splice(index,1);
            }
                        
          } 
       });
        
      }
   }

   filterCompare(type){
    if(this.globalService.compareArr !=null){
      //console.log(this.globalService.compareArr);
      return this.globalService.compareArr;
    }
  }
  deletePalnInCom(ProductId){  
    if(this.globalService.compareArr.length == 1){
      document.getElementById("compare_"+ProductId).click();
      $("#compare_boxid").hide();
    }else{
      document.getElementById("compare_"+ProductId).click();
    }
    
  }
  closeCompare(){    
    $("#compare_boxid").hide();
    this.globalService.compareArr.forEach(function (item) {
      debugger;
      document.getElementById("compare_"+item.ProductId).click();      
    });
    localStorage.removeItem("compareArr");
    this.globalService.compareArr=[];
  }

  redirectComparePage(){
    if(this.globalService.compareArr.length > 1){
    localStorage.setItem('compareArr', JSON.stringify(this.globalService.compareArr))
      const url = this.router.serializeUrl(this.router.createUrlTree(['/compare']));
      window.open(url, '_blank'); 
    }else{
      Swal.fire('select minimum of two quotes. ', '', 'error');
    }

    // if(sessionStorage.getItem('userName') ==null){
    //   this.globalService.isLogin=false;
    //   $("#showLoginmodal").modal("show");
    //   let interval = setInterval(() => {
    //     if(this.globalService.isLogin==true) {       
    //         localStorage.setItem('compareArr', JSON.stringify(this.globalService.compareArr))
    //         const url = this.router.serializeUrl(this.router.createUrlTree(['/compare']));
    //         window.open(url, '_blank'); 
    //          clearInterval(interval);
    //     }
    //   }, 1000);
    // }else{      
    //   localStorage.setItem('compareArr', JSON.stringify(this.globalService.compareArr))
    //   const url = this.router.serializeUrl(this.router.createUrlTree(['/compare']));
    //   window.open(url, '_blank'); 
    // }
  }

  goSelectedPlan(quotepremium,pageType){
    this.goProposal(quotepremium,pageType);
  }
  
  shareQuote(){
    
    Swal.fire('work on progress.', '', 'error');
  }
  copyUrl(type){
    if(type=='Email'){
      this.urlCopy = '';
    }
    if(type=='Link'){
      this.urlCopy = window.location.href;   
      var $temp = $("<input>");
      var $url = $(location).attr('href');
      $("body").append($temp);
      $temp.val($url).select();
      document.execCommand("copy");
      $temp.remove();
      //$("p").text("URL copied!");  
      Swal.fire('URL copied!', '', 'success');
      
      $("#shareQuote").modal("hide");
    }
    if(type=='copy'){
      this.urlCopy = window.location.href;
    }
    
    //alert(this.urlCopy);
  }
  calulatPremium(FinalPremium,TotalDiscount){
    let sum=Number(FinalPremium)+Number(TotalDiscount);

    return Math.floor(sum);
  }
  showAddons(premium,quoteModel){
    this.matchedAddonList=[];this.selectedAddonList=[];
    if(premium.Addons.length>0){
      
      this.matchedAddonList=premium.Addons;
      if(quoteModel.Addons.length>0){
        //this.selectedAddonList=quoteModel.Addons;
        
        for(let i=0;i<premium.Addons.length;i++){
          const checklist= quoteModel.Addons.find(v=>v.AddOnId = premium.Addons[i].AddonId);
         if(checklist==undefined){
          this.selectedAddonList.push({ ADDONDescription:quoteModel.Addons[i].ADDONDescription})
         }
        //  else{
        //   this.selectedAddonList.push({ ADDONDescription:quoteModel.Addons[i].ADDONDescription})
        //  }
          //console.log(checklist);
        }
      }
    }else{
      for(let i=0;i<quoteModel.Addons.length;i++){
        
       if(quoteModel.Addons[i].ADDONDescription !=null && quoteModel.Addons[i].ADDONDescription !=''){
        this.selectedAddonList.push({ ADDONDescription:quoteModel.Addons[i].ADDONDescription})
       }
      }
    }
    // function getArraysIntersection(a1,a2){
    //   return  a1.filter(function(n) { return a2.indexOf(n) !== -1;});
    // }

    // if(premium.Addons.length>0){
    //   var intersectingColors=getArraysIntersection(premium.Addons.AddOnId, quoteModel.Addons.AddOnId);
    //   console.log(intersectingColors);
    // }
  }
  filterAddOnList(addOnlists){
    debugger
    let vlist=[];
    for(let i=0;i<addOnlists.length;i++){
      if(addOnlists[i]!=null){
      vlist.push({ADDONDescription:addOnlists[i].ADDONDescription,AddOnPrice:addOnlists[i].AddOnPrice})
      }
    }
    return vlist;
  }
}
