import { Component, OnInit, AfterViewInit, ElementRef, ViewChild  } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { ErrorHandle } from 'src/app/common/models/ErrorHandle';
import { Product } from '../../common/Models/motor.product';
import { voluntryExcess } from '../../common/Models/motor.master';
import { QuotationModel, premiumModel, addonMaster, addon, twoWheelerDetails } from '../../common/Models/twoWheeler'

import { MotorService } from 'src/app/services/motor.service';
import { MasterService } from 'src/app/services/master.service';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/global.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { DateAdapter } from '@angular/material';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

declare var $: any;

@Component({
  selector: 'app-car-quotation',
  templateUrl: './car-quotation.component.html',
  styleUrls: ['./car-quotation.component.css']
})
export class CarQuotationComponent implements OnInit {
  isPACoverOwner: boolean = false;
  products: Product[] = [];
  premiumList: premiumModel[] = [];
  errorQuoteList: premiumModel[] = [];
  quoteModel: QuotationModel = null;
  ErrorHandle: ErrorHandle;
  vehicleData: twoWheelerDetails;
  registrationCode: any;
  addonList: addon[] = [];
  addonCollection: any[] = [];
  ExpiryEndDate: any;
  IDV: Number = 0;
  IDVCollection: premiumModel[] = [];
  voluntaryExcess: voluntryExcess[] = [];
  maxIDV: Number; minIDV: Number;
  liabilityCoversYear: number;
  urlCopy: string = "";
  isEmail: boolean = false;
  isSMS: boolean = false;
  premium = premiumModel;
  selectedAddonList = [];
  matchedAddonList = [];
  ManufacturerList = [];
  ModelsList = [];
  VariantList = [];
  Email:string="";
  Mobile:string="";
  public pipe = new DatePipe('en-US');
  NewBStartDate : any;
  NewBEndDate : any;
  NewBTPStartDate : any;
  NewBTPEndDate : any;
  isSAOD:boolean=true;
  PolicyType: string = ''; ProductPolicyType: string = '';
  @ViewChild("textEmail", { static: false }) textEmail: ElementRef;
  @ViewChild("textMobile", { static: false }) textMobile: ElementRef;


  keyword = 'name';
  data = [];
  private searchTerms = new Subject<string>();
  startdate: any;
  minRegDate: Date;
  maxRegDate: Date;
  registationdate: any;
  
  idleState = '';
  timedOut = false;
  lastPing?: Date = null;

  constructor(private router: Router, private activeRouter: ActivatedRoute, private idle: Idle, private keepalive: Keepalive
    , private motorService: MotorService, private dateAdapter: DateAdapter<Date>
    , private masterService: MasterService, private globalService: GlobalService) { 
      
    this.dateAdapter.setLocale('en-GB');
    const currentDate = new Date();

    this.minRegDate = new Date();
    this.maxRegDate = currentDate;
    this.minRegDate.setFullYear(currentDate.getFullYear() - 15);

    idle.setIdle(5);
          idle.setTimeout(900);
          idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
          idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.';
            this.reset();
          });
          idle.onTimeout.subscribe(() => {
            debugger;
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.sendMail();
            // this.userLogout();
            // window.location.href="https://www.riskcareinsure.com";//prod
          });
          idle.onIdleStart.subscribe(() => {
            this.idleState = 'You\'ve gone idle!'
          });
          idle.onTimeoutWarning.subscribe((countdown) => {//debugger;
            let minutes = Math.floor(countdown / 60);
            let extraSeconds = countdown % 60;
            minutes = minutes < 10 ? 0 + minutes : minutes;
            extraSeconds = extraSeconds < 10 ? 0 + extraSeconds : extraSeconds;
            this.idleState = 'Your session will expire in ' + minutes + ':' + extraSeconds+ ' seconds!'
            if(countdown<1){

            }
          });
          this.reset();

    }

    reset() {
      this.idle.watch();
      this.idleState = 'Started.';
      this.timedOut = false;
    }

    
  sendMail() {

    // if (sessionStorage.getItem('email') != null) {
      let from = "support@riskcare.co.in";

      let agentname = sessionStorage.getItem('userName');

      let emailid = sessionStorage.getItem('email');

      let name = sessionStorage.getItem('CustomerEmail');
      
      let mobile = sessionStorage.getItem('CustomerMobile');

      let Url = window.location.href;
      
      this.motorService.SendB2CSessionMail(Url,emailid,name,mobile).subscribe((data: any) => {

      });
    // }
  }

  ngOnInit(): void {
    this.liabilityCoversYear = 1;
    this.getPremium(1);
    $('#ncvid').hide();
    
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
    $("#showIDVDetails").click(function () {
      $('#idvdetailsDiv').show();
    });
    $(window).on('load', function () {
      // $("body").append("<div class='ncvCover'></div>");
      // $('.filter_dropdown_content.ncv').show();
    });
    $('input[type="radio"]').click(function () {
      if ($(this).attr('id') == 'ncvno') {
        $('.showNCBDetails').show();
      }
      else {
        $('.showNCBDetails').hide();
      }
    });
    $("#showFilter").click(function () {
      $("#showFilterDetails").toggle();
      $("#respoShow_plan").toggleClass("show");
    });
    $("ul.prevoiusNCB li a").click(function () {
      $(".ncvCover").remove();
      $(this).parents('.ncv').hide();
    });
    $('#ncvyes').click(function () {
      $(".ncvCover").remove();
      $(this).parents('.ncv').hide();
    })
    $(".tw-addonsList li a.addDropdown").click(function () {
      $(this).next("ul.addons_dropdown").toggleClass("show");
    });
    $("#nvbClose").click(function () {
      $(this).parents('#ncvid').hide();
      $(".ncvCover").hide();
    });
    $("#idvClose").click(function () {
      $(this).parents('#idvdetailsDiv').hide();
    });
    debugger;
    this.NewBStartDate = this.pipe.transform(new Date(), 'dd/MM/yyyy');
    let d = new Date();
    this.NewBEndDate = this.pipe.transform(new Date(d.getFullYear()+1,d.getMonth(),d.getDate()-1), 'dd/MM/yyyy');
    this.NewBTPStartDate = this.pipe.transform(new Date(), 'dd/MM/yyyy');
    this.NewBTPEndDate = this.pipe.transform(new Date(d.getFullYear()+3,d.getMonth(),d.getDate()-1), 'dd/MM/yyyy');
    //this.vehicleData=JSON.parse(sessionStorage.getItem('vehicledata'));

  }

  showNCB(){debugger;
    $('#ncvid').show();
  }

  NCBChange() {
    debugger;

    this.motorService.postQuoteData(this.quoteModel).subscribe((result: any) => {
      if (result && result.success == "done")
        this.getQuotationProductList(result.Id);
    })

  }

  ngAfterViewInit() {

  }

  getPremium(mode) {
    debugger;
    let id = this.activeRouter.snapshot.queryParamMap.get('sessionId');
    this.motorService.getQuotationQuery('', id, 5).subscribe((result: QuotationModel) => {

      if (mode == 1) {
        if (result.CurrentNCB == null || result.CurrentNCB == "") {
          result.CurrentNCB = "NA";
          result.LastNCB = "NA";
        } this.quoteModel = result;
        if (this.quoteModel.CurrentPolicyType == 'NEW') {
          this.liabilityCoversYear = 5;
        }
        if (this.quoteModel.PolicyType == 'Comprehensive') {
          this.PolicyType = 'Comprehensive/Package';
          this.ProductPolicyType = 'Package Premium';
        }
        if (this.quoteModel.PolicyType == 'ThirdParty') {
          this.PolicyType = 'Stand Alone Third Party';
          this.ProductPolicyType = 'Stand Alone Premium';
        }

        if (this.quoteModel.PolicyType == 'StandaloneOD') {
          this.PolicyType = 'Stand Alone Own Damage';
          this.ProductPolicyType = 'Own Damage Premium';
        }
        if (this.quoteModel.PACoverOwner == "Yes") {
          this.isPACoverOwner = true;
        }
        this.GetManufacture();
        this.getVehicleModels(result.ManufacturerId);
        this.getVehicleVariants(result.ModelId);
        this.getCity(this.quoteModel.RegistrationCode);
        this.getAddons();
        this.getVoluntaryExcess();
      }
      if((this.quoteModel.PolicyType == 'StandaloneOD' || this.quoteModel.PolicyType == 'Comprehensive')
          && this.quoteModel.CurrentPolicyType == "Expired"){
        
        }else{
          this.getQuotationProductList(id);
        }
      
      if(sessionStorage.getItem('registrationNumber')!=null){
        this.quoteModel.RegistrationCode=sessionStorage.getItem('registrationNumber');
      }
      debugger;
            let regdate = new Date(Number(this.quoteModel.YearOfPurchase), Number(this.quoteModel.MonthOfPurchase) - 1, Number(this.quoteModel.DayOfPurchase));
            var ageDifMs = Date.now() - regdate.getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            let age = Math.abs(ageDate.getUTCFullYear() - 1969);
            if (age > 3) {
              this.isSAOD = false;
            }
      debugger;
      let regDate = (this.quoteModel.DayOfPurchase.length == 1 ? ('0' + this.quoteModel.DayOfPurchase) : this.quoteModel.DayOfPurchase) + '/' + (this.quoteModel.MonthOfPurchase.length == 1 ? ('0' + this.quoteModel.MonthOfPurchase) : this.quoteModel.MonthOfPurchase) + '/' + this.quoteModel.YearOfPurchase;

      this.startdate = new Date(Number(this.quoteModel.YearOfPurchase), Number(this.quoteModel.MonthOfPurchase) - 1, Number(this.quoteModel.DayOfPurchase));

      if (this.quoteModel.CurrentPolicyType == 'NEW') {
        this.registationdate = new Date();
      }
      else {
        this.registationdate = this.startdate;
      }




      if (this.quoteModel.SumInsured != "") {
       // this.IDV = Number(this.quoteModel.SumInsured);
      }
    });

  }

  getQuotationProductList(id) {
    this.motorService.getQuotationProducts(id).subscribe((res: Product[]) => {
      this.products = res;
      console.log(res);
      this.IDVCollection = [];
      this.premiumList = [];
      this.errorQuoteList = [];

      this.products.forEach((v, i) => {
        debugger;
        // this.IDV = Number(this.quoteModel.SumInsured!=""?this.quoteModel.SumInsured:"0");
        this.motorService.getQuotation(5, v.ProductId, 0, v.PlanId, Number(this.IDV), 0, JSON.stringify(this.quoteModel)).subscribe((pemium: premiumModel) => {
          debugger;
          console.log(pemium);
          if (parseInt(pemium.FinalPremium) > 0) {
            console.log("pemium.CompanyId=",pemium.CompanyId,"\t pemium FinalPremium=",pemium.FinalPremium,"\t pemium=",pemium,typeof(pemium.CompanyId));
            if(pemium.CompanyId !== 12){
            this.premiumList.push(pemium);
            this.premiumList.sort(function (a, b) { return Number(a.FinalPremium) - Number(b.FinalPremium) });
            this.IDVCollection.push(pemium);
            if (this.IDV == 0) {
              this.IDV = this.sortIDV();
            }
            else {
              this.IDV = this.IDV;
            }
            this.sortIDV();
            }
            
          }
          else {
            if (pemium.ErrorMessage) {
              
            }
            else {
              pemium.ErrorMessage = 'Service error,try again.'
            }
            if(pemium.ProductImage)
              this.errorQuoteList.push(pemium)
          }



          debugger;
          var year = new Date(this.quoteModel.PreviousPolicyExpirDate).getFullYear();
          var month = new Date(this.quoteModel.PreviousPolicyExpirDate).getMonth();
          var day = new Date(this.quoteModel.PreviousPolicyExpirDate).getDate();
          this.ExpiryEndDate = new Date(year + 1, month, day);
          // this.ExpiryEndDate=this.datepipe.transform(this.ExpiryEndDate, 'yyyy-MM-dd');
        })
      })

    });
  }

  sortIDV() {
    this.IDVCollection.sort(function (a, b) { return Number(a.MaximumIDV) - Number(b.MaximumIDV) });
    this.maxIDV = Number(this.IDVCollection[this.IDVCollection.length - 1].MaximumIDV);
    this.IDVCollection.sort(function (a, b) { return Number(a.MinimumIDV) - Number(b.MinimumIDV) });
    this.minIDV = Number(this.IDVCollection[0].MinimumIDV);
    return this.maxIDV;
  }

  applyChanges() {debugger;
    this.premiumList = [];
    if (this.addonCollection.length > 0) {
      debugger
      let addons = [];
      for (let i = 0; i < this.addonCollection.length; i++) {
        addons.push({ AddonId: this.addonCollection[i] })
      }
      this.quoteModel.Addons = addons;
      this.premiumList = [];
    } else {
      this.quoteModel.Addons = [];
    }

    let validate = true;
    if (this.quoteModel.AutomobileMembership == "Yes") {
      if (this.quoteModel.MembershipNo == "" || this.quoteModel.MembershipNo == null) {
        Swal.fire('enter membership no', '', 'error');
        validate = false;
      }
      if (this.quoteModel.MembershipExpiry == "" || this.quoteModel.MembershipExpiry == null) {
        Swal.fire('enter membership expiry date', '', 'error');
        validate = false;
      }

    }
    if (this.quoteModel.PACover == "Yes") {
      this.quoteModel.NoOfPassengers = this.quoteModel.SeatingCapacity;
      if (this.quoteModel.SumInsured == "" || this.quoteModel.SumInsured == null) {
        Swal.fire('select suminsured', '', 'error');
        validate = false;
      }

    }

    this.saveQuote();

    if (validate == true) {
      this.getPremium(2);
    }

    this.addonClose();
    this.addcoverClose();

  }

  saveQuote(){
    
    this.motorService.postQuoteDataAndAddons(this.quoteModel).subscribe((result:any)=>{
      if(result && result.status=="done")
      sessionStorage.setItem("sessionId",result.Id);   

    });
  }

  goPolicyType(policyType) {
    debugger;
    if (policyType == 'Comprehensive') {
      this.PolicyType = 'Comprehensive/Package';
      this.ProductPolicyType = 'Package Premium';
    }
    if (policyType == 'ThirdParty') {
      this.PolicyType = 'Stand Alone Third Party';
      this.ProductPolicyType = 'Liability Premium';
    }

    if (policyType == 'StandaloneOD') {
      this.PolicyType = 'Stand Alone Own Damage';
      this.ProductPolicyType = 'Own Damage Premium';
    }
    this.quoteModel.PolicyType = policyType;
    this.quoteModel.PreviousPolicyType = policyType;
    this.motorService.postQuoteData(this.quoteModel).subscribe((result: any) => {
      if (result && result.success == "done")
        this.getQuotationProductList(result.Id);
    })

  }

  claimMade(claim, value, currentNCB) {
    if (claim == 'Yes') {
      this.quoteModel.ClaimMade = "Yes";
      this.quoteModel.LastNCB = value;
      this.quoteModel.CurrentNCB = currentNCB;

      this.NCBChange();
    } else {
      this.quoteModel.ClaimMade = "No";
      this.quoteModel.LastNCB = value;
      this.quoteModel.CurrentNCB = currentNCB;
      this.NCBChange();
    }

  }

  getAddons() {
    this.masterService.getAddons(this.quoteModel.VehicleType).subscribe((res: addon[]) => {
      this.addonList = res;
      if (this.quoteModel.Addons != null && this.quoteModel.Addons.length > 0) {
        this.quoteModel.Addons.forEach((v, i) => {
          let addon = this.addonList.find(element => element.AddOnId == v.AddOnId);
          addon.Checked = true;
          this.addCollection(addon.AddOnId);
        });
      }

    })
  }

  getVoluntaryExcess() {
    this.masterService.getVoluntryExcess(this.quoteModel.VehicleType).subscribe((res: voluntryExcess[]) => {
      this.voluntaryExcess = res;
    })
  }

  addCollection(addonId) {
    let idx = -1;
    if (this.addonCollection.length > 0)
      idx = this.addonCollection.indexOf(addonId);
    if (idx < 0) {
      this.addonCollection.push(addonId);
    } else {
      this.addonCollection.splice(idx, 1);
    }
  }

  getCity(rtoCode) {

    this.masterService.GetCityOfRegistration(rtoCode).subscribe(result => {
      this.registrationCode = result;
      sessionStorage.setItem('RegCode', result[0].CityId);

    })
  }

  NCBClick() {
    //$(this).closest('.insurer_div_content').show();
    $("body").append("<div class='modal-show'></div>");
    $(".insurer_div_content").show();
  }
  NCBClose() {
    $(".modal-show").hide();
    $(".insurer_div_content").hide();
  }

  PlanTypeClick() {
    //$(this).closest('.insurer_div_content').show();
    $("body").append("<div class='modal-show'></div>");
    $(".features_div_content").show();
  }
  PlanTypeClose() {
    $(".modal-show").hide();
    $(".features_div_content").hide();
  }

  IDVClick() {
    //$(this).closest('.insurer_div_content').show();
    $("body").append("<div class='modal-show'></div>");
    $(".premium_div_content").show();
  }
  IDVClose() {
    $(".modal-show").hide();
    $(".premium_div_content").hide();
  }

  addonClick() {
    //$(this).closest('.insurer_div_content').show();
    $("body").append("<div class='modal-show'></div>");
    $("#addCovres_div").show();
  }
  addonClose() {
    $(".modal-show").hide();
    $("#addCovres_div").hide();
  }

  addcoverClick() {
    //$(this).closest('.insurer_div_content').show();
    $("body").append("<div class='modal-show'></div>");
    $("#addtional_covers_modal").show();
  }
  addcoverClose() {
    $(".modal-show").hide();
    $("#addtional_covers_modal").hide();
  }

  goProposal(productPremium: premiumModel, flag = '') {
    debugger;
    let quotationArr = [this.quoteModel];
    let premiumArr = [productPremium];
    let addonArr = productPremium.Addons;
    if (this.quoteModel.PolicyType == "ThirdParty") {
      let addonArr = [];
    }
    let sessionId = this.activeRouter.snapshot.queryParamMap.get('sessionId');
    this.motorService.postPremiumData(quotationArr, premiumArr, addonArr).subscribe((result: any) => {
      if (flag != 'COMPARE' && flag != 'viewPlan') {
        if (result.success == 'done') {
          this.router.navigate(['/car-proposal'], { queryParams: { sessionId: sessionId, planId: productPremium.PlanId } });
        }

      }
      if (flag == 'viewPlan') {
        if (result.success == 'done') {
          localStorage.setItem('CompanyName', productPremium.CompanyName);
          localStorage.setItem('productId', productPremium.ProductId.toString());
          localStorage.setItem('ProductImage', productPremium.ProductImage);
          localStorage.setItem('ProductName', productPremium.ProductName);
          localStorage.setItem('ProductIDV', productPremium.ProductIDV);
          localStorage.setItem('FinalPremium', productPremium.FinalPremium.toString());

          const url = this.router.serializeUrl(this.router.createUrlTree(['/viewplandetails'], { queryParams: { sessionId: sessionId } }));
          window.open(url, '_blank');
          //this.router.navigate(['/proposal'],{queryParams: {sessionId:sessionId,planId:productPremium.PlanId}});
        }

      }
    });

  }
  updateIDV(flag) {
    debugger;
    let actualCount;
    let count;
    this.premiumList = [];
    this.errorQuoteList = [];
    //this.quoteModel.SumInsured=this.IDV.toString();
    this.IDVCollection.forEach((v, i) => {
      let idv = this.IDV;
      if (flag == 1) idv = 0;
      if (this.IDV > Number(v.MaximumIDV)) idv = Number(v.MaximumIDV);
      if (this.IDV < Number(v.MinimumIDV)) idv = Number(v.MinimumIDV);
      
      this.motorService.getQuotation(5, Number(v.ProductId), 0, v.PlanId, Number(idv), 0, JSON.stringify(this.quoteModel)).subscribe((pemium: premiumModel) => {
        if (parseInt(pemium.FinalPremium) > 0) {
          if(pemium.CompanyId!=12){
            this.premiumList.push(pemium);
          this.premiumList.sort(function (a, b) { return Number(a.FinalPremium) - Number(b.FinalPremium) });
          // this.IDVCollection.push(pemium);
          if (this.IDV == 0) {
            this.IDV = this.sortIDV();
          }
          else {
            this.IDV = this.IDV;
          }
          this.sortIDV();
          }
          
        }

        else {
          if (pemium.ErrorMessage) {
            
          }
          else {
            pemium.ErrorMessage = 'Service error,try again.'
          }
          if(pemium.ProductImage)
            this.errorQuoteList.push(pemium)
        }


      })
      if ((this.IDVCollection.length - 1) == i) {
        this.saveQuote();
        // window.location.reload();
      }
    });
   
    $('#idvdetailsDiv').hide();
    this.IDVClose();
  }

  updateVahicle(): void {
    if(this.vehicleData.RegistrationNo)
      this.quoteModel.RegistrationCode = this.vehicleData.RegistrationNo;
    
      //this.registrationCode[0].CityName=this.vehicleData.CityAndRTO;
    let rgdate = new Date();
    if (this.registationdate != null) {
      if (this.registationdate != undefined) {
        if (this.registationdate.toString().includes("/")) {
          let dateParts = this.registationdate.split("/");
          // month is 0-based, that's why we need dataParts[1] - 1
          let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
          rgdate = new Date(dateObject);
        }
        else {
          rgdate = new Date(this.registationdate);
        }
      }
    }
    let year = rgdate.getFullYear();
    let month = rgdate.getMonth() + 1;
    let day = rgdate.getDay();

    if (this.quoteModel.CurrentPolicyType == 'NEW') {
      this.quoteModel.ManufacturerYear = (new Date()).getFullYear().toString();
    }
    else {
      this.quoteModel.ManufacturerYear = year.toString();
    }
    this.quoteModel.DateOfRegistration = this.registationdate;
    if(this.vehicleData.RegistrationNo)
      this.quoteModel.RegistrationCode = this.vehicleData.RegistrationNo;

    if (this.quoteModel.CurrentPolicyType == 'NEW') {
      this.quoteModel.DayOfPurchase = this.pipe.transform(new Date(), 'yyyy-MM-dd').split('-')[2];
      this.quoteModel.MonthOfPurchase = this.pipe.transform(new Date(), 'yyyy-MM-dd').split('-')[1];
      this.quoteModel.YearOfPurchase = this.pipe.transform(new Date(), 'yyyy-MM-dd').split('-')[0];
    }
    else {
      this.quoteModel.DayOfPurchase = this.pipe.transform(this.registationdate, 'yyyy-MM-dd').split('-')[2]; //this.registationdate.split('-')[2];
      this.quoteModel.MonthOfPurchase = this.pipe.transform(this.registationdate, 'yyyy-MM-dd').split('-')[1];
      this.quoteModel.YearOfPurchase = this.pipe.transform(this.registationdate, 'yyyy-MM-dd').split('-')[0];
    }

    this.quoteModel.CityOfRegistration=sessionStorage.getItem('RegCode');

    this.getVahicleDetails();
    this.saveQuote();
    this.getPremium(2);
    // $('#vehicleModel').click();
    $("#editVehicle").modal("hide");
    //window.location.reload();
  }

  getVahicleDetails() {
    let interval = setInterval(() => {
      if (this.vehicleData == null) this.vehicleData = new twoWheelerDetails();
      let vehicle = this.ManufacturerList.find(v => v.ManufacturerId == Number(this.quoteModel.ManufacturerId));
      this.vehicleData.ManufacturerName = vehicle.ManufacturerName;
      this.quoteModel.ManufacturerName = vehicle.ManufacturerName;
      vehicle = this.ModelsList.find(v => v.ModelId == Number(this.quoteModel.ModelId));
      this.vehicleData.ModelName = vehicle.ModelName;
      this.quoteModel.ModelName = vehicle.ModelName;
      vehicle = this.VariantList.find(v => v.VehicleVariantId == Number(this.quoteModel.VariantId));
      this.vehicleData.VariantName = vehicle.VariantName;
      this.quoteModel.VariantName = vehicle.VariantName;
      if (this.vehicleData.ModelName != '') {
        clearInterval(interval);
      }
    }, 5000);


  }

  GetManufacture(): void {
    this.ManufacturerList = [];
    this.masterService.GetManufacturer(this.quoteModel.VehicleType).subscribe((data: any[]) => {
      this.ManufacturerList = data;

    });
  }

  getVehicleModels(manufacturerId: number): void {
    this.masterService.GetPopularVehicleModels(manufacturerId).subscribe((data: any[]) => {
      this.ModelsList = data;
    }, ((err: ErrorHandle) => {
      this.ErrorHandle = err;
      if (this.ErrorHandle.statusText == "Bad Request") {
        Swal.fire('Please contact administrator.', '', 'error');
      }
    }));
  }

  getVehicleVariants(modelId: number): void {
    this.masterService.GetVariant(modelId).subscribe((data: any[]) => {
      this.VariantList = data;
      this.getVahicleDetails();
    }, ((err: ErrorHandle) => {
      this.ErrorHandle = err;
      if (this.ErrorHandle.statusText == "Bad Request") {
        Swal.fire('Please contact administrator.', '', 'error');
      }
    }));
  }

  getVariant(variant) {

    this.VariantList.forEach((v) => {
      if (v.VehicleVariantId == variant) {
        this.quoteModel.CubicCapacity = v.CubicCapacity;
        this.quoteModel.SeatingCapacity = v.SeatingCapacity;
      }
    })

  }

  onValueChange(value) {
    if (Number(value) > this.maxIDV) value = this.maxIDV;
    this.IDV = value;
    let id = this.activeRouter.snapshot.queryParamMap.get('sessionId');
    //this.getQuotationProductList(id);   
  }
  RedirectTorto(check) {
    if (check == 'rto') {
      this.router.navigate(['/rto-registration']);
    } else {
      let id = this.activeRouter.snapshot.queryParamMap.get('sessionId');
      //this.router.navigate(['/buildplan']);
      this.router.navigate(['/buildplan'], { queryParams: { sessionId: id } })
    }

  }

  planCompare(quotepremium, isChecked: boolean) {
    //alert(quotepremium.ProductId);  
    console.log(quotepremium);
    if (isChecked) {
      if (this.globalService.compareArr.length < 4) {
        this.globalService.compareArr.push(quotepremium);
        $("#compare_boxid").show();
      } else {
        document.getElementById("compare_" + quotepremium.ProductId).click();
        $("#compare_boxid").show();
        Swal.fire('Max four company compare yet time. ', '', 'error');
      }
      this.goProposal(quotepremium, 'COMPARE')
    } else {
      this.globalService.compareArr.forEach((element, index) => {
        if (element.ProductId == quotepremium.ProductId) {
          if (this.globalService.compareArr.length == 1) {
            $("#compare_boxid").hide();
            this.globalService.compareArr.splice(index, 1);
          } else {
            this.globalService.compareArr.splice(index, 1);
          }
        }
      });

    }
  }

  filterCompare(type) {
    if (this.globalService.compareArr != null) {
      //console.log(this.globalService.compareArr);
      return this.globalService.compareArr;
    }
  }
  deletePalnInCom(ProductId) {
    if (this.globalService.compareArr.length == 1) {
      document.getElementById("compare_" + ProductId).click();
      $("#compare_boxid").hide();
    } else {
      document.getElementById("compare_" + ProductId).click();
    }

  }
  closeCompare() {
    $("#compare_boxid").hide();
    this.globalService.compareArr.forEach(function (item) {
      debugger;
      document.getElementById("compare_" + item.ProductId).click();
    });
    localStorage.removeItem("compareArr");
    this.globalService.compareArr = [];
  }

  redirectComparePage() {
    if (this.globalService.compareArr.length > 1) {
      localStorage.setItem('compareArr', JSON.stringify(this.globalService.compareArr))
      const url = this.router.serializeUrl(this.router.createUrlTree(['/compare']));
      window.open(url, '_blank');
    } else {
      Swal.fire('select minimum of two quotes. ', '', 'error');
    }
    
  }

  goSelectedPlan(quotepremium, pageType) {
    this.goProposal(quotepremium, pageType);
  }

  shareQuotes(premium) {
    this.premium = premium;
  }
  shareQuote() {debugger;
    let textEmail = this.Email;
    let textMobile = this.Mobile;
    if (this.isEmail == true) {
      if (textEmail == "") {
        Swal.fire('Plz. enter Email Id', '', 'error');
        return;
      } else {
        debugger;
        this.quoteModel.ExField1 = textEmail;
        this.quoteModel.ExField2 = window.location.href;
        this.motorService.QuotesMailer([this.quoteModel], [this.premium]).subscribe((result: any) => {
          if (result > 0) {
            $("#shareQuote").modal("hide");
            Swal.fire('Car quotation details successfully send.', '', 'success');
          }
          else {
            Swal.fire('Car quotation details not successfully send.', '', 'error');
          }

        });
      }
    }
    if (this.isSMS == true) {debugger;
      if (textMobile == "") {
        Swal.fire('Please enter Mobile number', '', 'error');
        return;
      } else {
        debugger;
        this.quoteModel.ExField1 = textMobile;
        this.quoteModel.ExField2 = window.location.href;
        this.motorService.QuotesSMS(textMobile, window.location.href,'Customer', 1).subscribe((result: any) => {
          if (result > 0) {
            $("#shareQuote").modal("hide");
            Swal.fire('Car quotation details successfully sent.', '', 'success');
          }
          else {
            Swal.fire('Car quotation details not successfully sent.', '', 'error');
          }

        });
      }
    }
  }

  copyUrl(type) {
    if (type == 'Email') {
      this.urlCopy = '';
      this.isEmail=true;
    }
    if (type == 'SMS') {
      this.urlCopy = '';
      this.isSMS=true;
    }
    if (type == 'Link') {
      this.urlCopy = window.location.href;
      var $temp = $("<input>");
      var $url = $(location).attr('href');
      $("body").append($temp);
      $temp.val($url).select();
      document.execCommand("copy");
      $temp.remove();
      //$("p").text("URL copied!");  
      Swal.fire('URL copied!', '', 'success');

      $("#shareQuote").modal("hide");
    }
    if (type == 'copy') {
      this.urlCopy = window.location.href;
    }

  }
  calulatPremium(Premium) {
    let sum = Number(Premium.PackagePremium) + Number(Premium.TotalDiscount) + Number(Premium.ServiceTax);

    return Math.floor(sum);
  }
  showAddonsMatched(premium, quoteModel) {

    if (premium.Addons != null && premium.Addons.length > 0) {
      return premium.Addons.length + '/' + quoteModel.Addons.length;
    } else {
      return '0/' + quoteModel.Addons.length;
    }
  }
  showAddons(premium, quoteModel) {
    this.matchedAddonList = premium.Addons;
    this.selectedAddonList = [];
    if (premium.Addons.length > 0) {

      this.matchedAddonList = premium.Addons;
      if (quoteModel.Addons.length > 0) {
        //this.selectedAddonList=quoteModel.Addons;

        for (let i = 0; i < quoteModel.Addons.length; i++) {
          let checklist = premium.Addons.find(v => v.AddOnId == Number(quoteModel.Addons[i].AddOnId));
          if (checklist == undefined) {
            checklist = this.addonList.find(v => v.AddOnId == Number(quoteModel.Addons[i].AddOnId));
            this.selectedAddonList.push({ ADDONDescription: checklist.ADDONDescription })
          }
          
        }
      }
    } else {
      for (let i = 0; i < this.quoteModel.Addons.length; i++) {

        let checklist = this.addonList.find(v => v.AddOnId == this.quoteModel.Addons[i].AddOnId);
        if (checklist != undefined) {
          this.selectedAddonList.push({ ADDONDescription: checklist.ADDONDescription })
        }
      }
    }
  }
  filterAddOnList(addOnlists) {
    debugger
    let vlist = [];
    for (let i = 0; i < addOnlists.length; i++) {
      if (addOnlists[i] != null) {
        vlist.push({ ADDONDescription: addOnlists[i].ADDONDescription, AddOnPrice: addOnlists[i].AddOnPrice })
      }
    }
    return vlist;
  }

  CheckPACoverOwner(isChecked: boolean) {
    if (isChecked) {
      this.quoteModel.PACoverOwner = "Yes"
    } else {
      this.quoteModel.PACoverOwner = "No"
    }

  }

  downloadAsPDF(a: any) {
    debugger;
    var data = document.getElementById('contentToConvert' + a);
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      //const pdf=new jsPDF();
      let pdf = new jsPDF()
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('newPDF.pdf');
    });
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  selectEvent(e) {
    debugger;
    let address: [] = e.address.split(' ');
    console.log(address.length)
    if (address.length <3) {
      e.address = e.address.split(' ')[1];
      e.address = e.address.split(',');
      e.address = e.address[1];
    }
    else {
      e.address = e.address.split(' ')[2];
      e.address = e.address.split(',');
      e.address = e.address[1];
    }
    

    this.vehicleData.RegistrationNo = e.address;
    
    if (e.address != "") {
      // let VehicleType = 5
      this.vehicleData.RegistrationNo = e.address.toUpperCase();
      this.vehicleData.RegistrationCode = e.address.toUpperCase();
      sessionStorage.setItem('registrationNumber', e.address.toUpperCase());
      this.getCity(e.address);
    }
  }

  searchClient(term): void {
    let searchText = term.target.value;//(<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    this.data = [];

    if (searchText.length > 1) {
      this.masterService.GetUnPopularCityOfRegistration(searchText).subscribe((vdata: any[]) => {
        let getdata = [];

        for (let i = 0; i < vdata.length; i++) {
          getdata.push({ id: vdata[i].CityId, name: vdata[i].CityName + ' (' + vdata[i].RegistrationCode + ')', address: vdata[i].CityName + ' ' + vdata[i].RegistrationCode });
          console.log(vdata[i].RegistrationCode);
        }
        this.data = getdata;

      })
      
    }
    this.searchTerms.next(term);
  }
}
