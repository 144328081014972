import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-management-team',
  templateUrl: './management-team.component.html',
  styleUrls: ['./management-team.component.css']
})
export class ManagementTeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
