import { premiumModel } from "./twoWheeler";

export class ProposalDetails{
PolicyId:	number;
CurrentPolicyType:	string;
PreviousPolicyType:	string;
UserId:	string;
Salutation:	string;
FirstName:	string;
LastName:	string;
UserName:	string;
Address1:	string;
Address2:	string;
Address3:	string;
City:	string;
State:	string;
Country:	string;
Pincode:	string;
MobileNo:	string;
Email:	string;
DateOfBirth	:string;
Gender:	string;
ExpiryPolicyNo:	string;
ExpiryPolicyDate:	string;
CompanyId:	number;
ProductId:	number;
CarRegistrationNo:	string;
EnginNo:	string;
ChassisNo:	string
IsFinanced:	boolean;
BankName:	string;
CityId:	number;
NomineeName:	string;
NomineeRelationShip:	string;
NomineeAge:	number;
GuardianName:	string;
GuardianRelation:	string;
GuardianAddress1:	string;
GuardianAddress2:	string;
GuardianAddress3:	string;
GuardianCity:	string;
GuardianState:	string;
GuardianPincode:	string;
GuardianCountry:	string;
SessionId:	string;
UpdateMode:	string;
MaritalStatusCode:	string;
OccupationCode:	string;
Pancard:	string;
GSTNumber:	string;
AadharCard:	string;
EIA:	string;
BankCity:	string;
AreaID:	string;
SaveMode:	string;
FromMode:	string;
PlanId:	number;
RequestOrigin:	string;
TPPolicyNumber:	string;
TPStartDate:	string;
TPEndDate:	string;
TPInsuranceCompany:	number;
Company:InsurerCompany[];
PremiumDetails:premiumModel[];
Portal: string;
}


export class Salutation{
    SalutationId: number;
    SalutationCode: string;
    SalutationName: string;
  }

  export class Occupation{    
        HealthOccupationId: number;
        HealthOccupationName: string;      
  }

  export class NomineeRelation    {
      NomineeRelationshipId:number;
      RelationShipCode: string;
      NomineeRelationshipName: string;
    }

    export class InsurerCompany{
      CompanyId: Number;
      CompanyName: String;
    }

    export class BankName{
    BankId: number;  
    BankName: string;
    }