import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { AppSettings } from 'src/app/globals';

declare let $: any;
@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.css']
})
export class QuotesComponent implements OnInit  {
  UserId:number;
  QuotesList=[];
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  constructor(private router:Router) { 
    if(sessionStorage.getItem('UserId')!=null){     
      this.UserId=parseInt(sessionStorage.getItem('UserId'));
    }else{
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {   
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [5, 10, 25],
      processing: true
    };
    this.GetHealthFeaturesList();
    
  }

  GetHealthFeaturesList(){
    // this.healthMasterService.GetQuotesList(this.UserId).subscribe((data: any[]) => {      
    //   this.QuotesList = data;   
    //   this.dtTrigger.next();
    // });
  }

  buttonInRowClick(id,QuotionId){
    localStorage.setItem('quotaionId',QuotionId.toString()); 
    this.router.navigate(['/proposal'], { queryParams: { id: id } })

  }


}
