import { Component,OnInit } from '@angular/core';

import { FormGroup, FormControl, Validators,
    AbstractControl ,ReactiveFormsModule,FormsModule, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HealthservicesService } from 'src/app/services/healthservices.service';
import Swal from 'sweetalert2';

import { CustomerLogin } from '../../Models/Customer';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit { 
  Show: boolean =false;
  // userName:any;
  // password:any;
  user:any= new CustomerLogin();
  submitted:boolean =false;


  Loginform: FormGroup = new FormGroup({
    userName: new FormControl(''),
    password: new FormControl(''),
    
  });
 
  constructor(private healthMasterService:HealthservicesService ,private formBuilder:FormBuilder,
    private router:Router)
    {
    }

  ngOnInit() {

    this.Loginform = this.formBuilder.group({
      userName: ['', [Validators.required]],
      password:['', [Validators.required]],
      });
  }

  
  get f(): { [key: string]: AbstractControl; }
  { return this.Loginform.controls; }
    
  
  async Login(){
    debugger;
    this.submitted =true;
    // console.log(this.Loginform);
    if(this.Loginform.invalid){
      return;
    }
      var val ={
        userName: this.user.UserName,
        password: this.user.Password,
        flag: "Login",
      }
    
   await this.healthMasterService.LoginCustomer(val).then(
        (Data:any)=>{
          //alert(Data);
          if(Data.length >0){
            Swal.fire("", "Login Sucessfull", "success");
            this.onReset();
            // console.log(Data);
            sessionStorage.setItem("FullName", Data[0].Fullname);
            sessionStorage.setItem("MobileNo", Data[0].MobileNo);
            sessionStorage.setItem("EmailId", Data[0].emailId);
            sessionStorage.setItem("isLogin", "true");
            // window.location.href="profiledashboard";
            this.router.navigate(['/profiledashboard']);

          }
          else{
            Swal.fire("", "Login Failed", "error");
          }
        } ,
        (error) => console.log("ApIerror=",error)
      );
    }
  
  ChangePassword() {
    this.Show = !this.Show;
  }

  
  onReset(){
 
    this.user.UserName="";
    this.user.Passsword="";
 
  }
}

