import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MotorInsuranceRoutingModule } from './motor-insurance-routing.module';
import { CarInsuranceComponent } from './car-insurance/car-insurance.component';
import { TwoWheelerComponent } from './two-wheeler/two-wheeler.component';
import { TwoWheelerQuotationComponent } from './two-wheeler-quotation/two-wheeler-quotation.component';
//import { NgxMaskModule, IConfig } from 'ngx-mask';
import { RtoComponent } from './rto/rto.component';
import { TwowheelerProposalComponent } from './twowheeler-proposal/twowheeler-proposal.component'
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MotorQuotationComponent } from './motor-quotation/motor-quotation.component';
import { AddonsComponent } from './addons/addons.component';
import { CompareComponent  } from './compare/compare.component';
import { ViewplandetailsComponent } from './viewplandetails/viewplandetails.component';
import { PaymentfailedComponent } from './paymentfailed/paymentfailed.component';
import { PaymentsuccessComponent } from './paymentsuccess/paymentsuccess.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MaterialModule } from '../material.module';
import { MatNativeDateModule } from '@angular/material';
import { ErrorPageComponent } from './error-page/error-page.component';
/*const maskConfig: Partial<IConfig> = {
  validation: false,
};*/

@NgModule({
  declarations: [CarInsuranceComponent, TwoWheelerComponent, TwoWheelerQuotationComponent, RtoComponent, TwowheelerProposalComponent, MotorQuotationComponent,AddonsComponent,CompareComponent, ViewplandetailsComponent,PaymentfailedComponent,PaymentsuccessComponent, ErrorPageComponent],
  imports: [
    CommonModule,FormsModule,AutocompleteLibModule,
    MotorInsuranceRoutingModule,
    ReactiveFormsModule,
    //NgxMaskModule.forRoot(maskConfig),
    MaterialModule,
    MatNativeDateModule,
        MatDatepickerModule
  ]
})
export class MotorInsuranceModule { }
