import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paymentfailed',
  templateUrl: './paymentfailed.component.html',
  styleUrls: ['./paymentfailed.component.css']
})
export class PaymentfailedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
