import { NgModule } from '@angular/core';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatNativeDateModule} from '@angular/material';
// import { MatDatepickerModule,    MatNativeDateModule,    MatFormFieldModule,    MatInputModule } from '@angular/material';

@NgModule({
  imports: [
    MatDatepickerModule, MatInputModule,MatNativeDateModule,
    BrowserAnimationsModule
  ],
  exports: [
    MatDatepickerModule, MatInputModule,MatNativeDateModule,
    BrowserAnimationsModule
  ],
  providers: [ MatDatepickerModule ]
})

export class MaterialModule {}