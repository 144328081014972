import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { QuotationModel, DigitCKYCModel, premiumModel, twoWheelerDetails, InitiateCKYCResp } from '../../common/Models/twoWheeler';
import { ProposalDetails, NomineeRelation, Occupation, Salutation, InsurerCompany, BankName } from '../../common/Models/motor.proposal';
import { Router, ActivatedRoute, } from '@angular/router';
import { ErrorHandle } from 'src/app/common/models/ErrorHandle';
import { AppSettings } from '../../globals';
import { MotorService } from 'src/app/services/motor.service';
import { MasterService } from 'src/app/services/master.service';
import Swal from 'sweetalert2';
import { User, UserAuthentication } from 'src/app/common/Models/userDetail';
import { EncrDecrService } from 'src/app/services/EncrDecrService';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatePipe } from '@angular/common';
import { iif } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
// import { DateAdapter } from '@angular/material';
// import { DateFormat } from 'src/app/common/date-format';
// import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { formatDate } from '@angular/common';
import { token } from 'src/app/common/Models/token';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { trim } from 'jquery';
import * as internal from 'assert';
import { on } from 'process';
declare var $: any;
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-bike-proposal',
  templateUrl: './bike-proposal.component.html',
  styleUrls: ['./bike-proposal.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }
    ,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class BikeProposalComponent implements OnInit {
  showPaybtn: boolean = false;
  fileUploaded: File;
  fileData: File = null;
  DisableAllOnKycSuccsess: boolean = false;
  formData = new FormData();
  vformData: FormData;
  filecount: number = 0;
  token: token;
  divStep1: boolean = true;
  divStep2: boolean = false;
  divStep3: boolean = false;
  IsCKYCNumber: string;
  CKYCNo: string;
  isLogedUser: boolean = false;
  ErrorHandle: ErrorHandle;
  urlCopy: string = "";
  isSelected: string = "";
  date: any = [];
  serializedDate: any;
  //datePickerConfig: Partial<BsDatepickerConfig>;
  premium: premiumModel;
  premiumList: premiumModel[] = [];
  quoteModel: QuotationModel = null;
  DigitCKYCModel: DigitCKYCModel = new DigitCKYCModel();
  vehicleData: twoWheelerDetails;
  registrationCode: any;
  registationdate;
  productId: string;
  planId: number;
  cityId: number;
  isEmail: boolean = false;
  isSMS: boolean = false;
  isregDisabled: boolean = false;
  Email: string = "";
  Mobile: string = "";
  stateCode: string;
  areas: any[] = [];
  proposalData: ProposalDetails;
  nomineeRelations: NomineeRelation[] = [];
  salutations: Salutation[] = [];
  occupations: Occupation[] = [];
  bankNames: BankName[] = [];
  prevInsurers: InsurerCompany[] = [];
  backtoproposerdiv: boolean = true;
  backtonominee: boolean = false;
  backtovehiclediv: boolean = false;
  intimationMessagemodal: boolean = false;
  noclaimBonus = [];
  autoCompleteData = [];
  proposalForm: FormGroup;
  submitted = false;
  minDate: Date;
  maxDate: Date;

  minExpDate: Date;
  maxExpDate: Date;
  expdate;
  minRegDate: Date;
  maxRegDate: Date;
  proposalId: any;
  startdate: any;
  DateOfBirthvalue: any
  isPrevPolDtls: boolean = false;

  CKYCDocType: any;
  public pipe = new DatePipe('en-US');
  @ViewChild("textEmail", { static: false }) textEmail: ElementRef;

  CKYCDocId: any;
  //OmkarB
  CKYCCINId: any;// For Reliance
  //OmkarB
  IsKYCVerified: boolean = false;
  IsPOIFailed: boolean = false;
  UploadPA: boolean = false;
  IsKYCRequired: boolean = false;
  // CKYCNumber: any;
  UploadCkycDoc: any;
  CKYCPOADocType: any;
  POADocId: any;
  docid: any;
  POAId: any;
  TransactionId: any;
  mobileNumber: string | null = null;
  emailId: string;

  objInitiateCKYCResp: InitiateCKYCResp;

  BajajDocTypeList: any = [
    {
      DocumentID: "A",
      DocumentName: 'Passport'
    },
    {
      DocumentID: "B",
      DocumentName: 'Voter ID'
    },
    {
      DocumentID: "C",
      DocumentName: 'PAN'
    },
    {
      DocumentID: "D",
      DocumentName: 'Driving License'
    },
    {
      DocumentID: "E",
      DocumentName: 'UID'
    },
    {
      DocumentID: "F",
      DocumentName: 'NREGA Job Card'
    },
    {
      DocumentID: "G",
      DocumentName: 'GSTIN'
    },
    {
      DocumentID: "Z",
      DocumentName: 'KYC Number'
    }
  ];

  ICICIDocTypeList: any = [

    {
      DocTypeCode: "CKYC",
      DocTypeText: 'CKYC'
    },
    {
      DocTypeCode: "PAN",
      DocTypeText: 'PAN'
    },
    {
      DocTypeCode: "AADHAAR",
      DocTypeText: 'AADHAAR'
    },
    {
      DocTypeCode: "CIN",
      DocTypeText: 'CIN'
    },
    {
      DocTypeCode: "VOTER",
      DocTypeText: 'VOTER'
    },
    {
      DocTypeCode: "DL",
      DocTypeText: 'DL'
    },
    {
      DocTypeCode: "PASSPORT",
      DocTypeText: 'PASSPORT'
    }
  ];

  RGIDocTypeList: any = [
    {
      DocTypeCode: "PAN",
      DocTypeText: 'PAN'
    },
    {
      DocTypeCode: "CKYC",
      DocTypeText: 'CKYC'
    },
    {
      DocTypeCode: "AADHAAR_No",
      DocTypeText: 'AADHAAR_No'
    },
    {
      DocTypeCode: "CIN",
      DocTypeText: 'CIN'
    },
    {
      DocTypeCode: "VOTERID",
      DocTypeText: 'VOTERID'
    },
    {
      DocTypeCode: "DL_No",
      DocTypeText: 'DL_No'
    },
    {
      DocTypeCode: "PASSPORT",
      DocTypeText: 'PASSPORT'
    }
  ];

  DocTypeList: any = [] = this.RGIDocTypeList;

  POIDocTypeList: any = [
    {
      DocumentID: "A",
      DocumentName: 'Passport'
    },
    {
      DocumentID: "B",
      DocumentName: 'Voter ID'
    },
    {
      DocumentID: "C",
      DocumentName: 'PAN'
    },
    {
      DocumentID: "D",
      DocumentName: 'Driving License'
    },
    {
      DocumentID: "E",
      DocumentName: 'UID'
    },
    {
      DocumentID: "F",
      DocumentName: 'GSTIN Number'
    },

  ]

  POADocTypeList: any = [
    {
      DocumentID: "A",
      DocumentName: 'Passport'
    },
    {
      DocumentID: "B",
      DocumentName: 'Voter ID'
    },
    {
      DocumentID: "C",
      DocumentName: 'PAN'
    },
    {
      DocumentID: "D",
      DocumentName: 'Driving License'
    },
    {
      DocumentID: "E",
      DocumentName: 'UID'
    },
    {
      DocumentID: "F",
      DocumentName: 'GSTIN Number'
    },

  ]

  idleState = '';
  timedOut = false;
  lastPing?: Date = null;

  constructor(private motorService: MotorService
    , private masterService: MasterService, private authService: AuthenticationService, private idle: Idle, private keepalive: Keepalive
    , private EncrDecr: EncrDecrService, private router: Router, private activeRouter: ActivatedRoute
    , private globalService: GlobalService, private formBuilder: FormBuilder) {
    this.globalService.showfooter = false;
    if (this.activeRouter.snapshot.queryParamMap.get('proposalid') != null && this.activeRouter.snapshot.queryParamMap.get('sessionId') != "") {
      this.proposalId = this.activeRouter.snapshot.queryParamMap.get('proposalid');
    }
    else {
      this.proposalId = 0;
    }
    // if(this.proposalId ==null || this.proposalId ==""){
    //   this.proposalId=0;
    // }
    this.getPremium();
    this.noclaimBonus[0] = 20;
    this.noclaimBonus[20] = 25;
    this.noclaimBonus[25] = 35;
    this.noclaimBonus[35] = 45;
    this.noclaimBonus[45] = 50;
    this.noclaimBonus[50] = 50;
    const currentDate = new Date();

    this.minDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 18);
    this.maxDate = currentDate;
    this.minDate.setFullYear(currentDate.getFullYear() - 88)
    //previous policy Expiry date
    this.minExpDate = new Date();
    // currentDate.setDate(currentDate.getDate() + 90);
    this.maxExpDate = currentDate;
    this.expdate = new FormControl(this.minDate);
    
    idle.setIdle(5);
    idle.setTimeout(900);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
    });
    idle.onTimeout.subscribe(() => {
      debugger;
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.sendMail();
      // this.userLogout();
      // window.location.href="https://www.riskcareinsure.com";//prod
    });
    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
    });
    idle.onTimeoutWarning.subscribe((countdown) => {//debugger;
      let minutes = Math.floor(countdown / 60);
      let extraSeconds = countdown % 60;
      minutes = minutes < 10 ? 0 + minutes : minutes;
      extraSeconds = extraSeconds < 10 ? 0 + extraSeconds : extraSeconds;
      this.idleState = 'Your session will expire in ' + minutes + ':' + extraSeconds+ ' seconds!'
      if(countdown<1){

      }
    });
    this.reset();

  }
  keyword = 'name';

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  
sendMail() {

  // if (sessionStorage.getItem('email') != null) {
    let from = "support@riskcare.co.in";

    let agentname = sessionStorage.getItem('userName');

    let emailid = sessionStorage.getItem('email');

    let name = sessionStorage.getItem('CustomerEmail');
      
      let mobile = sessionStorage.getItem('CustomerMobile');

    let Url = this.router.url;
    
    this.motorService.SendB2CSessionMail(Url,emailid,name,mobile).subscribe((data: any) => {

    });
  // }
}

  ngOnInit(): void {
    debugger;
    this.proposalData = new ProposalDetails();

    const storedMobileNumber = sessionStorage.getItem('CustomerMobile');
    
    if (storedMobileNumber) {
      this.mobileNumber = storedMobileNumber;
    }
  
    const storedEmailId = sessionStorage.getItem('CustomerEmail');
    
    if(storedEmailId){
      this.emailId = storedEmailId;
    }

    this.buildForm();
    $(".modal-backdrop").remove();

    if ($(window).width() > 767) {
      $('#vehDetails').click(function () {
        $('html, body').animate({ scrollTop: 600 }, 'slow');
        return false;
      });
      $('#gotoNominee').click(function () {
        $('html, body').animate({ scrollTop: 1000 }, 'slow');
        return false;
      });
    } else {

    }

    if (sessionStorage.getItem('vehicledata') != null) {
      this.vehicleData = JSON.parse(sessionStorage.getItem('vehicledata'));
    }

    this.registrationNoMasking('');

   

    if (sessionStorage.getItem('UserId') == null) {
      let getValues = this.activeRouter.snapshot.queryParamMap.get('userid');
      var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', getValues);
      var data = JSON.parse(decrypted).split('|');
      // console.log(data);
      // alert(data);
      let vUserAuthentication = new UserAuthentication();
      vUserAuthentication.UserName = data[0];
      vUserAuthentication.Password = data[1];
      this.authService.getAuthentication(vUserAuthentication).subscribe((data: token) => {
        this.token = data;
        if (this.token.access_token !== '') {
          sessionStorage.setItem('access_token', this.token.access_token);
          //get user 
          this.authService.geUser(vUserAuthentication.UserName)
            .subscribe((data: User[]) => {
              if (data.length > 0) {
                this.isLogedUser = true;
                this.globalService.isLogin = true;
                this.globalService.userName = 'Welcome,' + data[0].UserName;
                if (sessionStorage.getItem('UserId') == null)
                  window.location.reload();
                sessionStorage.setItem('UserId', data[0].UserId.toString());
                sessionStorage.setItem('userName', data[0].UserName);
                sessionStorage.setItem('email', data[0].Email);
                sessionStorage.setItem('mobile', data[0].Mobile);
                if (data[0].UserName == "") {
                  Swal.fire('Userid and password not matched', '', 'error');
                }
              } else {
                Swal.fire('Userid and password not matched', '', 'error');
              }
            }, ((err: ErrorHandle) => {
              this.ErrorHandle = err;
              Swal.fire(this.ErrorHandle.statusText, '', 'error');
            }));
        }
        else {
          Swal.fire('Some error.', '', 'error');
        }

      }, ((err: ErrorHandle) => {
        this.ErrorHandle = err;
        if (this.ErrorHandle.statusText == "Bad Request") {
          Swal.fire('Userid and password are incorrect.', '', 'error');
        }
      }));
      localStorage.setItem('UserId', vUserAuthentication.UserName);
    }

  }

  PreviousStep() {
    if (this.divStep2 == true) {
      this.divStep1 = true;
      this.divStep2 = false;
      this.divStep3 = false;
      $("#step-count-second").removeClass("current");
      $("#step-count-third").removeClass("current");
    }
    if (this.divStep3 == true) {
      this.divStep1 = false;
      this.divStep2 = true;
      this.divStep3 = false;

      $("#step-count-second").addClass("current");
      $("#step-count-third").removeClass("current");
    }
  }

  backtoProposer(flag) {
    if (flag == 0) {
      this.divStep1 = true;
      this.divStep2 = false;
      this.divStep3 = false;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      $("#step-count-first").addClass("current");
      $("#step-count-second").removeClass("current");
      $("#step-count-third").removeClass("current");
    }
    else if (flag == 1) {

      this.proposalForm.controls.Salutation.markAsTouched();
      this.proposalForm.controls.ProposerName.markAsTouched();
      this.proposalForm.controls.MobileNo.markAsTouched();
      this.proposalForm.controls.Email.markAsTouched();
      this.proposalForm.controls.Address1.markAsTouched();
      this.proposalForm.controls.Address2.markAsTouched();
      this.proposalForm.controls.Pincode.markAsTouched();
      this.proposalForm.controls.State.markAsTouched();

      if (this.proposalForm.value.OwnedBy == "Individual") {
        this.proposalForm.controls.DateOfBirth.markAsTouched();
        this.proposalForm.controls.Gender.markAsTouched();
      }

      if (this.proposalForm.controls.Salutation.value == '' || this.proposalForm.value.Salutation == null || this.proposalForm.value.Salutation == undefined) {
        Swal.fire('Please enter Salutation', '', 'error');
        return false;
      }


      if (this.proposalForm.controls.DateOfBirth.value == '' || this.proposalForm.value.DateOfBirth == null || this.proposalForm.value.DateOfBirth == undefined) {
        Swal.fire('Please enter Date Of Birth', '', 'error');
        return false;
      }
      if (this.proposalForm.controls.ProposerName.value == '' || this.proposalForm.value.ProposerName == null || this.proposalForm.value.ProposerName == undefined) {
        debugger
        this.proposalForm.controls['ProposerName'].setValue($("input[name='ProposerName']").val());
        Swal.fire('Please enter Full Name', '', 'error');
        return false;
      }
      if (this.proposalForm.controls.Gender.value == '' || this.proposalForm.value.Gender == null || this.proposalForm.value.Gender == undefined) {
        Swal.fire('Please enter Gender', '', 'error');
        return false;
      }

      if (this.proposalForm.controls.MobileNo.value == '' || this.proposalForm.value.MobileNo == null || this.proposalForm.value.MobileNo == undefined) {
        Swal.fire('Please enter Mobile Number', '', 'error');
        return false;
      }

      if (this.proposalForm.controls.Email.value == '' || this.proposalForm.value.Email == null || this.proposalForm.value.Email == undefined) {
        Swal.fire('Please enter Email', '', 'error');
        return false;
      }

      if (this.proposalForm.controls.Address1.value == '' || this.proposalForm.value.Address1 == null || this.proposalForm.value.Address1 == undefined) {
        Swal.fire('Please enter Address1', '', 'error');
        return false;
      }

      if (this.proposalForm.controls.Address2.value == '' || this.proposalForm.value.Address2 == null || this.proposalForm.value.Address2 == undefined) {
        Swal.fire('Please enter Address2', '', 'error');
        return false;
      }

      if (this.proposalForm.controls.Pincode.value == '' || this.proposalForm.value.Pincode == null || this.proposalForm.value.Pincode == undefined) {
        this.proposalForm.controls['Pincode'].setValue($("input[name='Pincode']").val());
        Swal.fire('Please enter Pincode', '', 'error');
        return false;
      }
      if (this.premium.CompanyId == 22) {
        if (this.proposalForm.controls.AreaID.value == '' || this.proposalForm.value.AreaID == null || this.proposalForm.value.AreaID == undefined) {
          Swal.fire('Please enter Area ID', '', 'error');
          return false;
        }
      }

      if (this.premium.CompanyId == 33) {
        if ((this.proposalForm.controls.Address1.value + '' + this.proposalForm.controls.Address2.value).length>78 ) {
          Swal.fire('Address should not exceed 79 chharacters. ', '', 'error');
          return false;
        }
      }

      if (this.premium.CompanyId == 12 || this.premium.CompanyId == 22 || this.premium.CompanyId == 33 
        || this.premium.CompanyId == 10 || this.premium.CompanyId == 29 || this.premium.CompanyId == 5
        || this.premium.CompanyId == 11 || this.premium.CompanyId == 30) {

        if (this.CKYCDocType == '' || this.CKYCDocType == null || this.CKYCDocType == undefined) {
          Swal.fire('Please select CKYC Document Type', '', 'error');
          return false;
        }
        if(this.premium.CompanyId == 11 && this.CKYCDocType != 'MOBILE' &&this. CKYCDocType != 'NAME' && this.CKYCDocType != 'EMAIL'){
          if (this.CKYCDocId == '' || this.CKYCDocId == null || this.CKYCDocId == undefined) {
          Swal.fire('Please enter CKYC Document Id', '', 'error');
          return false;
        }
      }
        if (this.premium.CompanyId == 22 && this.quoteModel.OwnedBy == 'Corporate') {
          if (this.CKYCCINId == '' || this.CKYCCINId == null || this.CKYCCINId == undefined) {
            Swal.fire('Please enter CIN', '', 'error');
            return false;
          }
        }
      }

      if (!(this.proposalForm.controls.ProposerName.errors
        || this.proposalForm.controls.MobileNo.errors
        || this.proposalForm.controls.Email.errors
        || this.proposalForm.controls.Address1.errors
        || this.proposalForm.controls.Address2.errors
        || this.proposalForm.controls.Pincode.errors
        || this.proposalForm.controls.State.errors)) {
        this.backtonominee = false;
        this.backtoproposerdiv = true;
        this.backtovehiclediv = true;

      }

      $('html, body').animate({ scrollTop: 600 }, 'slow');
      debugger;
      if (this.premium.CompanyId == 33) {
        this.DigitCKYCModel.ckycReferenceDocId = this.proposalForm.controls.CKYCDocType.value;
        this.DigitCKYCModel.ckycReferenceNumber = this.proposalForm.controls.CKYCDocId.value;
        this.DigitCKYCModel.dateOfBirth = this.proposalForm.controls.DateOfBirth.value;
        this.motorService.DigitCKYCQuoteApi(this.DigitCKYCModel).subscribe((result: any) => {

        }, ((err: ErrorHandle) => {
          this.ErrorHandle = err;
          if (this.ErrorHandle.statusText == "Bad Request") {
            Swal.fire('Please contact administrator.', '', 'error');
          }
        }));
      }
      this.divStep1 = false;
      this.divStep2 = true;
      this.divStep3 = false;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      $("#step-count-second").addClass("current");
      $("#step-count-third").removeClass("current");

    } else if (flag == 2) {
      debugger;
      this.proposalForm.controls.CarRegistrationNo.markAsTouched();
      this.proposalForm.controls.EnginNo.markAsTouched();
      this.proposalForm.controls.ChassisNo.markAsTouched();



      //debugger;
      if (this.proposalForm.value.EnginNo == "" || this.proposalForm.value.EnginNo == null || this.proposalForm.value.EnginNo == undefined) {
        Swal.fire('Please enter Engine number', '', 'error');
        return false;
      }
      if (this.proposalForm.value.ChassisNo == "" || this.proposalForm.value.ChassisNo == null || this.proposalForm.value.ChassisNo == undefined) {
        Swal.fire('Please enter Chassis number', '', 'error');
        return false;
      }
      if (this.quoteModel.CurrentPolicyType != 'NEW') {
        if (!this.validateRegistrationNo((this.proposalForm.value.CarRegistrationNo).replace(' ', '-'))) {
          Swal.fire('Please enter valid registration number', '', 'error');
          return false;
        }
      }

      if (this.quoteModel.CurrentPolicyType == 'RollOver') {
        this.proposalForm.controls.ExpiryPolicyNo.markAsTouched();
        this.proposalForm.controls.ExpiryPolicyDate.markAsTouched();
        this.proposalForm.controls.CompanyId.markAsTouched();
        this.proposalForm.controls.previousPolicyType.markAsTouched();


        if (this.proposalForm.value.CompanyId == "" || this.proposalForm.value.CompanyId == null || this.proposalForm.value.CompanyId == undefined) {
          Swal.fire('Please enter Previous Insurer', '', 'error');
          return false;
        }
        if ((this.proposalForm.value.CompanyId == "67" && this.premium.CompanyId == 10) || (this.proposalForm.value.CompanyId == "214" && this.premium.CompanyId == 10)) {
          Swal.fire('Kindly choose another insurer as no renewal service available with Future Generali through us', '', 'error');
          return false;
        }
        if (this.proposalForm.value.previousPolicyType == "" || this.proposalForm.value.previousPolicyType == null || this.proposalForm.value.previousPolicyType == undefined) {
          Swal.fire('Please enter previous Policy Type', '', 'error');
          return false;
        }
        if (this.proposalForm.value.ExpiryPolicyNo == "" || this.proposalForm.value.ExpiryPolicyNo == null || this.proposalForm.value.ExpiryPolicyNo == undefined) {
          Swal.fire('Please enter previous Policy No', '', 'error');
          return false;
        }
        if (this.proposalForm.value.ExpiryPolicyDate == "" || this.proposalForm.value.ExpiryPolicyDate == null || this.proposalForm.value.ExpiryPolicyDate == undefined) {
          Swal.fire('Please enter previous Policy Expiry Date', '', 'error');
          return false;
        }

        if (this.quoteModel.PolicyType == 'StandaloneOD') {
          if (this.proposalForm.value.TPCompanyId == "" || this.proposalForm.value.TPCompanyId == null || this.proposalForm.value.TPCompanyId == undefined) {
            Swal.fire('Please enter previous TP Insurer', '', 'error');
            return false;
          }
          if (this.proposalForm.value.TPPolicyNo == "" || this.proposalForm.value.TPPolicyNo == null || this.proposalForm.value.TPPolicyNo == undefined) {
            Swal.fire('Please enter previous TP Policy No', '', 'error');
            return false;
          }
          if (this.proposalForm.value.TPStartDate == "" || this.proposalForm.value.TPStartDate == null || this.proposalForm.value.TPStartDate == undefined) {
            Swal.fire('Please enter previous TP Policy Start Date', '', 'error');
            return false;
          }
          if (this.proposalForm.value.TPEndDate == "" || this.proposalForm.value.TPEndDate == null || this.proposalForm.value.TPEndDate == undefined) {
            Swal.fire('Please enter previous  TP Policy End Date ', '', 'error');
            return false;
          }
        }
      }

      if (this.quoteModel.CurrentPolicyType != 'NEW') {
        if (this.proposalForm.value.CarOwnershipChange == "" || this.proposalForm.value.CarOwnershipChange == null || this.proposalForm.value.CarOwnershipChange == undefined) {
          Swal.fire('Please select whether Ownership Transfer or not', '', 'error');
          return false;
        }
      }

      if (this.quoteModel.CurrentPolicyType != 'NEW') {
        if (this.proposalForm.value.CngLpgcheked == "" || this.proposalForm.value.CngLpgcheked == null || this.proposalForm.value.CngLpgcheked == undefined) {
          Swal.fire('Please select whether Two Wheeler have an external CNG/LPG kit', '', 'error');
          return false;
        }
      }

      this.backtoproposerdiv = true;
      this.backtovehiclediv = true;
      this.backtonominee = true;

      // $('html, body').animate({ scrollTop: 1000 }, 'slow');
      this.divStep1 = false;
      this.divStep2 = false;
      this.divStep3 = true;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      $("#step-count-second").addClass("current");
      $("#step-count-third").addClass("current");
    }
    else if (flag == 3) {
      this.backtonominee = false;
      this.backtovehiclediv = false;
      this.backtoproposerdiv = true;
    } else if (flag == 4) {
      if (this.proposalForm.value.OwnedBy == "Organization" || this.quoteModel.PACoverOwner == "Yes")
        this.backtoProposer(3);
      else
        this.backtoProposer(1);
    }
  }

  buildForm() {
    this.proposalForm = this.formBuilder.group({
      OwnedBy: ['', Validators.required],
      Salutation: ['', Validators.required],
      ProposerName: ['', Validators.required, Validators.minLength(5),
        Validators.maxLength(80),
        Validators.pattern('^[a-zA-Z ]*$')],
      FirstName: [''],
      LastName: [''],
      DateOfBirth: ['', Validators.required],
      //UserName:	['', Validators.required],
      Address1: ['', [Validators.required, Validators.maxLength(90)]],
      Address2: ['', Validators.required],
      Address3: ['', Validators.required],
      City: ['', Validators.required],
      State: ['', Validators.required],
      //Country:['', Validators.required],
      Pincode: ['', Validators.required, Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern('^[0-9]*$')],
      MobileNo: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Email: ['', [Validators.required, Validators.email]],
      CarRegistrationNo: ['', Validators.required],

      EnginNo: ['', [Validators.required, Validators.maxLength(17)]],
      ChassisNo: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(17)]],
      NomineeName: [''],
      AreaID: [''],
      Gender: [''],
      NomineeRelationShip: [''],
      NomineeAge: [''],
      IsFinanced: [''],
      CompanyId: [''],
      ExpiryPolicyNo: [''],
      ExpiryPolicyDate: [''],
      registationdate: ['', Validators.required],
      CarOwnershipChange: [''],
      ClaimMade: [''],
      LastNCB: [''],
      TPCompanyId: [''],
      TPPolicyNo: [''],
      TPStartDate: [''],
      previousPolicyType: [''],
      TPEndDate: [''],
      CKYCDocType: [''],
      CKYCDocId: [''],
      CKYCNumber: [''],
      CKYCNo: [''],
      CngLpgcheked:['']
    });
    //this.proposalForm.controls["OwnedBy"].setValue(this.quoteModel.OwnedBy);
    // this.proposalForm.controls["CarRegistrationNo"].setValue(this.quoteModel.RegistrationCode);
  }
  get f() { return this.proposalForm.controls; }
  getPremium() {
    debugger;
    let vehicledata = sessionStorage.getItem('vehicledata');
    let id = this.activeRouter.snapshot.queryParamMap.get('sessionId');
    let planid = this.activeRouter.snapshot.queryParamMap.get('planId');
    this.motorService.getQuotePremium(id, Number(planid)).subscribe((result: premiumModel) => {
      this.premium = result;
      this.productId = this.premium.ProductId;
      this.planId = this.premium.PlanId;
      //localStorage.setItem('TransactionID', this.premium.ClientQuoteNo.toString());
      this.TransactionId = (this.premium.ClientQuoteNo && this.premium.ClientQuoteNo !== undefined) ? this.premium.ClientQuoteNo.toString() : '';

      this.getSalutation();
      this.getNomineeRelation();
      this.getPrevInsurer();
      this.getBankName();

      this.getDocumnetType();

      if (sessionStorage.getItem('vehicleEngineno') == null) {
        sessionStorage.setItem('vehicleEngineno', '');
      }
      if (sessionStorage.getItem('vehicleChassino') == null) {
        sessionStorage.setItem('vehicleChassino', '');
      }
      this.proposalForm.controls['EnginNo'].setValue(sessionStorage.getItem('vehicleEngineno').toString());
      this.proposalForm.controls['ChassisNo'].setValue(sessionStorage.getItem('vehicleChassino').toString());
      this.motorService.getQuotationQuery('', id, 3).subscribe((result: QuotationModel) => {
        this.quoteModel = result;
        this.quoteModel.UserId = '';
        const currentDate = new Date();
        this.minExpDate = new Date();
        this.quoteModel.PreviousPolicyType = '';
        if (this.quoteModel.PreviousPolicyExpirDate != null) {
          if (this.quoteModel.PreviousPolicyExpirDate.length > 0) {

            this.proposalForm.controls['ExpiryPolicyDate'] = new FormControl(new Date(this.quoteModel.PreviousPolicyExpirDate));
            this.expdate = new FormControl(new Date(this.quoteModel.PreviousPolicyExpirDate));
          }
          else
            this.proposalForm.controls['ExpiryPolicyDate'] = new FormControl(currentDate);
        }
        else
          this.proposalForm.controls['ExpiryPolicyDate'] = null;
        //this.quoteModel.Addons=this.premium.Addons;

        //if(sessionStorage.getItem('PrevPolDtls')=="Yes")
        if (this.quoteModel.isPrevPolDtls == "Yes")
          this.isPrevPolDtls = true;
        else
          this.isPrevPolDtls = false;

        if (this.quoteModel.CurrentPolicyType == "RollOver") {
          this.minExpDate.setDate(currentDate.getDate() - 90);
          this.maxExpDate = currentDate;
          this.maxExpDate.setDate(currentDate.getDate() + 90);
        } else if (this.quoteModel.CurrentPolicyType == "Expired") {

          this.maxExpDate = currentDate;
          this.maxExpDate.setDate(currentDate.getDate() - 90);
          //currentDate.setFullYear(currentDate.getFullYear() - 15);
          this.minExpDate.setFullYear(currentDate.getFullYear() - 15);
        }

        const dtOfReg = new Date(Number(this.quoteModel.YearOfPurchase) - 1, 0, 1);
        this.minRegDate = dtOfReg;
        this.maxRegDate = new Date(Number(this.quoteModel.YearOfPurchase), 11, 31);

        if (this.quoteModel.CurrentPolicyType == 'NEW') {
          this.maxRegDate = new Date();
          this.minRegDate = currentDate;
          this.minRegDate.setMonth(currentDate.getMonth() - 6);
          this.maxRegDate.setMonth(this.maxRegDate.getMonth() + 6);
          $("#divregdate").css("pointer-events", "");
          this.isregDisabled = true;

        } else if (this.quoteModel.CurrentPolicyType == "RollOver") {
          $("#divregdate").css("pointer-events", "none");
          this.isregDisabled = false;
          this.proposalForm.get('ExpiryPolicyNo').setValidators(Validators.required);
          this.proposalForm.get('ExpiryPolicyDate').setValidators(Validators.required);
          this.proposalForm.get('CompanyId').setValidators(Validators.required);
          this.minRegDate = new Date();
          this.minRegDate.setFullYear(currentDate.getFullYear() - 15);

          const dtOfReg = new Date();
          dtOfReg.setMonth((new Date()).getMonth() - 6);;
          this.maxRegDate = dtOfReg;
        }


        if (this.quoteModel.CurrentPolicyType == 'NEW')
          this.proposalForm.controls['CarRegistrationNo'].setValue(this.quoteModel.RegistrationCode.replace('-', '') + ' ' + 'NEW');
        else {

          if (this.quoteModel.RegistrationCode) {
            if (this.quoteModel.RegistrationCode.length > 5)
              this.proposalForm.controls['CarRegistrationNo'].setValue(this.quoteModel.RegistrationCode);
            else
              this.proposalForm.controls['CarRegistrationNo'].setValue(this.quoteModel.RegistrationCode);
          }
          else {
            this.proposalForm.controls['CarRegistrationNo'].setValue(sessionStorage.getItem('registrationNumber'));
          }
          if (this.proposalForm.controls.CarRegistrationNo.value.length > 5) {
            if (this.proposalId == null || this.proposalId == "" || this.proposalId == '0' || this.proposalId == undefined) {
              this.getRtoDetails();
            }
          }
        }

        this.proposalForm.controls['OwnedBy'].setValue(this.quoteModel.OwnedBy);
        // let regdate=new Date(this.quoteModel.YearOfPurchase+'-'+this.quoteModel.MonthOfPurchase+'-'+this.quoteModel.DayOfPurchase);
        let regdate = (this.quoteModel.DayOfPurchase.length == 1 ? ('0' + this.quoteModel.DayOfPurchase) : this.quoteModel.DayOfPurchase) + '/' + (this.quoteModel.MonthOfPurchase.length == 1 ? ('0' + this.quoteModel.MonthOfPurchase) : this.quoteModel.MonthOfPurchase) + '/' + this.quoteModel.YearOfPurchase;
        // let dp = new DatePipe(navigator.language);
        // let dtr = dp.transform(regdate, 'dd/MM/yyyy');
        this.startdate = new Date(Number(this.quoteModel.YearOfPurchase), Number(this.quoteModel.MonthOfPurchase) - 1, Number(this.quoteModel.DayOfPurchase));
        // this.proposalForm.controls['registationdate'].setValue(regdate);
        if (this.quoteModel.CurrentPolicyType == 'NEW') {
          this.proposalForm.controls['registationdate'].setValue(this.pipe.transform(new Date(), 'dd/MM/yyyy'));
        }
        else {
          // this.proposalForm.controls['registationdate'].setValue(regdate);
          this.proposalForm.controls['registationdate'].setValue(this.startdate);
        }
        this.clickOwnedBy(this.quoteModel.OwnedBy);
        this.getCity(this.quoteModel.RegistrationCode.substring(0, 5));

        this.motorService.getProposalDetails(this.quoteModel.UserId, this.premium.ProductId, this.premium.PlanId, this.proposalId, this.quoteModel.SessionId).subscribe((res: ProposalDetails) => {

          if (res) {
            this.fillTheForm(res);
          }
        });
        if (this.proposalId != 0) {
          this.showPaymentLink();
        }
      })
    })
  }

  fillTheForm(data: ProposalDetails) {
    debugger
    this.proposalData = data;
    this.proposalForm.controls['FirstName'].setValue(data.FirstName);
    this.proposalForm.controls['LastName'].setValue(data.LastName);

    this.proposalForm.controls['Salutation'].setValue(data.Salutation);

    // this.proposalForm.controls['DateOfBirth']= new FormControl(new Date(data.DateOfBirth));
    this.proposalForm.controls['DateOfBirth'].setValue(data.DateOfBirth);

    // this.proposalForm.controls['City'].setValue(data.City);
    // this.proposalForm.controls['State'].setValue(data.State); 

    this.proposalForm.controls['Gender'].setValue(trim(data.Gender));
    this.proposalForm.controls['MobileNo'].setValue(data.MobileNo);
    this.proposalForm.controls['Email'].setValue(data.Email);

    this.proposalForm.controls['Address1'].setValue(data.Address1);
    this.proposalForm.controls['Address2'].setValue(data.Address2);
    this.proposalForm.controls['Address3'].setValue(data.Address3);

    this.proposalForm.controls['CarRegistrationNo'].setValue(data.CarRegistrationNo);
    this.proposalForm.controls['EnginNo'].setValue(data.EnginNo);
    this.proposalForm.controls['ChassisNo'].setValue(data.ChassisNo);

    this.proposalForm.controls['AreaID'].setValue(data.AreaID);
    if (data.Company != null) {
      this.proposalForm.controls['CompanyId'].setValue(Number(data.Company["CompanyId"]));
    }
    this.proposalForm.controls['ExpiryPolicyNo'].setValue(data.ExpiryPolicyNo);
    this.proposalForm.controls['previousPolicyType'].setValue(data.PreviousPolicyType);
    this.proposalForm.controls['ExpiryPolicyDate'].setValue(data.ExpiryPolicyDate);

    this.proposalForm.controls['CarOwnershipChange'].setValue(this.quoteModel.CarOwnershipChange);

    this.proposalForm.controls['NomineeName'].setValue(data.NomineeName);
    this.proposalForm.controls['NomineeAge'].setValue(data.NomineeAge);
    this.proposalForm.controls['NomineeRelationShip'].setValue(trim(data.NomineeRelationShip));


    if (data.IsFinanced == false) {
      this.proposalForm.controls['IsFinanced'].setValue('false');
    } else {
      this.proposalForm.controls['IsFinanced'].setValue('true');
    }

    this.proposalForm.controls['TPCompanyId'].setValue(Number(data.PremiumDetails["TPInsuranceCompany"]));
    this.proposalForm.controls['TPPolicyNo'].setValue(data.PremiumDetails["TPPolicyNumber"]);

    //let tpstartdt = new Date(data.TPStartDate);
    if (data.PremiumDetails["TPstartDate"] != null) {
      let tpstartdt = new Date(data.PremiumDetails["TPstartDate"].replace('AM', '').replace('PM', ''));
      this.proposalForm.controls['TPStartDate'].setValue(tpstartdt);
    }
    //let tpenddt = new Date(data.TPEndDate);
    if (data.PremiumDetails["TPEndDate"] != null) {
      let tpenddt = new Date(data.PremiumDetails["TPEndDate"].replace('AM', '').replace('PM', ''));
      this.proposalForm.controls['TPEndDate'].setValue(tpenddt);
    }
    debugger
    let regdate = (this.quoteModel.DayOfPurchase.length == 1 ? ('0' + this.quoteModel.DayOfPurchase) : this.quoteModel.DayOfPurchase) + '/' + (this.quoteModel.MonthOfPurchase.length == 1 ? ('0' + this.quoteModel.MonthOfPurchase) : this.quoteModel.MonthOfPurchase) + '/' + this.quoteModel.YearOfPurchase;

    this.startdate = new Date(Number(this.quoteModel.YearOfPurchase), Number(this.quoteModel.MonthOfPurchase) - 1, Number(this.quoteModel.DayOfPurchase));

    if (this.quoteModel.CurrentPolicyType == 'NEW') {
      this.proposalForm.controls['registationdate'].setValue(this.pipe.transform(new Date(), 'dd/MM/yyyy'));
    }
    else {
      // this.proposalForm.controls['registationdate'].setValue(regdate);
      this.proposalForm.controls['registationdate'].setValue(this.startdate);
    }

    this.getPincode(data.Pincode);

    let fullName = data.FirstName + ' ' + data.LastName;

    //$("input[name='ProposerName']").val(data.FirstName + ' ' + data.LastName);
    $("input[name='ProposerName']").val(data.UserName.replace(data.UserName.split(' ')[0], ''));
    $("input[name='Pincode']").val(data.Pincode);

    this.proposalForm.controls['ProposerName'].setValue(fullName);

  }

  selectEvent(e) {
    this.proposalData.BankName = e.name;
    this.proposalData.CityId = this.cityId;
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }

  getLatestPremim() {
    this.motorService.getQuotation(this.quoteModel.VehicleType, parseInt(this.productId), 0, this.planId, 0, 0, JSON.stringify(this.quoteModel)).subscribe((pemium: premiumModel) => {
      if (parseInt(pemium.FinalPremium) > 0) {
        this.premium = pemium;
      }
      //this.premiumList.push(pemium);   

    })
  }

  saveQuoteAndPremiumUpdate() {
    debugger;
    this.submitted = true;

    ///////////////////////////////
    if (this.proposalForm.controls.Salutation.value == '' || this.proposalForm.controls.Salutation.value == null || this.proposalForm.controls.Salutation.value == undefined) {
      Swal.fire('Please enter Salutation', '', 'error');
      return false;
    }
    //OmkarB
    //if (this.proposalForm.controls.DateOfBirth.value == '' || this.proposalForm.value.DateOfBirth == null || this.proposalForm.value.DateOfBirth == undefined) {
    //Swal.fire('Please enter Date Of Birth', '', 'error');
    //return false;
    //}
    if (this.proposalForm.controls.Salutation.value.toUpperCase() != 'M/S') {
      if ((this.proposalForm.controls.DateOfBirth.value == '' || this.proposalForm.value.DateOfBirth == null || this.proposalForm.value.DateOfBirth == undefined) && (this.proposalForm.value.Salutation.toUpperCase() != 'MS')) {
        Swal.fire('Please enter Date Of Birth', '', 'error');
        return false;
      }
    }
    else {
      if ((this.proposalForm.controls.DateOfBirth.value == '' || this.proposalForm.value.DateOfBirth == null || this.proposalForm.value.DateOfBirth == undefined) && (this.proposalForm.value.Salutation.toUpperCase() != 'MS')) {
        Swal.fire('Please enter Date Of Incorporation', '', 'error');
        return false;
      }
    }
    //OmkarB
    if (this.proposalForm.controls.ProposerName.value == '' || this.proposalForm.value.ProposerName == null || this.proposalForm.value.ProposerName == undefined) {
      this.proposalForm.controls['ProposerName'].setValue($("input[name='ProposerName']").val());
      Swal.fire('Please enter Full Name', '', 'error');
      return false;
    }
    //OmkarB
    if (this.proposalForm.controls.Salutation.value.toUpperCase() != 'M/S') {
      if (this.proposalForm.controls.Gender.value == '' || this.proposalForm.value.Gender == null || this.proposalForm.value.Gender == undefined) {
        Swal.fire('Please enter Gender', '', 'error');
        return false;
      }
    }
    //OmkarB
    if (this.proposalForm.controls.MobileNo.value == '' || this.proposalForm.value.MobileNo == null || this.proposalForm.value.MobileNo == undefined) {
      Swal.fire('Please enter Mobile Number', '', 'error');
      return false;
    }

    if (this.proposalForm.controls.Email.value == '' || this.proposalForm.value.Email == null || this.proposalForm.value.Email == undefined) {
      Swal.fire('Please enter Email', '', 'error');
      return false;
    }

    if (this.proposalForm.controls.Address1.value == '' || this.proposalForm.value.Address1 == null || this.proposalForm.value.Address1 == undefined) {
      Swal.fire('Please enter Address1', '', 'error');
      return false;
    }

    if (this.proposalForm.controls.Address2.value == '' || this.proposalForm.value.Address2 == null || this.proposalForm.value.Address2 == undefined) {
      Swal.fire('Please enter Address2', '', 'error');
      return false;
    }

    if (this.proposalForm.controls.Pincode.value == '' || this.proposalForm.value.Pincode == null || this.proposalForm.value.Pincode == undefined) {
      this.proposalForm.controls['Pincode'].setValue($("input[name='Pincode']").val());
      Swal.fire('Please enter Pincode', '', 'error');
      return false;
    }

    if (this.premium.CompanyId == 22) {
      if (this.proposalForm.controls.AreaID.value == '' || this.proposalForm.value.AreaID == null || this.proposalForm.value.AreaID == undefined) {
        Swal.fire('Please enter Area ID', '', 'error');
        return false;
      }
    }

    if (this.premium.CompanyId == 33) {
      if ((this.proposalForm.controls.Address1.value + '' + this.proposalForm.controls.Address2.value).length > 78) {
        Swal.fire('Address should not exceed 79 chharacters. ', '', 'error');
        return false;
      }
    }

    if (this.proposalForm.value.EnginNo == "" || this.proposalForm.value.EnginNo == null || this.proposalForm.value.EnginNo == undefined) {
      Swal.fire('Please enter Engine number', '', 'error');
      return false;
    }
    if (this.proposalForm.value.ChassisNo == "" || this.proposalForm.value.ChassisNo == null || this.proposalForm.value.ChassisNo == undefined) {
      Swal.fire('Please enter Chassis number', '', 'error');
      return false;
    }
    if (this.quoteModel.CurrentPolicyType != 'NEW') {
      if (!this.validateRegistrationNo((this.proposalForm.value.CarRegistrationNo).replace(' ', '-'))) {
        Swal.fire('Please enter valid registration number', '', 'error');
        return false;
      }
    }

    if (this.quoteModel.CurrentPolicyType == 'RollOver') {

      if (this.proposalForm.value.CompanyId == "" || this.proposalForm.value.CompanyId == null || this.proposalForm.value.CompanyId == undefined) {
        Swal.fire('Please enter Previous Insurer', '', 'error');
        return false;
      }
      if ((this.proposalForm.value.CompanyId == "67" && this.premium.CompanyId == 10) || (this.proposalForm.value.CompanyId == "214" && this.premium.CompanyId == 10)) {
        Swal.fire('Kindly choose another insurer as no renewal service available with Future Generali through us', '', 'error');
        return false;
      }
      if (this.proposalForm.value.previousPolicyType == "" || this.proposalForm.value.previousPolicyType == null || this.proposalForm.value.previousPolicyType == undefined) {
        Swal.fire('Please enter previous Policy Type', '', 'error');
        return false;
      }
      if (this.proposalForm.value.ExpiryPolicyNo == "" || this.proposalForm.value.ExpiryPolicyNo == null || this.proposalForm.value.ExpiryPolicyNo == undefined) {
        Swal.fire('Please enter previous Policy No', '', 'error');
        return false;
      }
      if (this.proposalForm.value.ExpiryPolicyDate == "" || this.proposalForm.value.ExpiryPolicyDate == null || this.proposalForm.value.ExpiryPolicyDate == undefined) {
        Swal.fire('Please enter previous Policy Expiry Date', '', 'error');
        return false;
      }
      if (this.quoteModel.PolicyType == 'StandaloneOD') {
        if (this.proposalForm.value.TPCompanyId == "" || this.proposalForm.value.TPCompanyId == null || this.proposalForm.value.TPCompanyId == undefined) {
          Swal.fire('Please enter previous TP Insurer', '', 'error');
          return false;
        }
        if (this.proposalForm.value.TPPolicyNo == "" || this.proposalForm.value.TPPolicyNo == null || this.proposalForm.value.TPPolicyNo == undefined) {
          Swal.fire('Please enter previous TP Policy No', '', 'error');
          return false;
        }
        if (this.proposalForm.value.TPStartDate == "" || this.proposalForm.value.TPStartDate == null || this.proposalForm.value.TPStartDate == undefined) {
          Swal.fire('Please enter previous TP Policy Start Date', '', 'error');
          return false;
        }
        if (this.proposalForm.value.TPEndDate == "" || this.proposalForm.value.TPEndDate == null || this.proposalForm.value.TPEndDate == undefined) {
          Swal.fire('Please enter previous  TP Policy End Date ', '', 'error');
          return false;
        }
      }
    }

    if (this.quoteModel.CurrentPolicyType != 'NEW') {
      if (this.proposalForm.value.CarOwnershipChange == "" || this.proposalForm.value.CarOwnershipChange == null || this.proposalForm.value.CarOwnershipChange == undefined) {
        Swal.fire('Please select whether Ownership Transfer or not', '', 'error');
        return false;
      }
    }




    //////////////////////////////



    let fullname = this.proposalForm.controls['ProposerName'].value;
    //OmkarB
    //if (this.proposalForm.value.OwnedBy == "Individual") {
    //OmkarB
    //  if(fullname.indexOf(' ')>0)
    //  {
    let lastName = fullname.split(' ')[fullname.split(' ').length - 1];
    this.proposalForm.controls['LastName'].setValue(lastName);
    this.proposalForm.controls['FirstName'].setValue(trim(fullname.replace(lastName, '')));

    //}
    //  else{
    //   Swal.fire('Enter lastname', '', 'error');
    //   return;
    //  }
    //}

    if (this.proposalForm.controls['DateOfBirth'].value == null) {
      this.proposalForm.controls['DateOfBirth'].setValue("");
    }
    //OmkarB
    //if (this.proposalForm.controls['DateOfBirth'].value == '') {
    //Swal.fire('Please enter Date Of Birth', '', 'error');
    //return false;
    //}
    if (this.proposalForm.controls.Salutation.value.toUpperCase() != 'M/S') {
      if (this.proposalForm.controls['DateOfBirth'].value == '' && (this.proposalForm.value.Salutation.toUpperCase() != 'MS')) {
        Swal.fire('Please enter Date Of Birth', '', 'error');
        return false;
      }
    }
    else {
      if ((this.proposalForm.controls.DateOfBirth.value == '' || this.proposalForm.value.DateOfBirth == null || this.proposalForm.value.DateOfBirth == undefined) && (this.proposalForm.value.Salutation.toUpperCase() != 'MS')) {
        Swal.fire('Please enter Date Of Incorporation', '', 'error');
        return false;
      }
    }
    //OmkarB
    if (Number(this.proposalForm.controls['NomineeAge'].value) <= 18) {
      Swal.fire('Nominee Age Should be 18 or more', '', 'error');
      return;
    }
    if (Number(this.proposalForm.controls['NomineeAge'].value) > 85) {
      Swal.fire('Nominee Age Should be 85 or less', '', 'error');
      return;
    }
    if (this.quoteModel.PACoverOwner == "Yes") {
      debugger;
      if (this.proposalForm.value.NomineeName == "") {
        Swal.fire('Please enter Nominee Name', '', 'error');
        return;
      }
      if (this.proposalForm.value.NomineeRelationShip == "") {
        Swal.fire('Please select Nominee RelationShip', '', 'error');
        return;
      }
      if (this.proposalForm.value.NomineeAge == "") {
        Swal.fire('Please enter Nominee Age', '', 'error');
        return;
      }
    }
    if (this.proposalForm.value.IsFinanced == "" || this.proposalForm.value.IsFinanced == null || this.proposalForm.value.IsFinanced == undefined) {
      Swal.fire('Please select whether vehicle financed or not', '', 'error');
      return;
    }
    console.log(this.proposalForm.errors);
    // stop here if form is invalid
    if (this.proposalForm.invalid) {
      this.validateAllFormFields(this.proposalForm);
      return;
    }
    this.proposalData.SessionId = this.quoteModel.SessionId;
    this.proposalData.Salutation = this.proposalForm.value.Salutation;
    this.proposalData.FirstName = this.proposalForm.value.FirstName;
    this.proposalData.LastName = this.proposalForm.value.LastName;
    this.proposalData.UserName = this.proposalData.Salutation + ' ' + this.proposalData.FirstName + ' ' + this.proposalData.LastName;
    this.proposalData.DateOfBirth = this.proposalForm.value.DateOfBirth == null ? "" : this.proposalForm.value.DateOfBirth;
    //OmkarB
    // if (this.proposalForm.controls['DateOfBirth'].value == '') {
    // Swal.fire('Please enter Date Of Birth', '', 'error');
    //return false;
    //}
    if (this.proposalForm.controls.Salutation.value.toUpperCase() != 'M/S') {
      if ((this.proposalForm.controls.DateOfBirth.value == '' || this.proposalForm.value.DateOfBirth == null || this.proposalForm.value.DateOfBirth == undefined) && (this.proposalForm.value.Salutation.toUpperCase() != 'MS')) {
        Swal.fire('Please enter Date Of Birth', '', 'error');
        return false;
      }
    }
    else {
      if ((this.proposalForm.controls.DateOfBirth.value == '' || this.proposalForm.value.DateOfBirth == null || this.proposalForm.value.DateOfBirth == undefined) && (this.proposalForm.value.Salutation.toUpperCase() != 'MS')) {
        Swal.fire('Please enter Date Of Incorporation', '', 'error');
        return false;
      }
    }
    //OmkarB
    let birthdate = new Date();
    if ((this.proposalForm.value.DateOfBirth).toString().includes('/')) {
      let arr = this.proposalForm.value.DateOfBirth.split('/');
      birthdate = new Date(arr[2] + '-' + arr[1] + '-' + arr[0]);
    }
    else {
      birthdate = new Date(this.proposalForm.value.DateOfBirth);
    }
    // if (this.isPrevPolDtls == false) {
    //   this.proposalData.DateOfBirth = "";
    // } else {
    this.proposalData.DateOfBirth = this.pipe.transform(birthdate, 'yyyy-MM-dd');
    //}

    this.proposalData.Gender = this.proposalForm.value.Gender;
    this.proposalData.MobileNo = this.proposalForm.value.MobileNo;
    this.proposalData.Email = this.proposalForm.value.Email;
    this.proposalData.Address1 = this.proposalForm.value.Address1;
    this.proposalData.Address2 = this.proposalForm.value.Address2;
    this.proposalData.Address3 = this.proposalForm.value.Address3;
    this.proposalData.EnginNo = this.proposalForm.value.EnginNo;
    this.proposalData.ChassisNo = this.proposalForm.value.ChassisNo;
    if (this.quoteModel.CurrentPolicyType == 'NEW') {
      this.proposalData.CarRegistrationNo = 'NEW';
    } else {
      this.proposalData.CarRegistrationNo = this.proposalForm.value.CarRegistrationNo;
    }

    this.proposalData.NomineeName = this.proposalForm.value.NomineeName;
    this.proposalData.NomineeRelationShip = this.proposalForm.value.NomineeRelationShip;
    this.proposalData.NomineeAge = this.proposalForm.value.NomineeAge == "" ? "0" : this.proposalForm.value.NomineeAge;

    this.proposalData.IsFinanced = this.proposalForm.value.IsFinanced;
    this.proposalData.CompanyId = this.proposalForm.value.CompanyId;

    this.proposalData.AreaID = this.proposalForm.value.AreaID;
    this.proposalData.City = this.cityId.toString();
    //this.proposalData.State=this.stateCode;
    this.proposalData.ExpiryPolicyNo = this.proposalForm.value.ExpiryPolicyNo;
    if (this.isPrevPolDtls == false) {
      this.proposalData.ExpiryPolicyDate = "";
    } else {
      this.proposalData.ExpiryPolicyDate = this.pipe.transform(new Date(this.proposalForm.value.ExpiryPolicyDate), 'yyyy-MM-dd');
    }
    debugger;
    let regDate = new Date();
    if ((this.proposalForm.value.registationdate).toString().includes('/')) {
      let arr = this.proposalForm.value.registationdate.split('/');
      regDate = new Date(arr[2] + '-' + arr[1] + '-' + arr[0]);
    }
    else {
      regDate = new Date(this.proposalForm.value.registationdate);
    }
    // let regDate=new Date(this.proposalForm.value.registationdate);
    this.quoteModel.DayOfPurchase = (regDate.getDate()).toString();
    this.quoteModel.MonthOfPurchase = (regDate.getMonth() + 1).toString();
    // this.quoteModel.DateOfRegistration=(regDate.getDay()+1).toString();
    this.quoteModel.DateOfRegistration = (regDate.getDate()).toString();
    this.quoteModel.YearOfPurchase = (regDate.getFullYear()).toString();
    this.quoteModel.ManufacturerYear = (regDate.getFullYear()).toString();
    if (this.isPrevPolDtls == false) {
      this.quoteModel.PreviousPolicyExpirDate = "";
    } else {
      this.quoteModel.PreviousPolicyExpirDate = this.pipe.transform(new Date(this.proposalData.ExpiryPolicyDate), 'yyyy-MM-dd');
    }
    this.quoteModel.PreviousPolicyType = this.proposalForm.controls['previousPolicyType'].value;
    this.quoteModel.CarOwnershipChange = this.proposalForm.value.CarOwnershipChange;
    this.quoteModel.ClaimMade = this.quoteModel.ClaimMade;
    // this.quoteModel.LastNCB=this.proposalForm.value.LastNCB;
    // this.quoteModel.CurrentNCB="";
    if (this.quoteModel.ClaimMade == "No") {
      if (this.quoteModel.LastNCB != '') {
        if (this.quoteModel.PolicyType == 'ThirdParty') {
          this.quoteModel.CurrentNCB = "0";
        }
        else {
          this.quoteModel.CurrentNCB = this.noclaimBonus[parseInt(this.quoteModel.LastNCB)];
        }

      } else
        this.quoteModel.CurrentNCB = "";
    }
    if (this.quoteModel.isPrevPolDtls == "No") {
      this.quoteModel.CurrentNCB = "";
    }
    this.proposalData.CurrentPolicyType = this.quoteModel.CurrentPolicyType;
    this.proposalData.PreviousPolicyType = this.quoteModel.PreviousPolicyType;

    this.proposalData.TPInsuranceCompany = this.proposalForm.value.TPCompanyId;
    this.proposalData.TPPolicyNumber = this.proposalForm.value.TPPolicyNo;
    this.proposalData.TPStartDate = this.proposalForm.value.TPStartDate;
    this.proposalData.TPEndDate = this.proposalForm.value.TPEndDate;

    this.quoteModel.OwnedBy = this.proposalForm.value.OwnedBy;
    //this.quoteModel.ExField2=this.premium.CompanyId==5?localStorage.getItem('TransactionID').toString():'';
    this.quoteModel.ExField2 = this.premium.CompanyId == 5 ? this.TransactionId.toString() : '';

    this.motorService.getQuotation(this.quoteModel.VehicleType, parseInt(this.premium.ProductId), 0, this.premium.PlanId, parseInt(this.premium.ProductIDV), 0, JSON.stringify(this.quoteModel)).subscribe((pemium: premiumModel) => {
      if (pemium != null && parseInt(pemium.FinalPremium) > 0)
        this.premium = pemium;
      console.log(this.premium);
      if (this.premium.ODStartDate != null) {
        this.premium.ODStartDate = this.premium.ODStartDate.replace(' 12:00:00 AM', '');
        this.premium.ODEndDate = this.premium.ODEndDate.replace(' 12:00:00 AM', '');
      }

      if (this.premium.TPStartDate != null) {
        this.premium.TPStartDate = this.premium.TPStartDate.replace(' 12:00:00 AM', '');
        this.premium.TPEndDate = this.premium.TPEndDate.replace(' 12:00:00 AM', '');
      }

      this.premium.SessionId = this.quoteModel.SessionId;
      this.motorService.postPremiumData([this.quoteModel], [this.premium], this.quoteModel.Addons).subscribe((result: any) => {
        if (result && result.success == "done")
          Swal.fire('Premium updated to ₹' + this.premium.FinalPremium, '', 'success');

        //show premium break up

      })
      $("#basicModal").modal("show");

      if (this.premium.CompanyId == 33) {
        this.saveKycDetails();
      }


      this.saveProposal();
    })
  }

  saveKycDetails() {
    let dob = this.pipe.transform(new Date(this.proposalForm.controls.DateOfBirth.value), 'dd-MM-yyyy');
    this.CKYCDocId = this.CKYCDocId.toUpperCase();

    var val = {
      isKYCDone: false,
      ckycReferenceDocId: this.CKYCDocType,
      ckycReferenceNumber: this.CKYCDocId,
      dateOfBirth: dob,
      photo: ''
    }

    debugger
    this.motorService.SaveKycDetails(val, [this.productId], [this.quoteModel.SessionId]).then((result: InitiateCKYCResp) => {

    });
  }
  saveProposal() {
debugger;
    this.proposalData.UserId = this.quoteModel.UserId;
    if (this.quoteModel.UserId == null)
      this.proposalData.UserId = '';
    this.proposalData.ProductId = parseInt(this.productId);
    this.proposalData.PlanId = this.planId;
    this.proposalData.Portal = 'B2C';

    this.motorService.postProposal(this.proposalData).subscribe(result => {

      this.proposalId = result;
      this.proposalData.PolicyId = Number(result);
      console.log('Current result: ', result);


    })

  }

  goPayment() {
    if (this.proposalId != null && this.proposalId != "") {
      let paymentUrl = AppSettings.DomainUrl + '/motorproposal/Processproposal?ProposalId=' + this.proposalId + '&ProductId=' + this.productId + '&sessionId=' + this.quoteModel.SessionId;
      //window.open(paymentUrl, '_blank');
      window.location.href = paymentUrl;
    }
  }

  showPaymentLink() {
    debugger;
    this.motorService.getQuotation(this.quoteModel.VehicleType, parseInt(this.premium.ProductId), 0, this.premium.PlanId, parseInt(this.premium.ProductIDV), 0, JSON.stringify(this.quoteModel)).subscribe((pemium: premiumModel) => {
      if (pemium != null && parseInt(pemium.FinalPremium) > 0)
        this.premium = pemium;
      if (this.premium.ODStartDate != null) {
        this.premium.ODStartDate = this.premium.ODStartDate.replace(' 12:00:00 AM', '');
        this.premium.ODEndDate = this.premium.ODEndDate.replace(' 12:00:00 AM', '');
      }

      if (this.premium.TPStartDate != null) {
        this.premium.TPStartDate = this.premium.TPStartDate.replace(' 12:00:00 AM', '');
        this.premium.TPEndDate = this.premium.TPEndDate.replace(' 12:00:00 AM', '');
      }

      this.premium.SessionId = this.quoteModel.SessionId;
      this.motorService.postPremiumData([this.quoteModel], [this.premium], this.quoteModel.Addons).subscribe((result: any) => {

      })
      $("#basicModal").modal("show");
    })
  }

  OwnedByClick(e) {
    if (e.target.value == 'C') {
      this.quoteModel.OwnedBy = 'Corporate';
      $('#show-me').hide();
      if (this.premium.CompanyId == 22) {
        this.proposalForm.controls['Salutation'].setValue('M/S');
      }
      else {
        this.proposalForm.controls['Salutation'].setValue('MS');
      }

      this.proposalForm.controls['Salutation'].disable();
    }
    else {
      $('#show-me').show();
      this.quoteModel.OwnedBy = 'Individual';
      this.proposalForm.controls['Salutation'].setValue('');
      this.getSalutation();
      this.proposalForm.controls['Salutation'].enable();
    }
    this.SalutationChange();
  }

  clickOwnedBy(ownedBy) {
    debugger
    this.quoteModel.OwnedBy = ownedBy;
    if (ownedBy == 'Individual') {
      this.proposalForm.get('LastName').setValidators(Validators.required);
      this.proposalForm.get('DateOfBirth').setValidators(Validators.required);
      this.proposalForm.get('Gender').setValidators(Validators.required);
      // this.proposalForm.get('NomineeName').setValidators(Validators.required); 
      // this.proposalForm.get('NomineeRelationShip').setValidators(Validators.required); 
      this.proposalForm.get('NomineeAge').setValidators([Validators.pattern("^(?:1[8-9]|[2-5][0-9]|85)$")]);
      //OmkarB
      //this.proposalForm.controls['Salutation'].setValue('');
      //OmkarB
    } else {
      this.proposalForm.controls["LastName"].clearValidators();
      this.proposalForm.get('NomineeName').clearValidators();
      this.proposalForm.get('NomineeRelationShip').clearValidators();
      this.proposalForm.get('NomineeAge').setValidators(Validators.required);

      // this.proposalForm.get('LastName').setValidators(Validators.nullValidator); 
      this.proposalForm.get('DateOfBirth').clearValidators();
      this.proposalForm.get('Gender').clearValidators();
      //Omkarb
      //this.proposalForm.controls['Salutation'].setValue('M/S');
      //Omkarb
      // this.proposalForm.controls['DateOfBirth'].setValue('1900-01-01');
    }
    this.proposalForm.controls["LastName"].updateValueAndValidity();
    this.proposalForm.get('DateOfBirth').updateValueAndValidity();
    this.proposalForm.get('Gender').updateValueAndValidity();
    this.proposalForm.controls["LastName"].updateValueAndValidity();
    this.proposalForm.get('NomineeName').updateValueAndValidity();
    this.proposalForm.get('NomineeRelationShip').updateValueAndValidity();
  }

  getCity(rtoCode) {
    this.masterService.GetCityOfRegistration(rtoCode).subscribe(result => {
      this.registrationCode = result;
    })
  }

  getSalutation() {
    this.motorService.getSalutations(2, this.premium.CompanyId).subscribe((response: Salutation[]) => {
      this.salutations = response;
    })
  }

  getNomineeRelation() {
    this.motorService.getNomineeRelation(2, this.premium.CompanyId).subscribe((response: NomineeRelation[]) => {
      this.nomineeRelations = response;
    })
  }

  getPrevInsurer() {
    this.motorService.getPrevoiusInsurer(this.premium.CompanyId).subscribe((response: InsurerCompany[]) => {
      this.prevInsurers = response;
    })
  }

  getPincode(pincode) {

    this.motorService.getCityPincode(pincode).subscribe((result: any) => {
      this.proposalData.Pincode = result[0].PinCode;
      this.proposalData.City = result[0].CityName;
      this.cityId = result[0].CityId;
      this.stateCode = result[0].StateCode;
      this.proposalData.State = result[0].StateCode;
      this.proposalForm.controls['City'].setValue(result[0].CityName);
      this.proposalForm.controls['State'].setValue(result[0].StateName);
      //this.proposalForm.controls['Pincode'].setValue(pincode.trim());
      this.getAreas(pincode);
    }
    );


  }
  getAreas(pincode) {
    this.motorService.getArea(pincode, this.premium.CompanyId).subscribe((response: any[]) => {
      this.areas = response;
    })
    this.proposalForm.controls['Pincode'].setValue(pincode.trim());
  }
  getBankName() {
    this.motorService.getBankName('0', this.premium.CompanyId).subscribe((response: BankName[]) => {
      this.bankNames = response;
      if (this.bankNames.length > 0) {

        this.bankNames.forEach((v, i) => {
          if (v.BankId == null) v.BankId = i;
          this.autoCompleteData.push({ id: v.BankId, name: v.BankName, address: v.BankName });
        })

      }

    })
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }


  registrationNoMasking(value) {

    $("input[name='number']").val(destroyMask(value));
    let MaskedRegNo = createMask($("input[name='number']").val());
    $("input[name='regNumber']").val(MaskedRegNo);
    this.proposalForm.controls['CarRegistrationNo'].setValue((MaskedRegNo.trim()));

    var full = 0; var trueLen = 0;
    function createMask(string) {
      console.log(string)
      console.log(string.length);
      var last4char = '';
      var reg = /^\d+$/;
      if (string.length > 8) {
        last4char = string.substr(string.length - 4)
      }
      if (last4char != '') {
        if (reg.test(last4char)) {
          full = 1;
          trueLen = string.length;
          console.log(full);
          if (string.length == 9) {
            return string.replace(/(\w{2})(\w{2})(\w{1})(\d{4})/, "$1-$2-$3-$4");
          }
          if (string.length == 10) {
            return string.replace(/(\w{2})(\w{2})(\w{2})(\d{4})/, "$1-$2-$3-$4");
          }
          if (string.length == 11) {
            return string.replace(/(\w{2})(\w{2})(\w{3})(\d{4})/, "$1-$2-$3-$4");
          }

        }
        else return string;
      }

      else return string;

    }

    function destroyMask(string) {
      console.log(string)
      console.log(full)
      if (full == 1) {
        console.log('trueLen ' + trueLen)
        return string.replace(/\W/g, '').substring(0, trueLen);
      }

      var last4char = '';
      var reg = /^\d+$/;
      if (string.length > 8) {
        last4char = string.substr(string.length - 4)
      }
      if (last4char != '') {
        if (reg.test(last4char)) {
          return string.replace(/\W/g, '').substring(0, string.length);
        }
      }

      return string.replace(/\W/g, '').substring(0, 14);
    }
  }
  backToquotation() {
    let sessionId = this.activeRouter.snapshot.queryParamMap.get('sessionId');
    this.router.navigate(['/bike-quotation'], { queryParams: { sessionId: sessionId } });
  }
  divManufacturer() {
    this.router.navigateByUrl('/rto-registration?type=ROLLOVER');
  }

  proposerNameChange(ProposerName: any) {
    ProposerName = ProposerName.replace(/  +/g, ' ');
    this.proposalForm.controls['ProposerName'].setValue((ProposerName.trim()));
  }

  alphaOnly($event) {
    var pattern = /^[a-zA-Z\s]*$/;///^[A-Za-z]+$/;
    const inputChar = $event.key;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  onBlurMethod(val) {
    document.getElementById("mobile").style.border = "1px solid #A5A5A5";
    const regex = /^[6-9]\d{9}$/gm;
    const str = val;
    let m;
    if (m = regex.exec(str) == null) {

      document.getElementById("mobile").style.border = "1px solid red";

    } else {
      while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          //alert(`Found match, group ${groupIndex}: ${match}`);              

        });
      }
    }
  }
  onlyNumber($event) {
    const pattern = /[0-9]/;
    const inputChar = $event.key;
    if (!pattern.test(inputChar.toString())) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  NumberAndAlphaOnly($event) {
    var pattern = /^[0-9a-zA-Z\_]+$/;///^[A-Za-z]+$/;
    const inputChar = $event.key;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  copyUrl(type) {
    this.isSelected = type;
    if (type == 'Email') {
      this.urlCopy = '';
      this.isEmail = true;
    }
    if (type == 'SMS') {
      this.urlCopy = '';
      this.isSMS = true;
    }
    if (type == 'Link') {
      debugger;
      this.urlCopy = window.location.href;
      this.urlCopy = this.urlCopy + "&proposalid=" + this.proposalId;
      var $temp = $("<input>");
      var $url = $(location).attr('href') + "&proposalid=" + this.proposalId;
      $("body").append($temp);
      $temp.val($url).select();
      document.execCommand("copy");
      $temp.remove();
      //$("p").text("URL copied!");  
      Swal.fire('URL copied!', '', 'success');

      $("#shareQuote").modal("hide");
    }
    if (type == 'copy') {
      this.urlCopy = window.location.href;
    }
  }
  removeSpace(value) {
    return trim(value);
  }

  shareQuote() {
    if (this.isSelected == "") {
      Swal.fire('Plz. Select any one share option', '', 'error');
      return;
    }
    // let textEmail=this.textEmail.nativeElement.value;
    let textEmail = this.Email;
    let textMobile = this.Mobile;
    if (this.isEmail == true) {
      if (textEmail == "") {
        Swal.fire('Plz. enter Email Id', '', 'error');
        return;
      } else {
        if (this.quoteModel.PolicyType == 'Comprehensive') {
          this.premium.ProductName = 'Comprehensive/Package';

        }
        if (this.quoteModel.PolicyType == 'ThirdParty') {
          this.premium.ProductName == 'Stand Alone Third Party';
        }

        if (this.quoteModel.PolicyType == 'StandaloneOD') {
          this.premium.ProductName == 'Stand Alone Own Damage';
        }

        this.quoteModel.RegistrationCode = this.proposalForm.controls['CarRegistrationNo'].value;
        this.quoteModel.ExField1 = textEmail;
        this.quoteModel.ExField2 = window.location.href + "&proposalid=" + this.proposalId;
        this.quoteModel.UserId = this.proposalForm.controls['Email'].value;
        this.quoteModel.IMT23 = this.proposalForm.controls['MobileNo'].value;
        this.motorService.QuotesMailer([this.quoteModel], [this.premium], this.proposalId).subscribe((result: any) => {
          if (result > 0) {
            $("#shareQuote").modal("hide");
            Swal.fire('Two wheeler proposal details successfully send.', '', 'success');
          }
          else {
            Swal.fire('Two wheeler proposal details not successfully send.', '', 'error');
          }

        });
      }
    }
    if (this.isSMS == true) {
      debugger;
      if (textMobile == "") {
        Swal.fire('Please enter Mobile number', '', 'error');
        return;
      } else {
        debugger;
        this.quoteModel.ExField1 = textMobile;
        this.quoteModel.ExField2 = window.location.href.replace('&', '%26');
        let url = window.location.href + "&proposalid=" + this.proposalId;
        // this.quoteModel.UserId=this.proposalForm.controls['Email'].value;
        // this.quoteModel.IMT23=this.proposalForm.controls['MobileNo'].value;
        this.motorService.QuotesSMS(textMobile, url.replace('&', '%26'), this.proposalForm.value.ProposerName, 2).subscribe((result: any) => {
          if (result > 0) {
            $("#shareQuote").modal("hide");
            Swal.fire('Two wheeler proposal details successfully sent.', '', 'success');
          }
          else {
            Swal.fire('Two wheeler proposal details successfully sent.', '', 'error');
          }

        });
      }
    }
  }


  validateRegistrationNo(regNo) {
    debugger;
    const regex = /^[A-Z]{2}[-][0-9A-Z]{1,2}[-](?:[A-Z])?(?:[A-Z]*)[-][0-9]{4}$/gm;
    const str = regNo.toUpperCase();
    let m;
    if (m = regex.exec(str) == null) {
      return false;
    }
    return true;
  }

  getRtoDetails() {

    let registrationNumber = this.proposalForm.value.CarRegistrationNo.toUpperCase();
    if (registrationNumber == "") {
      Swal.fire('Please enter registration number', '', 'error');
      return false;
    }
    if (!this.validateRegistrationNo(registrationNumber)) {
      Swal.fire('Please enter valid registration number', '', 'error');
      return false;
    }
    let regNo = registrationNumber;

    this.motorService.getflaVehicleByRegNo(regNo.replace(' ', '')).subscribe((fladata: any) => {
      if (fladata) {

        debugger
        if (fladata.fla_vh_class_desc) {
          if (fladata.fla_vh_class_desc.toUpperCase() == "LMV") {
            Swal.fire({
              // title: '<strong>Confirmation </strong>',
              text: 'Entered vehicle registration number is of a Private Car.',
              icon: 'error',
              allowOutsideClick: false,
              //showCancelButton: true,
              confirmButtonText: 'OK',
              //cancelButtonText: 'Cancel',
            }).then((result) => {

              if (result.isConfirmed) {
                this.proposalForm.controls['CarRegistrationNo'].setValue('');
                return false;
              } else if (result.isDismissed) {
              }
            })
          }
          else if (fladata.fla_vh_class_desc.toUpperCase() == "CV") {
            Swal.fire({
              // title: '<strong>Confirmation </strong>',
              text: 'Entered vehicle registration number is of a Goods Carrying vehicle.',
              icon: 'error',
              allowOutsideClick: false,
              // showCancelButton: true,
              confirmButtonText: 'OK',
              //cancelButtonText: 'Cancel',
            }).then((result) => {

              if (result.isConfirmed) {
                this.proposalForm.controls['CarRegistrationNo'].setValue('');
                return false;
              } else if (result.isDismissed) {
              }
            })
            //this.router.navigateByUrl('/');
            // return false;
          }

          else if (fladata.fla_vh_class_desc.toUpperCase() == "2W") {

            var validateFLAData = true;

            if (fladata.ManufacturerId != this.quoteModel.ManufacturerId)
              validateFLAData = false
            else if (fladata.ManufacturerName != this.quoteModel.ManufacturerName)
              validateFLAData = false
            else if (fladata.ModelId != this.quoteModel.ModelId)
              validateFLAData = false
            else if (fladata.ModelName != this.quoteModel.ModelName)
              validateFLAData = false
            else if (fladata.VariantId != this.quoteModel.VariantId)
              validateFLAData = false
            else if (fladata.VariantName != this.quoteModel.VariantName)
              validateFLAData = false
            else if (fladata.YearOfPurchase != this.quoteModel.YearOfPurchase)
              validateFLAData = false
            else if (fladata.DayOfPurchase != (this.quoteModel.DayOfPurchase.length == 1 ? ('0' + this.quoteModel.DayOfPurchase) : this.quoteModel.DayOfPurchase))
              validateFLAData = false
            else if (fladata.MonthOfPurchase !=(this.quoteModel.MonthOfPurchase.length == 1 ? ('0' + this.quoteModel.MonthOfPurchase) : this.quoteModel.MonthOfPurchase))
              validateFLAData = false
            // else if (fladata.MonthOfPurchase != this.quoteModel.MonthOfPurchase)
            //   validateFLAData = false

            if (validateFLAData == true) {
              Swal.fire({
                // title: '<strong>Confirmation </strong>',
                text: 'Vehicle details updated, Kindly recheck the filled in details',
                icon: 'success',
                allowOutsideClick: false,
                // showCancelButton: true,
                confirmButtonText: 'OK',
                //cancelButtonText: 'Cancel',
              }).then((result) => {

                if (result.isConfirmed) {

                  sessionStorage.setItem('registrationNumber', registrationNumber);
                  sessionStorage.setItem('vehicleEngineno', fladata.vehicleEngineno);
                  sessionStorage.setItem('vehicleChassino', fladata.vehicleChassino);

                  this.proposalForm.controls['EnginNo'].setValue(sessionStorage.getItem('vehicleEngineno').toString() != "" ? sessionStorage.getItem('vehicleEngineno').toString() : "");
                  this.proposalForm.controls['ChassisNo'].setValue(sessionStorage.getItem('vehicleChassino').toString() != "" ? sessionStorage.getItem('vehicleChassino').toString() : "");

                  if (fladata.insurance_policy_no)
                    this.proposalForm.controls['ExpiryPolicyNo'].setValue(fladata.insurance_policy_no);
                  if (fladata.insurance_upto) {
                    //  this.proposalForm.controls['ExpiryPolicyDate'] = new FormControl(new Date(fladata.insurance_upto));
                  }

                  let FLAPincode
                  if (fladata.AddressLine1) {
                    fladata.AddressLine1 = fladata.AddressLine1.trim();
                    FLAPincode = fladata.AddressLine1.substr(fladata.AddressLine1.length - 6)
                    if (!Number.isNaN(Number(FLAPincode))) {
                      if (FLAPincode != '999999') {
                        this.proposalForm.controls['Pincode'].setValue(' ' + FLAPincode);
                        this.getPincode(FLAPincode.trim());
                      }
                    }

                    let arr = fladata.AddressLine1.split(',');
                    if ((arr[0] + arr[1]).length < 78) {
                      if (arr.length > 3) {
                        this.proposalForm.controls['Address1'].setValue(arr[0]);
                        this.proposalForm.controls['Address2'].setValue(arr[1] + ' ' + arr[2]);
                      }
                      else {
                        this.proposalForm.controls['Address1'].setValue(arr[0]);
                        this.proposalForm.controls['Address2'].setValue(arr[1]);
                      }
                    }
                  }
                  //this.proposalForm.controls['ExpiryPolicyNo'].setValue( fladata.FinancierName);
                  this.proposalForm.controls['ProposerName'].setValue(fladata.OwnerName);

                } else if (result.isDismissed) {

                }
              })

            }
            else {
              Swal.fire({
                // title: '<strong>Confirmation </strong>',
                text: 'Vehicle Details Do Not Match, Recalculate Premium on Quote page with Correct Details',
                icon: 'error',
                allowOutsideClick: false,
                // showCancelButton: true,
                confirmButtonText: 'OK',
                //cancelButtonText: 'Cancel',
              }).then((result) => {

                if (result.isConfirmed) {
                  // this.router.navigateByUrl('/');
                  this.proposalForm.controls['CarRegistrationNo'].setValue('');
                  this.backToquotation();
                  return false;

                } else if (result.isDismissed) {

                }
              })
            }
          }
        }
        //////////////////

      }
      else {
        //this.vehicleDetails.RegistrationCode = registrationNumber.substring(0, 5);
        Swal.fire({
          // title: '<strong>Confirmation </strong>',
          text: 'Vehicle registration number is not available.',
          icon: 'error',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Edit',
          cancelButtonText: 'Cancel',
        }).then((result) => {

          if (result.isConfirmed) {
            this.proposalForm.controls['CarRegistrationNo'].setValue('');
            return false;
          } else if (result.isDismissed) {
            this.router.navigateByUrl('/');
            return false;
          }
        })
      }
    }, ((err: ErrorHandle) => {

      Swal.fire({
        // title: '<strong>Confirmation </strong>',
        text: 'Vehicle registration number is not available.',
        icon: 'error',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: 'Edit',
        cancelButtonText: 'Cancel',
      }).then((result) => {

        if (result.isConfirmed) {
          this.proposalForm.controls['CarRegistrationNo'].setValue('');
          return false;
        } else if (result.isDismissed) {
          this.router.navigateByUrl('/');
          return false;
        }
      })
    }));

  }
  showInspection() {
    if (this.intimationMessagemodal == true) {
      this.intimationMessagemodal = false;
    }
    else {
      this.intimationMessagemodal = true;
    }
  }
  hideInspection() {
    this.intimationMessagemodal = false;
  }
  gotoInspection() {
    debugger

    if (this.proposalId != null && this.proposalId != "") {
      // let paymentUrl=AppSettings.DomainUrl+'/motorproposal/Processproposal?ProposalId='+this.proposalId+'&ProductId='+this.productId+'&sessionId='+this.quoteModel.SessionId;
      //     //window.open(paymentUrl, '_blank');
      //     window.location.href=paymentUrl;

      // Swal.fire({
      //   title: 'Inspection',
      //   // text: ''+this.LoginDetails.MobileNumber+ ' !',
      //   text: 'Customer has been sent a self-inspection link. Please contact the customer for doing the needful ',

      //   icon: 'success',
      //   //showCancelButton: true,
      //   confirmButtonText: 'OK',
      //   //cancelButtonText: 'No, keep it',
      // }).then((result) => {

      //   if (result.isConfirmed) {

      //     //window.open("https://bimaraja.com/dashboard",'_self');
      //     $( ".modal-backdrop" ).remove();
      //     this.router.navigateByUrl('/');
      //     //window.open('Dashboard','_self')
      //     //this.Route.navigate(['Dashboard']);

      //   } else if (result.isDismissed) {
      //   }
      // })

      this.motorService.GotoInspection([this.proposalId], [this.productId], this.quoteModel.SessionId).subscribe((result: JSON) => {
        debugger
        if (result && result[0].Status == "Success") {
          // this.intimationMessagemodal=true;

          ////////////////
          Swal.fire({
            title: 'Inspection Link Sent',
            // text: ''+this.LoginDetails.MobileNumber+ ' !',
            text: 'Customer has been sent a self-inspection link. Please contact the customer for doing the needful ',

            icon: 'success',
            //showCancelButton: true,
            confirmButtonText: 'OK',
            //cancelButtonText: 'No, keep it',
          }).then((result) => {

            if (result.isConfirmed) {
              $(".modal-backdrop").remove();
              this.router.navigateByUrl('/');

            } else if (result.isDismissed) {
            }
          })

          ///////////////
        }
        else {
          // Swal.fire('Premium updated to '+this.premium.FinalPremium, '', 'success');
          Swal.fire('Failed', '', 'error');


        }
        //  Swal.fire('Premium updated to '+this.premium.FinalPremium, '', 'success');


        // if(this.intimationMessagemodal==true){
        //   this.intimationMessagemodal=false;
        // }
        // else{
        //   this.intimationMessagemodal=true;
        // }    

        //show premium break up

      })

    }

  }


  async InitiateKYCICICI() {
    let dob = this.pipe.transform(new Date(this.proposalForm.controls.DateOfBirth.value), 'dd-MM-yyyy');
    this.CKYCDocType = this.proposalForm.controls.CKYCDocType.value;
    this.CKYCDocId = this.CKYCDocId.toUpperCase();
    var val = {
      correlationId: this.quoteModel.SessionId,
      certificate_type: this.CKYCDocType,
      pep_flag: false,
      pan_details: this.CKYCDocType == 'PAN' ? {
        pan: this.CKYCDocId,
        dob: dob
      } : null,
      ckyc_details: this.CKYCDocType == 'CKYC' ? {
        ckyc_number: this.CKYCDocId,
        dob: dob
      } : null,
      aadhaar_details: this.CKYCDocType == 'AADHAAR' ? {
        aadhaar_number: this.CKYCDocId,
        full_name: this.proposalForm.controls.ProposerName.value,
        dob: dob,
        gender: this.proposalForm.controls.Gender.value

      } : null,
      cin_details: this.CKYCDocType == 'CIN' ? {
        cin: this.CKYCDocId,
        doi: dob
      } : null,
      epic_details: this.CKYCDocType == 'VOTER' ? {
        epic_number: this.CKYCDocId,
        dob: dob
      } : null,
      dl_details: this.CKYCDocType == 'DL' ? {
        dl_number: this.CKYCDocId,
        dob: dob
      } : null,
      passport_details: this.CKYCDocType == 'PASSPORT' ? {
        passport_number: this.CKYCDocId,
        dob: dob
      } : null
    }

    debugger
    return await this.motorService.InitiateKYCICICI(val, [this.productId], [this.quoteModel.SessionId]).then((result: InitiateCKYCResp) => {
      // if (result.Status == 'Failed') {
      //   Swal.fire(result.StatusMessage, '', 'error');
      //   return false;
      // }
      // else {
      //   return true;
      // }
      return result


    });
  }

  async InitiateKYCBAJAJ() {
    let dob = this.pipe.transform(new Date(this.proposalForm.controls.DateOfBirth.value), 'dd-MM-yyyy');
    this.CKYCDocId = this.CKYCDocId.toUpperCase();
    if (this.IsCKYCNumber == 'Y' && this.CKYCNo != '') {
      this.CKYCDocType = 'Z';
      this.docid = this.CKYCNo;
    }
    else if (this.IsPOIFailed == true) {
      this.docid = this.POAId.toUpperCase();

    }
    else {
      this.docid = this.CKYCDocId.toUpperCase();

    }
    if (this.BajajDocTypeList.docTypeCode == 'E') {
      // var n = this.CKYCDocId.indexOf(" ");
      // var res = this.CKYCDocId.substring(n+1,-1);
      this.docid = this.CKYCDocId.substr(this.CKYCDocId.length - 4);
    }

    if (this.CKYCDocType == 'E' && this.IsPOIFailed == true) {
      this.docid = this.POAId.substr(this.POAId.length - 4);

    }
    var val =
    {
      docTypeCode: this.CKYCDocType,//Z for ckyc number
      docNumber: this.docid,
      fieldType: "PROPOSAL_NUMBER",
      fieldValue: this.TransactionId,//localStorage.getItem('TransactionID'), //this.premium.ClientQuoteNo, //this.quoteModel.SessionId,
      dob: dob,
      appType: "KYC_WS_BROKER",
      productCode: this.productId,
      sysType: "OPUS",
      locationCode: "9906",
      userId: "webservice.bombay@riskcare.com",
      kycType: "03",
      customerType: this.proposalForm.controls.Salutation.value == "MS" ? 'O' : 'I',
      passportFileNumber: "",
      gender: this.proposalForm.controls.Gender.value,
      field1: "MOTOR",
      field2: (this.CKYCDocType == 'E' && this.IsPOIFailed == true) ? this.proposalForm.controls.ProposerName.value.toUpperCase() : ""
    }
    debugger
    return await this.motorService.InitiateKYCBAJAJ(val, [this.productId], [this.quoteModel.SessionId]).then((result: InitiateCKYCResp) => {
      if (result.Status == 'Failed') {
        // Swal.fire(result.StatusMessage, '', 'error');
        // this.objInitiateCKYCResp.Status=result.Status;
        // this.objInitiateCKYCResp.StatusMessage=result.StatusMessage;

        if (result.StatusMessage == 'Proof of identification is failed. Kindly provide proof of address.') {
          this.IsPOIFailed = true;
        }

        if (result.StatusMessage == 'Proof of address is failed. Kindly upload proof of address.') {
          this.UploadPA = true;
        }
        // return false;
      }
      // else {
      //   this.objInitiateCKYCResp.Status=result.Status;
      //   this.objInitiateCKYCResp.StatusMessage=result.StatusMessage;
      //   this.objInitiateCKYCResp.CKYCNumber=result.CKYCNumber;
      //   // return true;
      // }
      return result;
    });
  }

  async InitiateKYCRGI() {
    let dob = this.pipe.transform(new Date(this.proposalForm.controls.DateOfBirth.value), 'dd-MM-yyyy');
    //OmkarB
    let TempCIN;
    if (this.premium.CompanyId == 22 && this.quoteModel.OwnedBy == 'Corporate') {
      TempCIN = this.CKYCCINId;
    }
    else {
      TempCIN = this.CKYCDocType == 'CIN' ? this.CKYCDocId : null;
    }
    //OmkarB
    this.CKYCDocId = this.CKYCDocId.toUpperCase();
    var val =
    {
      PAN: this.CKYCDocType == 'PAN' ? this.CKYCDocId : null,
      DOB: dob,
      CKYC: this.CKYCDocType == 'CKYC' ? this.CKYCDocId : null,
      MOBILE: this.proposalForm.controls.MobileNo.value,
      PINCODE: this.proposalForm.controls.Pincode.value,
      BIRTHYEAR: "",
      ReturnURL: "Test",
      UNIQUEID: this.quoteModel.SessionId,
      AADHAAR_No: this.CKYCDocType == 'AADHAAR_No' ? this.CKYCDocId : null,
      CIN: this.CKYCCINId != '' ? this.CKYCCINId : null,
      VOTERID: this.CKYCDocType == 'VOTERID' ? this.CKYCDocId : null,
      DL_No: this.CKYCDocType == 'DL_No' ? this.CKYCDocId : null,
      PASSPORT: this.CKYCDocType == 'PASSPORT' ? this.CKYCDocId : null,
      FULLNAME: this.proposalForm.controls.ProposerName.value,
      GENDER: this.proposalForm.controls.Gender.value
    }

    debugger
    return await this.motorService.InitiateKYCRGI(val, [this.productId], [this.quoteModel.SessionId], [this.planId]).then((result: InitiateCKYCResp) => {
      // if (result.Status == 'Failed') {
      //   Swal.fire(result.StatusMessage, '', 'error');
      //   return false;
      // }
      // else {
      //   return true;
      // }
      return result;
    });
  }
  async InitiateKYCFGI() {
    let dob = this.pipe.transform(new Date(this.proposalForm.controls.DateOfBirth.value), 'dd-MM-yyyy');
    this.CKYCDocId = this.CKYCDocId.toUpperCase();
    var val =
    {
      req_id: this.quoteModel.SessionId,
      customer_type: this.proposalForm.controls.Salutation.value == 'MS' ? 'C' : 'I',
      id_type: this.CKYCDocType,
      id_num: this.CKYCDocId,
      dob: dob,
      full_name: this.proposalForm.controls.ProposerName.value,
      gender: this.proposalForm.controls.Gender.value,
      url_type: "",
      system_name: "",
    }

    debugger
    return await this.motorService.InitiateKYCFGI(val, [this.productId], [this.quoteModel.SessionId], [this.planId]).then((result: InitiateCKYCResp) => {
      return result;
    });
  }
  async InitiateKYCUIIC() {
    let dob = this.pipe.transform(new Date(this.proposalForm.controls.DateOfBirth.value), 'dd/MM/yyyy');
   

    //this.CKYCDocId=this.CKYCDocId.substr(this.CKYCDocId.length - 4);
    
    var val =
    {
      oem_unique_identifier: this.quoteModel.SessionId,
      ckyc_no: this.CKYCNo== undefined?"":this.CKYCNo,
      customer_type: this.proposalForm.controls.Salutation.value=='MS' ? 'C' : 'I',
      customer_name:  this.proposalForm.controls.ProposerName.value,
      address1: this.proposalForm.controls.Address1.value,
      address2: this.proposalForm.controls.Address2.value,
      pincode: this.proposalForm.controls.Pincode.value,
      gender: this.proposalForm.controls.Gender.value,
      dob: dob,
      email: this.proposalForm.controls.Email.value,
      mobile_no: this.proposalForm.controls.MobileNo.value,
      aadhar_last_four_digits: this.CKYCDocType == 'AADHAAR_No' ? this.CKYCDocId.substr(this.CKYCDocId.length - 4) : "",
      pan: this.CKYCDocType == 'PAN' ? this.CKYCDocId.toUpperCase() : "",
      tieup_name: "",
      redirecturl: "",
      additional_field1: "",
      additional_field2: "",
      additional_field3: "",
      additional_field4:""
    }

    debugger
    return await this.motorService.InitiateKYCUIIC(val, [this.productId], [this.quoteModel.SessionId], [this.planId]).then((result: InitiateCKYCResp) => {
      // if (result.Status == 'Failed') {
      //   Swal.fire(result.StatusMessage, '', 'error');
      //   return false;
      // }
      // else {
      //   return true;
      // }
      return result;
    });
  }
  async InitiateKYCUSGI() {
    let dob = this.pipe.transform(new Date(this.proposalForm.controls.DateOfBirth.value), 'dd/MM/yyyy');

    //this.CKYCDocId=this.CKYCDocId.substr(this.CKYCDocId.length - 4);

    var val =
    {
      source:"",
      customerType: this.proposalForm.controls.Salutation.value == 'MS' ? 'C' : 'I',
      uniqueTransactionNumber: "",
      idNo: this.CKYCDocId,
      idType: this.CKYCDocType,
      dob: dob,
      mobileNo: this.proposalForm.controls.MobileNo.value,
      pincode: this.proposalForm.controls.Pincode.value,
      cKYCNo: this.CKYCNo == undefined ? "" : this.CKYCNo,
      extraField1: this.CKYCDocType == 'AADHAAR' ? this.proposalForm.controls.ProposerName.value : "",
      extraField2: this.CKYCDocType == 'AADHAAR' ? this.proposalForm.controls.Gender.value : "",
      extraField3: "",
      extraField4: "",
      extraField5: ""
    }

    debugger;
    return await this.motorService.InitiateKYCUSGI(val, [this.productId], [this.quoteModel.SessionId], [this.planId]).then((result: InitiateCKYCResp) => {
      // if (result.Status == 'Failed') {
      //   Swal.fire(result.StatusMessage, '', 'error');
      //   return false;
      // }
      // else {
      //   return true;
      // }
      return result;
    });
  }
  async InitiateKYCHDFC() {
    let dob = this.pipe.transform(new Date(this.proposalForm.controls.DateOfBirth.value), 'dd/MM/yyyy');
   

    //this.CKYCDocId=this.CKYCDocId.substr(this.CKYCDocId.length - 4);
    
    var val =
    {
      pan : this.CKYCDocType == 'PAN' ? this.CKYCDocId.toUpperCase() : "",
      dob: dob,
      mobile : (this.CKYCDocType == 'NAME'|| this.CKYCDocType == 'EMAIL'|| this.CKYCDocType == 'MOBILE')? this.proposalForm.controls.MobileNo.value: "",
      name :  (this.CKYCDocType == 'NAME')? this.proposalForm.controls.ProposerName.value: "",
      kyc_id: this.CKYCDocType == 'KYC ID' ? this.CKYCDocId : "",
      ckyc_number: this.CKYCDocType == 'CKYC NUMBER' ? this.CKYCDocId : "",
      aadhaar_uid: this.CKYCDocType == 'AADHAAR' ? this.CKYCDocId.substr(this.CKYCDocId.length - 4) : "",
      agent_id: "",
      gc_cust_id: this.CKYCDocType == 'GC CUST ID' ? this.CKYCDocId: "",
      eia_number: this.CKYCDocType == 'EIA' ? this.CKYCDocId: "",
      email_address: this.CKYCDocType == 'EMAIL'? this.proposalForm.controls.Email.value: "",
      redirecturl: "",
    }

    debugger
    return await this.motorService.InitiateKYCHDFC(val, [this.productId], [this.quoteModel.SessionId], [this.planId]).then((result: InitiateCKYCResp) => {

      return result;
    });
  }
  getDocumnetType() {
    this.motorService.getDocumnetType(this.premium.CompanyId).subscribe((response: InsurerCompany[]) => {
      this.DocTypeList = response;
      this.CKYCDocType = "";

    })

    // alert(this.premium.CompanyId)
    if (this.premium.CompanyId == 12) {
      this.DocTypeList = this.ICICIDocTypeList;
    }
    else if (this.premium.CompanyId == 5) {
      this.DocTypeList = this.BajajDocTypeList;
    }
    else if (this.premium.CompanyId == 22) {
      this.DocTypeList = this.RGIDocTypeList;
    }

    if (this.premium.CompanyId == 12 || this.premium.CompanyId == 5 || this.premium.CompanyId == 22 
      || this.premium.CompanyId == 11 || this.premium.CompanyId == 29 || this.premium.CompanyId == 30) {
      this.IsKYCVerified = false;
      this.IsKYCRequired = true;
    }

  }

  async VerifyKYC() {
    this.proposalForm.controls.Salutation.markAsTouched();
    this.proposalForm.controls.ProposerName.markAsTouched();
    this.proposalForm.controls.MobileNo.markAsTouched();
    this.proposalForm.controls.Email.markAsTouched();
    this.proposalForm.controls.Address1.markAsTouched();
    this.proposalForm.controls.Address2.markAsTouched();
    this.proposalForm.controls.Pincode.markAsTouched();
    this.proposalForm.controls.State.markAsTouched();
    this.CKYCDocType = this.proposalForm.controls.CKYCDocType.value;
    this.CKYCDocId = this.proposalForm.controls.CKYCDocId.value;
    this.CKYCNo = this.proposalForm.controls.CKYCNo.value;
    if (this.proposalForm.value.OwnedBy == "Individual") {
      this.proposalForm.controls.DateOfBirth.markAsTouched();
      this.proposalForm.controls.Gender.markAsTouched();
    }

    if (this.proposalForm.controls.Salutation.value == '' || this.proposalForm.value.Salutation == null || this.proposalForm.value.Salutation == undefined) {
      Swal.fire('Please enter Salutation', '', 'error');
      return false;
    }


    if (this.proposalForm.controls.DateOfBirth.value == '' || this.proposalForm.value.DateOfBirth == null || this.proposalForm.value.DateOfBirth == undefined) {
      Swal.fire('Please enter Date Of Birth', '', 'error');
      return false;
    }
    if (this.proposalForm.controls.ProposerName.value == '' || this.proposalForm.value.ProposerName == null || this.proposalForm.value.ProposerName == undefined) {
      debugger
      this.proposalForm.controls['ProposerName'].setValue($("input[name='ProposerName']").val());
      Swal.fire('Please enter Full Name', '', 'error');
      return false;
    }
    if (this.proposalForm.controls.Gender.value == '' || this.proposalForm.value.Gender == null || this.proposalForm.value.Gender == undefined) {
      Swal.fire('Please enter Gender', '', 'error');
      return false;
    }

    if (this.proposalForm.controls.MobileNo.value == '' || this.proposalForm.value.MobileNo == null || this.proposalForm.value.MobileNo == undefined) {
      Swal.fire('Please enter Mobile Number', '', 'error');
      return false;
    }

    if (this.proposalForm.controls.Email.value == '' || this.proposalForm.value.Email == null || this.proposalForm.value.Email == undefined) {
      Swal.fire('Please enter Email', '', 'error');
      return false;
    }

    if (this.proposalForm.controls.Address1.value == '' || this.proposalForm.value.Address1 == null || this.proposalForm.value.Address1 == undefined) {
      Swal.fire('Please enter Address1', '', 'error');
      return false;
    }

    if (this.proposalForm.controls.Address2.value == '' || this.proposalForm.value.Address2 == null || this.proposalForm.value.Address2 == undefined) {
      Swal.fire('Please enter Address2', '', 'error');
      return false;
    }

    if (this.proposalForm.controls.Pincode.value == '' || this.proposalForm.value.Pincode == null || this.proposalForm.value.Pincode == undefined) {
      this.proposalForm.controls['Pincode'].setValue($("input[name='Pincode']").val());
      Swal.fire('Please enter Pincode', '', 'error');
      return false;
    }
    if (this.premium.CompanyId == 22) {
      if (this.proposalForm.controls.AreaID.value == '' || this.proposalForm.value.AreaID == null || this.proposalForm.value.AreaID == undefined) {
        Swal.fire('Please enter Area ID', '', 'error');
        return false;
      }
    }

    if (this.premium.CompanyId == 33) {
      if ((this.proposalForm.controls.Address1.value + '' + this.proposalForm.controls.Address2.value).length > 78) {
        Swal.fire('Address should not exceed 79 chharacters. ', '', 'error');
        return false;
      }
    }
    debugger;

    if (this.premium.CompanyId == 12 || this.premium.CompanyId == 22 || this.premium.CompanyId == 11 || this.premium.CompanyId == 30) {

      if (this.CKYCDocType == '' || this.CKYCDocType == null || this.CKYCDocType == undefined) {
        Swal.fire('Please select CKYC Document Type', '', 'error');
        return false;
      }
      if (this.CKYCDocId == '' || this.CKYCDocId == null || this.CKYCDocId == undefined) {
        Swal.fire('Please enter CKYC Document Id', '', 'error');
        return false;
      }
      if(this.premium.CompanyId == 11 && this.CKYCDocType != 'MOBILE' &&this. CKYCDocType != 'NAME' && this.CKYCDocType != 'EMAIL'){
        if (this.CKYCDocId == '' || this.CKYCDocId == null || this.CKYCDocId == undefined) {
          Swal.fire('Please enter CKYC Document Id', '', 'error');
          return false;
        }
      }
      if (this.premium.CompanyId == 22 && this.quoteModel.OwnedBy == 'Corporate') {
        if (this.CKYCCINId == '' || this.CKYCCINId == null || this.CKYCCINId == undefined) {
          Swal.fire('Please enter CIN', '', 'error');
          return false;
        }
      }
    }


    if (this.premium.CompanyId == 12) {
      //var flag;
      this.objInitiateCKYCResp = await this.InitiateKYCICICI();
      // if(this.objInitiateCKYCResp.Status=='Failed'){
      //   Swal.fire(this.objInitiateCKYCResp.StatusMessage, '', 'error');
      //   return false;
      // }
    }

    if (this.premium.CompanyId == 5) {
      if (this.premium.CompanyId == 5 && this.CKYCDocId != '' && this.IsPOIFailed == false) {
        this.CKYCDocType = 'C';
      }
      if (this.IsCKYCNumber == '' || this.IsCKYCNumber == null) {
        Swal.fire('Please select CKYC Number Available or not', '', 'error');
        return false;
      }
      // if ((this.CKYCDocType == '' || this.CKYCDocType == null || this.CKYCDocType == undefined)) {
      //   Swal.fire('Please select CKYC Document Type', '', 'error');
      //   return false;
      // }
      if (this.IsCKYCNumber == 'Y' && this.CKYCNo == '') {
        Swal.fire('Please enter CKYC Number', '', 'error');
        return false;
      }
      if (this.IsCKYCNumber == 'N' && (this.CKYCDocId == '' || this.CKYCDocId == null || this.CKYCDocId == undefined)) {
        Swal.fire('Please enter PAN No.', '', 'error');
        return false;
      }

      this.objInitiateCKYCResp = await this.InitiateKYCBAJAJ();
      // if(this.objInitiateCKYCResp.Status='Failed'){
      //   return false;
      // }
      // else{
      //   return true;
      // }
    }

    if (this.premium.CompanyId == 22) {
      // var flag;
      this.objInitiateCKYCResp = await this.InitiateKYCRGI();
      // if(flag==false){
      //   return false;
      // }
    }
    if (this.premium.CompanyId == 10) {
      // var flag;
      this.objInitiateCKYCResp = await this.InitiateKYCFGI();
      // if(flag==false){
      //   return false;
      // }
    }
    if (this.premium.CompanyId == 29) {
      // var flag;
      this.objInitiateCKYCResp = await this.InitiateKYCUIIC();
      // if(flag==false){
      //   return false;
      // }
    }
    if (this.premium.CompanyId == 11) {
      // var flag;
      this.objInitiateCKYCResp = await this.InitiateKYCHDFC();
      // if(flag==false){
      //   return false;
      // }
    }
    if (this.premium.CompanyId == 30) {
      // var flag;
      this.objInitiateCKYCResp = await this.InitiateKYCUSGI();
      // if(flag==false){
      //   return false;
      // }
    }

    if (this.objInitiateCKYCResp.Status == 'Failed') {
      Swal.fire(this.objInitiateCKYCResp.StatusMessage, '', 'error');
      this.IsKYCVerified = false;
      return false;
    }
    else if (this.objInitiateCKYCResp.Status == 'Redirect') {
      if (this.premium.CompanyId == 10) {
        let RedirectUrl = AppSettings.API_ENDPOINT_CKYC.replace('api', '') + 'RedirectKYCFGI?ProductId=' + this.productId + '&sessionId=' + this.quoteModel.SessionId + '&PlanId=' + this.planId + '&RedirectionURL=' + this.objInitiateCKYCResp.StatusMessage;

        window.location.href = RedirectUrl;

      } 
      else
      if (this.premium.CompanyId == 30) {

        // window.location.href = this.objInitiateCKYCResp.StatusMessage;
        window.open(this.objInitiateCKYCResp.StatusMessage, "_blank");
        setTimeout(function () {
          this.IsKYCVerified = true;
          this.IsKYCRequired = false;
        }, 10000)
      }
      else {
        window.location.href = this.objInitiateCKYCResp.StatusMessage;
      }
      //this.IsKYCVerified = true;
      //return false;
    }
    else {
      Swal.fire(this.objInitiateCKYCResp.StatusMessage, '', 'success');
      this.IsKYCVerified = true;
      this.IsKYCRequired = false;
      let birthdate = new Date();
      if (this.premium.CompanyId == 11 || this.premium.CompanyId == 30) {
        this.DisableAllOnKycSuccsess=true;
        birthdate = new Date(this.objInitiateCKYCResp.DOB);
        let DOB=this.pipe.transform(birthdate, 'yyyy-MM-dd')
        this.proposalForm.controls['DateOfBirth'].setValue(DOB);
        this.proposalData.DateOfBirth = this.pipe.transform(this.objInitiateCKYCResp.DOB, 'yyyy-MM-dd');
      
        this.proposalForm.controls['ProposerName'].setValue(this.objInitiateCKYCResp.FullName);
        this.proposalData.FirstName=this.objInitiateCKYCResp.FullName;
       }
    }


    if (!(this.proposalForm.controls.ProposerName.errors
      || this.proposalForm.controls.MobileNo.errors
      || this.proposalForm.controls.Email.errors
      || this.proposalForm.controls.Address1.errors
      || this.proposalForm.controls.Address2.errors
      || this.proposalForm.controls.Pincode.errors
      || this.proposalForm.controls.State.errors)) {
      // this.backtonominee = false;
      // this.backtoproposerdiv = true;
      // this.backtovehiclediv = true;
      //if(this.proposalForm.value.OwnedBy=="Organization" || this.quoteModel.PACoverOwner=="Yes")

    }
  }
  //OmkarB

  TestfillTheForm() {
    debugger
    //  this.proposalData = data;


    this.proposalForm.controls['Salutation'].setValue('Mr.');


    // this.proposalForm.controls['DateOfBirth']= new FormControl(new Date(data.DateOfBirth));
    // this.proposalForm.controls['DateOfBirth'].setValue(data.DateOfBirth);

    this.proposalForm.controls['DateOfBirth'].setValue(this.pipe.transform(new Date('12/12/1990'), 'dd/MM/yyyy'));
    let arr = this.proposalForm.value.DateOfBirth.split('/');
    this.DateOfBirthvalue = new Date(Number(arr[2]), Number(arr[1]) - 1, Number(arr[0]));

    // this.proposalForm.controls['DateOfBirth'] = new FormControl(this.pipe.transform(new Date(data.DateOfBirth), 'dd/MM/yyyy'));
    // this.DateOfBirthvalue = new FormControl(new Date(this.quoteModel.PreviousPolicyExpirDate));
    // this.proposalForm.controls['Salutation'].setValue(data.Salutation);
    // this.proposalForm.controls['FirstName'].setValue(data.FirstName);
    // this.proposalForm.controls['LastName'].setValue(data.LastName);

    this.proposalForm.controls['Gender'].setValue(trim('M'));
    this.proposalForm.controls['MobileNo'].setValue('9383839393');
    this.proposalForm.controls['Email'].setValue('test@gmail.com');
    this.proposalForm.controls['Address1'].setValue('Address1');
    this.proposalForm.controls['Address2'].setValue('Address1');
    this.proposalForm.controls['Address3'].setValue('Address1');


    // this.proposalForm.controls['CarRegistrationNo'].setValue('MH-01-AQ-1929');
    this.proposalForm.controls['EnginNo'].setValue('shjhjsh828289289298');
    this.proposalForm.controls['ChassisNo'].setValue('hjeeui8989289882892');

    //this.proposalForm.controls['AreaID'].setValue(data.AreaID);

    //this.proposalForm.controls['CompanyId'].setValue(Number(data.Company["CompanyId"]));

    this.proposalForm.controls['ExpiryPolicyNo'].setValue('SHIIHS00202002020');

    this.proposalForm.controls['previousPolicyType'].setValue('comprehensive');
    //this.proposalForm.controls['ExpiryPolicyDate'].setValue(data.ExpiryPolicyDate);
    this.proposalForm.controls['ExpiryPolicyDate'].setValue(this.pipe.transform(new Date('10/05/2022'), 'dd/MM/yyyy'));

    debugger
    this.proposalForm.controls['CarOwnershipChange'].setValue('No');

    this.proposalForm.controls['NomineeName'].setValue('Nomineeeee');
    this.proposalForm.controls['NomineeRelationShip'].setValue(trim('spouse'));
    this.proposalForm.controls['NomineeAge'].setValue('46');

    // if(this.quoteModel.CarOwnershipChange==false){
    //   this.proposalForm.controls['CarOwnershipChange'].setValue('No');
    // }else{
    //   this.proposalForm.controls['CarOwnershipChange'].setValue('Yes');
    // }

    // if (data.IsFinanced == false) {
    //   this.proposalForm.controls['IsFinanced'].setValue('No');
    // } else {
    //   this.proposalForm.controls['IsFinanced'].setValue('Yes');
    // }
    // this.proposalForm.controls['IsFinanced'].setValue(data.IsFinanced);

    // this.proposalForm.controls['TPCompanyId'].setValue(data.TPInsuranceCompany);
    // this.proposalForm.controls['TPPolicyNo'].setValue(data.TPPolicyNumber);
    // let tpstartdt = new Date(data.TPStartDate);
    // this.proposalForm.controls['TPStartDate'].setValue(tpstartdt);
    // let tpenddt = new Date(data.TPEndDate);
    // this.proposalForm.controls['TPEndDate'].setValue(tpenddt);

    // let regDate=new Date(Number(this.quoteModel.YearOfPurchase),Number(this.quoteModel.MonthOfPurchase)-1,Number(this.quoteModel.DayOfPurchase));

    // let regDate = this.quoteModel.DayOfPurchase + '/' + (this.quoteModel.MonthOfPurchase.length == 1 ? ('0' + this.quoteModel.MonthOfPurchase) : this.quoteModel.MonthOfPurchase) + '/' + this.quoteModel.YearOfPurchase;
    // this.proposalForm.controls['registationdate'].setValue(regDate);//= new FormControl(regDate);
    debugger
    // let regDate=new Date(Number(this.quoteModel.YearOfPurchase),Number(this.quoteModel.MonthOfPurchase)-1,Number(this.quoteModel.DayOfPurchase));

    // let regdate = (this.quoteModel.DayOfPurchase.length == 1 ? ('0' + this.quoteModel.DayOfPurchase) : this.quoteModel.DayOfPurchase) + '/' + (this.quoteModel.MonthOfPurchase.length == 1 ? ('0' + this.quoteModel.MonthOfPurchase) : this.quoteModel.MonthOfPurchase) + '/' + this.quoteModel.YearOfPurchase;
    // this.startdate = new Date(Number(this.quoteModel.YearOfPurchase), Number(this.quoteModel.MonthOfPurchase) - 1, Number(this.quoteModel.DayOfPurchase));

    //this.startdate = new Date(Number(this.quoteModel.YearOfPurchase), Number(this.quoteModel.MonthOfPurchase) - 1, Number(this.quoteModel.DayOfPurchase));

    // if (this.quoteModel.CurrentPolicyType == 'NEW') {
    //   this.proposalForm.controls['registationdate'].setValue(this.pipe.transform(new Date(), 'dd/MM/yyyy'));
    // }
    // else {
    //   this.proposalForm.controls['registationdate'].setValue(regdate);
    // }

    // let fullName = data.FirstName + ' ' + data.LastName;
    this.proposalForm.controls['ProposerName'].setValue('Test Name');


    //alert('Hi')

    this.proposalForm.controls['Pincode'].setValue(421201);
    //this.proposalForm.controls['City'].setValue(data.City);
    //  this.proposalForm.controls['State'].setValue(data.State);
    this.getPincode('421201');

    debugger;

  }

  SalutationChange() {
    debugger

    const currentDate = new Date();

    if (this.proposalForm.controls['Salutation'].value.toUpperCase() == 'MS' || this.proposalForm.controls['Salutation'].value.toUpperCase() == 'M/S') {
      this.quoteModel.OwnedBy = 'Corporate';
      currentDate.setFullYear(currentDate.getFullYear());
      this.proposalForm.controls['DateOfBirth'].setValue(currentDate);
      this.proposalForm.controls['Gender'].setValue('');
      this.proposalForm.controls['NomineeName'].setValue('');
      this.proposalForm.controls['NomineeRelationShip'].setValue('');
      this.proposalForm.controls['NomineeAge'].setValue('');


    }
    else {
      this.quoteModel.OwnedBy = 'Individual';
      currentDate.setFullYear(currentDate.getFullYear() - 18);
      this.proposalForm.controls['DateOfBirth'].setValue(currentDate);


    }

    this.maxDate = currentDate;

    this.proposalForm.controls['OwnedBy'].setValue(this.quoteModel.OwnedBy);

    this.clickOwnedBy(this.quoteModel.OwnedBy);
  }
  //OmkarB


  fileProgress(fileInput: any) {
    let data, header;

    const target: DataTransfer = <DataTransfer>(fileInput.target);
    // this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx)/);
    this.fileUploaded = target.files[0];
    // if (target.files.length > 1) {
    //   this.inputFile.nativeElement.value = '';
    // }
    // if (this.isExcelFile) {
    //   this.spinnerEnabled = true;
    //   const reader: FileReader = new FileReader();
    //   reader.onload = (e: any) => {
    //     /* read workbook */
    //     const bstr: string = e.target.result;
    //     const wb: xlsx.WorkBook = xlsx.read(bstr, { type: 'binary' });

    //     /* grab first sheet */
    //     const wsname: string = wb.SheetNames[0];
    //     const ws: xlsx.WorkSheet = wb.Sheets[wsname];

    //     /* save data */
    //     data = xlsx.utils.sheet_to_json(ws);
    //   };

    //   reader.readAsBinaryString(target.files[0]);

    //   reader.onloadend = (e) => {
    //     this.spinnerEnabled = false;
    //     this.keys = Object.keys(data[0]);
    //     this.dataSheet.next(data)
    //   }
    // } else {
    //   Swal.fire('plz. select only excel file', '', 'error');
    //   return;
    // }
    //debugger;
    for (let i = 0; i < fileInput.target.files.length; i++) {
      let fileItem = <File>fileInput.target.files[i];
      if (fileItem.size > 10000000) {
        Swal.fire('Each File should be less than 10 MB of size.', '', 'error');
        return;
      }
    }
    for (let j = 0; j < fileInput.target.files.length; j++) {
      this.filecount++;
      this.fileData = <File>fileInput.target.files[j];
      const formData = new FormData();
      formData.append('file', this.fileData);
      this.vformData = formData;
    }
  }

  addCkycDocuments(fileInput: any) {
    debugger;
    let data1, header;
    for (let j = 0; j < fileInput.target.files.length; j++) {
      this.filecount++;
      this.fileData = <File>fileInput.target.files[j];
      //alert ("first"+this.cheque );
      this.UploadCkycDoc = this.fileData.name;
      if (this.fileData.size > 10000000) {

        alert("file size should be less than 10 MB");
        this.UploadCkycDoc = '';
        return;
      }
      this.formData.append(this.CKYCPOADocType, this.fileData);
      this.formData.append('appType', 'KYC_WS_BROKER');
      this.formData.append('fieldType', 'PROPOSAL_NUMBER');
      this.formData.append('fieldValue', this.TransactionId);//('fieldValue',localStorage.getItem('TransactionID')); //this.quoteModel.SessionId)
      this.formData.append('kycDocumentType', 'POA');
      this.formData.append('kycDocumentCategory', this.CKYCPOADocType);
      this.formData.append('documentNumber', this.POADocId);

      this.vformData = this.formData;
    }
  }

}
