import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class MotorService {
    url=AppSettings.API_ENDPOINT;
    url2=AppSettings.API_ENDPOINT2;
urlhealth=AppSettings.API_ENDPOINTHEALTH;
    urlCKYC=AppSettings.API_ENDPOINT_CKYC;

    constructor(private http: HttpClient) { }

   

    

  postQuoteData(data){
    data.ExField1='B2C';
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }    
    return this.http.post(this.url+'/MotorQuotation/SaveMotorQuotes',data,httpOptions);
  }

  postQuoteDataAndAddons(quoteData){debugger;
    quoteData.ExField1='B2C';
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }    
    return this.http.post(this.url+'/MotorQuotation/SaveMotorQuoteAndAddons',quoteData,httpOptions);
  }

  DigitCKYCQuoteApi(quoteData){
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }    
    return this.http.post(this.url+'/MotorQuotation/SaveMotorQuoteAndAddons',quoteData,httpOptions);
  }

  getQuotationProducts(sessionId){  
      return this.http.get(this.url+'/MotorProducts/GetQuoteMotorProducts?SessionId='+sessionId);    
  }

  getQuotationQuery(userId,sessionId,vehicleType){  
    return this.http.get(this.url+'/MotorQuotation/GetQueryDetails?UserId='+userId+'&SessionId='+sessionId+'&vehicleType='+vehicleType);    
   }

   getVehicleByRegNo(registationNo){  
    return this.http.get(this.url+'/MotorQuotation/GetVehicleByRegistrationNo?registrationNo='+registationNo);    
   }

   getQuotePremium(sessionId,planId:number){  
    return this.http.get(this.url+'/MotorQuotation/GetProductPremium?SessionId='+sessionId+'&planId='+planId);    
   }

   getflaVehicleByRegNo(registationNo){  
    return this.http.get(this.url+'/MotorMaster/GetVehicleDetailsOnRgistrationNumber?RegistrationNumber='+registationNo);    
   }

  getQuotation(vehicleType:number,productType:number,InvoiceValue:number,PlanId:number,idv:number,year,query:any){
    
    const data=query;
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }    
    return this.http.post(this.url+'/MotorQuotation/MotorQuotesGateway?vehicleType='+vehicleType+'&productType='+productType+'&PlanId='+PlanId+'&idv='+idv,data,httpOptions);
  }

  postPremiumData(quotedata,premiumdata,addonsData){debugger;
    quotedata[0].ExField1='B2C';
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }   
    const RequestData={
      oProductDetails:premiumdata,
      oGetQuoteList:quotedata,
      oProductAddOnDetails:addonsData
    } 
    return this.http.post(this.url+'/MotorQuotation/SaveQuotationPremium',JSON.stringify(RequestData),httpOptions);
  }

  getProposalDetails(userId,productId,PlanId,proposalId,sessionId){
    return this.http.get(this.url+'/MotorProposalAPI/GetProposalInfo/'+userId+'/'+productId+'/'+PlanId+'/'+proposalId+'/'+sessionId);
  }

  postProposal(proposalData:any){    
    const data=proposalData;
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }    
    return this.http.post(this.url+'/MotorProposalAPI/SaveMotorProposalDetails',data,httpOptions);
  }

  getSalutations(lob,companyId){
    return this.http.get(this.url2+'/Proposer/Salutations/'+lob+'/'+companyId);
  }
  getNomineeRelation(lob,companyId){
    return this.http.get(this.url2+'/Proposer/NomineeRelation/'+lob+'/'+companyId);
  }

  getCityPincode(pincode){
    return this.http.get(this.url2+'/Master/StateCityPincode/'+pincode);
  }

  FetchStateCity(Pincode: any){
    return this.http.get('http://1.22.180.122:4441/api/MasterEntry/PinCode/'+ Pincode + '/admin@riskcare.co.in');
  }

  getPrevoiusInsurer(companyId){
    return this.http.get(this.url+'/MotorProposalAPI/GetPreviousInsurer?CompanyId='+companyId);
  }
  getArea(pincode,companyId){
    return this.http.get(this.url+'/MotorProposalAPI/GetAreaName/'+pincode+'/'+companyId);
  }
   getBankName(searchText,companyId){
    return this.http.get(this.url+'/MotorProposalAPI/GetBankName/'+searchText+'/'+companyId);
  }

  GetPaymentDetails(ProposalId){
    return this.http.get(this.url+'/MotorProposalAPI/GetPaymentDetails?ProposalId='+ProposalId);
  }

  CompareQuotes(SessionId){
    let vehicleType=5;    
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }    
    return this.http.post(this.url+'/MotorQuotation/CompareQuotes?SessionId='+SessionId+'&vehicleTypeId='+vehicleType,httpOptions);
  }

  // QuotesMailer(quotedata,premiumdata){
  //   const httpOptions = {
  //     headers: new HttpHeaders({'Content-Type': 'application/json'})
  //   }   
  //   const RequestData={
  //     oProductDetails:premiumdata,
  //     oGetQuoteList:quotedata,
  //     oProductAddOnDetails:null
  //   } 
  //   return this.http.post(this.url+'/MotorQuotation/QuotesMailer',JSON.stringify(RequestData),httpOptions);
  // }
  QuotesMailer(quotedata,premiumdata,proposalId=null){
    debugger
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }   
    const RequestData={
      oProductDetails:premiumdata,
      oGetQuoteList:quotedata,
      oProductAddOnDetails:null,
      proposalId:proposalId
    } 
    return this.http.post(this.url+'/MotorQuotation/QuotesMailer',JSON.stringify(RequestData),httpOptions);
    // return this.http.post(this.url+'/MotorQuotation/QuotesMailer',JSON.stringify(RequestData),httpOptions);
    
  }
  QuotesSMS(mobile,url,name,flag){
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }   
    
    return this.http.post(this.url + '/MotorQuotation/QuotesSMS?name=' + name + '&flag=' + flag+ '&mobileno=' + mobile+ '&url=' + url.replace('&','%26'),httpOptions);
  }

  CompareQuotesMailer(quotedata,textHtml){
    
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }   
    const RequestData={
      oProductDetails:null,
      oGetQuoteList:quotedata,
      oProductAddOnDetails:null,
      HTMLText:textHtml
    
    } 
    debugger;
    const postData={ RequestData:JSON.stringify(RequestData)};

    return this.http.post(this.url+'/MotorQuotation/QuotesMailer',JSON.stringify(RequestData),httpOptions);
  }



  GotoInspection(ProposalId,ProductId,SessionId){
    return this.http.get(AppSettings.DomainUrl+'/Inspection/ProcessInspection?ProposalId='+ProposalId+'&ProductId='+ProductId+'&sessionId='+SessionId);
   // Processproposal?ProposalId='+this.proposalId+'&ProductId='+this.productId+'&sessionId='+this.quoteModel.SessionId;
  }

  InitiateKYCICICI(val:any,ProductId,SessionId){    
    const data=val;
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})    }    
    
      return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCICICI?ProductId='+ProductId+'&sessionId='+SessionId, data).toPromise();
      // return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCICICI?ProductId='+ProductId+'&sessionId='+SessionId,data,httpOptions).toPromise();
  }

  InitiateKYCBAJAJ(val:any,ProductId,SessionId){    
    const data=val;
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})    }    
    
      return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCBAJAJ?ProductId='+ProductId+'&sessionId='+SessionId, data).toPromise();
      // return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCICICI?ProductId='+ProductId+'&sessionId='+SessionId,data,httpOptions).toPromise();
  }

  InitiateKYCRGI(val:any,ProductId,SessionId,PlanId){    
    const data=val;
    // const httpOptions = {
    //   headers: new HttpHeaders({'Content-Type': 'application/json'})    }    
    
      return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCRGI?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, data).toPromise();
      // return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCICICI?ProductId='+ProductId+'&sessionId='+SessionId,data,httpOptions).toPromise();
  }

  SaveKycDetails(val:any,ProductId,SessionId){    
    const data=val;
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})    }    
    
      return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCGODIGIT?ProductId='+ProductId+'&sessionId='+SessionId, data).toPromise();
      // return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCICICI?ProductId='+ProductId+'&sessionId='+SessionId,data,httpOptions).toPromise();
  }

  getDocumnetType(companyId){
    return this.http.get(this.urlCKYC+'/Master/GetCKYCDocument?CompanyId='+companyId);
  }

  InitiateKYCFGI(val:any,ProductId,SessionId,PlanId){    
    const data=val;
    // const httpOptions = {
    //   headers: new HttpHeaders({'Content-Type': 'application/json'})    }    
    
    //return this.http.post('https://localhost:44301/api/InitiateKYC/InitiateKYCFGI?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, data).toPromise();
    return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCFGI?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, data).toPromise();
  }
  InitiateKYCHDFC(val:any,ProductId,SessionId,PlanId){    
    const data=val;
    //return this.http.post('https://localhost:44301/api/InitiateKYC/InitiateKYCUIIC?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, data).toPromise();
    return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCHDFC?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, data).toPromise();
  }
InitiateKYCUIIC(val:any,ProductId,SessionId,PlanId){    
    const data=val;
    // const httpOptions = {
    //   headers: new HttpHeaders({'Content-Type': 'application/json'})    }    
    
    //return this.http.post('https://localhost:44301/api/InitiateKYC/InitiateKYCUIIC?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, data).toPromise();
    return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCUIIC?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, data).toPromise();
  }
  InitiateKYCUSGI(val:any,ProductId,SessionId,PlanId){    
    const data=val;
    // const httpOptions = {
    //   headers: new HttpHeaders({'Content-Type': 'application/json'})    }    
    
    //return this.http.post('https://localhost:44301/api/InitiateKYC/InitiateKYCUIIC?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, data).toPromise();
    return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCUSGI?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, data).toPromise();
  }
  // UploadCKYCDetails(file){      
  //   const reqHeader = new HttpHeaders
  //   ({authorization: 'bearer ' + localStorage.getItem('access_token')});     
  //   return this.http.post('https://localhost:44301/api/UploadDocuments/UploadDocumentBajaj?productid=5&&SessionId='+file.SessionId,file ,{ headers:reqHeader});
  // }
  UploadCKYCDetails(val: any,ProductId,SessionId,PlanId) {
    // return this.http.post('https://localhost:44301/api/UploadDocuments/UploadDocumentBajaj?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, val);
    return this.http.post(this.urlCKYC+'/UploadDocuments/UploadDocumentBajaj?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, val);
   }
   SaveRiskcareUser(Name,Email,mobile) {
    // var ContactUs=JSON.stringify(data);
    return this.http.post(this.urlhealth + '/Common/SaveRiskcareUser?email='+Email+'&mobile='+mobile+'&Name='+Name,"");
  }
  
  SendOTP(data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    return this.http.post(this.urlhealth + '/Common/SendOTP', data, httpOptions);
  }

  
  OTPValidation(data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    return this.http.post(this.urlhealth + '/Common/UserLoginWithOTP', data, httpOptions);
  }

  SendB2CSessionMail(Url,emailid,name,mobile) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    return this.http.post(this.urlhealth + '/Common/SendB2CSessionMail?Url='+Url+'&&emailid='+emailid+'&&name='+name+'&&mobile='+mobile, httpOptions);
  }

}