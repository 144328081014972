import { Component, OnInit } from '@angular/core';
import { ContactUs } from '../Models/healthMaster.models';
import { HealthservicesService } from 'src/app/services/healthservices.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  ContactUs:any= new ContactUs();
  constructor(private healthMasterService:HealthservicesService) { 
    this.ContactUs=new ContactUs();
    this.ContactUs.Name="";
    this.ContactUs.Email="";
    this.ContactUs.Message="";
  }

  ngOnInit(): void {
  }


  Save(){debugger;
    this.healthMasterService.postContact(this.ContactUs).subscribe((res:any)=>{   
      if(res==true){
        let data=res;
        Swal.fire('Thank you for providing your details. Our team will contact you soon.', '', 'success');
      }
      
    });
  }



}
