import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void 
  {

    /**JS for Circular Slider Starts**/
    var
   carousel = document.querySelector('.carousel'),
   figure = carousel.querySelector('figure'),
   nav = carousel.querySelector('nav'),
   numImages = figure.childElementCount,
   theta =  2 * Math.PI / numImages,
   currImage = 0
   ;
   nav.addEventListener('click', onClick, true);
   
   function onClick(e) 
   {
     e.stopPropagation();
  
     var t = e.target;
     if (t.tagName.toUpperCase() != 'BUTTON')
     return;
  
     if (t.classList.contains('next')) 
     {
       currImage++;
     }
     else 
     {
       currImage--;
     } 
  
     figure.style.transform = `rotateY(${currImage * -theta}rad)`;
    }
    /**JS for Circular Slider Ends**/
  }
}
