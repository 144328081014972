import { Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormControl } from '@angular/forms'
import { QuotationModel, DigitCKYCModel, premiumModel, twoWheelerDetails, InitiateCKYCResp } from '../../../common/Models/twoWheeler';
import { CovidQuestions, DigitHealthQuestion, insuredMember, proposer } from '../../../common/Models/premium.models';
import { address, Occupation, Salutation, Questionaire, NomineeRelation, InsurerCompany } from '../../../common/Models/proposal.models';
import { PinCode } from '../../../common/Models/healthMaster.models';
import { MotorService } from 'src/app/services/motor.service';
import { premiumData } from '../../../common/Models/premium.models';
import { HealthProposalService } from '../../../services/proposal.service';
import { GlobalService } from 'src/app/global.service';
import { HealthservicesService } from '../../../services/healthservices.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppSettings } from '../../../globals';
import Swal from 'sweetalert2';
import { flatten, ThrowStmt } from '@angular/compiler';
import { last } from 'rxjs/operators';
import { ErrorHandle } from 'src/app/common/models/ErrorHandle';
import { PaymentsummeryComponent } from '../paymentsummery/paymentsummery.component';
//import { DateFormat } from "../../common/date-format";
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { DatePipe } from '@angular/common';
import { debug } from 'util';
declare var $: any;
declare var zingchart: any;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};


@Component({
  selector: 'app-health-proposal',
  templateUrl: './health-proposal.component.html',
  styleUrls: ['./health-proposal.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }
    ,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class HealthProposalComponent implements OnInit {
  public pipe = new DatePipe('en-US');
  date: any = [];
  maxLength: number;
  formData = new FormData();
  checkConfirm: boolean = false;
  DocumentTypeReceive: string;
  validateMember: boolean = false;
  validateAddress: boolean = false;
  logedUserName: string;
  isLogin: boolean;
  hidenominee: boolean;
  ErrorHandle: ErrorHandle;
  dropdownList = [];
  dropdownListChild = [];
  dropdownListOccupation = [];
  selectedItems = [];
  dropdownSettingsChild = {};
  dropdownSettings: IDropdownSettings = {};
  dropdownListOccupationSetting: { singleSelection: boolean; idField: string; textField: string; itemsShowLimit: number; allowSearchFilter: boolean; enableCheckAll: boolean; };
  members: insuredMember[] = [];
  proposerAddress: address = new address();
  premiunDetails:any= new premiumData();
  CovidDetails: CovidQuestions = new CovidQuestions();
  DigitHealthQuestion: DigitHealthQuestion;
  salutations: Salutation[] = [];
  occupations: Occupation[] = [];
  quiestionaires: Questionaire[] = [];
  maritalStatus: any;
  maritalStatusCode: any;
  pincode: PinCode;
  nomineeRelations: NomineeRelation[] = [];
  divMember = true;
  divAddress = false;
  divEditMember = false;
  divQuestion = false;
  divstep1: boolean = true;
  divstep2: boolean = false;
  divstep3: boolean = false;
  divstep4: boolean = false;
  myConfigValue: number;
  companyId: number;
  productId: number;
  planId: number;
  customer: proposer;
  BaseCoverageList = [];
  KeyList = [];
  HighlightList = [];
  quoteId: number;

  startPage: Number;
  paginationLimit: Number;

  startPage2: Number;
  paginationLimit2: Number;
  isPanindia: boolean = false;
  isStarproduct: boolean = false;
  enablefreetext: boolean = false;
  isStarMediQue75: boolean = false;
  isStarMediQue76: boolean = false;
  isStarMediQue77: boolean = false;
  CityList = [];
  AreaList = [];
  years1: any;
  day1: any;

  //CYC
  DocTypeList: any ;
  IsKYCVerified: boolean = false;
  IsCKYCNumber: string;
  CKYCNo: string = '';
  CKYCDocId: any;
  CKYCDocType: any;
  CKYCCINId: any;// For Reliance
  DigitCKYCModel: DigitCKYCModel = new DigitCKYCModel();

  IsPOIFailed: boolean = false;
  UploadPA: boolean = false;
  IsKYCRequired: boolean = false;
  // CKYCNumber: any;
  UploadCkycDoc: any;
  UploadCkycIdentityProofDoc : any;
  CKYCPOADocType: any;
  AddressProofDocType: any;
  POADocId: any;
  POADocumentId : any;
  docid: any;
  POAId: any;
  TransactionId: any;

  

  objInitiateCKYCResp: InitiateCKYCResp = new InitiateCKYCResp();
  QID: any;
  fileUploaded: File;
  fileData: File = null;
  vformData: any;
  vformDataPOI: FormData;
  vformDataPOA: FormData;
  filecount: number = 0;

  familyRelationId: number = 0;
  familyRelationTitleId: number = 0;
  familyRelationFirstName: string;
  familyRelationLastName: string;

  residentialStatus: number = 0;
  incomeSource: number = 0;
  IsPEP: number = 0;
  PEPRemarks: string;


  CKYCIdentityProofDocType: any;
  CKYCAddressProofDocType: any;
  UploadCkycAddressProofDoc: any;
  isUploadIDProof: boolean = false;
  isUploadAddressProof: boolean = false;
  IsCKYCNumberAvailable: boolean = false;
  IsPANAvail: boolean = false;
  AvailCKYCNumber: any;
  IsSearchkyc: boolean = false;
  IDProofDocId: any;
  IDDocTypeList: any;
  AvailPAN: any;
  AvailPANCard : any;
  IsPANAvailChecked: boolean = false;
  IsCKYCAvailChecked: boolean = false;
  IsShowNextSelectionBtn: boolean = false;
  UploadCkycPhotographDoc: any;
  UploadCkycForm60Doc: any;
  UploadCkycIDProofBSDoc: any;
  UploadCkycIDProofDoc: any;
  PANChecked: string = "No";
  CKYCChecked: string = "No";
  StarStateName: any;
  isAddharVerified:boolean=false;
  AddharNo:any;
  //CKYC
  image: string;
  productName: string;
  companyName: string;
  planType: string;
  NetPremium: number;
  ServiceTaxAmount: number;
  FinalPremium: number;
  ServiceTax: number;
  ProposerName: string;
  ProposerMobileNumber: string;
  ProposerEmailID: string;
  flags :number;
  ToatalSumInsured :number;
  IsMarried:boolean = false;
  validatation: boolean = false;
  FilePath:any;
  FileName:any;
  IsVisible : boolean = false;
  isAbhaNoValid : boolean = false;
  EmailOTP: string;
  isEmailOTPVerified: boolean = false;
  AllowEmailverify: boolean = false;

  @ViewChild(PaymentsummeryComponent, { static: false }) appHealth: PaymentsummeryComponent;
  constructor(private proposalService: HealthProposalService
    , private motorService: MotorService
    , private router: ActivatedRoute
    , private route: Router,
    private globalService: GlobalService
    //,private router: Router
    , private healthService: HealthservicesService,
    private healthMasterService: HealthservicesService) {
    this.globalService.showfooter = false;
    this.getMembers();
    this.proposerAddress.email = "";
    this.proposerAddress.mobile = 0;
    this.proposerAddress.emergencyMobile = "";
    this.proposerAddress.pincode = 0;
    this.proposerAddress.state = "";
    this.proposerAddress.city = "";
    this.proposerAddress.area = "";
    this.proposerAddress.address1 = "";
    this.proposerAddress.address2 = "";
    this.proposerAddress.maritalStatusCode = null;
    this.proposerAddress.PanIndiaCover = "No";
    this.proposerAddress.RentCappingDiscount = "No";
    this.proposerAddress.HPR = "No";
    this.proposerAddress.HPRValue = "No";
    this.myConfigValue = 50;
    this.date[0] = '';
    // this.proposerAddress.EmailOTP = "";
  }
  FGGIDocTypeList: any = [
    {
      DocumentID: "A",
      DocumentName: 'Passport'
    },
    {
      DocumentID: "B",
      DocumentName: 'Voter ID'
    },
    {
      DocumentID: "C",
      DocumentName: 'PAN'
    },
    {
      DocumentID: "D",
      DocumentName: 'Driving License'
    },
    {
      DocumentID: "E",
      DocumentName: 'UID'
    },
    {
      DocumentID: "F",
      DocumentName: 'NREGA Job Card'
    },
    {
      DocumentID: "G",
      DocumentName: 'GSTIN'
    },
    {
      DocumentID: "Z",
      DocumentName: 'KYC Number'
    }
  ];
  CAREIdentityProofDocTypeList: any = [
    {
      DocumentID: "PAN",
      DocumentName: 'PAN'
    },
    {
      DocumentID: "VoterID",
      DocumentName: 'Voter ID'
    },
    {
      DocumentID: "DrivingLicence",
      DocumentName: 'Driving Licence '
    },
    {
      DocumentID: "Passport",
      DocumentName: 'Passport'
    }
  ];
  CAREAddressProofDocTypeList: any = [
    {
      DocumentID: "VoterID",
      DocumentName: 'Voter ID'
    },
    {
      DocumentID: "DrivingLicence",
      DocumentName: 'Driving Licence '
    },
    {
      DocumentID: "Passport",
      DocumentName: 'Passport'
    }
  ];
  BajajDocTypeList: any = [
    {
      DocumentID: "A",
      DocumentName: 'Passport'
    },
    {
      DocumentID: "B",
      DocumentName: 'Voter ID'
    },
    {
      DocumentID: "C",
      DocumentName: 'PAN'
    },
    {
      DocumentID: "D",
      DocumentName: 'Driving License'
    },
    {
      DocumentID: "E",
      DocumentName: 'UID'
    },
    {
      DocumentID: "F",
      DocumentName: 'NREGA Job Card'
    },
    {
      DocumentID: "G",
      DocumentName: 'GSTIN'
    },
    {
      DocumentID: "Z",
      DocumentName: 'KYC Number'
    }
  ];

  RGIDocTypeList: any = [
    {
      DocumentID: "PAN",
      DocumentName: 'PAN'
    },
    {
      DocumentID: "CKYC",
      DocumentName: 'CKYC'
    },
    {
      DocumentID: "AADHAAR_No",
      DocumentName: 'AADHAAR_No'
    },
    {
      DocumentID: "CIN",
      DocumentName: 'CIN'
    },
    {
      DocumentID: "VOTERID",
      DocumentName: 'VOTERID'
    },
    {
      DocumentID: "DL_No",
      DocumentName: 'DL_No'
    },
    {
      DocumentID: "PASSPORT",
      DocumentName: 'PASSPORT'
    }
  ];
  
  POIDocTypeList: any = [
    {
      DocumentID: "A",
      DocumentName: 'Passport'
    },
    {
      DocumentID: "B",
      DocumentName: 'Voter ID'
    },
    {
      DocumentID: "C",
      DocumentName: 'PAN'
    },
    {
      DocumentID: "D",
      DocumentName: 'Driving License'
    },
    {
      DocumentID: "E",
      DocumentName: 'UID'
    },
    {
      DocumentID: "F",
      DocumentName: 'GSTIN Number'
    },

  ]
  
  POADocTypeList: any = [
    {
      DocumentID: "A",
      DocumentName: 'Passport'
    },
    {
      DocumentID: "B",
      DocumentName: 'Voter ID'
    },
    {
      DocumentID: "C",
      DocumentName: 'PAN'
    },
    {
      DocumentID: "D",
      DocumentName: 'Driving License'
    },
    {
      DocumentID: "E",
      DocumentName: 'UID'
    },
    {
      DocumentID: "F",
      DocumentName: 'GSTIN Number'
    },

  ]


  

  ngOnInit() {
    debugger;
    this.globalService.lendingpage = true;
    this.startPage = 0;
    this.paginationLimit = 2;

    this.startPage2 = 0;
    this.paginationLimit2 = 2;

    if (sessionStorage.getItem('userName') == null) {
      this.isLogin = false;

    } else {
      this.logedUserName = sessionStorage.getItem('userName');
      this.isLogin = true;
    }



    this.bindSlider();

    this.getPremium();

    $('#txtaddress1').on('keypress', function (event) {
      //alert();
      debugger;
      var regex = new RegExp("^[A-Za-z][-A-Za-z0-9./#&,]+$");///^[A-Za-z][A-Za-z0-9./#&,]*$/
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (regex.test(key)) {
        event.preventDefault();
        return false;
      }
    });

    $('#txtaddress2').on('keypress', function (event) {
      //alert();
      debugger;
      var regex = new RegExp("^[A-Za-z][A-Za-z0-9./#&,-]+$");///^[A-Za-z][A-Za-z0-9./#&,]*$/
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (regex.test(key)) {
        event.preventDefault();
        return false;
      }
    });
    console.log("PolicyType=" + this.premiunDetails.PolicyType);
  }

  async getPremium() {debugger;
    let id = this.router.snapshot.queryParamMap.get('id');
    // this.quoteId = parseInt(localStorage.getItem('quotaionId'));
this.quoteId = parseInt(this.QID);
    await this.GetquoteId(id);

    //this.quoteId = parseInt('13820');
    sessionStorage.setItem('policyDetailsId', id.toString());
    this.proposalService.getPremium(id, this.QID).subscribe((res: any) => {
      //console.log("res=", res);
      this.premiunDetails = res;
      this.ToatalSumInsured =this.premiunDetails.ToatalSumInsured;
      this.productId = res.ProductId;
      this.companyId = res.CompanyId;
      if (this.premiunDetails.CompanyId == 10 && res.PolicyType =="Individual") {
        this.ToatalSumInsured = this.ToatalSumInsured/res.TotalMember;
      }
      this.planId = res.PlanTypeId;
      if (this.productId == 3) {
        this.isPanindia = true;
        this.proposerAddress.WaiverRent = "No";
        this.proposerAddress.NonMedical = 0;
        this.hidenominee = true;
      }
      if (this.productId == 26) {
        this.maxLength = 60;
      }
      else {
        this.maxLength = 90;
      }
      debugger;
      this.getOccupation();
      this.getSalutation();
      this.getQuestionaire();
      this.getNomineeRelation();
      this.getMaritalStatus();
      this.getCustomer(this.QID);
      this.getDocumnetType();
      this.getIDDocumnetType();



    },
    (error) => console.log("ApIerror=",error)
    );

  }

  getCustomer(quoteId) {
    debugger
    this.proposalService.getCustomer(quoteId).subscribe((res: proposer) => {
      debugger;
      this.customer = res;
      if (this.customer.CustomerName != '') {
        let fullName = this.customer.CustomerName.split(' ');
        let firstName = ''; let lastName = '';
        if (fullName.length > 1) {
          lastName = fullName[fullName.length - 1];
        } if (fullName.length > 2) {
          firstName = fullName[0] + ' ' + fullName[1];
        }
        else if (fullName.length > 1) {
          firstName = fullName[0];
        } else firstName = this.customer.CustomerName;

        for (let i = 0; i < this.members.length; i++) {
          let mem = this.members[i];
          if (mem.memberName == 'Self') {
            mem.lastname = lastName;
            mem.name = firstName;
          }
        }
        this.proposerAddress.email = this.customer.Email;
        this.proposerAddress.mobile = parseInt(this.customer.Mobile);
        this.proposerAddress.pincode = parseInt(this.customer.Pincode);
        this.getPincodeCity(this.customer.Pincode);

      }
      this.healthService.getRedirectCkycDetails(this.QID).subscribe((result: any) => {
        if(result) {
          let insuredmembers = this.filterItemsOfType(1);
          for (let i = 0; i < insuredmembers.length; i++) {
            let member = insuredmembers[i];
            if (member.memberName == 'Self'){
              member.name = result.Name;
              member.lastname = result.lastName;
              member.dateOfBirth = this.pipe.transform(result.DOB, 'yyyy-dd-MM');//result.DOB;              ;
              this.IsVisible = true;
            }
          }
        }
        
      })
    })
  }

  getSalutation() {
    this.healthService.getSalutations(1, this.companyId).subscribe((res: Salutation[]) => {
      this.salutations = res;
    })
  }
  getOccupation() {
    this.healthService.getOccupations(1, this.companyId).subscribe((res: Occupation[]) => {
      this.occupations = res;
    })
  }
  getNomineeRelation() {
    this.healthService.getNomineeRelations(1, this.companyId).subscribe((res: NomineeRelation[]) => {
      this.nomineeRelations = res;
    })
  }

  getMaritalStatus() {
    this.proposalService.getMaritalStatus(this.productId).subscribe(status => {
      this.maritalStatus = status;
    })
  }

  getQuestionaire() {
    debugger
    this.proposalService.getQuestionaire(1, this.companyId).subscribe((res: Questionaire[]) => {
      this.quiestionaires = res;
      for (let i = 0; i < res.length; i++) {
        this.quiestionaires[i].QuestionValue = "no";
        this.quiestionaires[i].MemberData = [];
        for (let j = 0; j < this.members.length; j++) {
          this.quiestionaires[i].MemberData.push({ memberName: this.members[j].memberName, value: false, quantity: '' })
        }
      }
    });

    this.getSidebarDetails();
  }
  getPincode($event) {
    debugger;
    let pin = $event.target.value;
    this.getPincodeCity(pin);
    if (this.productId = 17) {
      this.GetStarCity(pin);
      this.GetStarArea(pin, this.proposerAddress.cityId)
    }
  }

  //SAtish
  getOtherOccupation($event) {
    debugger;
    let otherocc = $event.target.value;
  }
  //SAtish

  getPincodeCity(pincode) {
    this.healthService.getPincodes(pincode).subscribe((res: PinCode[]) => {
      if (res.length > 0)
        this.pincode = res[0];
      this.proposerAddress.state = this.pincode.StateName;
      this.proposerAddress.city = this.pincode.CityName;
      this.proposerAddress.cityId = this.pincode.CityId;
      this.proposerAddress.stateCode = this.pincode.StateCode;
    })
  }
  getOccupationName(Id) {
    for (let i = 0; i < this.occupations.length; i++) {
      if (this.occupations[i].HealthOccupationId == Id)
        return this.occupations[i].HealthOccupationName;
    }
    return Id;
  }

  saveProposal($event) {
    debugger;
    $event.preventDefault();

    let membersData = [];
    this.checkMemberDetails();
    this.checkAddressDetails();
    if (this.checkConfirm == false) {
      Swal.fire('Please confirm declaration terms conditions.', '', 'error');
      return;
    }
    if (this.isStarMediQue77 == true) {
      Swal.fire('You have opted for Critical illness , please contact us.', '', 'error');
      return;
    }
    // if(this.DocumentTypeReceive==""){
    //   Swal.fire('Please select document type receive', '', 'error');      
    //   return;
    // }          
    if (this.validateAddress == true) {
      let membersData = [];
      let insuredmembers = this.filterItemsOfType(1);
      for (let i = 0; i < insuredmembers.length; i++) {
        let member = insuredmembers[i];
        if (member.dateOfBirth == "") return false;
        if (member.occupation == '290' && (member.otheroccupation == "" || member.otheroccupation == null)) {
          Swal.fire('Please Enter Other Occupation Details!')
          return false;
        }
        else if (member.occupation != '290') {
          member.otheroccupation == ""
        }
        membersData.push({
          Member: member.memberName,
          SalutationId: member.salutation,
          InsuredName: member.name,
          LastName: member.lastname,
          NomineeName: member.nomineeName,
          RelationWithNomineeId: member.nomineeRelation,
          Gender: member.gender,
          InsuredDOB: this.healthService.formatDate(member.dateOfBirth),
          OccupationId: member.occupation,
          Height: member.height,
          Weight: member.weight,
          BMI: member.bmi,
          MaritalStatusCode: member.maritalStatusCode,
          nomineeDOB: member.nomineeDOB,
          OtherOccupation: member.otheroccupation,
          nomineeOccupation: member.nomineeOccupation,
          nomineeGender: member.nomineeGender,
          AbhaNo: member.abhaNo,
        })
      }

      let questionaire = []; let questionMemberData = [];
      if (this.quiestionaires.length > 0) {
        for (let i = 0; i < this.quiestionaires.length; i++) {
          let que = this.quiestionaires[i];
          if (this.productId == 26) {
            if (que.QuestionValue == 'yes') {
              Swal.fire('We cannot proceed for policy issuance, as proposer is not satisfying good health requirements', '', 'error');
              return;
            }
          }
          if (this.productId == 17) {
            if (que.QuestionValue == 'yes' && que.HealthQuestionId != 78) {
              Swal.fire('We cannot proceed for policy issuance, as proposer is not satisfying good health requirements', '', 'error');
              return;
            }
            if (que.QuestionValue == 'no' && que.HealthQuestionId == 78) {
              Swal.fire('Please select Personal accident cover as yes', '', 'error');
              return;
            }
          }

          if (this.productId == 3) {
            if (que.QuestionValue == 'yes') {
              Swal.fire('As the insured person is failing in medical conditions, hence the policy will get issued post medical examination.Kindly contact to our nearest branch to process', '', 'error');
              return;
            }
          }

          questionaire.push({ HealthQuestionId: que.HealthQuestionId, HealthQuestionValue: que.QuestionValue, HealthQuestionData: que.QuestionData })

          if (que.MemberData.length > 0 && que.QuestionValue == 'yes') {
            for (let j = 0; j < que.MemberData.length; j++) {
              questionMemberData.push({ Member: que.MemberData[j].memberName, HealthQuestionId: que.HealthQuestionId, Quantity: (que.MemberData[j].quantity == '' ? 0 : parseInt(que.MemberData[j].quantity)) });
            }
          }
        }
      }

      //let quoteId=parseInt(localStorage.getItem('quotaionId')) ; 
      let digiquestion = [];
      let digisubquestion = [];
      if (this.productId == 24) {
        if (this.CovidDetails.RPCVI == "RPCVItrue") {
          this.CovidDetails.RPCVI = "true";
        }
        else {
          this.CovidDetails.RPCVI = "false";
        }
        if (this.CovidDetails.RPVCN == "RPVCNtrue") {
          this.CovidDetails.RPVCN = "true";
        }
        else {
          this.CovidDetails.RPVCN = "false";
        }
        if (this.CovidDetails.RCOVI == "RCOVItrue") {
          this.CovidDetails.RCOVI = "true";
        }
        else {
          this.CovidDetails.RCOVI = "false";
        }

        digiquestion.push({
          questionCode: "RPCVI",
          answerType: "BOOLEAN",
          isApplicable: this.CovidDetails.RPCVI,
          detailAnswer: this.CovidDetails.RPCVI,
        })
        digiquestion.push({
          questionCode: "RCOVI",
          answerType: "BOOLEAN",
          isApplicable: this.CovidDetails.RCOVI,
          detailAnswer: this.CovidDetails.RCOVI,
        })
        digiquestion.push({
          questionCode: "RPVCN",
          answerType: "BOOLEAN",
          isApplicable: this.CovidDetails.RPVCN,
          detailAnswer: this.CovidDetails.RPVCN,
        })
        digisubquestion.push({
          questionCode: "RPCVI",
          SubquestionCode: "RPCV1",
          answerType: "TEXT_DESCRIPTION",
          isApplicable: this.CovidDetails.RPCV1 != "" ? true : false,
          detailAnswer: this.CovidDetails.RPCV1,
        })
        digisubquestion.push({
          questionCode: "RPCVI",
          SubquestionCode: "RPCV2",
          answerType: "DOMAIN",
          isApplicable: this.CovidDetails.RPCV2 != "" ? true : false,
          detailAnswer: this.CovidDetails.RPCV2,
        })
        digisubquestion.push({
          questionCode: "RPCVI",
          SubquestionCode: "RPCV3",
          answerType: "TEXT_DESCRIPTION",
          isApplicable: this.CovidDetails.RPCV3 != "" ? true : false,
          detailAnswer: this.CovidDetails.RPCV3,
        })
        digisubquestion.push({
          questionCode: "RPCVI",
          SubquestionCode: "RPCV4",
          answerType: "TEXT_DESCRIPTION",
          isApplicable: this.CovidDetails.RPCV4 != "" ? true : false,
          detailAnswer: this.CovidDetails.RPCV4,
        })
        digisubquestion.push({
          questionCode: "RPCVI",
          SubquestionCode: "RPCV5",
          answerType: "TEXT_DESCRIPTION",
          isApplicable: this.CovidDetails.RPCV5 != "" ? true : false,
          detailAnswer: this.CovidDetails.RPCV5,
        })
        digisubquestion.push({
          questionCode: "RPCVI",
          SubquestionCode: "RPCV6",
          answerType: "TEXT_DESCRIPTION",
          isApplicable: this.CovidDetails.RPCV6 != "" ? true : false,
          detailAnswer: this.CovidDetails.RPCV6,
        })
        digisubquestion.push({
          questionCode: "RPVCN",
          SubquestionCode: "RPVC1",
          answerType: "DOMAIN",
          isApplicable: this.CovidDetails.RPVC1 != "" ? true : false,
          detailAnswer: this.CovidDetails.RPVC1,
        })
        digisubquestion.push({
          questionCode: "RPVCN",
          SubquestionCode: "RPVC2",
          answerType: "TEXT_DESCRIPTION",
          isApplicable: this.CovidDetails.RPVC2 != "" ? true : false,
          detailAnswer: this.CovidDetails.RPVC2,
        })
        digisubquestion.push({
          questionCode: "RPVCN",
          SubquestionCode: "RPVC3",
          answerType: "TEXT_DESCRIPTION",
          isApplicable: this.CovidDetails.RPVC3 != "" ? true : false,
          detailAnswer: this.CovidDetails.RPVC3,
        })
        digisubquestion.push({
          questionCode: "RPVCN",
          SubquestionCode: "RPVC4",
          answerType: "DOMAIN",
          isApplicable: this.CovidDetails.RPVC4 != "" ? true : false,
          detailAnswer: this.CovidDetails.RPVC4,
        })
        digisubquestion.push({
          questionCode: "RPVCN",
          SubquestionCode: "RPVC5",
          answerType: "TEXT_DESCRIPTION",
          isApplicable: this.CovidDetails.RPVC5 != "" ? true : false,
          detailAnswer: this.CovidDetails.RPVC5,
        })
      }


      if (this.productId != 17) { this.proposerAddress.area = "0" }
this.quoteId = this.QID;
      let maritalstatus = '';
      if (sessionStorage.getItem('CoverType') == 'Individual') {
        maritalstatus = insuredmembers[0].maritalStatusCode;
      }
      else {
        maritalstatus = 'M';
      }

      let proposalObj = {
        ProposalDetails: {
          //QuotationId:quoteId,

          QuotionId: this.quoteId,
          UserId: parseInt(sessionStorage.getItem('UserId')),
          ProposerDOB: this.proposerAddress.ProposerDOB,
          ProposerMaritalStatusCode: insuredmembers[0].maritalStatusCode,
          ProposerAddress1: this.proposerAddress.address1,
          ProposerAddress2: this.proposerAddress.address2,
          ProposerLandMark: this.proposerAddress.landmark,
          ProposerStateCode: this.proposerAddress.stateCode,
          ProposerCityId: this.proposerAddress.cityId,
          ProposerPinCode: this.proposerAddress.pincode,
          ProposerMobileNumber: this.proposerAddress.mobile,
          ProposerEmailID: this.proposerAddress.email,
          PanIndiaCover: this.proposerAddress.PanIndiaCover,
          RentCappingDiscount: this.proposerAddress.RentCappingDiscount,
          ProposerAreaId: this.proposerAddress.area,
	  ProposerPAN:(this.AvailPAN == '' || this.AvailPAN == null || this.AvailPAN == undefined) ? "":this.AvailPAN ,
          Portal: 'B2C',

        },
        ProposalMemberDetails: membersData,
        ProposalMemberHealthQuestion: {
          MemberHealthQuestionDetails: questionaire,
          ProposalMemberHabitQuantity: questionMemberData
        }

      };

      let DigitProposalobj = {
        QuestionDetails: digiquestion,
        SubQuestionDetails: digisubquestion
      };

      // if (this.companyId == 33) {
      //   this.saveKycDetails();
      // }
debugger;
      console.log(JSON.stringify(proposalObj));
      this.proposalService.postProposalData(JSON.stringify(proposalObj)).subscribe((res: string) => {
        if (Number(res) > 0) {
          //this.route.navigate(['/paymentsummery'], { queryParams: { proposalId: res } })
          sessionStorage.setItem('proposalId', res.toString());
          this.appHealth.getPremium();
          debugger;
          if (this.productId == 24) {
            this.proposalService.postDigitQuestionData(res.toString(), JSON.stringify(DigitProposalobj)).subscribe((res: string) => {

            }, ((err: ErrorHandle) => {
              this.ErrorHandle = err;
              if (this.ErrorHandle.statusText == "Bad Request") {
                Swal.fire(this.ErrorHandle.statusText, '', 'error');

              }
            })
            );
          }

          $("#paymentSummary").modal("show");

          // const url = this.route.serializeUrl(this.route.createUrlTree(['/paymentsummery'], { queryParams: { proposalId: res } }));
          //   window.open(url, '_blank');
        } else {
          Swal.fire('Proposal submit issue.', '', 'error');
        }
        //let paymentUrl=AppSettings.DomainUrl+'/proposal/Processproposal?ProposalId='+res+'&ProductId='+this.productId;
        //window.location.href=paymentUrl;
      },
        ((err: ErrorHandle) => {
          this.ErrorHandle = err;
          if (this.ErrorHandle.statusText == "Bad Request") {
            Swal.fire(this.ErrorHandle.statusText, '', 'error');

          }
        })
      );
    }
  }


  saveKycDetails() {
    let dob = this.pipe.transform(new Date(this.proposerAddress.ProposerDOB), 'dd-MM-yyyy');
    this.CKYCDocId = this.CKYCDocId.toUpperCase();

    var val = {
      isKYCDone: false,
      ckycReferenceDocId: this.CKYCDocType,
      ckycReferenceNumber: this.CKYCDocId,
      dateOfBirth: dob,
      photo: ''
    }

    debugger
    this.motorService.SaveKycDetails(val, [this.productId], [this.quoteId]).then((result: InitiateCKYCResp) => {

    });
  }

  async getMembers() {
    // if(this.members==null || this.members.length==0){
    //     this.members =JSON.parse(sessionStorage.getItem('members'));
    //   }

    this.quoteId = parseInt(sessionStorage.getItem('quotaionId'));
    if (sessionStorage.getItem('quotaionId') == null)
      this.quoteId = parseInt(this.router.snapshot.queryParamMap.get('quoteId'));

    let id = this.router.snapshot.queryParamMap.get('id');
    //this.quoteId = parseInt(localStorage.getItem('Demo'));
    this.quoteId = this.QID;
    await this.GetquoteId(id);

    this.healthService.getQuoteRequestData(this.QID).subscribe((quote: any) => {
      if (quote) {
        for (let i = 0; i < quote.HealthQuoteMembers.length; i++) {
          debugger;
          let member = new insuredMember();
          if(quote.HealthQuoteMembers[i].Relation != 'Elder'&&quote.HealthQuoteMembers.length==1){
            member.id = i+1;
          }
          else{  
            member.id = i;
          }
          member.memberName = quote.HealthQuoteMembers[i].Relation;
          member.dateOfBirth =quote.HealthQuoteMembers[i].DateOfBirth;
          if (member.dateOfBirth =="0001-01-01T00:00:00") {
            member.dateOfBirth="";
          }
          if (member.memberName == "Self") {
            this.proposerAddress.ProposerDOB = member.dateOfBirth;
          }
          this.date[member.id] = new FormControl(new Date(quote.HealthQuoteMembers[i].DateOfBirth));
          member.age = quote.HealthQuoteMembers[i].Age;
          member.height = quote.HealthQuoteMembers[i].Height;
          member.weight = quote.HealthQuoteMembers[i].Weight;
          member.occupation = quote.HealthQuoteMembers[i].OccupationId;
          member.salutation = quote.HealthQuoteMembers[i].SalutationId;
          member.maritalStatusCode = quote.HealthQuoteMembers[i].MaritalStatusCode;
          member.gender = quote.HealthQuoteMembers[i].Gender;
          member.nomineeName = quote.HealthQuoteMembers[i].NomineeName;
          member.nomineeRelation = quote.HealthQuoteMembers[i].RelationWithNomineeId;        
          member.name = quote.HealthQuoteMembers[i].name;
          member.lastname = quote.HealthQuoteMembers[i].LastName;
          member.bmi = quote.HealthQuoteMembers[i].Bmi;      
          member.sumInsured = quote.HealthQuoteMembers[i].SumInsured;
          this.members.push(member);
          this.bmichart(member.memberName,  member.id,'height', 'Y');
          member.abhaNo = quote.HealthQuoteMembers[i].AbhaNo;
        }
      }
    })

    console.log(this.members);
  }
  filterItemsOfType(type) {
    return this.members.filter(x => x.memberName != 'Elder');
  }
  settings = {
    fill: '#1abc9c',
    background: '#d7dcdf'
  }
  bindSlider() {
    debugger;
    const sliders = document.querySelectorAll('.range-slider');
    Array.prototype.forEach.call(sliders, (slider) => {
      slider.querySelector('input').addEventListener('input', (event) => {
        slider.querySelector('textarea').innerHTML = event.target.value;
        this.applyFill(event.target);
      });
      this.applyFill(slider.querySelector('input'));
    });

  }

  applyFill(slider) {
    debugger;
    const percentage = 100 * (slider.value - slider.min) / (slider.max - slider.min);
    const bg = `linear-gradient(90deg, ${this.settings.fill} ${percentage}%, ${this.settings.background} ${percentage + 0.1}%)`;
    slider.style.background = bg;
  }
  calculateAge($event, member, memberName) {
    debugger
    var asd=this.companyId;
    if ($event == null) return;
    let age = this.returnAge($event);
    let insuredmembers = this.filterItemsOfType(1);
    for (var i = 0; i < insuredmembers.length; i++) {
      if(this.companyId==23){
        this.getAge(insuredmembers[i].dateOfBirth)
      var todayDate = new Date();
      var Birthyear =  $event.value._d.getFullYear();//year
      var Birthmonth = ($event.value._d.getMonth()+1);//month
      if(Birthmonth<10){
        Birthmonth='0'+Birthmonth
      }

      
      var Birthday =  $event.value._d.getDate();
      if(Birthday<10){
        Birthday='0'+Birthday
      }

      var BirthAge=JSON.parse(this.getAgee(Birthmonth+'/'+Birthday+'/'+Birthyear));
      
         if (member === i) {
        //   if (age >= 100) {
        //     // insuredmembers[i].dateOfBirth ="";
        //     Swal.fire('Age should not be greater than 99 years', '', 'error');
        //     return;
        //   } 
        //   else if (age == 99) {
            var todayDate = new Date();
            var ageyear = todayDate.getFullYear() - $event.value._d.getFullYear();//year
            var agemonth = todayDate.getMonth() - $event.value._d.getMonth();//month
            var ageday = todayDate.getDate() - $event.value._d.getDate();
            let nowDay = new Date();
            if(memberName.substring(0, 5) != 'Child'){
              if(BirthAge.years <18){
                Swal.fire('Age should not be Less than 18 years', '', 'error');
              
              }
              if (BirthAge.years >99 ) {
                Swal.fire('Age should not be greater than 99 years 11 months 29 days', '', 'error');
              
              }
              else if ( BirthAge.years==99 && BirthAge.months >11){
                Swal.fire('Age should not be greater than 99 years 11 months 29 days', '', 'error');
              }
              else if (BirthAge.years>17 && BirthAge.years==99 && BirthAge.months ==11 && BirthAge.days >29){
                Swal.fire('Age should not be greater than 99 years 11 months 29 days', '', 'error');
              }
              else{
                insuredmembers[i].age = age;
              }
            }
            else{
              // if (this.day1 < 91) {
                if (BirthAge.totalDays < 91) {

                Swal.fire('Child Age should not be less than 91 days', '', 'error');
                document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
                return false;
              }
              if (BirthAge.years >24) {
                Swal.fire('Child Age should not be greater than 24 years 11 months 29 days', '', 'error');
              
              }
              else if ( BirthAge.years==24 && BirthAge.months >11){
                Swal.fire('Child Age should not be greater than 24 years 11 months 29 days', '', 'error');
              }
              else if ( BirthAge.years==24 && BirthAge.months ==11 && BirthAge.days >29){
                Swal.fire('Child Age should not be greater than 24 years 11 months 29 days', '', 'error');
              }
              else{
                insuredmembers[i].age = age;
              }
            }
            
            // else{
            //   if( BirthAge.years==99 && BirthAge.months >11){
                
            //     Swal.fire('Age should not be greater than 99 years 11 months 29 days', '', 'error');
            
            //   }
            //   else{
            //     if( BirthAge.years==99 && BirthAge.months ==11 && BirthAge.days >29){
            //       Swal.fire('Age should not be greater than 99 years 11 months 29 days', '', 'error');
            
            //     }
            //   }
            // }
            // if (ageday >= 1) {
            //   Swal.fire('Age should not be greater than 99 years', '', 'error');
            // }
            // if (agemonth > 11) {
            //   Swal.fire('Age should not be greater than 99 years', '', 'error');
            // }
          // }
          // else {
          //   insuredmembers[i].age = age;
          // }
          insuredmembers[i].dateOfBirth = $event.value._d;
         }
      }
      else{
        if (member === i) {
          if (age >= 100) {
            // insuredmembers[i].dateOfBirth ="";
            Swal.fire('Age should not be greater than 99 years', '', 'error');
            return;
          } else if (age == 99) {
  
            var todayDate = new Date();
            var ageyear = todayDate.getFullYear() - $event.value._d.getFullYear();//year
            var agemonth = todayDate.getMonth() - $event.value._d.getMonth();//month
            var ageday = todayDate.getDate() - $event.value._d.getDate();
            let nowDay = new Date();
            if (ageyear >= 100) {
              Swal.fire('Age should not be greater than 99 years', '', 'error');
            }
            if (ageday >= 1) {
              Swal.fire('Age should not be greater than 99 years', '', 'error');
            }
            if (agemonth > 11) {
              Swal.fire('Age should not be greater than 99 years', '', 'error');
            }
          }
          else {
            insuredmembers[i].age = age;
          }
          insuredmembers[i].dateOfBirth = $event.value._d;
        }
      }


    }
  }




  private returnAge($event) {
    debugger;
    // if($event!=null){
    // let day=$event.getDate();
    // let m=$event.getMonth()
    // let y=$event.getFullYear();
    // let dt=new Date(y,m,day);

    // var ageDifMs = Date.now() - dt.getTime();
    // var ageDate = new Date(ageDifMs); // miliseconds from epoch
    // let age= Math.abs(ageDate.getUTCFullYear() - 1970);
    // return age;
    if ($event != null) {
      let day = $event.target.value._i.date;//$event.getDate();
      let m = $event.target.value._i.month + 1;//$event.getMonth()
      let y = $event.target.value._i.year;//$event.getFullYear();

      // let day=$event.value.getDate();s
      // let m=$event.value.getMonth()
      // let y=$event.value.getFullYear();
      let dt = new Date(y, m-1, day);

      var ageDifMs = Date.now() - dt.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      let age = Math.abs(ageDate.getUTCFullYear() - 1970);
      return age;
    }
  }


  goBack(divid) {
    // this.divAddress=false;
    this.route.navigateByUrl('/healthquotation');
  }
  GotoNextDiv(divid) {
    debugger;
    // if (divid == "divMember") {
    if (divid == "divstep1") {
      this.divstep1 = true;
      this.divstep2 = false;
      this.divstep3 = false;
      this.divstep4 = false;
      $("#step-count-first").addClass("current");
      $("#step-count-second").removeClass("current");
      $("#step-count-third").removeClass("current");
      $("#step-count-fourth").removeClass("current");

    }
    // if (divid == "divAddress") {
    this.validatation = true;
    if (divid == "divstep2") {
      // this.checkMemberDetails();
      //if (this.validateMember == true) {
      // let success=this.validateMemberData();
      // if(success==true){
      this.divstep1 = false;
      this.divstep2 = true;
      this.divstep3 = false;
      this.divstep4 = false;
      $("#step-count-first").removeClass("current");
      $("#step-count-second").addClass("current");
      $("#step-count-third").removeClass("current");
      $("#step-count-fourth").removeClass("current");
      this.divAddress = true;
      //this.divMember=false;
      this.divEditMember = false;
      this.divQuestion = false;
      if (this.productId == 17) {
        debugger;
        this.isStarproduct = true;
        this.GetStarCity(this.proposerAddress.pincode);
        this.GetStarArea(this.proposerAddress.pincode, this.proposerAddress.city);
      }
      let insuredmembers = this.filterItemsOfType(1);
    for (let i = 0; i < insuredmembers.length; i++) {
      let member = insuredmembers[i];
      let age = this.memberAgeCalculation(member.dateOfBirth);
      let nomineeage = this.memberAgeCalculation(member.nomineeDOB);
      let Propage = this.memberAgeCalculation(this.proposerAddress.ProposerDOB);

      let today = new Date();
      let past = new Date(member.dateOfBirth);
      debugger;
      let diff = Math.floor(today.getTime() - past.getTime());
      let day = 1000 * 60 * 60 * 24;

      let days = Math.floor(diff / day);
      let months = Math.floor(days / 31);
      let years = Math.floor(months / 12);
      debugger;
      this.getAge(member.dateOfBirth)
      member.age = this.years1;
      age = this.years1;
      days = this.day1;
      if (age < 18 && member.memberName.substring(0, 5) != 'Child') {
        Swal.fire('Age should not be less than 18 years', '', 'error');
        document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
        return false;
      }
      if (age > 99 && member.memberName.substring(0, 5) != 'Child') {
        Swal.fire('Age should not be greater than 99 years', '', 'error');
        document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
        return false;
      }
      //SAtish
      if (this.productId == 3) {
        if (member.bmi < '18' || member.bmi > '35') {
          Swal.fire('Please Enter Valid BMI Range!')
          document.getElementById("bmi_" + member.bmi).style.border = "1px solid red";
          return false;
        }
        if (member.occupation == '290' && (member.otheroccupation == "" || member.otheroccupation == null)) {
          Swal.fire('Please Enter Other Occupation Details!')
          return false;
        }
        else if (member.occupation != '290') {
          member.otheroccupation = ""
        }
      }
      if (this.companyId == 10) {
         if (member.memberName.substring(0, 5) != 'Child' && member.bmi >= '32.1') {
          Swal.fire('Please Enter Valid BMI Range!')
          return false;
        }
      }
      //SAtish
      if (this.productId == 26) {


        if (member.salutation == '51' && member.gender == 'F') {
          Swal.fire('Please select gender according to salutation.', '', 'error');
          document.getElementById("gender_" + member.memberName).style.border = "1px solid red";
          return false;
        }
        else {
          document.getElementById("gender_" + member.memberName).style.border = "1px solid #A5A5A5";
        }

        if (member.salutation == '52' && member.gender == 'M') {
          Swal.fire('Please select gender according to salutation.', '', 'error');
          document.getElementById("gender_" + member.memberName).style.border = "1px solid red";
          return false;
        }
        else {
          document.getElementById("gender_" + member.memberName).style.border = "1px solid #A5A5A5";
        }
      }

      if (this.productId == 17) {
        if (nomineeage < 18) {
          Swal.fire('Nominee age should be greater than 18.', '', 'error');
          document.getElementById("nomineeDOB").style.border = "1px solid red";
          return false;
        }
      }
      if (member.salutation == null) {
        Swal.fire('Please select Salutation.', '', 'error');
        document.getElementById("salutatition_" + member.memberName).style.border = "1px solid red";
        return false;
      }
      else {
        document.getElementById("salutatition_" + member.memberName).style.border = "1px solid #A5A5A5";
        //return true;
      }
      
      if (member.name == "") {
        Swal.fire('Please enter First Name.', '', 'error');
        document.getElementById("firstName_" + member.memberName).style.border = "1px solid red";
        return false;
      }
      else {
        document.getElementById("firstName_" + member.memberName).style.border = "1px solid #A5A5A5";
        //return true;
      }

      if (member.lastname == "") {
        Swal.fire('Please enter Last Name.', '', 'error');
        document.getElementById("lastName_" + member.memberName).style.border = "1px solid red";
        return false;
      }
      else {
        document.getElementById("lastName_" + member.memberName).style.border = "1px solid #A5A5A5";
        //return true;
      }

      if (member.gender == null) {
        Swal.fire('Please select Gender.', '', 'error');
        document.getElementById("gender_" + member.memberName).style.border = "1px solid red";
        return false;
      }
      else {
        document.getElementById("gender_" + member.memberName).style.border = "1px solid #A5A5A5";
        //return true;
      }

      if (member.dateOfBirth == null) {
        Swal.fire('Please select Date Of Birth.', '', 'error');
        document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
        return false;
      }
      else if (member.dateOfBirth != null) {
        document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid #A5A5A5";
        //return true;
      }

      if (this.companyId == 10) {
        if (member.memberName.substring(0, 5) != 'Child' && Number(member.bmi) >= 32.1) {
          Swal.fire('BMI should be less than 32.1.', '', 'error');
          return false;
        }
      }

      if (age == 0) {
        if (member.memberName.substring(0, 5) == 'Child') {
          // let memDOB = new Date(member.dateOfBirth);

          //     var todayDate=new Date();
          //     var time_difference = todayDate.getTime() - memDOB.getTime();
          // var result = time_difference / (1000 * 60 * 60 * 24);
          //     var ageday = todayDate.getDate() - memDOB.getDate();
          //     var agemonth = todayDate.getMonth() - memDOB.getMonth();
          // if (agemonth<=3) {
          //   if(ageday<91){
          //   Swal.fire('Child Age should not be less than 91 days', '', 'error');      
          //   document.getElementById("dateofBirth_"+member.memberName).style.border = "1px solid red";
          //   return false;
          //   }
          // }
          if(this.companyId == 10){
            if (days < 1) {
              Swal.fire('Child Age should not be less than 1 days', '', 'error');
              document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
              return false;
            }
          }
          else{
            if (days < 91) {
              Swal.fire('Child Age should not be less than 91 days', '', 'error');
              document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
              return false;
            }
          }
          
        }
        else {
          Swal.fire('Age should not be 0 years', '', 'error');
          document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
          return false;
        }

      } else if(this.companyId == 10){
        if (days < 1) {
          Swal.fire('Child Age should not be less than 1 days', '', 'error');
          document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
          return false;
        }
      } else if (member.dateOfBirth != null) {
        document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid #A5A5A5";
        //return true;
      }

      if (this.productId == 3) {
        if (age >= 30 && member.memberName.substring(0, 5) == 'Child') {
          Swal.fire('Child Age should not be greater than 30 years', '', 'error');
          document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
          return false;
        }
      }
      else if (this.productId == 6 && this.planId == 9) {
        if (age >= 25 && member.memberName.substring(0, 5) == 'Child') {
          Swal.fire('Child Age should not be greater than 25 years', '', 'error');
          document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
          return false;
        }
      }
      else if (age >= 24 && member.memberName.substring(0, 5) == 'Child') {
        Swal.fire('Child Age should not be greater than 24 years', '', 'error');
        document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
        return false;
      }
      if (age >= 100) {
        Swal.fire('Age should be less than 100 years', '', 'error');
        document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
        return false;
      }
      else if (this.companyId==10 && age > 50) {
        Swal.fire('Age should be less than 50 years', '', 'error');
        document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
        return false;
      }
      else if (this.companyId!=10 && age < 100) {
        document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid #A5A5A5";
        //return true;
      }
      if (age > 99 && age < 100) {
        let memDOB = new Date(member.dateOfBirth);
        var todayDate = new Date();
        var ageyear = Math.abs(memDOB.getUTCFullYear() - 1970); //todayDate.getFullYear() - memDOB.getFullYear();//year
        var agemonth = todayDate.getMonth() - memDOB.getMonth();//month
        var ageday = todayDate.getDate() - memDOB.getDate();
        let nowDay = new Date();
        if (ageyear > 100) {
          Swal.fire('Age should be less than 100 years', '', 'error');
          return false;
        }
        if (ageday > 30) {
          Swal.fire('Age should be less than 100 years', '', 'error');
          return false;
        }
        if (agemonth > 11) {
          Swal.fire('Age should be less than 100 years', '', 'error');
          return false;
        }
      }
      if (this.productId == 26) {
        if (age >= 99) {
          if (this.day1 >= 1) {
            let memDOB = new Date(member.dateOfBirth);
            var todayDate = new Date();
            var ageyear = Math.abs(memDOB.getUTCFullYear() - 1970); //todayDate.getFullYear() - memDOB.getFullYear();//year
            var agemonth = todayDate.getMonth() - memDOB.getMonth();//month
            var ageday = todayDate.getDate() - memDOB.getDate();
            let nowDay = new Date();
            if (ageyear > 100) {
              Swal.fire('Age should be less than 100 years', '', 'error');
              return false;
            }
            if (ageday >= 1) {
              Swal.fire('Age should not be greater than 99 years', '', 'error');
              return false;
            }
            if (agemonth > 11) {
              Swal.fire('Age should be less than 100 years', '', 'error');
              return false;
            }
          }
        }
      }
      if (this.productId == 17) {
        if (Propage >= 100 || Propage < 18) {
          Swal.fire('Proposer Age should be between 18 to 100 years', '', 'error');
          document.getElementById("ProposerDOB").style.border = "1px solid red";
          return false;
        }
        if (nomineeage >= 100 || nomineeage < 18) {
          Swal.fire('Nominee Age should be between 18 to 100 years', '', 'error');
          document.getElementById("nomineeDOB").style.border = "1px solid red";
          return false;
        }
      }
      if (member.height == 0) {
        Swal.fire('Please select Height.', '', 'error');
        document.getElementById("height" + member.memberName).style.border = "1px solid red";
        return false;
      }
      else if (member.height != 0) {
        debugger;
        if (member.height == undefined) {
          Swal.fire('Please select Height.', '', 'error');
          document.getElementById("height" + member.memberName).style.border = "1px solid red";
          return false;
        }
        else {
          document.getElementById("height" + member.memberName).style.border = "1px solid #A5A5A5";
          //return true;
        }
      }

      if (member.weight == 0) {
        Swal.fire('Please select Weight.', '', 'error');
        document.getElementById("weight" + member.memberName).style.border = "1px solid red";
        return false;
      }
      else if (member.weight != 0) {
        if (member.weight == undefined) {
          Swal.fire('Please select Weight.', '', 'error');
          document.getElementById("weight" + member.memberName).style.border = "1px solid red";
          return false;
        }
        else {
          document.getElementById("weight" + member.memberName).style.border = "1px solid #A5A5A5";
          // return true;
        }

      }
      if (member.occupation == null) {
        Swal.fire('Please select Occupation.', '', 'error');
        document.getElementById("occupation_" + member.memberName).style.border = "1px solid red";
        return false;
      }
      else {
        document.getElementById("occupation_" + member.memberName).style.border = "1px solid #A5A5A5";
        //return true;
      }
      if (this.productId == 26) {
        if (member.memberName == "Self") {
          if (member.nomineeName == "") {
            Swal.fire('Please enter Nominee Name.', '', 'error');
            document.getElementById("nomineeName_" + member.memberName).style.border = "1px solid red";
            return false;
          }
          else {
            document.getElementById("nomineeName_" + member.memberName).style.border = "1px solid #A5A5A5";
            //return true;
          }
          if (member.nomineeRelation == null) {
            Swal.fire('Please select Nominee Relation.', '', 'error');
            document.getElementById("nomineeRelation_" + member.memberName).style.border = "1px solid red";
            return false;
          }
          else {
            document.getElementById("nomineeRelation_" + member.memberName).style.border = "1px solid #A5A5A5";
            //return true;
          }
        }

      }
      else {
        if (member.nomineeName == "") {
          Swal.fire('Please enter Nominee Name.', '', 'error');
          document.getElementById("nomineeName_" + member.memberName).style.border = "1px solid red";
          return false;
        }
        else {
          document.getElementById("nomineeName_" + member.memberName).style.border = "1px solid #A5A5A5";
          //return true;
        }
        if (member.nomineeRelation == null) {
          Swal.fire('Please select Nominee Relation.', '', 'error');
          document.getElementById("nomineeRelation_" + member.memberName).style.border = "1px solid red";
          return false;
        }
        else {
          document.getElementById("nomineeRelation_" + member.memberName).style.border = "1px solid #A5A5A5";
          //return true;
        }
      }




      if (member.maritalStatusCode == null) {
        Swal.fire('Please select Marital Status.', '', 'error');
        document.getElementById("maritalstatus_" + member.memberName).style.border = "1px solid red";
        return false;
      }
      else {
        document.getElementById("maritalstatus_" + member.memberName).style.border = "1px solid #A5A5A5";
      }
    }
      //}
      //}
    }
    // if (divid == "divEditMember") {
    //   this.divEditMember = true;
    //   //this.divMember=false;
    //   //this.divAddress=false;
    //   this.divQuestion = false;
    // }
    if (divid == "divQuestion") {
      // this.checkAddressDetails();
      // if (this.validateAddress == true) {
      //   this.updateMemberDetails();
      //   this.divQuestion = true;
      // }

      // this.divMember=false;
      // this.divAddress=false;
      // this.divEditMember=false;
    }
    if (divid == "divstep3") {
      this.divstep1 = false;
      this.divstep2 = false;
      this.divstep3 = true;
      this.divstep4 = false;
      $("#step-count-first").removeClass("current");
      $("#step-count-second").removeClass("current");
      $("#step-count-third").addClass("current");
      $("#step-count-fourth").removeClass("current");

      if (this.companyId == 33) {
        let insuredmembers = this.filterItemsOfType(1);
        this.DigitCKYCModel.ckycReferenceDocId = this.CKYCDocType;
        this.DigitCKYCModel.ckycReferenceNumber = this.CKYCDocId;
        this.DigitCKYCModel.dateOfBirth = insuredmembers[0].dateOfBirth;
        this.motorService.DigitCKYCQuoteApi(this.DigitCKYCModel).subscribe((result: any) => {

        }, ((err: ErrorHandle) => {
          this.ErrorHandle = err;
          if (this.ErrorHandle.statusText == "Bad Request") {
            Swal.fire('Please contact administrator.', '', 'error');
          }
        }));
      }
      this.checkAddressDetails();
      if (this.validateAddress == true) {
        this.updateMemberDetails();
        this.divQuestion = true;
      }

    }
    if (divid == "divstep4") {
      this.divstep1 = false;
      this.divstep2 = false;
      this.divstep3 = false;
      this.divstep4 = true;
      $("#step-count-first").removeClass("current");
      $("#step-count-second").removeClass("current");
      $("#step-count-third").removeClass("current");
      $("#step-count-fourth").addClass("current");
    }
  }

  PreviousStep() {
    debugger;
    if (this.divstep2 == true) {
      this.divstep1 = true;
      this.divstep2 = false;
      this.divstep3 = false;
      this.divstep4 = false;
      $("#step-count-first").addClass("current");
      $("#step-count-second").removeClass("current");
      $("#step-count-third").removeClass("current");
      $("#step-count-fourth").removeClass("current");
    }
    if (this.divstep3 == true) {
      this.divstep1 = false;
      this.divstep2 = true;
      this.divstep3 = false;
      this.divstep4 = false;
      $("#step-count-first").removeClass("current");
      $("#step-count-second").addClass("current");
      $("#step-count-third").removeClass("current");
      $("#step-count-fourth").removeClass("current");
    }
    if (this.divstep4 == true) {
      this.divstep1 = false;
      this.divstep2 = false;
      this.divstep3 = true;
      this.divstep4 = false;
      $("#step-count-first").removeClass("current");
      $("#step-count-second").removeClass("current");
      $("#step-count-third").addClass("current");
      $("#step-count-fourth").removeClass("current");
    }
  }

  validateMemberData() {
    let validate = true;
    if (sessionStorage.getItem('CoverType') == "FamilyFloater") {
      let member = this.members.filter(x => x.memberName == 'Elder');
      let maxAge = member[0].age;
      this.members.forEach((v, i) => {
        if (v.age > maxAge) {
          Swal.fire('Age should not be more than ' + maxAge, '', 'error');
          validate = false;
        } else {
          if (v.age == 0) { Swal.fire('Age should not be 0', '', 'error'); validate = false; }
          //this.updateMemberDetails();
        }
      });
    }
    //else{
    //   this.updateMemberDetails();
    //   }
    return validate;
  }
  updateMemberDetails() {
    let membersdata = [];
    for (let i = 0; i < this.members.length; i++) {
      let m = this.members[i];
      var dob = new Date(m.dateOfBirth);
      var dateOfBirth = dob.getFullYear() + '-' + (dob.getMonth() + 1) + '-' + dob.getDate();
      membersdata.push({
        Relation: m.memberName, SumInsured: m.sumInsured, DateOfBirth: m.dateOfBirth, Age: m.age,
        weight: m.weight, height: m.height, bmi: m.bmi
      });
    }
    let policyType = sessionStorage.getItem('CoverType');
    this.quoteId = this.QID;

    let quoteData = {
      QuoteId: this.quoteId,
      HealthQuoteRequest: {
        SessionId: '',
        IPAddress: '',
        UserId: '',
        PanIndiaCover: this.proposerAddress.PanIndiaCover,
        RentCappingDiscount: this.proposerAddress.RentCappingDiscount,
        ReqDate: new Date(),
        NoOfMembers: this.members.length,
        PolicyType: policyType,
        Portal:'B2C'
      },
      HealthQuoteMembers: membersdata,
      BajajPolicyCoverData: {
        PanIndiaCover: this.proposerAddress.PanIndiaCover,
        RentCappingDiscount: this.proposerAddress.RentCappingDiscount,
        WaiverRent: this.proposerAddress.WaiverRent,
        NonMedical: this.proposerAddress.NonMedical,
        HPR: this.proposerAddress.HPR,
        HPRValue: this.proposerAddress.HPRValue
      }
    }


    this.healthMasterService.postQuoteData(quoteData).subscribe((res: number) => {
      let quote = res;
      this.getQuotation();
    });

  }

  getQuotation() {
    this.quoteId = this.QID;
    this.flags = sessionStorage.getItem('CoverType') == "Individual" ? 0 : 1;
    this.healthService.getQuotation( this.flags, this.quoteId, this.planId, this.productId).subscribe((res: premiumData) => {
      this.premiunDetails = res;
      this.premiunDetails.QuotionId = this.quoteId;
      if(this.premiunDetails.CompanyId==33)
      {
        this.saveKycDetails();
      }
      this.proposalService.postPremiumData(this.premiunDetails).subscribe((proposalNo: number) => {
        Swal.fire('Premium updated to ₹' + this.premiunDetails.FinalPremium, 'Including GST', 'success');
      });

    });
  }

  keyPress($event) {
    const pattern = /[0-9]/;
    const inputChar = $event.key;//String.fromCharCode($event).charCodeAt;
    if (!pattern.test(inputChar.toString())) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  alphaOnly($event) {
    var pattern = /^[a-zA-Z ]*$/;
    //var pattern = /^[A-Za-z]+$/;
    const inputChar = $event.key;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  singleAnddouble($event) {
    var pattern = /'|"/g;
    const inputChar = $event.key;
    if (pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  bmichart(memberName, id, e, flag) {
    debugger

    let value;

    if (flag == 'N') {
      if (e == "height") {
        value = $("#txtHeight" + id).val();
        if (value!=undefined)
        $("#height" + memberName).val(value);
      }
      if (e == "weight") {
        value = $("#txtWeight" + id).val();
        if (value!=undefined)
        $("#weight" + memberName).val(value);
      }
    }
    else {
      if (e == "height") {
        value = $("#height" + memberName).val();
        if (value!=undefined)
        $("#txtHeight" + id).val(value);

      }
      if (e == "weight") {
        value = $("#weight" + memberName).val();
        if (value!=undefined)
        $("#txtWeight" + id).val(value);
      }

    }
    debugger

    let members = this.filterItemsOfType(1);
    if (members.length >= 1)
      members[0].id = 1;
    if (members.length >= 2)
      members[1].id = 2;
    if (members.length >= 3)
      members[2].id = 3;
    if (members.length >= 4)
      members[3].id = 4;
    if (members.length >= 5)
      members[4].id = 5;
    if (members.length >= 6)
      members[5].id = 6;



    for (let i = 0; i < members.length; i++) {

      let member = members[i];
      // if (member.memberName == memberName) {
      if (member.id == id && member.memberName == memberName) {
        if (e == "height") {
          if (value!=undefined)
        member.height = Number(value);
        } else {
          if (value!=undefined)
        member.weight = Number(value)
        }

        if (member.height != 0 && member.weight != 0) {
          var bmi = (member.weight / ((member.height / 100) * (member.height / 100)));
          if (isNaN(bmi) || bmi < 10){
            bmi = 10;
          }
          //SAtish
          else if (this.productId == 3) {
            if ((bmi < 18 || bmi > 35) && (member.memberName.substring(0, 5) != 'Child')) {
              Swal.fire('Please Enter Valid BMI Range!')
              //return false;
            }
          }
          else if (this.companyId == 10) {
            if (member.memberName.substring(0, 5) == 'Child' && member.age > 16) {
              if(bmi >= 32.1) {
                Swal.fire('Please Enter Valid BMI Range!')
              return false;
              }
              
            }
            else if (member.memberName.substring(0, 5) != 'Child' && bmi >= 32.1) {
              Swal.fire('Please Enter Valid BMI Range!')
              return false;
            }
          }
          //SAtish
          else if (bmi > 40)
            bmi = 40;
          member.bmi = bmi.toFixed(2);
          this.bmichartBind();
        }
      }
    }
  }

  demoTest(productId:any,comId:any,i:any){
    debugger;
    if(productId = 26){
      if(comId == 23 && i ==0){
        return true;
      }else{
        if(comId == 23){
          return false;
      
        }
        
      }
    }else{
      return false;
    }
  }

  bmichartBind() {
    var myConfig8 = {
      "type": "gauge",
      "scale-r": {
        "aperture": 180,
        "values": "0:100:20",
        "center": {
          "size": 2,
          "background-color": "#66CCFF #FFCCFF",
          "border-color": "none"
        },
        "ring": {
          "size": 5,
          "rules": [
            {
              "rule": "%v >= 0 && %v <= 10",
              "background-color": "#52e03f"
            },
            {
              "rule": "%v >= 20 && %v <= 20",
              "background-color": "#f0f80f"
            },
            {
              "rule": "%v >= 40 && %v <= 30",
              "background-color": "#f8a90f"
            },
            {
              "rule": "%v >= 60 && %v <= 40",
              "background-color": "#ff6541"
            },
            {
              "rule": "%v >= 80 && %v <=50",
              "background-color": "#f83b0f"
            }
          ]
        },
        "labels": ["very poor", "Poor", "Fair", "Good", "Great"],  //Scale Labels
        "item": {  //Scale Label Styling
          "font-color": "purple",
          "font-size": 8,
          "font-weight": "normal",   //or "normal"
          "font-style": "normal",   //or "italic"
          "offset-r": 0,
          "angle": "auto"
        }
      },
      "plot": {
        "csize": "2%",
        "size": "80%",
        "background-color": "#000000"
      },
      "series": [
        { "values": [this.myConfigValue] }
      ]

    };
    let insuredmembers = this.filterItemsOfType(1);
    for (let i = 0; i < insuredmembers.length; i++) {
      let member = insuredmembers[i];
      this.myConfigValue = Number(member.bmi);
      zingchart.render({
        id: 'myChart_' + member.memberName,
        data: myConfig8,
        //data : myConfig8.series[0],
        height: "90px",
        width: "120px"
      });

    }
    // zingchart.render({ 
    //   id : 'myChart', 
    //   data : myConfig8, 
    //   height : "100%", 
    //   width: "100%"
    // });
  }
  checkMemberDetails() {
    debugger;
    // this.CovidDetails.RPCVD=false;
    // this.CovidDetails.RPCV1="false";
    // this.CovidDetails.RPCV2="false";
    // this.CovidDetails.RPCV3="false";
    // this.CovidDetails.RPCV4="false";
    // this.CovidDetails.RPCV5="false";
    // this.CovidDetails.RPCV6="false";
    // this.CovidDetails.RPVCN=false;
    // this.CovidDetails.RPVC1="false";
    // this.CovidDetails.RPVC2="false";
    // this.CovidDetails.RPVC3="false";
    // this.CovidDetails.RPVC4="false";
    // this.CovidDetails.RPVC5="false";
    // this.CovidDetails.RPVC6="false";
    // this.CovidDetails.RPVC7="false";
    // this.CovidDetails.RCOVI=false;
    // this.CovidDetails.RPCVI=false;
    let insuredmembers = this.filterItemsOfType(1);
    for (let i = 0; i < insuredmembers.length; i++) {
      let member = insuredmembers[i];
    //   let age = this.memberAgeCalculation(member.dateOfBirth);
    //   let nomineeage = this.memberAgeCalculation(member.nomineeDOB);
    //   let Propage = this.memberAgeCalculation(this.proposerAddress.ProposerDOB);

    //   let today = new Date();
    //   let past = new Date(member.dateOfBirth);
    //   debugger;
    //   let diff = Math.floor(today.getTime() - past.getTime());
    //   let day = 1000 * 60 * 60 * 24;

    //   let days = Math.floor(diff / day);
    //   let months = Math.floor(days / 31);
    //   let years = Math.floor(months / 12);
    //   debugger;
    //   this.getAge(member.dateOfBirth)
    //   member.age = this.years1;
    //   age = this.years1;
    //   days = this.day1;
    //   if (age < 18 && member.memberName.substring(0, 5) != 'Child') {
    //     Swal.fire('Age should not be less than 18 years', '', 'error');
    //     document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
    //     return false;
    //   }
    //   if (age > 99 && member.memberName.substring(0, 5) != 'Child') {
    //     Swal.fire('Age should not be greater than 99 years', '', 'error');
    //     document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
    //     return false;
    //   }
    //   //SAtish
    //   if (this.productId == 3) {
    //     if (member.bmi < '18' || member.bmi > '35') {
    //       Swal.fire('Please Enter Valid BMI Range!')
    //       document.getElementById("bmi_" + member.bmi).style.border = "1px solid red";
    //       return false;
    //     }
    //     if (member.occupation == '290' && (member.otheroccupation == "" || member.otheroccupation == null)) {
    //       Swal.fire('Please Enter Other Occupation Details!')
    //       return false;
    //     }
    //     else if (member.occupation != '290') {
    //       member.otheroccupation = ""
    //     }
    //   }
    //   if (this.companyId == 10) {
    //      if (member.memberName.substring(0, 5) != 'Child' && member.bmi >= '32.1') {
    //       Swal.fire('Please Enter Valid BMI Range!')
    //       return false;
    //     }
    //   }
    //   //SAtish
    //   if (this.productId == 26) {


    //     if (member.salutation == '51' && member.gender == 'F') {
    //       Swal.fire('Please select gender according to salutation.', '', 'error');
    //       document.getElementById("gender_" + member.memberName).style.border = "1px solid red";
    //       return false;
    //     }
    //     else {
    //       document.getElementById("gender_" + member.memberName).style.border = "1px solid #A5A5A5";
    //     }

    //     if (member.salutation == '52' && member.gender == 'M') {
    //       Swal.fire('Please select gender according to salutation.', '', 'error');
    //       document.getElementById("gender_" + member.memberName).style.border = "1px solid red";
    //       return false;
    //     }
    //     else {
    //       document.getElementById("gender_" + member.memberName).style.border = "1px solid #A5A5A5";
    //     }
    //   }

    //   if (this.productId == 17) {
    //     if (nomineeage < 18) {
    //       Swal.fire('Nominee age should be greater than 18.', '', 'error');
    //       document.getElementById("nomineeDOB").style.border = "1px solid red";
    //       return false;
    //     }
    //   }
      // if (member.salutation == null) {
      //   Swal.fire('Please select Salutation.', '', 'error');
      //   document.getElementById("salutatition_" + member.memberName).style.border = "1px solid red";
      //   return false;
      // }
      // else {
      //   document.getElementById("salutatition_" + member.memberName).style.border = "1px solid #A5A5A5";
      //   //return true;
      // }

      // if (member.name == "") {
      //   Swal.fire('Please enter First Name.', '', 'error');
      //   document.getElementById("firstName_" + member.memberName).style.border = "1px solid red";
      //   return false;
      // }
      // else {
      //   document.getElementById("firstName_" + member.memberName).style.border = "1px solid #A5A5A5";
      //   //return true;
      // }

      // if (member.lastname == "") {
      //   Swal.fire('Please enter Last Name.', '', 'error');
      //   document.getElementById("lastName_" + member.memberName).style.border = "1px solid red";
      //   return false;
      // }
      // else {
      //   document.getElementById("lastName_" + member.memberName).style.border = "1px solid #A5A5A5";
      //   //return true;
      // }

      // if (member.gender == null) {
      //   Swal.fire('Please select Gender.', '', 'error');
      //   document.getElementById("gender_" + member.memberName).style.border = "1px solid red";
      //   return false;
      // }
      // else {
      //   document.getElementById("gender_" + member.memberName).style.border = "1px solid #A5A5A5";
      //   //return true;
      // }

      // if (member.dateOfBirth == null) {
      //   Swal.fire('Please select Date Of Birth.', '', 'error');
      //   document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
      //   return false;
      // }
      // else if (member.dateOfBirth != null) {
      //   document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid #A5A5A5";
      //   //return true;
      // }

      // if (this.companyId == 10) {
      //   if (member.memberName.substring(0, 5) != 'Child' && Number(member.bmi) >= 32.1) {
      //     Swal.fire('BMI should be less than 32.1.', '', 'error');
      //     return false;
      //   }
      // }

      // if (age == 0) {
      //   if (member.memberName.substring(0, 5) == 'Child') {
      //     // let memDOB = new Date(member.dateOfBirth);

      //     //     var todayDate=new Date();
      //     //     var time_difference = todayDate.getTime() - memDOB.getTime();
      //     // var result = time_difference / (1000 * 60 * 60 * 24);
      //     //     var ageday = todayDate.getDate() - memDOB.getDate();
      //     //     var agemonth = todayDate.getMonth() - memDOB.getMonth();
      //     // if (agemonth<=3) {
      //     //   if(ageday<91){
      //     //   Swal.fire('Child Age should not be less than 91 days', '', 'error');      
      //     //   document.getElementById("dateofBirth_"+member.memberName).style.border = "1px solid red";
      //     //   return false;
      //     //   }
      //     // }
      //     if(this.companyId == 10){
      //       if (days < 1) {
      //         Swal.fire('Child Age should not be less than 1 days', '', 'error');
      //         document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
      //         return false;
      //       }
      //     }
      //     else{
      //       if (days < 91) {
      //         Swal.fire('Child Age should not be less than 91 days', '', 'error');
      //         document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
      //         return false;
      //       }
      //     }
          
      //   }
      //   else {
      //     Swal.fire('Age should not be 0 years', '', 'error');
      //     document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
      //     return false;
      //   }

      // } else if(this.companyId == 10){
      //   if (days < 1) {
      //     Swal.fire('Child Age should not be less than 1 days', '', 'error');
      //     document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
      //     return false;
      //   }
      // } else if (member.dateOfBirth != null) {
      //   document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid #A5A5A5";
      //   //return true;
      // }

      // if (this.productId == 3) {
      //   if (age >= 30 && member.memberName.substring(0, 5) == 'Child') {
      //     Swal.fire('Child Age should not be greater than 30 years', '', 'error');
      //     document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
      //     return false;
      //   }
      // }
      // else if (this.productId == 6 && this.planId == 9) {
      //   if (age >= 25 && member.memberName.substring(0, 5) == 'Child') {
      //     Swal.fire('Child Age should not be greater than 25 years', '', 'error');
      //     document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
      //     return false;
      //   }
      // }
      // else if (age >= 24 && member.memberName.substring(0, 5) == 'Child') {
      //   Swal.fire('Child Age should not be greater than 24 years', '', 'error');
      //   document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
      //   return false;
      // }
      // if (age >= 100) {
      //   Swal.fire('Age should be less than 100 years', '', 'error');
      //   document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
      //   return false;
      // }
      // else if (this.companyId==10 && age > 50) {
      //   Swal.fire('Age should be less than 50 years', '', 'error');
      //   document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid red";
      //   return false;
      // }
      // else if (this.companyId!=10 && age < 100) {
      //   document.getElementById("dateofBirth_" + member.memberName).style.border = "1px solid #A5A5A5";
      //   //return true;
      // }
      // if (age > 99 && age < 100) {
      //   let memDOB = new Date(member.dateOfBirth);
      //   var todayDate = new Date();
      //   var ageyear = Math.abs(memDOB.getUTCFullYear() - 1970); //todayDate.getFullYear() - memDOB.getFullYear();//year
      //   var agemonth = todayDate.getMonth() - memDOB.getMonth();//month
      //   var ageday = todayDate.getDate() - memDOB.getDate();
      //   let nowDay = new Date();
      //   if (ageyear > 100) {
      //     Swal.fire('Age should be less than 100 years', '', 'error');
      //     return false;
      //   }
      //   if (ageday > 30) {
      //     Swal.fire('Age should be less than 100 years', '', 'error');
      //     return false;
      //   }
      //   if (agemonth > 11) {
      //     Swal.fire('Age should be less than 100 years', '', 'error');
      //     return false;
      //   }
      // }
      // if (this.productId == 26) {
      //   if (age >= 99) {
      //     if (this.day1 >= 1) {
      //       let memDOB = new Date(member.dateOfBirth);
      //       var todayDate = new Date();
      //       var ageyear = Math.abs(memDOB.getUTCFullYear() - 1970); //todayDate.getFullYear() - memDOB.getFullYear();//year
      //       var agemonth = todayDate.getMonth() - memDOB.getMonth();//month
      //       var ageday = todayDate.getDate() - memDOB.getDate();
      //       let nowDay = new Date();
      //       if (ageyear > 100) {
      //         Swal.fire('Age should be less than 100 years', '', 'error');
      //         return false;
      //       }
      //       if (ageday >= 1) {
      //         Swal.fire('Age should not be greater than 99 years', '', 'error');
      //         return false;
      //       }
      //       if (agemonth > 11) {
      //         Swal.fire('Age should be less than 100 years', '', 'error');
      //         return false;
      //       }
      //     }
      //   }
      // }
      // if (this.productId == 17) {
      //   if (Propage >= 100 || Propage < 18) {
      //     Swal.fire('Proposer Age should be between 18 to 100 years', '', 'error');
      //     document.getElementById("ProposerDOB").style.border = "1px solid red";
      //     return false;
      //   }
      //   if (nomineeage >= 100 || nomineeage < 18) {
      //     Swal.fire('Nominee Age should be between 18 to 100 years', '', 'error');
      //     document.getElementById("nomineeDOB").style.border = "1px solid red";
      //     return false;
      //   }
      // }
      // if (member.height == 0) {
      //   Swal.fire('Please select Height.', '', 'error');
      //   document.getElementById("height" + member.memberName).style.border = "1px solid red";
      //   return false;
      // }
      // else if (member.height != 0) {
      //   debugger;
      //   if (member.height == undefined) {
      //     Swal.fire('Please select Height.', '', 'error');
      //     document.getElementById("height" + member.memberName).style.border = "1px solid red";
      //     return false;
      //   }
      //   else {
      //     document.getElementById("height" + member.memberName).style.border = "1px solid #A5A5A5";
      //     //return true;
      //   }
      // }

      // if (member.weight == 0) {
      //   Swal.fire('Please select Weight.', '', 'error');
      //   document.getElementById("weight" + member.memberName).style.border = "1px solid red";
      //   return false;
      // }
      // else if (member.weight != 0) {
      //   if (member.weight == undefined) {
      //     Swal.fire('Please select Weight.', '', 'error');
      //     document.getElementById("weight" + member.memberName).style.border = "1px solid red";
      //     return false;
      //   }
      //   else {
      //     document.getElementById("weight" + member.memberName).style.border = "1px solid #A5A5A5";
      //     // return true;
      //   }

      // }
      // if (member.occupation == null) {
      //   Swal.fire('Please select Occupation.', '', 'error');
      //   document.getElementById("occupation_" + member.memberName).style.border = "1px solid red";
      //   return false;
      // }
      // else {
      //   document.getElementById("occupation_" + member.memberName).style.border = "1px solid #A5A5A5";
      //   //return true;
      // }
      // if (this.productId == 26) {
      //   if (member.memberName == "Self") {
      //     if (member.nomineeName == "") {
      //       Swal.fire('Please enter Nominee Name.', '', 'error');
      //       document.getElementById("nomineeName_" + member.memberName).style.border = "1px solid red";
      //       return false;
      //     }
      //     else {
      //       document.getElementById("nomineeName_" + member.memberName).style.border = "1px solid #A5A5A5";
      //       //return true;
      //     }
      //     if (member.nomineeRelation == null) {
      //       Swal.fire('Please select Nominee Relation.', '', 'error');
      //       document.getElementById("nomineeRelation_" + member.memberName).style.border = "1px solid red";
      //       return false;
      //     }
      //     else {
      //       document.getElementById("nomineeRelation_" + member.memberName).style.border = "1px solid #A5A5A5";
      //       //return true;
      //     }
      //   }

      // }
      // else {
      //   if (member.nomineeName == "") {
      //     Swal.fire('Please enter Nominee Name.', '', 'error');
      //     document.getElementById("nomineeName_" + member.memberName).style.border = "1px solid red";
      //     return false;
      //   }
      //   else {
      //     document.getElementById("nomineeName_" + member.memberName).style.border = "1px solid #A5A5A5";
      //     //return true;
      //   }
      //   if (member.nomineeRelation == null) {
      //     Swal.fire('Please select Nominee Relation.', '', 'error');
      //     document.getElementById("nomineeRelation_" + member.memberName).style.border = "1px solid red";
      //     return false;
      //   }
      //   else {
      //     document.getElementById("nomineeRelation_" + member.memberName).style.border = "1px solid #A5A5A5";
      //     //return true;
      //   }
      // }




      // if (member.maritalStatusCode == null) {
      //   Swal.fire('Please select Marital Status.', '', 'error');
      //   document.getElementById("maritalstatus_" + member.memberName).style.border = "1px solid red";
      //   return false;
      // }
      // else {
      //   document.getElementById("maritalstatus_" + member.memberName).style.border = "1px solid #A5A5A5";
      // }
      if(this.validatation == true) {		
        this.checkMaritalStatus(member.memberName,member.maritalStatusCode);
      }
      if (this.validatation == true) {
        this.checkNomineeRelation(member.maritalStatusCode,member.memberName,member.nomineeRelation);
      }

      if (this.validatation == true) {

        if (this.companyId ==10) {
          var val = {
            flag : 0,
            PolicyDetailsId: sessionStorage.getItem('policyDetailsId'),
            QuotionId : sessionStorage.getItem('quotaionId'),
            Relation  : member.memberName,
            InsuredName : member.name,
            LastName : member.lastname,
            InsuredDOB : this.healthService.formatDate(member.dateOfBirth),
            Age : Number(member.age),
            OccupationId : member.occupation,
            NomineeName : member.nomineeName,
            RelationWithNomineeId : member.nomineeRelation,
            BMI : member.bmi,
            Gender : member.gender,
            Height  : Number(member.height),
            Weight : Number(member.weight),
            SalutationId : member.salutation,
            MaritalStatusCode : member.maritalStatusCode,
            AbhaNo : member.abhaNo
          
          }   
          console.log(val)
          this.proposalService.SaveBasicMemberDetails(val).subscribe((data : any ) =>{
            if (data.status == 'SUCCESS') {
              return true
            }
            else return false 
         })
  
        }
      }

      if (this.companyId == 10 && this.isAbhaNoValid) {
        if($('#checkYesChk_'+member.memberName.replace(' ','\\ ')).is(':checked')){
          this.validatation = true;
        }
        else {
          this.validatation = false;
          Swal.fire('Please check the  checkbox.', '', 'error');
          return false;
        }
        
      }
      else if (this.companyId==10 && document.getElementById("abhaNo_"+ member.memberName).style.border == "1px solid red") {
        this.validatation = false;
        Swal.fire('Please check '+member.memberName+' Abha Number.', '', 'error');
        return false;
      }


    }
    
    if(this.validatation == true) {
      this.validateMember = true;
    }
    this.getDocumnetType();
    this.getIDDocumnetType();
    if (this.companyId == 10) {
      this.getMemberDetails();
      this.healthService.getRedirectCkycDetails(this.QID).subscribe((result: any) => {
        if(result) {
          let Address = result.Address.split(',');
          this.proposerAddress.email = result.EmailId;
          this.proposerAddress.mobile = Number(result.Mobile);
          this.objInitiateCKYCResp.CKYCNumber= result.Ckyc_Number.toString();
          this.proposerAddress.address1 = Address[0];
          this.proposerAddress.address2 = Address[1];
          this.proposerAddress.area = Address[2];
          this.CKYCDocType = result.id_type;
          this.CKYCDocId = result.id_num;
          this.IsVisible = true;
          this.IsKYCVerified =true ;
        } 
      })
    }
  }

  isLeapYear(year) {
    debugger;
    var d = new Date(year, 1, 28);
    d.setDate(d.getDate() + 1);
    return d.getMonth() == 1;
  }

  getAge(date) {
    debugger;
    var d = new Date(date), now = new Date();
    this.years1 = now.getFullYear() - d.getFullYear();
    d.setFullYear(d.getFullYear() + this.years1);
    if (d > now) {
      this.years1--;
      d.setFullYear(d.getFullYear() - 1);
    }
    var days = (now.getTime() - d.getTime()) / (3600 * 24 * 1000);
    this.day1 = days;
    return this.years1 + days / (this.isLeapYear(now.getFullYear()) ? 366 : 365);
  }

  // let date = '1685-03-21';

  // alert(this.getAge(date) + ' years');

  ExceptSpecialChars(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32
      || k == 35 || k == 44 || k == 46 || k == 47 || k == 92 || k == 38 || k == 39 || k == 34
      || k == 64 || (k >= 48 && k <= 57));
  }
  checkAddressDetails() {
    debugger;
    // debugger;
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var addregex = /^([{}+=~`$%^<>?])+$/; //^$~{}?<>*=`
    if (this.productId == 26) {
      addregex = /^[A-Za-z][A-Za-z0-9./#&,]*$/
      //^([ A-Za-z0-9-./#&,])+$/; 
    }
    // var addregex = /^[a-zA-Z0-9]+$/;
    //var phoneno = /^\d{10}$/;
    var phoneno = /^[6-9]\d{9}$/gm;
    var pin = /^[1-9][0-9]{5}$/;
    //console.log(this.maritalStatus);
    //Address 
    //SAtish
    for (let i = 0; i < this.members.length; i++) {
      if (this.productId == 3) {
        if (this.members[i].bmi < '18' || this.members[i].bmi > '35') {
          Swal.fire('Please Enter Valid BMI Range!')
          return false;
        }
      }
      if (this.members[i].occupation == '290' && (this.members[i].otheroccupation == "" || this.members[i].otheroccupation == null)) {
        Swal.fire('Please Enter Other Occupation Details!')
        return false;
      }
      else if (this.members[i].occupation != '290') {
        this.members[i].otheroccupation = "";
      }
    }
    //SAtish
    if (this.proposerAddress.RentCappingDiscount.toUpperCase() == 'YES' && this.proposerAddress.WaiverRent.toUpperCase() == "YES") {
      Swal.fire('Room Rent Capping Discount and Add On - Waiver of room rent capping can not opted together!', '', 'error');
      return false;
    }

    if (this.proposerAddress.email == "") {
      Swal.fire('Please enter Email.', '', 'error');
      document.getElementById("email").style.border = "1px solid red";
      return false;
    }
    else {
      if (!regex.test(this.proposerAddress.email)) {
        Swal.fire('Please enter Email.', '', 'error');
        document.getElementById("email").style.border = "1px solid red";
        return false;
      } else {
        document.getElementById("email").style.border = "1px solid #A5A5A5";

      }

    }

    if(this.AllowEmailverify==true){
      if(this.isEmailOTPVerified==false){
        Swal.fire('Please verify Email', '', 'error');
        return false;
      }
    }

    if (this.proposerAddress.mobile == 0) {
      Swal.fire('Please enter valid Mobile Number.', '', 'error');
      document.getElementById("mobile").style.border = "1px solid red";
      return false;
    }
    else {
      let mobile = this.proposerAddress.mobile;
      if (mobile.toString().match(phoneno)) {
        document.getElementById("mobile").style.border = "1px solid #A5A5A5";
      } else {
        Swal.fire('Please enter valid Mobile Number.', '', 'error');
        document.getElementById("mobile").style.border = "1px solid red";
        return false;
      }

    }

    // if (this.proposerAddress.emergencyMobile != '') {
    //   let mobile = this.proposerAddress.emergencyMobile;
    //   if (mobile.toString().match(phoneno)) {
    //     document.getElementById("emergency_Mobile").style.border = "1px solid #A5A5A5";
    //   } else {
    //     Swal.fire('Please enter valid Alternate Mobile Number.', '', 'error');
    //     document.getElementById("emergency_Mobile").style.border = "1px solid red";
    //     return false;
    //   }
    // } else {
    //   document.getElementById("emergency_Mobile").style.border = "1px solid #A5A5A5";
    // }

    // if(this.proposerAddress.emergencyMobile==0){
    //   document.getElementById("emergency_Mobile").style.border = "1px solid red";
    //   return false;
    // }
    // else{
    //   document.getElementById("emergency_Mobile").style.border = "1px solid #A5A5A5";
    // }

    // if(document.getElementById("maritalstatus")!=null){
    //   if(this.maritalStatusCode==''  || this.maritalStatusCode==undefined){
    //     document.getElementById("maritalstatus").style.border = "1px solid red";
    //     return false;
    //   }else{
    //     document.getElementById("maritalstatus").style.border = "1px solid #A5A5A5";
    //   }
    // }

    if (this.proposerAddress.pincode == 0 || this.proposerAddress.pincode.toString().length < 6) {
      Swal.fire('Please enter valid Pincode.', '', 'error');
      document.getElementById("pincode").style.border = "1px solid red";
      return false;
    }
    else {
      if (this.proposerAddress.pincode.toString().match(pin)) {
        document.getElementById("pincode").style.border = "1px solid #A5A5A5";
      } else {
        Swal.fire('Please enter valid Pincode.', '', 'error');
        document.getElementById("pincode").style.border = "1px solid red";
        return false;
      }

    }
    if (this.proposerAddress.state == "") {
      Swal.fire('Please enter State.', '', 'error');
      document.getElementById("state").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("state").style.border = "1px solid #A5A5A5";

    }
    if (this.proposerAddress.city == "") {
      Swal.fire('Please enter City.', '', 'error');
      document.getElementById("city").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("city").style.border = "1px solid #A5A5A5";

    }

    if (this.proposerAddress.area == "") {
      Swal.fire('Please enter Area.', '', 'error');
      document.getElementById("area").style.border = "1px solid red";
      return false;
    }
    // else if(addregex.test(this.proposerAddress.area)){
    //   Swal.fire('Area should not contain special characters.', '', 'error');
    //   document.getElementById("address1").style.border = "1px solid red";
    //   return false;
    // }
    else {
      if (this.productId == 26 && this.proposerAddress.area.length > 60) {
        Swal.fire('Area must be at max 60 characters long.', '', 'error');
        document.getElementById("area").style.border = "1px solid red";
        return false;
      }
      else {
        document.getElementById("area").style.border = "1px solid #A5A5A5";
      }

    }



    if (this.proposerAddress.address1 == "") {
      Swal.fire('Please enter Address1.', '', 'error');
      document.getElementById("address1").style.border = "1px solid red";
      return false;
    }
    // else if(addregex.test(this.proposerAddress.address1)){
    //   Swal.fire('Address1 should not contain special characters', '', 'error');
    //   document.getElementById("address1").style.border = "1px solid red";
    //   return false;
    // }
    else {
      if (this.productId == 26 && this.proposerAddress.address1.length > 60) {
        Swal.fire('Address1 must be at max 60 characters long.', '', 'error');
        document.getElementById("address1").style.border = "1px solid red";
        return false;
      }
      else {
        document.getElementById("address1").style.border = "1px solid #A5A5A5";
      }

    }

    if (this.proposerAddress.address2 == "" && this.productId != 26) {
      Swal.fire('Please enter Address2.', '', 'error');
      document.getElementById("address2").style.border = "1px solid red";
      return false;
    }
    // else if(addregex.test(this.proposerAddress.address2)){
    //   Swal.fire('Address2 should not contain special characters.', '', 'error');
    //   document.getElementById("address1").style.border = "1px solid red";
    //   return false;
    // }
    else {
      if (this.productId == 26 && this.proposerAddress.address2.length > 60) {
        Swal.fire('Address2 must be at max 60 characters long.', '', 'error');
        document.getElementById("address2").style.border = "1px solid red";
        return false;
      }
      else {
        document.getElementById("address2").style.border = "1px solid #A5A5A5";
      }
    }

    this.validateAddress = true;
  }
  vcheckConfirm(values) {
    if (values == 'Yes') {
      if (this.checkConfirm == true) {
        this.checkConfirm = false;
      } else {
        this.checkConfirm = true;
      }

    } else {
      this.checkConfirm = false;
    }
  }


  isAbhaNoConfirm(abhaNo,memberName) {
    debugger;
    this.isAbhaNoValid = false;
    document.getElementById("abhaNo_"+ memberName).style.border = "1px solid #A5A5A5";
    const regex = /^[6-9]\d{13}$/gm;
    const str = abhaNo;
    let m:any;
    if (abhaNo.length == 14) {
      $('#checkYes_'+memberName.replace(' ','\\ ')).show();
      this.isAbhaNoValid = true;
    }
    else if (m = regex.exec(str) == null && (abhaNo != null && abhaNo != undefined && abhaNo != "")){
      document.getElementById("abhaNo_"+ memberName).style.border = "1px solid red";
      $('#checkYes_'+memberName.replace(' ','\\ ')).hide();
      this.isAbhaNoValid = false;
      return false;
      }
    else  {
      $('#checkYes_'+memberName.replace(' ','\\ ')).hide();
      this.isAbhaNoValid = false;
      return false;
    }
  }
  DocTypePolicy(docType) {
    this.DocumentTypeReceive = docType;
  }
  getSidebarDetails() {
    let searchtext = "Proposal";
    this.healthMasterService.GetBaseCoverage(searchtext).subscribe((res: any[]) => {
      if (res != null) {
        for (let i = 0; i < res.length; i++) {
          if (res[i].Status == 'true') {
            if (res[i].CategoryName == "Must to have") {
              this.BaseCoverageList.push({ item_text: res[i].Remarks });
            }
            if (res[i].CategoryName == "Good to have") {
              this.KeyList.push({ item_text: res[i].Remarks });
            }
            // if(res[i].CategoryName =="Key Highlights"){
            //  this.HighlightList.push({ item_text:res[i].Remarks }); 
            // }

          }
        }
      }
    });
  }
  numberToText(SumInsured) {

    var Amount = SumInsured.toString();
    var lastThree = Amount.substring(Amount.length - 3);
    var otherNumbers = Amount.substring(0, Amount.length - 3);
    if (otherNumbers != '')
      lastThree = ',' + lastThree;

    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  }

  showMoreItems(text) {
    if (text == "MusttoHave") {
      this.paginationLimit = Number(this.paginationLimit) + 2;
    }

    if (text == "GoodtoHave") {
      this.paginationLimit2 = Number(this.paginationLimit2) + 2;
    }

  }
  showLessItems(text) {
    if (text == "MusttoHave") {
      this.paginationLimit = Number(this.paginationLimit) - 2;
    }

    if (text == "GoodtoHave") {
      this.paginationLimit2 = Number(this.paginationLimit2) - 2;
    }
  }
  PanIndiaCover(type: string) {
    this.proposerAddress.PanIndiaCover = type;
  }
  RentCappingDiscount(type: string) {
    this.proposerAddress.RentCappingDiscount = type;
  }
  CKYCNumberAvailChange(type: string) {
    if (type == 'Yes') {
      this.IsCKYCNumberAvailable = true;
      this.IsCKYCAvailChecked = true;
      this.CKYCChecked = "Yes";
    }
    else {
      this.IsCKYCNumberAvailable = false;
      this.IsCKYCAvailChecked = false;
      this.CKYCChecked = "No";


    }
  }
  PANAvailChange(type: string) {
    if (type == 'Yes') {
      this.IsPANAvail = true;
      this.IsPANAvailChecked = true;
      this.PANChecked = "Yes";
    }
    else {
      this.IsPANAvail = false;
      this.IsPANAvailChecked = false;
      this.PANChecked = "No";

    }
  }
  //SAtish
  RentWaiver(type: string) {
    this.proposerAddress.WaiverRent = type;
  }
  //SAtish
  //  ngAfterViewInit() {   
  //   $(document).ready(function () {
  //     $(".viewPlans").click(function () { 
  //       $("#myModal").modal('show');
  //     });
  //     $("#insuureFilter").click(function () {
  //       $("#insuureModal").modal('show');
  //     });
  //     $("#coverFilter").click(function () {
  //       $("#coverModal").modal('show');

  //     });
  //     $("#featuresFilter").click(function () {
  //       $("#featuresrModal").modal('show');
  //     });
  //   });
  // }

  ngAfterViewInit() {
    var div_top = $('.proposalHeader').offset().top;
    $(window).scroll(function () {
      var window_top = $(window).scrollTop() - 0;
      if (window_top > div_top) {
        if (!$('.proposalHeader').is('.topDetailssticky')) {
          $('.proposalHeader').addClass('topDetailssticky');
        }
      } else {
        $('.proposalHeader').removeClass('topDetailssticky');
      }
    });
  }
  memberAgeCalculation(dob) {
    if (dob != "" && dob != null) {
      var date = new Date(dob);
      var ageDifMs = Date.now() - date.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      let age = Math.abs(ageDate.getUTCFullYear() - 1970);
      return age;

    }
    else {
      return 0;
    }

  }
  onBlurMethod(val) {
    document.getElementById("mobile").style.border = "1px solid #A5A5A5";
    const regex = /^[6-9]\d{9}$/gm;
    const str = val;
    let m;
    if (m = regex.exec(str) == null) {

      document.getElementById("mobile").style.border = "1px solid red";

    } else {
      while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          //alert(`Found match, group ${groupIndex}: ${match}`);              

        });
      }
    }
  }
  onBlurMethod1(val) {
    document.getElementById("emergency_Mobile").style.border = "1px solid #A5A5A5";
    const regex = /^[6-9]\d{9}$/gm;
    const str = val;
    let m;
    if (m = regex.exec(str) == null) {

      document.getElementById("emergency_Mobile").style.border = "1px solid red";

    } else {
      while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          //alert(`Found match, group ${groupIndex}: ${match}`);              

        });
      }
    }
  }

  //Star Changes For City and Area 
  GetStarCity(Pincode) {
    var cityList: any = [];
    // cityList.push(this.CityList[0].city)
    // this.CityList = cityList;
    // this.CityList.forEach(function (value: any) {       
    //       cityList.push(value);             
    //   });
    this.healthMasterService.FetchStarCity(Pincode, this.productId).subscribe((res: any) => {
      debugger;
      if (res != null) {
        cityList.push(res.city)
        this.CityList = cityList;
        this.StarStateName = res.state_name;
      }
    });
  }

  GetStarArea(Pincode, Cityid) {
    debugger;
    var areaList: any = [];
    this.healthMasterService.FetchStarArea(Pincode, Cityid, this.productId).subscribe((res: any) => {
      debugger;
      if (res != null) {
        areaList.push(res.area)
      }
      this.AreaList = areaList;
    });
  }

  CheckStarcritical(Questionval, Questionid) {
    debugger;
    if (Questionval == 'yes' && Questionid == 77) {
      Swal.fire('You have opted for Critical illness , please contact us.', '', 'error');
      this.isStarMediQue77 = true;

    }
    else if (Questionval == 'no' && Questionid == 77) {
      this.isStarMediQue77 = false;
    }

    else if (Questionval == 'yes' && (Questionid == 80 || Questionid == 81)) {
      Swal.fire('We cannot proceed for policy issuance, as proposer is not satisfying good health requirements', '', 'error');
    }
    if(this.companyId==10){
      if (Questionval == 'no' && Questionid == 17) {
        Swal.fire('We cannot proceed for policy issuance, as proposer is not satisfying good health requirements.', '', 'error');
        return false;
  
      }      
      if (Questionval == 'no' && Questionid == 21) {
        Swal.fire('We cannot proceed for policy issuance, as proposer is not indian resident.', '', 'error');
        return false;
  
      }     
       if (Questionval == 'yes' && (Questionid == 23 || Questionid == 19 ||Questionid == 17 ||Questionid == 18 ||Questionid == 20 )) {
        Swal.fire('We cannot proceed for policy issuance, as proposer is not satisfying good health requirements.', '', 'error');
        return false;
  
      }      
      if (Questionval == 'yes' && Questionid == 16) {
        Swal.fire('We cannot proceed for policy issuance, as proposer is already have health total policy of future generali.', '', 'error');
        return false;
  
      }      
      if (Questionval == 'yes' && Questionid == 22) {
        Swal.fire('We cannot proceed for policy issuance, as proposers health insurence policy has been declined before.', '', 'error');
        return false;
  
      }
    }

  }

  openbjModal() {
    $("#bajajdeclaration").modal("show");
  }


  fileProgress(fileInput: any) {
    let data, header;
    const target: DataTransfer = <DataTransfer>(fileInput.target);
    this.fileUploaded = target.files[0];

    for (let i = 0; i < fileInput.target.files.length; i++) {
      let fileItem = <File>fileInput.target.files[i];
      if (fileItem.size > 3000000) {
        Swal.fire('Each File should be less than 3 MB of size.', '', 'error');
        return;
      }
    }
    for (let j = 0; j < fileInput.target.files.length; j++) {
      this.filecount++;
      this.fileData = <File>fileInput.target.files[j];
      const formData = new FormData();
      formData.append('photoDocument', this.fileData);
      this.vformData = formData;
    }
  }

  fileProgressPOI(fileInput: any) {
    let data, header;
    const target: DataTransfer = <DataTransfer>(fileInput.target);
    this.fileUploaded = target.files[0];

    for (let i = 0; i < fileInput.target.files.length; i++) {
      let fileItem = <File>fileInput.target.files[i];
      if (fileItem.size > 3000000) {
        Swal.fire('Each File should be less than 3 MB of size.', '', 'error');
        return;
      }
    }
    for (let j = 0; j < fileInput.target.files.length; j++) {
      this.filecount++;
      this.fileData = <File>fileInput.target.files[j];
      const formData = new FormData();
      formData.append('identityProofDocument', this.fileData);
      this.vformData = formData;
    }
  }

  fileProgressPOA(fileInput: any) {
    let data, header;
    const target: DataTransfer = <DataTransfer>(fileInput.target);
    this.fileUploaded = target.files[0];

    for (let i = 0; i < fileInput.target.files.length; i++) {
      let fileItem = <File>fileInput.target.files[i];
      if (fileItem.size > 3000000) {
        Swal.fire('Each File should be less than 3 MB of size.', '', 'error');
        return;
      }
    }
    for (let j = 0; j < fileInput.target.files.length; j++) {
      this.filecount++;
      this.fileData = <File>fileInput.target.files[j];
      const formData = new FormData();
      formData.append('addressProofDocument', this.fileData);
      this.vformData = formData;
    }
  }

  getDocumnetType() {
    this.healthService.getDocumnetType(this.companyId).subscribe((response: InsurerCompany[]) => {
      this.DocTypeList = response;
      this.CKYCDocType = "";

    })

    // alert(this.premium.CompanyId)
    // if (this.premium.CompanyId == 12) {
    //   this.DocTypeList = this.ICICIDocTypeList;
    // }
    // else 
    if (this.companyId == 5) {
      this.DocTypeList = this.BajajDocTypeList;
    }
    // else if (this.premium.CompanyId == 22) {
    //   this.DocTypeList = this.RGIDocTypeList;
    // }

    if (this.companyId == 12 || this.companyId == 5 || this.companyId == 22) {
      this.IsKYCVerified = false;
    }

  }
  getIDDocumnetType() {
    this.healthService.getIDDocumnetType(this.companyId).subscribe((response: InsurerCompany[]) => {
      this.IDDocTypeList = response;

    })
  }
  async VerifyKYC() {
    debugger;

    this.quoteId = this.QID;


    if (this.companyId == 10) {
      this.objInitiateCKYCResp = await this.InitiateKYCFGI();
      this.checkAddressDetails();
    if (this.validateAddress = true) {
      var value =  {
          flag : 1,
          PolicyDetailsId: sessionStorage.getItem('policyDetailsId'),
          ProposerAddress1: this.proposerAddress.address1,
          ProposerAddress2: this.proposerAddress.address2,
          ProposerStateCode: this.proposerAddress.stateCode,
          ProposerCityId: this.proposerAddress.cityId,
          ProposerPinCode: this.proposerAddress.pincode,
          ProposerMobileNumber: this.proposerAddress.mobile.toString(),
          ProposerAlternateMobileNumber : this.proposerAddress.emergencyMobile,
          ProposerEmailID: this.proposerAddress.email,
          PanIndiaCover: this.proposerAddress.PanIndiaCover,
          ProposerAreaId: this.proposerAddress.area,
          ProposerPAN:(this.AvailPAN == '' || this.AvailPAN == null || this.AvailPAN == undefined) ? "":this.AvailPAN ,
          id_type: this.CKYCDocType,
          id_num: this.CKYCDocId
      }
      debugger;
      console.log(value);
      this.proposalService.SaveBasicMemberDetails(value).subscribe((data : any ) =>{
        if (data.status == 'SUCCESS') {
          return true
        }
      })
    }
    }
    if (this.companyId == 22) {
      this.objInitiateCKYCResp = await this.InitiateKYCRGI();
    }
    if (this.companyId == 23) {
      if(this.isAddharVerified==false){

        if ((this.AvailPAN == '' || this.AvailPAN == null || this.AvailPAN == undefined)) {
          Swal.fire('Please enter PAN Number.', '', 'error');
          return false;
        }
        this.objInitiateCKYCResp = await this.InitiateKYCCARE();
      }
      else if(this.isAddharVerified==true){
        if ((this.AddharNo == '' || this.AddharNo == null || this.AddharNo == undefined)) {
          Swal.fire('Please enter Addhar Number.', '', 'error');
          return false;
        }
        this.objInitiateCKYCResp = await this.InitiateAddharValidateCARE();
      }
    }

    if (this.companyId == 27) {
      if (this.validateMember == true) {

        if (this.CKYCDocType == '' || this.CKYCDocType == null || this.CKYCDocType == undefined) {
          Swal.fire('Please select KYC Document Type', '', 'error');
          return false;
        }
        if (this.CKYCDocId == '' || this.CKYCDocId == null || this.CKYCDocId == undefined) {
          Swal.fire('Please enter KYC Document Id', '', 'error');
          return false;
        }

      }
      this.objInitiateCKYCResp = await this.InitiateKYCSH();
    }

    if (this.objInitiateCKYCResp.Status == 'Failed') {
      Swal.fire(this.objInitiateCKYCResp.StatusMessage, '', 'error');

      if (this.companyId == 23 && this.isAddharVerified==false) {

        // this.IsKYCVerified = true;
        // this.isUploadIDProof = true;
        this.isAddharVerified=true;
        return false;
      }
      else if (this.companyId == 23 && this.isAddharVerified==true) {
        // this.IsKYCVerified = true;
        // this.IsShowNextSelectionBtn = true;
         this.isUploadIDProof = true;
         this.isAddharVerified=true;
      }
      else if (this.companyId == 27) {

        this.IsKYCVerified = true;
        this.isUploadIDProof = true;
        this.IsSearchkyc = true;
        return false;
      }
      else {

        this.IsKYCVerified = false;
        return false;
      }
    }
    else if (this.objInitiateCKYCResp.Status == 'Redirect') {
      if (this.companyId == 10) {
        let RedirectUrl = AppSettings.API_ENDPOINT_CKYC.replace('api','') + 'RedirectKYCFGI?ProductId=' + this.productId + '&sessionId=' + this.quoteId + '&PlanId=' + this.planId+'&RedirectionURL=' + this.objInitiateCKYCResp.StatusMessage+ '&LOB=HEALTH';
        //let RedirectUrl = 'https://localhost:44301/RedirectKYCFGI?ProductId=' + this.productId + '&sessionId=' + this.quoteId + '&PlanId=' + this.planId+ '&RedirectionURL=' + this.objInitiateCKYCResp.StatusMessage+ '&LOB=HEALTH';

        window.location.href = RedirectUrl;
      }
      // else if (this.companyId == 23) {
      //   let RedirectUrl = AppSettings.API_ENDPOINT_CKYC.replace('api', '') + 'RedirectKYCFGI?ProductId=' + this.productId + '&sessionId=' + this.quoteId + '&PlanId=' + this.planId + '&RedirectionURL=' + this.objInitiateCKYCResp.StatusMessage + '&LOB=HEALTH';
      //   //let RedirectUrl = 'https://localhost:44301/RedirectKYCFGI?ProductId=' + this.productId + '&sessionId=' + this.quoteId + '&PlanId=' + this.planId+ '&RedirectionURL=' + this.objInitiateCKYCResp.StatusMessage+ '&LOB=HEALTH';

      //   window.location.href = RedirectUrl;
      // }
      else {
        window.location.href = this.objInitiateCKYCResp.StatusMessage;
      }
      }
    else {
      Swal.fire(this.objInitiateCKYCResp.StatusMessage, '', 'success');
      this.IsKYCVerified = true;
      this.IsShowNextSelectionBtn = true;
    }

  }


  async InitiateKYCBAJAJ() {
    let insuredmembers = this.filterItemsOfType(1);
    let dob = this.pipe.transform(new Date(insuredmembers[0].dateOfBirth), 'dd-MM-yyyy');
    this.CKYCDocId = this.CKYCDocId.toUpperCase();
    if (this.IsCKYCNumber == 'Y' && this.CKYCNo != '') {
      this.CKYCDocType = 'Z';
      this.docid = this.CKYCNo;
    }
    else if (this.IsPOIFailed == true) {
      this.docid = this.POAId.toUpperCase();

    }
    else {
      this.docid = this.CKYCDocId.toUpperCase();

    }
    if (this.BajajDocTypeList.docTypeCode == 'E') {
      // var n = this.CKYCDocId.indexOf(" ");
      // var res = this.CKYCDocId.substring(n+1,-1);
      this.docid = this.CKYCDocId.substr(this.CKYCDocId.length - 4);
    }

    if (this.CKYCDocType == 'E' && this.IsPOIFailed == true) {
      this.docid = this.POAId.substr(this.POAId.length - 4);

    }
    var val =
    {
      docTypeCode: this.CKYCDocType,//Z for ckyc number
      docNumber: this.docid,
      fieldType: "PROPOSAL_NUMBER",
      fieldValue: this.TransactionId,//localStorage.getItem('TransactionID'), //this.premium.ClientQuoteNo, //this.quoteModel.SessionId,
      dob: dob,
      appType: "KYC_WS_BROKER",
      productCode: this.productId,
      sysType: "OPUS",
      locationCode: "9906",
      userId: "webservice.bombay@riskcare.com",
      kycType: "03",
      customerType: sessionStorage.getItem('CoverType') == "Individual" ? 'I' : 'F',
      passportFileNumber: "",
      gender: insuredmembers[0].gender,
      field1: "HEALTH",
      field2: (this.CKYCDocType == 'E' && this.IsPOIFailed == true) ? (insuredmembers[0].name + ' ' + insuredmembers[0].lastname).toUpperCase() : ""
    }
    debugger
    return await this.motorService.InitiateKYCBAJAJ(val, [this.productId], [this.quoteId]).then((result: InitiateCKYCResp) => {
      if (result.Status == 'Failed') {
        // Swal.fire(result.StatusMessage, '', 'error');
        // this.objInitiateCKYCResp.Status=result.Status;
        // this.objInitiateCKYCResp.StatusMessage=result.StatusMessage;

        if (result.StatusMessage == 'Proof of identification is failed. Kindly provide proof of address.') {
          this.IsPOIFailed = true;
        }

        if (result.StatusMessage == 'Proof of address is failed. Kindly upload proof of address.') {
          this.UploadPA = true;
        }
        // return false;
      }
      // else {
      //   this.objInitiateCKYCResp.Status=result.Status;
      //   this.objInitiateCKYCResp.StatusMessage=result.StatusMessage;
      //   this.objInitiateCKYCResp.CKYCNumber=result.CKYCNumber;
      //   // return true;
      // }
      return result;
    });
  }


  async InitiateKYCSTAR() {
    // let dob = this.pipe.transform(new Date(this.proposalForm.controls.DateOfBirth.value), 'dd-MM-yyyy');
    // this.CKYCDocId=this.CKYCDocId.toUpperCase();
    // if(this.IsCKYCNumber=='Y' && this.CKYCNo!=''){
    //   this.CKYCDocType='Z';
    //   this.CKYCDocId=this.CKYCNo;
    // }

    // var custType="";
    // if(this.proposalForm.controls.Salutation.value=="MS"){
    //   custType="O";
    // }
    // else{
    //   custType="I"; 
    // }
    // if(this.BajajDocTypeList.docTypeCode=='E'){
    // var n = this.CKYCDocId.indexOf(" ");
    // var res = this.CKYCDocId.substring(n+1,-1);
    // this.CKYCDocId=this.CKYCDocId.substr(this.CKYCDocId.length - 4);
    //}
    let insuredmembers = this.filterItemsOfType(1);
    var val =
    {
      ckycId: this.CKYCNo,//Z for ckyc number
      titleId: insuredmembers[0].salutation,
      firstName: insuredmembers[0].name,
      middleName: '',
      lastName: insuredmembers[0].lastname,
      maidenName: '',
      genderId: insuredmembers[0].gender,
      birthdate: insuredmembers[0].dateOfBirth,
      occupationId: insuredmembers[0].occupation,
      residentialStatusId: this.residentialStatus,
      maritalStatusId: insuredmembers[0].maritalStatusCode,
      pan: this.CKYCDocId,
      familyRelationId: this.familyRelationId,
      familyRelationTitleId: this.familyRelationTitleId,
      familyRelationFirstName: this.familyRelationFirstName,
      familyRelationMiddleName: '',
      familyRelationLastName: this.familyRelationLastName,
      addressLineOne: this.proposerAddress.address1,
      addressLineTwo: this.proposerAddress.address2,
      cityName: this.proposerAddress.city,
      districtName: this.proposerAddress.area,
      stateCode: this.proposerAddress.stateCode,
      postalCode: this.proposerAddress.pincode,
      incomeSourceId: this.incomeSource,
      isAnyonePEP: this.IsPEP,
      pepRemarks: this.PEPRemarks
    }
    debugger
    return await this.healthMasterService.InitiateKYCSTAR(this.vformData, val, [this.productId], [this.quoteId]).then((result: InitiateCKYCResp) => {
      if (result.Status == 'Failed') {
        // Swal.fire(result.StatusMessage, '', 'error');
        // this.objInitiateCKYCResp.Status=result.Status;
        // this.objInitiateCKYCResp.StatusMessage=result.StatusMessage;

        // if(result.StatusMessage == 'Proof of identification is failed. Kindly provide proof of address.'){
        //   this.IsPOIFailed=true;
        // }

        // if(result.StatusMessage == 'Proof of address is failed. Kindly upload proof of address.'){
        //   this.UploadPA=true;
        // }
        // return false;
      }
      // else {
      //   this.objInitiateCKYCResp.Status=result.Status;
      //   this.objInitiateCKYCResp.StatusMessage=result.StatusMessage;
      //   this.objInitiateCKYCResp.CKYCNumber=result.CKYCNumber;
      //   // return true;
      // }
      return result;
    });
  }

  async InitiateKYCRGI() {
    let insuredmembers = this.filterItemsOfType(1);
    for (let i = 0; i < insuredmembers.length; i++) {
      let member = insuredmembers[i];    
      let dob = this.pipe.transform(new Date(member.dateOfBirth), 'dd-MM-yyyy');

    this.CKYCDocId = this.CKYCDocId.toUpperCase();
    var val =
    {
      PAN: this.CKYCDocType == 'PAN' ? this.CKYCDocId : null,
      DOB: dob,
      CKYC: this.CKYCDocType == 'CKYC' ? this.CKYCDocId : null,
      MOBILE: this.proposerAddress.mobile,
      PINCODE: this.proposerAddress.pincode,
      BIRTHYEAR: "",
      ReturnURL: "Test",
      UNIQUEID: this.quoteId,
      AADHAAR_No: this.CKYCDocType == 'AADHAAR_No' ? this.CKYCDocId : null,
      CIN: this.CKYCDocType == 'CIN' ? this.CKYCDocId : null,
      VOTERID: this.CKYCDocType == 'VOTERID' ? this.CKYCDocId : null,
      DL_No: this.CKYCDocType == 'DL_No' ? this.CKYCDocId : null,
      PASSPORT: this.CKYCDocType == 'PASSPORT' ? this.CKYCDocId : null,
      FULLNAME: insuredmembers[0].name + ' ' + insuredmembers[0].lastname,
      GENDER: insuredmembers[0].gender
    }

      debugger
      return await this.healthMasterService.InitiateKYCRGI(val, [this.productId], [this.quoteId], [this.planId]).then((result: InitiateCKYCResp) => {
        return result;
      });
    }
  }
    async InitiateKYCFGI() {
   // let insuredmembers = this.filterItemsOfType(1);
    let insuredmembers = this.filterItemsOfType(1);
    for (let i = 0; i < insuredmembers.length; i++) {
      let member = insuredmembers[i];
      this.CKYCDocId = this.CKYCDocId.toUpperCase();
   // }
      if (member.memberName == 'Self'){
        let dob = this.pipe.transform(new Date(member.dateOfBirth), 'dd-MM-yyyy');
        var val =
        {
          req_id: this.quoteId,
          customer_type: insuredmembers[0].salutation == 'MS' ? 'C' : 'I',
          id_type: this.CKYCDocType,
          id_num: this.CKYCDocId,
          dob: dob,
          full_name: insuredmembers[0].name + ' ' + insuredmembers[0].lastname,
          gender: insuredmembers[0].gender,
          url_type: "",
          system_name: ""
        }
      }
    }      

    debugger
    return await this.healthService.InitiateKYCFGI(val, [this.productId], [this.quoteId], [this.planId]).then((result: InitiateCKYCResp) => {
      debugger;
      if (result.Status == 'Success') {
        let Address = result.Address1.split(',');
        this.proposerAddress.address1 = Address[0];
        this.proposerAddress.address2 = Address[1];
        this.proposerAddress.area = Address[2];
        let insuredmembers = this.filterItemsOfType(1);
        for (let i = 0; i < insuredmembers.length; i++) {
          let member = insuredmembers[i];
          if (member.memberName == 'Self'){ 
            member.name = result.Name;
            member.lastname = result.LastName;
            this.proposerAddress.email = result.Emailid;
            this.proposerAddress.mobile = Number(result.MobileNo);
            this.IsVisible = true ;
          }
        }
      }
      
      return result;
    });
    
  }

  async InitiateKYCCARE() {
    let insuredmembers = this.filterItemsOfType(1);
    for (let i = 0; i < insuredmembers.length; i++) {
      let member = insuredmembers[i];
      //let dob = this.pipe.transform(new Date(member.dateOfBirth), 'dd-MM-yyyy');
      let dob = this.pipe.transform(new Date(member.dateOfBirth), 'yyyy-MM-dd');

      // this.CKYCDocId = this.CKYCDocId.toUpperCase();
      // var val =
      // {
      //   pan_details: {
      //     pan: this.CKYCDocId,
      //     dob: dob
      //   },
      //   getCkycEkycInputIO: {
      //     pan: this.CKYCDocId,
      //     document_type: "PAN",
      //     id_number: "",
      //     consent_purpose: "this is a consent purpose string pass anything",
      //     dob: dob,

      //   },
      //   certificate_type: ""
      // }


      //this.CKYCDocId = this.CKYCDocId.toUpperCase();
      var val =
      {
        pan_details: {
          pan: this.AvailPAN,
          dob: dob
        },
        getCkycEkycInputIO: {
          pan: this.AvailPAN,
          document_type: "PAN",
          id_number: "",
          consent_purpose: "this is a consent purpose string pass anything",
          dob: dob,

        },
        certificate_type: ""
      }

      debugger
      return await this.healthMasterService.InitiateKYCCARE(val, [this.productId], [this.quoteId], [this.planId]).then((result: InitiateCKYCResp) => {
        return result;
      });
    }
  }
  async InitiateKYCSH() {

    let DocumentID: number;
    let DocumentNumber: string;
    DocumentID = this.CKYCDocType;
    DocumentNumber = this.CKYCDocId.toUpperCase();
    return await this.healthMasterService.InitiateKYCSH(DocumentID, DocumentNumber, this.productId, this.quoteId).then((result: InitiateCKYCResp) => {
      return result;
    });

  }
  async GetquoteId(id) {
    await this.proposalService.GetquoteIdData(id).then((res: any) => {
      this.QID = res;
    });
  }
  async UploadData(id) {
    //$('#loader').show();
    // return await this.motorService.UploadCKYCDetails(this.vformData)
    //   .subscribe((res: any[]) => {
    //     return res;
    //   });
    if (id == "UploadCkycIdentityProofDoc") {

      if (this.CKYCIdentityProofDocType == '' || this.CKYCIdentityProofDocType == null || this.CKYCIdentityProofDocType == undefined) {
        Swal.fire('Please select Identity Proof', '', 'error');
        return false;
      }
      if (this.UploadCkycIdentityProofDoc == '' || this.UploadCkycIdentityProofDoc == null || this.UploadCkycIdentityProofDoc == undefined) {
        Swal.fire('Please upload file.', '', 'error');
        return false;
      }
      return await this.healthMasterService.UploadCKYCDetails(this.vformData, [this.productId], [this.quoteId], [this.planId]).subscribe((Data: any) => {
        debugger;
        this.objInitiateCKYCResp = Data;
        if (this.objInitiateCKYCResp.Status == "Success") {
          Swal.fire('Documents saved successfully', '', 'success');
          this.isUploadIDProof = true;
          this.isUploadAddressProof = true;

        }
        else {
          Swal.fire('Something went wrong', '', 'error');
          return false;
        }

      });
    }
    else if (id == "UploadCkycAddressProofDoc") {
      if (this.CKYCAddressProofDocType == '' || this.CKYCAddressProofDocType == null || this.CKYCAddressProofDocType == undefined) {
        Swal.fire('Please select Address Proof', '', 'error');
        return false;
      }
      if (this.UploadCkycAddressProofDoc == '' || this.UploadCkycAddressProofDoc == null || this.UploadCkycAddressProofDoc == undefined) {
        Swal.fire('Please upload file.', '', 'error');
        return false;
      }

      return await this.healthMasterService.UploadCKYCDetails(this.vformData, [this.productId], [this.quoteId], [this.planId]).subscribe((Data: any) => {
        debugger;
        this.objInitiateCKYCResp = Data;
        if (this.objInitiateCKYCResp.Status == "Success") {
          Swal.fire('Documents saved successfully', '', 'success');
          this.IsKYCVerified = true;
          this.IsKYCRequired = false;
this.UploadPA = false;
        }
        else {
          Swal.fire('Something went wrong', '', 'error');
          return false;
        }

      });
    }
    else {
      Swal.fire('Something went wrong', '', 'error');
      return false;
    }

  }


  addCkycDocuments(fileInput: any) {
    debugger;
    let data1, header;
    for (let j = 0; j < fileInput.target.files.length; j++) {
      this.filecount++;
      this.fileData = <File>fileInput.target.files[j];
      //alert ("first"+this.cheque );
      console.log("event=", fileInput);
      if (fileInput.target.id == "UploadCkycIdentityProofDoc") {

        this.UploadCkycIdentityProofDoc = this.fileData.name;
        if (this.fileData.size > 10000000) {

          alert("file size should be less than 10 MB");
          this.UploadCkycIdentityProofDoc = '';
          return;
        }
        this.formData.append(this.CKYCIdentityProofDocType, this.fileData);
        this.formData.append('proposalNumber', String(this.quoteId));
        this.formData.append('docType', 'Identity Proof');
        

        this.vformData = this.formData;
      }
      else {

        this.UploadCkycDoc = this.fileData.name;
        if (this.fileData.size > 10000000) {

          alert("file size should be less than 10 MB");
          this.UploadCkycDoc = '';
          return;
        }
        this.formData.append(this.CKYCAddressProofDocType, this.fileData);
        this.formData.append('proposalNumber', String(this.quoteId));
        this.formData.append('docType', 'Address Proof');

        this.vformData = this.formData;
      }
    }
    this.UploadData(fileInput.target.id);
  }

  async InitiateAddharValidateCARE() {
    let insuredmembers = this.filterItemsOfType(1);
    for (let i = 0; i < insuredmembers.length; i++) {
      let member = insuredmembers[i];
      //let dob = this.pipe.transform(new Date(member.dateOfBirth), 'dd-MM-yyyy');
      let dob = this.pipe.transform(new Date(member.dateOfBirth), 'yyyy-MM-dd');
      var val =
      {
          aadharno: this.AddharNo.substr(this.AddharNo.length - 4),
          name:  insuredmembers[0].name+" "+insuredmembers[0].lastname,
          dob: dob,
          gender: insuredmembers[0].gender
          
      }

      debugger
      return await this.healthMasterService.AddharKYCCARE(val, [this.productId], [this.quoteId], [this.planId]).then((result: InitiateCKYCResp) => {
        return result;
      });
    }
  }

  getMemberDetails() {
    debugger;
    var val = {
      flag : 2,
      PolicyDetailsId: sessionStorage.getItem('policyDetailsId')
    }
    console.log(val)
      this.proposalService.GetBasicMemberDetails(val).subscribe((response : any ) =>{
         this.proposerAddress.address1 = response[0].Address1,
         this.proposerAddress.address2 = response[0].Address2,
         this.proposerAddress.email = response[0].EmailID,         
         this.proposerAddress.mobile = Number(response[0].MobileNumber),
         this.proposerAddress.pincode = Number(response[0].PinCode);
         this.proposerAddress.state = response[0].StateName,         
         this.proposerAddress.city = response[0].CityName,         
         this.proposerAddress.stateCode = response[0].StateCode ,
         this.proposerAddress.cityId = response[0].CityId,
         this.proposerAddress.area = response[0].AreaId,
         this.proposerAddress.maritalStatusCode = response[0].MaritalStatusDescription,
         this.proposerAddress.PanIndiaCover = response[0].PanIndiaCover,
        //  this.CKYCDocType = response[0].DocumentID,
         this.CKYCDocType = response[0].DocumentID,
        //  this.CKYCDocType = this.DocTypeList[0].DocumentID;
         this.CKYCDocId = response[0].id_num,
         this.proposerAddress.emergencyMobile = response[0].AlternateMobileNumber
      })

      // this.DocTypeList = [];
      // this.DocTypeList.push('PAN');
      
  }

  getAgee(dateString) {
    var now = new Date();
    var today = new Date(now.getFullYear(),now.getMonth(),now.getDate());
  
    var yearNow = now.getFullYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();
  let Strdob=dateString.substring(6,10)+"-"+dateString.substring(0,2)+"-"+dateString.substring(3,5)
    // var dob = new Date(dateString.substring(6,10),
    //                    dateString.substring(0,2),                   
    //                    dateString.substring(3,5)                  
    //                    );
    var dob = new Date(Strdob);
  
    var yearDob = dob.getFullYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearString = "";
    var monthString = "";
    var dayString = "";
  
  
    var yearAge = yearNow - yearDob;
  
    if (monthNow >= monthDob)
      var monthAge = monthNow - monthDob;
    else {
      yearAge--;
      var monthAge = 12 + monthNow -monthDob;
    }
  
    if (dateNow >= dateDob)
      var dateAge = dateNow - dateDob;
    else {
      monthAge--;
      var dateAge = 31 + dateNow - dateDob;
  
      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
  
    let totalDays= Math.floor((Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) - Date.UTC(dob.getFullYear(), dob.getMonth(), dob.getDate()) ) /(1000 * 60 * 60 * 24));

    age = {
        years: yearAge,
        months: monthAge,
        days: dateAge,
        totalDays:totalDays
        };
  ageString=JSON.stringify(age);
    return ageString;
  }

  checkMaritalStatus(memberRelation:any,maritalStatus:any){
    debugger;
    if (this.companyId == 10) {
    this.validatation = false;
    if (this.members.filter(x => x.memberName == 'Spouse').length !=0 || this.members.filter(x => x.memberName.substring(0,5) == 'Child').length !=0) {
      this.IsMarried = true;
    }
    else { 
      this.IsMarried =false;
    }
    if(this.IsMarried && memberRelation == 'Self' && maritalStatus =='S'){
      Swal.fire('Please check '+memberRelation+' marital status.', '', 'error');
      return false;
    }
    else if(!this.IsMarried && memberRelation == 'Self' && maritalStatus =='M'){
      Swal.fire('Please check '+memberRelation+' marital status.', '', 'error');
      return false;
    }
    else if(this.IsMarried && memberRelation == 'Spouse' && maritalStatus =='S'){
      Swal.fire('Please check '+memberRelation+' marital status.', '', 'error');
      return false;
    }

    else if((memberRelation.substring(0,5) == 'Child') && maritalStatus == "M") {
      Swal.fire('Please check '+memberRelation+' marital status.', '', 'error');
      return false;
    }
    else this.validatation = true;

  }
}

  checkNomineeRelation(maritalStatus:any,memberName:any,nomineeRelation:any) {

    debugger;
    if (this.companyId == 10) {
    this.validatation = false;
    
    if(maritalStatus=='S' && memberName == 'Self' && (nomineeRelation == 12)) {
      Swal.fire('Please Check '+memberName+' Nominee Relationship.', '', 'error');
      return false;
    }
    else if(memberName.substring(0,5) == 'Child' && nomineeRelation != 282 && nomineeRelation !=283) {
      Swal.fire('Please Check '+memberName+' Nominee Relationship.', '', 'error');
      return false;
    }
    else  this.validatation = true;
  }
}

  checkGender(memberName:any,gender:any,salutation:any) {
    debugger;
    if(this.companyId == 10){
    this.validatation = false;
    if (gender == 'F' && salutation == 15) {
      Swal.fire('Please Check '+memberName+' Gender', '', 'error');
      return false;
    }

    else if (gender == 'M' && (salutation == 14 || salutation ==16)) {
      Swal.fire('Please Check '+memberName+' Gender', '', 'error');
      return false;
    }
    else this.validatation = true;
  }

  }

  captureOTP(OTPType: any) {
    debugger;
    // this.submitted = true;
    
    if (OTPType == 'E' && (this.proposerAddress.email == '' || this.proposerAddress.email == undefined)) {
      Swal.fire("", "Kindly Enter Email Id", "error");
      return;
    }
    
    var val = {
      IsActive: true,
      UserId: "",
      MobileNo: "",
      OTPExpiry: "",
      EmailId: this.proposerAddress.email,
      Remarks: OTPType,
      Status: "",
      CreatedBy: "",
      CreatedByIP: ""
    }

    // this.spinner.show();

    this.healthService.SendOTP(val).subscribe((data: any) => {
      debugger;
      if (data.Status == "SUCCESS") {
        // this.otpBox = "Y";
        // this.phoneBox = "N";
        if (OTPType == 'M')
          Swal.fire("", "OTP sent to mobile number", "success");
        else
          Swal.fire("", "OTP sent to Email id", "success");
      }
      else {
        Swal.fire("", "OTP sending failed", "error");
        // this.MobileNumber = '';
      }

      // setTimeout(() => {
      //   this.spinner.hide();
      // }, 1000);

    });


  }

  OTPValidation(OTPType: any) {
debugger;
    // this.submitted = true;
    // if (this.form1.invalid) {
    //   return;
    // }

    // if (OTPType == 'M' && (this.MobileOTP == '' || this.MobileOTP == undefined)) {
    //   Swal.fire("", "Kindly Enter OTP sent on your Mobile number", "error");
    //   return;
    // }
// this.EmailOTP=this.proposalForm.controls.EmailOTP.value;
    if (OTPType == 'E' && (this.proposerAddress.EmailOTP == '' || this.proposerAddress.EmailOTP == undefined)) {
      Swal.fire("", "Kindly Enter OTP sent on your Email id", "error");
      return;
    }

    // if(this.OTPSuccess==false){
    //   Swal.fire("", "Time out!! Please resend OTP", "error");
    //   return false;
    // }

    var val = {
      IsActive: true,
      UserId: "",
      MobileNo: "",
      OTPExpiry: "",
      EmailId: this.proposerAddress.email,
      Remarks: "",
      Status: "",
      CreatedBy: "",
      CreatedByIP: ""
    }
    this.healthService.OTPValidation(val).subscribe((data: any) => {
      debugger;
      // if (this.MobileOTP == data[0].OTP && this.EmailOTP == data[0].EOTP) {
      //   localStorage.setItem("UserId", data[0].UserId);
      //   localStorage.setItem("UserEmail", this.EmailId);
      //   // this.OTPSuccess=true;
      //   // this.SelectUserProfileById();

      // }
      // else {
      // this.OTPSuccess=false;

      // if (OTPType == 'M' && (this.MobileOTP == data[0].OTP)) {
      //   //Swal.fire("", "Mobile OTP is correct", "success");
      //   this.isMobileOTPVerified = true;
      // }
      // else 
      if (OTPType == 'E' && (this.proposerAddress.EmailOTP == data[0].EOTP)) {
        //Swal.fire("", "Email OTP is correct", "success");
        this.isEmailOTPVerified = true;
      }
      // else if (OTPType == 'M' && (this.MobileOTP != data[0].OTP)) {
      //   Swal.fire("", "Mobile OTP is not correct", "error");
      // }
      else if (OTPType == 'E' && (this.proposerAddress.EmailOTP != data[0].EOTP)) {
        Swal.fire("", "Email OTP is not correct", "error");
      }
      else {
        Swal.fire("", "Please provide correct OTP", "error");
      }

      //}
    });

    ////
  }
  }
