import { Component, OnInit } from '@angular/core';
import { HealthservicesService } from '../../services/healthservices.service';
import { Customer } from '../../common/Models/healthMaster.models';
import Swal from 'sweetalert2';

declare var $: any;
@Component({
  selector: 'app-student-travel-insurance',
  templateUrl: './student-travel-insurance.component.html',
  styleUrls: ['./student-travel-insurance.component.css']
})
export class StudentTravelInsuranceComponent implements OnInit {
  hide: any;
  $element: any;
  Customer:any= new Customer();

  constructor(private healthMasterService:HealthservicesService) { 
    this.Customer=new Customer();
    this.Customer.CustomerName="";
    this.Customer.Email="";
    this.Customer.Mobile="";
    this.Customer.Product="Student Travel Insurance";
    this.Customer.Lob="TRAVEL";
    this.Customer.Gender="M";
  }

  ngOnInit() {
    this.$element.on('click.dismiss.bs.modal', '[data-dismiss="modal"]', $.proxy(this.hide, this))
  }
  openNewpopup() {
    $("#Newpopup").modal('show');
    $(".modal-backdrop").removeClass('modal-backdrop fade show');
  }
  openNewpopup1() {
    $("#Newpopup1").modal('show');
    $(".modal-backdrop").removeClass('modal-backdrop fade show');
  }
  openNewpopup2() {
    $("#Newpopup2").modal('show');
    $(".modal-backdrop").removeClass('modal-backdrop fade show');
  }
  Save(){debugger;

    
    if (document.querySelector('input[name="radio"]:checked') == null) {
      Swal.fire('Please select gender', '', 'error');
      document.getElementById("male").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("male").style.border = "1px solid #A5A5A5";
    }
    if(this.Customer.CustomerName==""){
      Swal.fire('Please enter Name', '', 'error');
      document.getElementById("male").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("male").style.border = "1px solid #A5A5A5";
    }
    if(this.Customer.Mobile==""){
      Swal.fire('Please enter Mobile', '', 'error');
      document.getElementById("myNumber").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("myNumber").style.border = "1px solid #A5A5A5";
    }
    if(this.Customer.Email==""){
      Swal.fire('Please enter Email', '', 'error');
      document.getElementById("myEmail").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("myEmail").style.border = "1px solid #A5A5A5";
    }

    this.healthMasterService.postHealthCustomer(this.Customer).subscribe((res:any)=>{   
      if(res==true){
        let data=res;
        Swal.fire('Thank you for providing your details. Our team will contact you soon.', '', 'success');
      }
    });
  }

  onBlurMethod(val){
    document.getElementById("mobile").style.border = "1px solid #A5A5A5";
    const regex = /^[6-9]\d{9}$/gm;
    const str = val;
    let m;
    if(m = regex.exec(str) == null){
  
      document.getElementById("mobile").style.border = "1px solid red";
      
    }else{
        while ((m = regex.exec(str)) !== null) {        
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            m.forEach((match, groupIndex) => {
                //alert(`Found match, group ${groupIndex}: ${match}`);              
                
            });
        }
    }  
   }

   keyPress($event) {
    const pattern = /[0-9]/;    
    const inputChar = $event.key;
    if (!pattern.test(inputChar.toString())) {    
        // invalid character, prevent input
        event.preventDefault();
    }
}

}
