import { Injectable, Directive } from '@angular/core';
@Injectable({
    providedIn: 'root'
  })
export class GlobalService {  
    constructor() { }
    public isLogin:boolean=false;
    public showfooter:boolean=true;
    public userName='';
    compareArr:any= [];
    favouriteList:any=[];
    public lendingpage:boolean=false;
}