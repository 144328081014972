import { User, UserAuthentication } from './../../../common/Models/userDetail';
import { Component, OnInit,Input, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import{premiumModel,premiumData,quotePlan} from '../../../common/Models/premium.models';
import { HealthservicesService } from '../../../services/healthservices.service';
import { HealthIndexComponent } from '../health-index/health-index.component';
import { BuildplanComponent } from '../buildplan/buildplan.component';
import{HealthProposalService} from '../../../services/proposal.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {ErrorHandle} from '../../../common/models/ErrorHandle';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {token} from '../../../common/Models/token'
import { first } from 'rxjs/operators';
import { GlobalService } from 'src/app/global.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

declare var $: any;

@Component({
  selector: 'app-quotations',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.css']
})
export class QuotationsComponent implements OnInit {
  favlistcount: number;
  constructor(private healthService: HealthservicesService, private router: Router) {
    console.log(JSON.parse(sessionStorage.getItem('healthcovers')));
   }
   arrayFiltered = [];
   premiumArray: premiumData[] = [];
   bestSuitedPremium: premiumData[] = [];
   otherPremium: premiumData[] = [];
   premiums: premiumModel[] = [];
   plans: quotePlan[] = [];
   sumInsuredCollection = [];
   premiumCollection = [];
   members: any;
   companyShow: any[] = [];
   products = [];
   showLoad: boolean = true;
   @ViewChild(HealthIndexComponent, { static: false }) appHealth: HealthIndexComponent;
   @ViewChild(BuildplanComponent, { static: false }) buildplan: BuildplanComponent;
  ngOnInit() {
    this.getPremiums();
  }

  ngAfterViewInit() {

    $(document).ready(function () {
      $(".viewPlans").click(function () {
        $("#myModal").modal('show');
      });
      $("#insuureFilter").click(function () {
        $("#insuureModal").modal('show');
      });
      $("#coverFilter").click(function () {
        $("#coverModal").modal('show');

      });
      $("#featuresFilter").click(function () {
        $("#featuresrModal").modal('show');
      });
      $("#premiumFilter").click(function () {
        $("#featureModal").modal('show');
      });
      $("#basePlanFilter").click(function () {
        $("#BasePlanModal").modal('show');
      });


      //featureModal



      // $("#matchviewPlan").click(function () {
      //   $("#viewPlanModal").modal('show');
      // });
      // $("#viewPlan").on('click', function () {
      //   $(".hidequotationDetails").toggleClass('show');
      // });
      $(".btn-addcart").click(function () {
        $("#compareDiv").show();
      });
      $(".quotes_compare_remove_button").click(function () {
        $("#compareDiv").hide();
      });

      // $("#insuureModal").on('click', function () {
      //   $("#qFOverlay").append("<div class='overlapModal'></div>");
      //   $("body").css({ "overflow": "hidden" });
      //   $(".insureModal").addClass("show");
      //   $('.overlapModal').click(function () {
      //     $(".show").removeClass("show");
      //     $(".overlapModal").hide();
      //     $("body").css({ "overflow": "visible" });
      //   });
      // });

      var $this = $('.filter');

      if ($(window).width() < 768) {
        if ($this.find('div').length > 2) {
          $('.filterTitle').append('<div class="filter_btn"><a href="javascript:;" class="showMore"></a></div>');
        }
        // If more than 2 Education items, hide the remaining
        $('.filter .filter_list').slice(0, 0).addClass('shown');
        $('.filter .filter_list').not('.shown').hide();
        $('.filter .showMore').on('click', function () {
          $('.filter .filter_list').not('.shown').toggle(300);
          $(this).toggleClass('showLess');
        });
      }
      else {
        //alert('More than 960');
      }

    });

    var div_top = $('.filter').offset().top;
    $(window).scroll(function () {
      var window_top = $(window).scrollTop() - 0;
      if (window_top > div_top) {
        if (!$('.filter').is('.filtersticky')) {
          $('.filter').addClass('filtersticky');
        }
      } else {
        $('.filter').removeClass('filtersticky');
      }
    });

  }
  addItem(newItem: number) {
    this.favlistcount = newItem;
  }
  getPremiums() {
    debugger;
    let counter = 0;
    let quoteId = parseInt(sessionStorage.getItem('quotaionId'));
    this.healthService.getQuotationPlan(quoteId).subscribe((res: quotePlan[]) => {
      debugger
      if (res) {
        this.plans = res;
        this.plans.forEach((v) => {
          if (!this.products.find(element => element == v.ProductId))
            this.products.push(v.ProductId);
        })

        // this.plans=this.plans.slice(0,1);
        console.log(res);
        if (this.plans.length > 0) {
          for (let i = 0; i < this.plans.length; i++) {
            let planId = this.plans[i].PlanID;
            let ProductId = this.plans[i].ProductId; this.premiumArray = [];
            let flags = 0;
            this.healthService.getQuotation(flags, quoteId, planId, ProductId).subscribe((res: premiumData) => {
              this.companyShow[res.CompanyId] = true;
              counter++;
              if (counter < this.plans.length - 2) {
                this.showLoad = false;
              } else this.showLoad = false;
              res.CostPerDay = Math.round(res.CostPerDay);
              console.log(res);
              if (res.Premium > 0)
                this.premiumArray.push(res);
              //sorting desc
              // this.premiumArray.sort(function(a, b){return a.FinalPremium - b.FinalPremium});
              //   if(this.buildplan.healthCovers.length==0) {      

              debugger

              if(this.premiumArray.length>0){
              if (this.premiumArray[0].MatchedFeature == 0) {
                let idx = 0;
                this.premiumArray.sort(function (a, b) { return a.FinalPremium - b.FinalPremium });
                let itemFilter = this.premiumArray.filter((item, i) => { return item.ProductId == res.ProductId });
                if (itemFilter.length > 1) {
                  // itemFilter.forEach((item,i)=>{           
                  //   if(itemFilter.length-1>i){
                  //       itemFilter[i].Premium=0;



                  //   }
                  // })
                  itemFilter.sort(function (a, b) { return b.FinalPremium - a.FinalPremium });
                  console.log('sort')
                  console.log(this.premiumArray);
                  console.log(itemFilter);


                  const sIndex = this.premiumArray.indexOf(itemFilter[1]);

                  console.log(sIndex);

                  this.premiumArray.splice(sIndex, 1); //remove filter

                }

              }

              else {
                this.premiumArray.sort(function (a, b) {
                  // return (a.Premium - b.Premium && b.MatchedFeature-a.MatchedFeature) ;
                  if (a.MatchedFeature == b.MatchedFeature) {
                    return a.Premium - b.Premium;
                  }
                  else {
                    return b.MatchedFeature - a.MatchedFeature;
                  }
                  //if(b.MatchedFeature>a.MatchedFeature ) return 1;


                });
                //remove filter start

                let itemFilter = this.premiumArray.filter((item, i) => { return item.ProductId == res.ProductId });
                if (itemFilter.length > 1) {
                  const sIndex = this.premiumArray.indexOf(itemFilter[1]);

                  this.premiumArray.splice(sIndex, 1); //last change
                }
                //remove filter end
              }

            }
              // this.arrayFiltered = [];
              // this.premiumArray.forEach(obj => {
              //   const item = this.arrayFiltered.find(thisItem => thisItem.ProductId === obj.ProductId);
              //   if (item) {
              //       if (item.Premium < obj.Premium) {
              //          item.Premium = obj.Premium;
              //       }

              //      return;
              //   }

              //   this.arrayFiltered.push(obj);
              // });
              // this.premiumArray=this.arrayFiltered;

              //  this.premiumArray.sort(function(a, b){return a.FinalPremium - b.FinalPremium});



              debugger
              if (this.bestSuitedPremium.length > 0) {
                this.bestSuitedPremium = [];
                this.otherPremium = [];
              }

              if (this.premiumArray.length > 0) {
                this.premiumArray.forEach((v, i) => {
                  if (i > 0)
                    this.otherPremium.push(v);
                  else
                    this.bestSuitedPremium.push(v);
                })

              }
              console.log(this.premiumArray);
              let data = this.premiumArray;
              this.sumInsuredCollection = [];
              this.premiumCollection = [];
              if (data) {
                for (let p = 0; p < data.length; p++) {
                  let prem = data[p];
                  this.sumInsuredCollection.push(prem.SumInsured);
                  this.premiumCollection.push(prem.Premium);
                }
              }
            })
          }
        }
      }

    });


  }
  ReplyViewIssue(id) {
    //alert(id);
    //localStorage.removeItem('IssueId');
    //localStorage.setItem('IssueId', id);
  }


  memberApply($event) {
    debugger;
    $event.preventDefault();
    this.appHealth.RedirectToBuildPlan();
    let isRun = false;
    let interval = setInterval(() => {
      if (this.appHealth.submitted == true) {
        this.appHealth.submitted = false;
        isRun = true;
        this.getPremiums();
        $('.close').click();
      }
      if (isRun == false) clearInterval(interval);

    }, 1000);

  }

  processBuildPlan($event) {
    debugger;
    $event.preventDefault();
    this.buildplan.RedirectToQuotations();
    let isRun = false;
    let interval = setInterval(() => {
      if (this.buildplan.submitted == true) {
        this.buildplan.submitted = false;
        isRun = true;
        this.getPremiums();
        $('.close').click();
      }
      if (isRun == false) clearInterval(interval);

    }, 1000);
    return this.buildplan.submitted;
  }


  premiumSorting(flag) {
    debugger;
    if (flag == 'lower') {
      this.otherPremium.sort(function (a, b) { return a.FinalPremium - b.FinalPremium });
    }
    if (flag == 'higher') {
      this.otherPremium.sort(function (a, b) { return b.FinalPremium - a.FinalPremium });
    }
    //this.premiumArray = [...this.premiumArray];
  }


  sortPremium() {
    this.premiumCollection.sort(function (a, b) { return a - b });
    return {
      minPremium: this.premiumCollection[0],
      maxPremium: this.premiumCollection[this.premiumCollection.length - 1]
    }
  }

  sortSumInsured() {
    this.sumInsuredCollection.sort(function (a, b) { return a - b });
    let mimPremium = this.sumInsuredCollection[0];
    let maxPremium = this.sumInsuredCollection[this.sumInsuredCollection.length - 1];
    if (mimPremium == maxPremium)
      mimPremium = 0;

    return {
      minSumInsured: mimPremium / 100000,
      maxSumInsured: maxPremium
      // minSumInsured:this.numberToText(mimPremium),
      // maxSumInsured:this.numberToText(maxPremium)
    }
  }

  numberToText(SumInsured) {
    if (SumInsured != undefined) {
      var Amount = SumInsured.toString();
      var lastThree = Amount.substring(Amount.length - 3);
      var otherNumbers = Amount.substring(0, Amount.length - 3);
      if (otherNumbers != '')
        lastThree = ',' + lastThree;

      return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    }
  }

  noOfPlans() {
    //if(this.mrm)
  }

  getPlanCountText() {
    this.getMembers();
    let planText = 'for ';
    if (this.members[0].memberName == "Elder")
      return '';
    else {

      this.members = this.filtermembers();
      for (let i = 0; i < this.members.length; i++) {
        planText += this.members[i].memberName + "(" + this.members[i].age + "), "
      }
      return planText;
    }
    return '';

  }

  getMembers() {
    this.members = JSON.parse(sessionStorage.getItem('members'));

  }
  filtermembers() {
    if (this.members != null) {
      return this.members.filter(x => x.memberName != 'Elder');
    }

  }
  goBack(divid) {
    this.router.navigateByUrl('/buildplan');
  }

  downloadAsPDF() {
    var data = document.getElementById('divquotes');
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      //const pdf=new jsPDF();
      let pdf = new jsPDF()
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('newPDF.pdf');
    });
  }

}
