import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
// import { ErrorHandle } from '../models/ErrorHandle';
import Swal from 'sweetalert2';
import { Customer } from '../Models/Customer';
import { HealthservicesService } from 'src/app/services/healthservices.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  DateOfBirth:string;
  profileForm:FormGroup;
  userName:string;
  userEmail:string;
  userMobile:string;
  loading = false;
  submitted = false;
 // ErrorHandle:ErrorHandle;
  datePickerConfig: Partial<BsDatepickerConfig>;
  CustomerEdit = new Customer();

  constructor(private router:Router,private formBuilder:FormBuilder,   
   private healthMasterService:HealthservicesService) { 
    this.datePickerConfig = Object.assign({}, { containerClass: 'theme-blue' }) 
   }

  ngOnInit() {
    // if(sessionStorage.getItem('userName')!=null){     
    //   this.userName=sessionStorage.getItem('userName');
    //   this.userEmail=sessionStorage.getItem('email');
    //   this.userMobile=sessionStorage.getItem('mobile');
    // }else{
    //   this.router.navigate(['/health']);
    // }

    
  
        this.profileForm = this.formBuilder.group({   
          fullName: new FormControl(null, Validators.required),      
          Email: new FormControl(null, [Validators.required, Validators.email]),
          Mobile: new FormControl(null, [Validators.required, Validators.pattern("[0-9]{10}")]),
          Gender: new FormControl(null, Validators.required),
          DateOfBirth: new FormControl(null, Validators.required),
          // Pincode: new FormControl(null, Validators.required),
          State:new FormControl(null, Validators.required),
          City:new FormControl(null, Validators.required),
          Address1: new FormControl(null, Validators.required),
          Address2: new FormControl(null),
          Landmark: new FormControl(null)
          
      });
      if(sessionStorage.getItem('EmailId')!=null || sessionStorage.getItem('MobileNo')!=null){     
        this.userEmail =sessionStorage.getItem('EmailId');
        this.GetUserProfile();
      }
      else{
        this.router.navigate(['/login']);
      }
      
  }
  get r() { return this.profileForm.controls; }


  async updateProfile(){
    debugger;
    this.submitted = true;
        
        if (this.profileForm.invalid) {
            return;
        }
      
        //this.loading = true;
        console.log(this.profileForm);
        var val ={
        
          fullName: this.CustomerEdit.fullName,
          emailId: this.CustomerEdit.emailId,
          mobileNo: this.CustomerEdit.mobileNo,
          password: this.CustomerEdit.password,
          gender: this.CustomerEdit.gender,
          dateOfBirth: this.CustomerEdit.dateOfBirth,
          state: this.CustomerEdit.state,
          city: this.CustomerEdit.city,
          address: this.CustomerEdit.address,
          addressAlternate: this.CustomerEdit.addressAlternate,
          landmark: this.CustomerEdit.landmark,
          flag: "UpdateDetails",
        }
  
        await this.healthMasterService.UpdateCustomer(val).then(
          (Data:any)=>{
            //alert(Data);
            if(Data.length >0){
              Swal.fire("", Data, "success");
             // this.onReset();
              // this.router.navigate(['/Login']);
            }
          } ,
          (error) => console.log("ApIerror=",error)
        )
      }
    

    async GetUserProfile(){
      debugger;

          var val ={
            userName: this.userEmail,
            flag: 2,
          }
    
          await this.healthMasterService.GetCustomerDetails(val).then(
            (Data:any)=>{
              if(Data.length >0){
                 //console.log(Data);
             
                //this.CustomerEdit =Data[0];  
                this.CustomerEdit.fullName =Data[0].FullName;
                this.CustomerEdit.emailId=Data[0].EmailId;
                this.CustomerEdit.mobileNo=Data[0].MobileNo;
                this.CustomerEdit.password=Data[0].Password;
                this.CustomerEdit.gender=Data[0].Gender;
                this.CustomerEdit.dateOfBirth=Data[0].DateOfBirth;
                this.CustomerEdit.state=Data[0].State;
                this.CustomerEdit.city=Data[0].City;
                this.CustomerEdit.address=Data[0].Address;
                this.CustomerEdit.addressAlternate=Data[0].AddressAlternate;
                this.CustomerEdit.landmark=Data[0].Landmark;
              }
            } ,
            (error) => console.log("ApIerror=",error)
          )
        }


  }
        // this.authService.updateUserProfile(this.profileForm.value).subscribe((data:any)=>{          
        //   if(data.length>0){
        //       this.loading = false;
        //       Swal.fire('User profile update successfully.', '', 'success');              
        //   }
        //   else{
        //       this.loading = false;
        //       Swal.fire('Some error.', '', 'error');
        //   }         
          
        // }); 
  

  // getPincode($event){
  //   let pin=$event.target.value;
  //   //this.getCityByPincode(pin);
  // }
  // getCityByPincode(pin){
  //   this.healthMasterService.getPincodes(pin).subscribe((res:any[])=>{
  //     this.profileForm.get('State').setValue(res[0].StateName);
  //       this.profileForm.get('City').setValue(res[0].CityName);
  //   })
  // }
  // keyPress($event){
  //   const pattern = /[0-9]/;
  //   const inputChar = $event.key;//String.fromCharCode($event).charCodeAt;
  //   if (!pattern.test(inputChar.toString())) {    
  //       // invalid character, prevent input
  //       event.preventDefault();
  //   }
  // }

  // GetUserProfile(username){
  //   this.authService.GetUserProfile(username)
  //   .subscribe((data: any[]) =>{ 
  //     if(data[0].UserName !=""){        
  //       this.profileForm.get('UserId').setValue(data[0].UserId);
  //       this.profileForm.get('UserName').setValue(data[0].UserName);
  //       this.profileForm.get('Email').setValue(data[0].Email);
  //       this.profileForm.get('Mobile').setValue(data[0].Mobile);
  //       this.profileForm.get('Gender').setValue(data[0].Gender);
  //       this.profileForm.get('DateOfBirth').setValue(data[0].DateOfBirth);
  //       this.profileForm.get('Pincode').setValue(data[0].Pincode);
  //       this.profileForm.get('State').setValue(data[0].State);
  //       this.profileForm.get('City').setValue(data[0].City);
  //       this.profileForm.get('Address1').setValue(data[0].Address1);
  //       this.profileForm.get('Address2').setValue(data[0].Address2);
  //       this.profileForm.get('Landmark').setValue(data[0].Landmark);
  //     }else{
  //       Swal.fire('User profile not upload', '', 'error');
  //     }
  //   });

  // }

