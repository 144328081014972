import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-viewplandetails',
  templateUrl: './viewplandetails.component.html',
  styleUrls: ['./viewplandetails.component.css']
})
export class ViewplandetailsComponent implements OnInit {
  CompanyName:string;
  productId:number;
  ProductImage:string;
  ProductName:string;
  ProductIDV:string;
  FinalPremium:string;
  constructor(private masterService:MasterService
    ,private router:Router,private activeRouter:ActivatedRoute) { }

  ngOnInit() {
    // let productPremium=this.activeRouter.snapshot.queryParamMap.get('productPremium');
    // console.log(productPremium);
    this.getPremium();
  }
  buyNow(){}
  getPremium(){   
    

    this.CompanyName=localStorage.getItem('CompanyName');
    this.productId=parseInt(localStorage.getItem('productId'));
    this.ProductImage=localStorage.getItem('ProductImage');
    this.ProductName=localStorage.getItem('ProductName');
    this.ProductIDV=localStorage.getItem('ProductIDV');
    this.FinalPremium=localStorage.getItem('FinalPremium');

  //   this.viewPlanService.getHealthKeyFeatures(productId,planId).subscribe((res:any[])=>{
  //     this.healthKeyFeatures=res;
  //     //console.log(res);
  //   });

    
  //   // this.viewPlanService.getCashHospitalsList(this.searchText).subscribe((res:any[])=>{
  //   //   this.cashlessHospitalsList=res;
  //   //   //console.log(res);
  //   // });

  //   this.viewPlanService.getHealthDocumentsList(productId,planId).subscribe((res:any[])=>{
  //     this.healthDocumentsList=res;
  //     //console.log(res);
  //   },
  //   ((err:ErrorHandle) => {
  //     this.ErrorHandle=err;   
  //     this.ishealthDocuments=true;    
  //     //  if(this.ErrorHandle.statusText=="Bad Request"){
  //     //    this.ishealthDocuments=true;
  //     //    //Swal.fire(this.ErrorHandle.statusText, '', 'error');
         
  //     //  }
  // }));
  //   let vcompanyId=this.companyId;
  //   let vplanId=this.planId;
  //   this.viewPlanService.GetHealthKeyHighlights(this.companyId,productId,vplanId).subscribe((res:any[])=>{
  //     this.HighlightsList=res;
  //     //console.log(res);
  //   },
  //   ((err:ErrorHandle) => {
  //     this.ErrorHandle=err;     
  //     this.isHighlightsList=true;  
  //     //  if(this.ErrorHandle.statusText=="Bad Request"){
  //     //    this.isHighlightsList=true;
  //     //    //Swal.fire(this.ErrorHandle.statusText, '', 'error');
         
  //     //  }
  // }));

  //   this.viewPlanService.GetClaimProcess(productId,planId).subscribe((res:any[])=>{
  //     this.orgClaimProcessList=res;
  //     this.claimProcessList=res.filter((v,i,a)=>a.findIndex(t=>(t.ClaimProcessID === v.ClaimProcessID))===i);
  //     //console.log(res);
  //   },
  //   ((err:ErrorHandle) => {
  //     this.ErrorHandle=err;    
  //     this.isClaimProcessList=true;   
  //     //  if(this.ErrorHandle.statusText=="Bad Request"){
  //     //   this.isClaimProcessList=true;
                 
  //     //  }
  // }));
  // //this.getCashHospitalsList();
  }
}
