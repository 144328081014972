import { Component, OnInit } from '@angular/core';
import { CustomerLogin } from '../../Models/Customer';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HealthservicesService } from 'src/app/services/healthservices.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit {

  Show: boolean =false;
  // userName:any;
  // password:any;
  user:any= new CustomerLogin();
  submitted:boolean =false;


  Loginform: FormGroup = new FormGroup({
    userName: new FormControl(''),
    // password: new FormControl(''),
    
  });
 
  constructor(private healthMasterService:HealthservicesService ,private formBuilder:FormBuilder,
    private router:Router)
    {
    }

  ngOnInit() {

    this.Loginform = this.formBuilder.group({
      userName: ['', [Validators.required]],
      // password:['', [Validators.required]],
      });
  }

  
  get f(): { [key: string]: AbstractControl; }
  { return this.Loginform.controls; }
    
  
  async ForgetPassword(){
    debugger;
    this.submitted =true;
    //alert("test")
    // console.log(this.Loginform);
    if(this.Loginform.invalid){
      return;
    }
      var val ={
        userName: this.user.UserName,
        // password: this.user.Password,
        flag: "ForgetPassword",
      }
    
   await this.healthMasterService.ForgetPassword(val).then(
        (Data:any)=>{
          //alert(Data);
          if(Data.length >0){
            Swal.fire("", Data, "success");
            this.onReset();

           // this.router.navigate(['/Log']);

          }
          else{
            Swal.fire("", "Login Failed", "error");
          }
        } ,
        (error) => console.log("ApIerror=",error)
      );
    }
  
  ChangePassword() {
    this.Show = !this.Show;
  }

  
  onReset(){
 
    this.user.UserName="";
   // this.user.Passsword="";
 
  }
}
