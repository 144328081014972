export class SumInsured {
    SumInsuredId: number;
    SumInsuredValue: number;
    SumInsuredText: string;
   
}

export class FamilyMembers {
    MemberId: number;   
    MemberName: string;
   
}
export class Childrens {
    ChildId: number;   
    Name: string;
   
}

export class healthCover{
    coverId:Number;
    parrentCoverId:number;
    name:string;
    description:string;

}

export class PinCode{
    StateCode:string;
    StateName: string;
    CityId: number;
    CityName:string;
    PinCode: string;
    Zone: string;
}

export class ContactUs {
    Name: string;
    Email: string;
    Mobile: number;
    Message: string;
   
}

export class Customer {
    CustomerName: string;
    Email: string;
    Mobile: number;
    Lob: string;
    Product: string;
    Gender: string;
}

export class Renewal {
    CustomerName: string;
    Email: string;
    Mobile: string;
    Product: string;
    Gender: string;
    PolicyNo: string;
    InsuranceCompanyName: string;
    Request: string;
    PolicyDate: string;
}

export class ContactUsModal1{
    position:string="";
    fullName: string="";
    emailId: string="";
    mobileNo: string="";
    qualification: string="";
}