import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { insuredMember, proposer } from '../../../common/Models/premium.models'
import { Router } from '@angular/router';
import { HealthservicesService } from '../../../services/healthservices.service';
import { healthCover } from '../../../common/Models/healthMaster.models';
import { catchError } from 'rxjs/operators';
// import { ChartComponent } from '../chart/chart.component';
import { premiumModel, premiumData, quotePlan } from '../../../common/Models/premium.models';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/global.service';
declare var zingchart: any;
declare var $: any;

@Component({
  selector: 'app-buildplan',
  templateUrl: './buildplan.component.html',
  styleUrls: ['./buildplan.component.css']
})
export class BuildplanComponent implements OnInit {
  weight: number;
  height: number;
  bmi: string;
  myConfigValue: number;
  submitted: boolean = false;
  isShownBasicMedical: boolean = false;
  isShownSmoking: boolean = false;
  isShownAlcohol: boolean = false;
  members: insuredMember[] = [];
  healthCovers: any[] = [];
  medicalHistory: any[] = [];
  healthBMI: insuredMember[] = [];
  build_features: any;
  dynModel: any[] = [];
  rdButtonModel: any[] = [];
  fIndex = 0;
  isValidate: string = "false";
  feature: any;
  @Input() display;
  @Output() displayChanged: EventEmitter<number> = new EventEmitter();
  constructor(private router: Router, private healthService: HealthservicesService,
    private globalService:GlobalService) { }
    @Input() isPopup: boolean = false;
    premiumArray: premiumData[] = [];
    plans: quotePlan[] = [];
    
  ngOnInit() {
    this.getMembers();
    this.getPremiums();
    this.getFeatures();

    this.weight = 0;
    this.height = 0;
    this.bmi = "";
    //this.getMembers();
    $(document).ready(function () {
      $('.js-btn-tooltip').tooltip();
      $('.js-btn-tooltip--custom').tooltip({
        customClass: 'tooltip-custom'
      });
      $('.js-btn-tooltip--custom-alt').tooltip({
        customClass: 'tooltip-custom-alt'
      });

      $('.js-btn-popover').popover();
      $('.js-btn-popover--custom').popover({
        customClass: 'popover-custom'
      });
      $('.js-btn-popover--custom-alt').popover({
        customClass: 'popover-custom-alt'
      });

    });

  }

  RedirectToQuotations(): void {
    debugger
    this.isValidate = "false";
    this.rdButtonModel.forEach((v, i) => {
      if (this.dynModel[i] == undefined) {
        this.isValidate = "true";
        Swal.fire('Please select values.', '', 'error');
        return false;
      }
    });
    if (this.isValidate == "true") {
      return;
    }

    // if ($("input[type='radio'][name='BasicMedical']:checked").val()) {
    //   if ($("input[type='radio'][name='BasicMedical']:checked").val() == 'Yes') {
    //     Swal.fire('Please contact nearest branch.', '', 'error');
    //     return;
    //   }
    // }
    // else {
    //   Swal.fire('Please select Pre-exist disease for any member(s) Yes or No.', '', 'error');
    //   return;
    // }

    if (this.healthCovers.length != 0) {
      for (let i = 0; i < this.healthCovers.length; i++) {
        if (this.healthCovers[i].id == -59 && this.healthCovers[i].val == 'yes') {
          // alert('Please contact nearest branch.');
          Swal.fire('Please contact nearest branch.', '', 'error');
          return;
        }
      }

    }

    if (sessionStorage.getItem('healthcovers') == null) {
      sessionStorage.setItem('healthcovers', JSON.stringify(this.healthCovers));
    }
    sessionStorage.setItem('members', JSON.stringify(this.members));


    debugger
    if (sessionStorage.getItem('compareArr') != null) {
      sessionStorage.removeItem('compareArr');
      this.globalService.compareArr=[];
    }
    if (this.globalService.compareArr.length>0) {
      sessionStorage.removeItem('compareArr');
      this.globalService.compareArr=[];
    }

    this.saveQuoteData().subscribe((res: number) => {

      let data = res;
      this.submitted = true;
      // localStorage.setItem('quotaionId',data.toString());
      if (!this.isPopup) {
        if (data) {
          
          this.router.navigate(['/healthquotation']);
        }
      }
    },
      err => console.log(err),
    );
    if (!this.isPopup) {
      this.router.navigate(['/healthquotation']);
    }

  }

  getFeatures() {
    this.healthService.getBuildFeatures().subscribe(features => {
      console.log(features);
      this.build_features = features;
      
    })
  }

  initIncrement() {
    this.fIndex = 0;
  }
  increment() {
    this.fIndex++;
    return this.fIndex;
  }

  saveQuoteData() {
    console.log(this.dynModel);
    console.log(this.fIndex);
    let membersdata = [];
    let coversData = [];
    let membersHealthData = [];
    for (let i = 0; i < this.members.length; i++) {
      let m = this.members[i];
      let sumInsures = parseInt(m.sumInsured) * 100000;
      membersdata.push({
        Relation: m.memberName, SumInsured: sumInsures, DateOfBirth: m.dateOfBirth, Age: m.age,
        weight: m.weight, height: m.height, bmi: m.bmi
      });

    }

    for (let i = 0; i < this.healthCovers.length; i++) {
      let m = this.healthCovers[i];
      coversData.push({ CoverId: m.id, CoverValue: m.val });
    }

    for (let i = 0; i < this.healthCovers.length; i++) {
      let m = this.healthCovers[i];
      for (let j = 0; j < this.medicalHistory.length; j++) {

        if (this.medicalHistory[j].id == m.id) {
          membersHealthData.push({ Relation: this.medicalHistory[j].memberName, FeatureId: this.medicalHistory[j].id });
        }
      }
    }

    let policyType = sessionStorage.getItem('CoverType');
    let quoteId = sessionStorage.getItem('quotaionId');
    let userId = sessionStorage.getItem('UserId')
    let quoteData = {
      QuoteId: quoteId,
      UserId: 1,
      HealthQuoteRequest: {
        SessionId: '',
        IPAddress: '',
        UserId: userId,
        ReqDate: new Date(),
        NoOfMembers: this.members.length,
        PolicyType: policyType
      },
      HealthQuoteMembers: membersdata,
      HealthQuoteCovers: coversData,//.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i),
      QuotationMembersHealth: membersHealthData
    }
    sessionStorage.removeItem("quotedata");
    sessionStorage.setItem("quotedata", JSON.stringify(quoteData));
    return this.healthService.postQuoteCoverFeatureData(JSON.stringify(quoteData));

  }

  ClearAllFeatures() {
    debugger;
    // this.feature = this.build_features[0].features;
    for (let i = 0; i < this.build_features[0].Features.length; i++) {

      this.rdButtonModel[this.build_features[0].Features[i].Id] = null;
      this.dynModel[this.build_features[0].Features[i].Id] = 0;

    }

    this.healthCovers=[];

  }

  RedirectTohealth(): void {
    //this.displayChanged.emit();
    this.router.navigate(['/health']);
  }

  changeBasicMedical(e) {
    if (e.target.value == 'Yes')
      this.isShownBasicMedical = true;
    else
      this.isShownBasicMedical = false
  }

  changeSmoking(e) {
    if (e.target.value == 'Yes')
      this.isShownSmoking = true;
    else
      this.isShownSmoking = false
  }
  changeAlcohol(e) {
    if (e.target.value == 'Yes')
      this.isShownAlcohol = true;
    else
      this.isShownAlcohol = false
  }

  clearFeatureValue(id) {
    debugger
    for (var i = 0; i < this.healthCovers.length; i++) {
      if (this.healthCovers[i].id == id) {
        this.healthCovers.splice(i, 1);
      }
    }
    this.rdButtonModel[id] = 'No';
    this.dynModel[id] = '';
  }

  collectionCovers(id, val, e, etype) {

    debugger
    if (id == -59) {
      if (val == 'Yes') {
        Swal.fire('Please contact nearest branch.', '', 'error');
        sessionStorage.setItem('PEDQuestionVal', val);
        return;
      }
      else {
        sessionStorage.setItem('PEDQuestionVal', val);
        return;
      }
    }

    if (val == "") val = e.target.value;

    for (var i = 0; i < this.healthCovers.length; i++) {
      if (this.healthCovers[i].id == id) {
        this.healthCovers.splice(i, 1);
      }
    }

    if (this.dynModel[id] == val && etype == 'RadioButton') {
      this.dynModel[id] = '';
      return true;
    }
    else
      if (val == "--Select--") {
        return;
      }
    this.dynModel[id] = val;
    this.healthCovers.push({ id: id, val: val });

    this.medicalHistory.forEach((element, index) => {
      if (element.id == id) {
        this.medicalHistory.splice(index, 1)
      }
      //delete this.medicalHistory[index];
    });
  

  }

  updateMember(id: number, memberName) {
    for (let i = 0; i < this.healthCovers.length; i++) {
      if (this.healthCovers[i].id == id && this.healthCovers[i].val == "yes")
        this.medicalHistory.push({ memberName: memberName, id: id })
    }

  }

  getMembers() {
    debugger;
    this.members = JSON.parse(sessionStorage.getItem('members'));
    if (sessionStorage.getItem("quotedata") != null) {
      let quotedata = JSON.parse(sessionStorage.getItem("quotedata"));
      if (quotedata.HealthQuoteCovers.length > 0) {
        for (let i = 0; i < quotedata.HealthQuoteCovers.length; i++) {
          let props = "id" + quotedata.HealthQuoteCovers[i].CoverId;
          this.dynModel[quotedata.HealthQuoteCovers[i].CoverId] = quotedata.HealthQuoteCovers[i].CoverValue;
          this.rdButtonModel[quotedata.HealthQuoteCovers[i].CoverId] = 'Yes';
          this.healthCovers.push({ id: quotedata.HealthQuoteCovers[i].CoverId, val: quotedata.HealthQuoteCovers[i].CoverValue })
        }

      }
      if (quotedata.QuotationMembersHealth.length > 0) {
        for (let i = 0; i < quotedata.QuotationMembersHealth.length; i++) {
          this.medicalHistory.push({ memberName: quotedata.QuotationMembersHealth[i].Relation, id: quotedata.QuotationMembersHealth[i].FeatureId })
        }

      }

    }
    debugger
    if (sessionStorage.getItem('PEDQuestionVal') != null) {
      $('#' + sessionStorage.getItem('PEDQuestionVal') + 'BasicMedical').prop('checked', true);
    }
    //this.getPremiums();
  }

  onValueChange( e, id, name) {
    debugger;
    //alert(e);
    // this.height=0;
    // this.weight=0;
    let value;
    if (e == "height") {
      value = $("#Sliderheight" + id).val();
      $("#txtHeight" + id).val(value);
    }

    if (e == "weight") {
      value = $("#Sliderweight" + id).val();
      $("#txtWeight" + id).val(value);
    }
    
    let insuredmembers = this.filterItemsOfType(1);
    if(insuredmembers.length>=3) 
    insuredmembers[2].id=3;
    if(insuredmembers.length>=4)
    insuredmembers[3].id=4;
    if(insuredmembers.length>=5)
    insuredmembers[4].id=5;
    if(insuredmembers.length>=6)
    insuredmembers[5].id=6;
    for (var i = 0; i < insuredmembers.length; i++) {
      if (insuredmembers[i].id == id && insuredmembers[i].memberName == name) {
        if (e == "height") {
          insuredmembers[i].height = Number(value);
        } else {
          insuredmembers[i].weight = Number(value)
        }

        if (insuredmembers[i].height > 0 && insuredmembers[i].weight > 0) {
          var bmi = (insuredmembers[i].weight / ((insuredmembers[i].height / 100) * (insuredmembers[i].height / 100)));
          if (isNaN(bmi) || bmi < 10)
            bmi = 10;
          else if (bmi > 40)
            bmi = 40;
          this.bmi = bmi.toFixed(2);
          insuredmembers[i].bmi = this.bmi;
          this.bmichartBind();
        }

      }
    }
  }
  onValueChange1(e, id, name) {
    debugger;
    //alert(e);
    // this.height=0;
    // this.weight=0;
    let value;
    if (e == "height") {
      value = $("#txtHeight" + id).val();
      $("#Sliderheight" + id).val(value);
    }
    if (e == "weight") {
      value = $("#txtWeight" + id).val();
      $("#Sliderweight" + id).val(value);
    }
    let insuredmembers = this.filterItemsOfType(1);
    if (insuredmembers.length >= 3)
      insuredmembers[2].id = 3;
    if (insuredmembers.length >= 4)
      insuredmembers[3].id = 4;
    if (insuredmembers.length >= 5)
      insuredmembers[4].id = 5;
    if (insuredmembers.length >= 6)
      insuredmembers[5].id = 6;
    for (var i = 0; i < insuredmembers.length; i++) {
      if (insuredmembers[i].id == id && insuredmembers[i].memberName == name) {
        if (e == "height") {
          insuredmembers[i].height = Number(value);
        } else {
          insuredmembers[i].weight = Number(value)
        }

        if (insuredmembers[i].height > 0 && insuredmembers[i].weight > 0) {
          var bmi = (insuredmembers[i].weight / ((insuredmembers[i].height / 100) * (insuredmembers[i].height / 100)));
          if (isNaN(bmi) || bmi < 10)
            bmi = 10;
          else if (bmi > 40)
            bmi = 40;
          this.bmi = bmi.toFixed(2);
          insuredmembers[i].bmi = this.bmi;
          this.bmichartBind();
        }

      }
    }
  }
  bmichartBind() {
    var myConfig8 = {
      "type": "gauge",
      "scale-r": {
        "aperture": 180,
        "values": "0:100:20",
        "center": {
          "size": 2,
          "background-color": "#66CCFF #FFCCFF",
          "border-color": "none"
        },
        "ring": {
          "size": 5,
          "rules": [
            {
              "rule": "%v >= 0 && %v <= 10",
              "background-color": "#52e03f"
            },
            {
              "rule": "%v >= 20 && %v <= 20",
              "background-color": "#f0f80f"
            },
            {
              "rule": "%v >= 40 && %v <= 30",
              "background-color": "#f8a90f"
            },
            {
              "rule": "%v >= 60 && %v <= 40",
              "background-color": "#ff6541"
            },
            {
              "rule": "%v >= 80 && %v <=50",
              "background-color": "#f83b0f"
            }
          ]
        },
        "labels": ["very poor", "Poor", "Fair", "Good", "Great"],  //Scale Labels
        "item": {  //Scale Label Styling
          "font-color": "purple",
          "font-size": 8,
          "font-weight": "normal",   //or "normal"
          "font-style": "normal",   //or "italic"
          "offset-r": 0,
          "angle": "auto"
        }
      },
      "plot": {
        "csize": "2%",
        "size": "80%",
        "background-color": "#000000"
      },
      "series": [
        { "values": [this.myConfigValue] }
      ]

    };
    let insuredmembers = this.filterItemsOfType(1);
    for (let i = 0; i < insuredmembers.length; i++) {
      let member = insuredmembers[i];
      this.myConfigValue = Number(member.bmi);
      zingchart.render({
        id: 'myChart_' + member.memberName,
        data: myConfig8,
        //data : myConfig8.series[0],
        height: "90px",
        width: "120px"
      });

    }
  }

  filterItemsOfType(type) {
    if (this.members != null) {
      return this.members.filter(x => x.memberName != 'Elder');
    }

  }

  getPremiums() {
    debugger;
    let quoteId = parseInt(sessionStorage.getItem('quotaionId'));
    this.healthService.getQuotationPlan(quoteId).subscribe((res: quotePlan[]) => {
      if (res) {
        this.plans = res;
        if (this.plans.length > 0) {
          for (let i = 0; i < this.plans.length; i++) {
            let planId = this.plans[i].PlanID;
            let ProductId = this.plans[i].ProductId; this.premiumArray = [];
            let flags= 0;
            this.healthService.getQuotation(flags, quoteId, planId, ProductId).subscribe((res: premiumData) => {
              if (res.Premium > 0)
                this.premiumArray.push(res);

            })
          }
        }
      }

    });


  }

  sortPremium() {
    if (this.premiumArray != null && this.premiumArray.length > 0) {
      this.premiumArray.sort(function (a, b) { return a.Premium - b.Premium });
      return {
        minPremium: this.premiumArray[0].Premium,
        monthlyPremium: Math.round(this.premiumArray[0].Premium / 12)
      }
    } else {
      return {
        minPremium: 0,
        monthlyPremium: 0
      }
    }
  }

  sortFeatureList(list: any) {
    //console.log(list);
    return list.sort(function (a, b) { return a.ShowSequence - b.ShowSequence });
  }

}
