import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-single-view-blog',
  templateUrl: './single-view-blog.component.html',
  styleUrls: ['./single-view-blog.component.css']
})
export class SingleViewBlogComponent implements OnInit {
  abc:any;
  blogData:any;

  constructor(private location:Location){}

  ngOnInit():void {
    // console.log("location=",this.location.getState());
    this.abc=this.location.getState();
    this.blogData=this.abc.blogDetail;
    
  }

  replaceImgUrl(urlVal:string){

    if(urlVal){
      let splitVal=urlVal.split('/');
      // console.log("splitVal=",splitVal);
      let arrval=splitVal.splice(1);
      // console.log("arrval=",arrval);
  
      let UrlSt='https://uat.bimaraja.com:3452/'+arrval.join('/');
      // console.log("UrlSt=",UrlSt);
      
      return UrlSt;
    }
    
    return '';
  }
}
