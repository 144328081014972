import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HealthservicesService } from 'src/app/services/healthservices.service';
import { ContactUsModal1 } from '../Models/healthMaster.models';
import { Location } from '@angular/common';
import { handleError, handleSuccess } from 'src/app/Reusable/Modal';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  contactData1: any = new ContactUsModal1();
  fileUpload: any;
  formData: any = new FormData();
  abc: any;
  jobId: any;
 

  constructor(private router:Router,private contactService:HealthservicesService, private location: Location,) { }

  ngOnInit(): void {
    this.abc = this.location.getState();
    this.contactData1.position = this.abc.OpenPosition;
    this.jobId = this.abc.Jid;
  }

  validationForm1() {
    debugger;
    if (this.contactData1.fullName === '') {
      handleError('Full Name', 'Field is Mandatory');
    } else if (this.contactData1.emailId === '') {
      handleError('Email Id', 'Field is Mandatory');
    } else if (this.contactData1.mobileNo === '') {
      handleError('Phone No', 'Field is Mandatory');
    } else if (this.contactData1.qualification === '') {
      handleError('Qualification', 'Field is Mandatory');
    } else if (this.contactData1.position === '') {
      handleError('Position', 'Field is Mandatory');
    } else {
      this.submitJobApply();
    }
  }
  submitJobApply() {
    debugger
    // console.log("contactData1=",this.contactData1);
    this.formData.append('JobId', this.jobId);
    this.formData.append('Position', this.contactData1.position);
    this.formData.append('FullName', this.contactData1.fullName);
    this.formData.append('EmailId', this.contactData1.emailId);
    this.formData.append('MobileNo', this.contactData1.mobileNo);
    this.formData.append('Qualification', this.contactData1.qualification);

    //   for (var pair of this.formData.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]);
    // }

    this.contactService.applyJob(this.formData).subscribe(
      (data: any) => {
        // console.log("data=",data);
        handleSuccess('Success', data);
        this.handleClear1();
        // this.router.navigateByUrl('/Career');
        // window.open('/Career','_self');
        // window.location.reload();
      },
      (err: any) => {
        // console.log("err=",err);
        handleError('Oops something went wrong', 'Please try again later');
      }
    );
  }

  handleClear1() {
    this.formData.delete('JobId');
    this.formData.delete('Position');
    this.formData.delete('FullName');
    this.formData.delete('EmailId');
    this.formData.delete('MobileNo');
    this.formData.delete('Qualification');
    this.formData.delete('file');

    this.contactData1.position = '';
    this.contactData1.fullName = '';
    this.contactData1.emailId = '';
    this.contactData1.mobileNo = '';
    this.contactData1.qualification = '';
    let txtFileUpload: any = document.getElementById('resume');
    //  console.log("txtFileUpload",txtFileUpload);
    txtFileUpload.current.value = null;
  }

  changeFile(fileInput: any) {
    let b: any;
    b = this.selectFile(fileInput);

    if (b == 1) {
      handleError('Oops something went wrong', 'Please try again later');
    } else {
      this.formData = new FormData();
      for (let j = 0; j < fileInput.target.files.length; j++) {
        this.fileUpload = <File>fileInput.target.files[j];
        this.formData.append('file', this.fileUpload);
      }
    }
  }
  selectFile(event: any) {
    var mimeType = event.target.files[0].type; //type
    if (mimeType.match(/application\/(pdf|doc|docx)$ */) == null) {
      return 1;
    }
    return 0;
  }

}


