import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TwoWheelerComponent } from './two-wheeler/two-wheeler.component';
import { CarInsuranceComponent } from './car-insurance/car-insurance.component';
import { TwoWheelerQuotationComponent } from './two-wheeler-quotation/two-wheeler-quotation.component';
import { TwowheelerProposalComponent } from './twowheeler-proposal/twowheeler-proposal.component';
import { PaymentfailedComponent } from './paymentfailed/paymentfailed.component';
import { PaymentsuccessComponent } from './paymentsuccess/paymentsuccess.component';
import { CompareComponent } from './compare/compare.component';

import { ErrorPageComponent } from './error-page/error-page.component';

const routes: Routes = [
  { path: 'car-Insurence', component: CarInsuranceComponent },
  { path: 'two-wheeler', component: TwoWheelerComponent },
  { path: 'two-wheeler-quotation', component: TwoWheelerQuotationComponent },
  { path: 'proposal', component: TwowheelerProposalComponent },
  {
    path: 'paymentsuccess',
    component: PaymentsuccessComponent
  },
  {
    path: 'paymentfailed',
    component: PaymentfailedComponent
  },{
    path: 'compare',
    component: CompareComponent
  },
  {
    path:'error-page',
    component:ErrorPageComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MotorInsuranceRoutingModule { }
