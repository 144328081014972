import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HealthservicesService } from 'src/app/services/healthservices.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  blogData:any=[];

  constructor(private router:Router,private contactService:HealthservicesService) { }

  ngOnInit() {
    this.fetchBlogList();
  }

  fetchBlogList(){
    let val ={
      flag:'All'
    }
    this.contactService.fetchBlog(val).subscribe((data: any) => { 
        // console.log("data=",data);
        this.blogData = data;
      },
      (err:any) => {
        // console.log("positionData err=",err);
        // handleError("Oops something went wrong","Please try again later");
      }
    );
  }

  particularBlog(blog:any){
    // console.log("Blog=",blog);
    localStorage.setItem('blogObj',blog);
    this.router.navigate([`/blog/${blog.BlogId}`], { state: {blogDetail:blog} });
    // this.router.navigate(['/Blog'], { queryParams: { BlogId: blog.BlogId } });
  }

  replaceImgUrl(urlVal:string){

    if(urlVal){
      let splitVal=urlVal.split('/');
      // console.log("splitVal=",splitVal);
      let arrval=splitVal.splice(1);
      // console.log("arrval=",arrval);
  
      let UrlSt='https://uat.bimaraja.com:3452/'+arrval.join('/');
      // console.log("UrlSt=",UrlSt);
      
      return UrlSt;
    }
    
    return '';
  }

}
